"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrintTripButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Print = _interopRequireDefault(require("@material-ui/icons/Print"));

var _greenState = require("@symbiotic/green-state");

var _BannerButton = require("../components/Button/BannerButton");

var _WanderLostNavigator = require("../urls/WanderLostNavigator");

var _device = require("../framework/device");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* globals window:readonly */


var PrintTripButton = function PrintTripButton(_ref) {
  var trip = _ref.trip;
  var navigator = (0, _greenState.useInstance)(_WanderLostNavigator.WanderLostNavigator);
  var printRoute = navigator.tripPrint({
    tripId: trip.tripId
  }); // don't show print button on ipad (printing crashes open layers)

  if ((0, _device.isTouchDevice)()) {
    return null;
  } // don't show print button if we're already on the trip print page


  if (window.location.href.includes(printRoute.url())) {
    return null;
  }

  return _react.default.createElement(_BannerButton.BannerButton, {
    onClick: function onClick() {
      return printRoute.go();
    },
    name: "print-trip",
    tooltip: "Print entire trip",
    icon: _react.default.createElement(_Print.default, null),
    label: "Print"
  });
};

exports.PrintTripButton = PrintTripButton;
PrintTripButton.propTypes = {
  trip: _propTypes.default.object.isRequired
};