"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Heading = exports.Divider = exports.MenuButton = exports.MenuItem = exports.Menu = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _core = require("@material-ui/core");

var _MenuList = _interopRequireDefault(require("@material-ui/core/MenuList"));

var _MenuItem = _interopRequireDefault(require("@material-ui/core/MenuItem"));

var _Divider = _interopRequireDefault(require("@material-ui/core/Divider"));

var _greenState = require("@symbiotic/green-state");

var _WanderLostURLBuilder = require("../urls/WanderLostURLBuilder");

var _typography = require("../typography");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles(theme) {
  return {
    menu: {
      minWidth: 200,
      maxWidth: 400
    },
    heading: {
      fontSize: 10,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      padding: "8px ".concat(theme.spacing(2), "px 0"),
      margin: 0
    },
    divider: {},
    item: {
      fontSize: 14,
      padding: "2px ".concat(theme.spacing(2), "px"),
      '&:link, &:visited': {
        color: theme.palette.text.primary
      },
      '&:hover, &:active': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent'
      }
    },
    button: {
      marginBottom: theme.spacing(1),
      '&:hover, &:active': {
        backgroundColor: 'transparent'
      }
    }
  };
};

var Menu = function Menu(_ref) {
  var classes = _ref.classes,
      children = _ref.children,
      _ref$nav = _ref.nav,
      nav = _ref$nav === void 0 ? 'secondary' : _ref$nav;
  return _react.default.createElement(_MenuList.default, {
    className: classes.menu,
    dense: true,
    component: "div",
    nav: nav
  }, children);
};

exports.Menu = Menu;
Menu.propTypes = {
  children: _propTypes.default.node.isRequired,
  classes: _propTypes.default.object.isRequired,
  nav: _propTypes.default.string
};
exports.Menu = Menu = (0, _core.withStyles)(styles)(Menu); // eslint-disable-next-line react/display-name

var Link = _react.default.forwardRef(function (props, ref) {
  return _react.default.createElement(_reactRouterDom.NavLink, _extends({
    innerRef: ref
  }, props));
});

var MenuItem = function MenuItem(_ref2) {
  var classes = _ref2.classes,
      children = _ref2.children,
      to = _ref2.to,
      routeParams = _ref2.routeParams;
  var urlBuilder = (0, _greenState.useInstance)(_WanderLostURLBuilder.WanderLostURLBuilder);
  return _react.default.createElement(_MenuItem.default, {
    button: true,
    component: Link,
    to: urlBuilder[to](routeParams).path(),
    navtarget: to,
    className: classes.item,
    "data-type": "sub-nav-item"
  }, children);
};

exports.MenuItem = MenuItem;
MenuItem.propTypes = {
  children: _propTypes.default.node.isRequired,
  classes: _propTypes.default.object.isRequired,
  to: _propTypes.default.string.isRequired,
  routeParams: _propTypes.default.object
};
exports.MenuItem = MenuItem = (0, _core.withStyles)(styles)(MenuItem);

var MenuButton = function MenuButton(_ref3) {
  var classes = _ref3.classes,
      children = _ref3.children,
      onClick = _ref3.onClick;
  return _react.default.createElement(_MenuItem.default, {
    button: true,
    className: classes.button,
    onClick: onClick
  }, children);
};

exports.MenuButton = MenuButton;
MenuButton.propTypes = {
  children: _propTypes.default.node.isRequired,
  classes: _propTypes.default.object.isRequired,
  onClick: _propTypes.default.func
};
exports.MenuButton = MenuButton = (0, _core.withStyles)(styles)(MenuButton);

var Divider = function Divider(_ref4) {
  var classes = _ref4.classes;
  return _react.default.createElement(_Divider.default, {
    className: classes.divider
  });
};

exports.Divider = Divider;
Divider.propTypes = {
  classes: _propTypes.default.object.isRequired
};
exports.Divider = Divider = (0, _core.withStyles)(styles)(Divider);

var Heading = function Heading(_ref5) {
  var classes = _ref5.classes,
      children = _ref5.children;
  return _react.default.createElement(_typography.P, {
    className: classes.heading
  }, children);
};

exports.Heading = Heading;
Heading.propTypes = {
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired
};
exports.Heading = Heading = (0, _core.withStyles)(styles)(Heading);