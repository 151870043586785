import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InjectLoadAndSubscribe, TripMealsState, Button, Section } from '@wanderlost-sdk/components';
import { TripMealNotes } from './TripMealNotes';
import { Paper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = () => ({
    heading: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    mealTitle: {
        whiteSpace: 'pre-line'
    },
    '@media print': {
        paper: {
            boxShadow: 'none',
            marginTop: '-20px'
        }
    }
});

export class TripMealsDaySummary extends Component {
    static propTypes = {
        trip: PropTypes.object.isRequired,
        dayNumber: PropTypes.number.isRequired,
        canEdit: PropTypes.bool,
        gutterBottom: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    }

    buildColumns = (mealTypes) => mealTypes.map((mealType, index) => ({
        label: mealType.label,
        width: index <= 2 ? '20%' : '15%'
    }));

    render() {
        const { classes, trip, dayNumber, gutterBottom = false, canEdit = false } = this.props;

        return (
            <InjectLoadAndSubscribe diKey={TripMealsState} methodName="ensureLoaded">
                {({ getMealSummaryState }) => {
                    const { datesWithMeals, mealTypes } = getMealSummaryState().get();
                    const columns = this.buildColumns(mealTypes);

                    // Without this we see an error when you shorten/lengthen a trip
                    // Might be a race condition we could fix but not worth investing in
                    if (!datesWithMeals[dayNumber - 1]) {
                        return null;
                    }

                    const { meals } = datesWithMeals[dayNumber - 1];

                    return (
                        <Section
                            title="Meals"
                            titleActions={
                                canEdit &&
                                    <Button to="tripMealsManage" routeParams={{tripId: trip.tripId, from: 'tripDetails' }} color="primary" variant="contained" size="small" name="manage-meals">Plan Meals</Button>
                            }
                            noDivider
                            gutterBottom={gutterBottom}
                        >
                            <Paper className={classes.paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(({ label, width, ...rest }) => (
                                                <TableCell key={label} style={{ width: width }} {...rest}>{label}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow name="meal-summary">
                                            {meals.map(meal => (
                                                <TableCell key={meal.mealTypeId}>
                                                    {Boolean(meal.owner) &&
                                                        <div><strong>{meal.isMine ? 'You' : meal.owner.name}</strong></div>
                                                    }
                                                    {Boolean(meal.title) &&
                                                        <span className={classes.mealTitle}>{meal.title}</span>
                                                    }
                                                    {Boolean(meal.notesHTML) &&
                                                        <TripMealNotes meal={meal} />
                                                    }
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Section>
                    );
                }}
            </InjectLoadAndSubscribe>
        );
    }

}

TripMealsDaySummary = withStyles(styles)(TripMealsDaySummary);
