import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'compose-function';
import { H3, P, Section, Display, Button, InjectTogglable } from '@wanderlost-sdk/components';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withDependencies } from '@symbiotic/green-state';
import { WanderLostNavigator} from '@wanderlost-sdk/components';
import { hasAction } from '@wanderlost-sdk/core';

export class TripSettings extends Component {
    static propTypes = {
        navigator: PropTypes.instanceOf(WanderLostNavigator).isRequired,
        trip: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired
    }

    state = {
        error: null
    }

    handleDelete = async (e) => {
        const { trip, navigator } = this.props;

        e.preventDefault();

        this.setState({ error : null });

        try{
            await trip.delete();
            navigator.tripList().go();
        }
        catch(err){
            if (err.displayMessage){
                this.setState({ error: err.displayMessage });
            }
            else {
                this.setState({ error: `hmm... something went wrong, please try again` });
                // rethrow to sentry
                throw err;
            }
        }
    };

    render() {
        const { trip, classes } = this.props;
        const { error } = this.state;
        const canDelete = Boolean(trip) && hasAction(trip, 'self', 'delete');

        if (!canDelete) {
            return null;
        }

        return (
            <Display screen>
                <Section
                    title="Settings"
                >
                    <Grid container style={{ alignItems: 'center', marginTop: '1em' }}>
                        <Grid item sm={8}>
                            <H3>Delete Trip</H3>
                            <P>Deleting a trip is permanent. This will remove all trip data added by anyone invited to the trip. This includes routes, meals, gear, etc.</P>
                            {Boolean(error) &&
                                <P style={{ color: 'red' }}>{error}</P>
                            }
                        </Grid>
                        <Grid item sm={4} style={{ textAlign: 'right' }}>
                            <InjectTogglable initialValue={false}>
                                {({ isOn: shouldConfirm, on: showConfirmation, off: cancel }) => (
                                    shouldConfirm ?
                                        <>
                                            <Button size="small" onClick={cancel}>Cancel</Button>
                                            <Button size="small" variant="outlined" className={classes.confirmDeleteButton} name="confirm-delete-trip" onClick={this.handleDelete}>Yes, Permanently Delete Trip</Button>
                                        </> :
                                        <Button size="small" variant="outlined" onClick={showConfirmation} name="delete-trip">Delete Trip</Button>
                                )}
                            </InjectTogglable>
                        </Grid>
                    </Grid>
                </Section>
            </Display>
        );
    }
}

TripSettings = compose(
    withDependencies({
        navigator: WanderLostNavigator
    }),
    withStyles(theme => ({
        confirmDeleteButton: {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            '&:hover': {
                color: theme.palette.background.paper,
                backgroundColor: theme.palette.error.main
            }
        }
    }))
)(TripSettings);
