import React from 'react';
import { ViewAllTripsButton } from './trips/ViewAllTripsButton';
import { UpcomingTripsList } from './trips/UpcomingTripsList';
import { Layout, SectionV2 } from '@wanderlost-sdk/components';
import { CreateTripBannerForm } from './trips/CreateTripBannerForm';
import { Grid } from '@material-ui/core';
import { GPXImport } from '@wanderlost-sdk/cartographer';
import { ChildContainerContext } from './ChildContainerContext';
import { LandingPageCTAs } from './LandingPageCTAs';

export let LandingPage = () => (
    <Layout
        page="landing-page"
        BannerProps={{
            size: 'large',
            center: true,
            title: 'Plan Memorable Adventures with Friends',
            subTitle: 'Plan a trip to start sharing routes, gear and meals',
            renderContent: () => (
                <div style={{ marginTop: '32px' }}>
                    <CreateTripBannerForm />
                </div>
            )
        }}
    >
        <SectionV2
            title={`Your Upcoming Trips`}
            iconImageURL={`/images/section-icons/trips.svg`}
            actions={
                <ViewAllTripsButton />
            }
        >
            <Grid container spacing={3}>
                <Grid item xs={6} sm={7} md={8} lg={9}>
                    <UpcomingTripsList />
                </Grid>
                <Grid xs={6} sm={5} md={4} lg={3} item style={{ marginLeft: 'auto' }}>
                    <div style={{ marginLeft: 'auto', maxWidth: 250 }}>
                        <ChildContainerContext>
                            <GPXImport
                                onSuccess={({ trip }) => {
                                    window.location.hash = trip ? `/trips/${trip.tripId}/map` : `/maps`;
                                }}
                            />
                        </ChildContainerContext>
                    </div>
                </Grid>
            </Grid>
        </SectionV2>
        <LandingPageCTAs />
    </Layout>
);

