import React from 'react';
import { TripBreadcrumbs, TripBreadcrumbItem, TripPageHeading } from '@wanderlost-sdk/components';
import { InjectTripDetails } from '../trips/InjectTripDetails';
import { TripPackingLists } from './TripPackingLists';
import { TripDetailsLayout } from '../trips/TripDetailsLayout';

export const TripPackingListPage = () => (
    <InjectTripDetails>
        {({ trip }) => (
            <TripDetailsLayout
                trip={trip}
                page="trip-packing-lists-page"
                breadcrumbs={
                    <TripBreadcrumbs trip={trip} routeName="tripDetails">
                        <TripBreadcrumbItem>Packing Lists</TripBreadcrumbItem>
                    </TripBreadcrumbs>
                }
            >
                <TripPageHeading>Packing Lists</TripPageHeading>

                <TripPackingLists />
            </TripDetailsLayout>
        )}
    </InjectTripDetails>
);
