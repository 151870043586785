"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HelpTextDivider = void 0;

var _react = _interopRequireDefault(require("react"));

var _Divider = _interopRequireDefault(require("@material-ui/core/Divider"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var HelpTextDivider = function HelpTextDivider() {
  return _react.default.createElement(_Divider.default, {
    light: true,
    style: {
      margin: '0.4rem 0'
    }
  });
};

exports.HelpTextDivider = HelpTextDivider;