import React from 'react';
import PropTypes from 'prop-types';
import { useInstance } from '@symbiotic/green-state';
import { Theme } from '../theme';
import { CartographerComponent } from '@wanderlost-sdk/cartographer';
import { MapContainerContext } from './MapContainerContext';

// TODO: There is little reason for this component to exist
// Cartographer should encapsulate...
// - getting height from theme
// - varying features loading indicator position when drawer is open
// The rest of the props are just pass throughs
const Map = ({ onMount, toolbarBreadcrumb, toolbarDefaultTitle }) => {
    const theme = useInstance(Theme);

    return (
        <CartographerComponent
            toolbarBreadcrumb={toolbarBreadcrumb}
            toolbarDefaultTitle={toolbarDefaultTitle}
            height={theme.wanderlost.mapHeight}
            onMount={onMount}
        />
    );
};

Map.propTypes = {
    onMount: PropTypes.func,
    toolbarBreadcrumb: PropTypes.node,
    toolbarDefaultTitle: PropTypes.node,
};

const MapWithContainer = (props) => {
    return (
        <MapContainerContext>
            <Map {...props} />
        </MapContainerContext>
    );
};

export {
    MapWithContainer as Map
};
