import React from 'react';
import PropTypes from 'prop-types';
import { InjectAndSubscribe } from '@symbiotic/green-state';
import { TripDetailsState } from './TripDetailsState';

export const InjectTripDetails = ({ children }) => (
    <InjectAndSubscribe diKey={TripDetailsState}>
        {({ trip, activeDayNumber, setActiveDayNumber }) => children({ trip, activeDayNumber, setActiveDayNumber })}
    </InjectAndSubscribe>
);

InjectTripDetails.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]).isRequired
};
