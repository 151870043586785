"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripFormDialog = void 0;

var _react = _interopRequireDefault(require("react"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _components = require("../components");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _TripForm = require("./TripForm");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var TripFormDialog = function TripFormDialog(_ref) {
  var title = _ref.title,
      onCancel = _ref.onCancel,
      rest = _objectWithoutProperties(_ref, ["title", "onCancel"]);

  return _react.default.createElement(_TripForm.TripForm, _extends({}, rest, {
    noRenderSubmit: true
  }), function (_ref2) {
    var submitButton = _ref2.submitButton,
        formContent = _ref2.formContent;
    return _react.default.createElement(_components.Dialog, {
      isOpen: true,
      title: title,
      actionsComponent: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Button.default, {
        onClick: onCancel,
        tabIndex: "-1"
      }, "Cancel"), submitButton)
    }, formContent);
  });
};

exports.TripFormDialog = TripFormDialog;
TripFormDialog.propTypes = {
  title: _propTypes.default.node,
  onCancel: _propTypes.default.func.isRequired
};