import React from 'react';
import { QuoteIcon } from '../icons';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { P, Display } from '@wanderlost-sdk/components';

export let Quote = ({ classes, content, attribution }) => (
    <Display screen>
        <div className={classes.container}>
            <QuoteIcon classes={{ root: classes.icon }} color="secondary" />
            <P color="textSecondary" className={classes.content}>
                {content}
            </P>
            <P className={classes.attribution}>
                {attribution}
            </P>
        </div>
    </Display>
);

Quote.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.node,
    attribution: PropTypes.node,
};

Quote = withStyles(theme => ({
    container: {
        textAlign: 'center',
        padding: `0 ${theme.spacing(2)}px`
    },
    content: {
        fontSize: '1.8rem',
        fontStyle: 'italic',
        lineHeight: 1.2,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    attribution: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: theme.palette.tertiary.dark
    },
    icon: {
        opacity: 0.5,
        width: 100,
        height: 100,
    }
}))(Quote);
