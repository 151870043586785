"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Section = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    sectionTitleContainer: {
      marginBottom: "".concat(theme.spacing(1), "px"),
      display: 'flex',
      alignItems: 'flex-end'
    },
    sectionTitle: {
      flexGrow: 1
    }
  };
};

var Section = function Section(_ref) {
  var actionsComponent = _ref.actionsComponent,
      children = _ref.children,
      classes = _ref.classes,
      title = _ref.title,
      rest = _objectWithoutProperties(_ref, ["actionsComponent", "children", "classes", "title"]);

  return _react.default.createElement("div", rest, _react.default.createElement(_Typography.default, {
    variant: "h4",
    color: "textPrimary",
    className: classes.sectionTitleContainer
  }, _react.default.createElement("span", {
    className: classes.sectionTitle
  }, title), actionsComponent), children);
};

exports.Section = Section;
Section.propTypes = {
  title: _propTypes.default.node.isRequired,
  children: _propTypes.default.node.isRequired,
  actionsComponent: _propTypes.default.node,
  classes: _propTypes.default.object.isRequired
};
exports.Section = Section = (0, _withStyles.default)(styles)(Section);