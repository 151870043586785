"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProgressBar = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _LinearProgress = _interopRequireDefault(require("@material-ui/core/LinearProgress"));

var _styles = require("@material-ui/core/styles");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ProgressBar = function ProgressBar(_ref) {
  var classes = _ref.classes,
      value = _ref.value;
  return _react.default.createElement(_LinearProgress.default, {
    variant: "determinate",
    value: value,
    classes: classes
  });
};

exports.ProgressBar = ProgressBar;
ProgressBar.propTypes = {
  classes: _propTypes.default.object.isRequired,
  value: _propTypes.default.number.isRequired
};
exports.ProgressBar = ProgressBar = (0, _styles.withStyles)(function (theme) {
  return {
    root: {
      border: "1px solid ".concat(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.light,
      height: '15px',
      borderRadius: '9999px'
    },
    bar: {
      backgroundColor: theme.palette.primary.main
    }
  };
})(ProgressBar);