import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InjectLoadAndSubscribe, TripMealsState, MealWithRecipeForm, Button, SectionV2, H3 } from '@wanderlost-sdk/components';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
    mealType: {},
    meal: {
        paddingBottom: `${theme.spacing(2)}px`
    },
});

export class TripMealsDayPlan extends Component {
    static propTypes = {
        trip: PropTypes.object.isRequired,
        dayNumber: PropTypes.number.isRequired,
        canEdit: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    }

    render() {
        const { classes, trip, dayNumber, canEdit = false } = this.props;

        return (
            <InjectLoadAndSubscribe diKey={TripMealsState} methodName="ensureLoaded">
                {({ getMealSummaryState, teamMembers }) => {
                    const { datesWithMeals, mealTypes } = getMealSummaryState().get();

                    // Without this we see an error when you shorten/lengthen a trip
                    // Might be a race condition we could fix but not worth investing in
                    if (!datesWithMeals[dayNumber - 1]) {
                        return null;
                    }

                    const { meals } = datesWithMeals[dayNumber - 1];

                    return (
                        <SectionV2
                            title={`Day ${dayNumber} Meals`}
                            iconImageURL={'/images/section-icons/meals.svg'}
                            actions={
                                canEdit &&
                                    <Button to="tripMealsManage" routeParams={{tripId: trip.tripId, from: 'tripDetails' }} variant="outlined" size="small" name="manage-meals">Plan for Whole Trip</Button>
                            }
                        >
                            {meals.map((meal, i) => (
                                <div className={classes.meal} key={`${meal.dayNumber}-${meal.mealTypeId}`}>
                                    <H3 className={classes.mealType} variant="caption" gutterBottom={false}>{mealTypes[i].label}</H3>
                                    <MealWithRecipeForm
                                        meal={meal}
                                        mealType={mealTypes[i]}
                                        teamMembers={teamMembers}
                                        hideLabels={true}
                                        readOnly={!canEdit}
                                    />
                                </div>
                            ))}
                        </SectionV2>
                    );
                }}
            </InjectLoadAndSubscribe>
        );
    }

}

// eslint-disable-next-line no-class-assign
TripMealsDayPlan = withStyles(styles)(TripMealsDayPlan);
