"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isHTML5DateSupported = exports.isTouchDevice = void 0;
/* global window:readonly document:readonly */

var isTouchDevice = function isTouchDevice() {
  return 'ontouchstart' in window || window.navigator.MaxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;
};

exports.isTouchDevice = isTouchDevice;

var isHTML5DateSupported = function isHTML5DateSupported() {
  var input = document.createElement('input');
  input.setAttribute('type', 'date');
  input.setAttribute('value', 'test');
  return input.value !== 'test';
};

exports.isHTML5DateSupported = isHTML5DateSupported;