/* globals window:0 */
import { State } from '@symbiotic/green-state';
import { TripRoutesStateFactory } from '@wanderlost-sdk/cartographer';

export class TripDetailsState extends State {
    constructor({ trip, tripsState, tripRoutesStateFactory }) {
        super({ trip, activeDayNumber: 1 });

        const unsub = tripsState.subscribe(({ trips }) => {
            const _trip = trips.find(_trip => _trip.tripId === trip.tripId);
            this.setState({ trip: _trip });
        });

        const superDispose = this.dispose;
        this.dispose = () => {
            superDispose();
            unsub();
        };

        this.getTripRoutesState = () => {
            const tripRoutesState = tripRoutesStateFactory.create({ getTrip: () => this.get().trip });
            return tripRoutesState;
        };

        // set to zero to make "before you go" active
        this.setActiveDayNumber = dayNumber => {
            // Scroll to the top of the day content when the day changes
            window.scrollTo({ top: 240 });
            this.setState({ activeDayNumber: dayNumber });
        }

        this.onTripDatesChange = ({ durationInDays }) => {
            // If the active day is no longer valid (b/c the trip was shortened) go to the last day
            // This also makes sense since shortening the trip can move content onto the last day (such as routes)
            if (this.state.activeDayNumber > durationInDays) {
                this.setState({ activeDayNumber: durationInDays });
            }
        };
    }
}

export class TripDetailsStateFactory {
    static inject = () => [TripRoutesStateFactory];
    constructor(tripRoutesStateFactory) {
        this.create =  ({ trip, tripsState }) => {
            return new TripDetailsState({ trip, tripsState, tripRoutesStateFactory });
        };
    }
}
