"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColorPicker = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _Check = _interopRequireDefault(require("@material-ui/icons/Check"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ColorPicker = function ColorPicker(_ref) {
  var classes = _ref.classes,
      value = _ref.value,
      onSelect = _ref.onSelect;
  var colors = [{
    label: 'Default'
  }, {
    label: 'Magenta',
    value: '#A620C9'
  }, {
    label: 'Pink',
    value: '#E749D2'
  }, {
    label: 'Red',
    value: '#F11111'
  }, {
    label: 'Orange',
    value: '#FA4D16'
  }, {
    label: 'Yellow',
    value: '#F6BF26'
  }, {
    label: 'Green',
    value: '#35B6A1'
  }, {
    label: 'Dark Green',
    value: '#07927B'
  }, {
    label: 'Blue',
    value: '#09A3EF'
  }, {
    label: 'Dark Blue',
    value: '#3852DF'
  }, {
    label: 'Purple',
    value: '#7986CB'
  }, {
    label: 'Gray',
    value: '#616161'
  }];
  return _react.default.createElement("div", {
    name: "color-picker",
    tabIndex: -1,
    className: classes.container
  }, colors.map(function (_ref2) {
    var label = _ref2.label,
        thisValue = _ref2.value;
    return _react.default.createElement(_Button.default, {
      variant: "outlined",
      key: label,
      title: label,
      className: classes.button,
      style: {
        backgroundColor: thisValue
      },
      onClick: function onClick(e) {
        return onSelect(e, {
          label: label,
          value: thisValue
        });
      },
      "data-selected": thisValue === value
    }, thisValue === value ? _react.default.createElement(_Check.default, {
      className: (0, _classnames.default)(classes.check, _defineProperty({}, classes.checkDefault, !thisValue))
    }) : _react.default.createElement(_react.default.Fragment, null, "\xA0"), !thisValue && _react.default.createElement("div", {
      className: classes.slash
    }));
  }));
};

exports.ColorPicker = ColorPicker;
ColorPicker.propTypes = {
  value: _propTypes.default.string,
  onSelect: _propTypes.default.func.isRequired,
  classes: _propTypes.default.object.isRequired
};
exports.ColorPicker = ColorPicker = (0, _withStyles.default)(function () {
  return {
    container: {
      padding: '11px 16px',
      width: '190px',
      height: '80px',
      position: 'relative',
      '&:focus': {
        outline: 'none'
      }
    },
    button: {
      position: 'relative',
      margin: '3px',
      width: '20px',
      height: '20px',
      minWidth: 0,
      padding: 0,
      borderRadius: '50%',
      '&:hover': {
        width: '22px',
        height: '22px',
        margin: '2px'
      }
    },
    check: {
      width: '16px',
      height: '16px',
      color: 'white'
    },
    checkDefault: {
      color: 'black'
    },
    slash: {
      position: 'absolute',
      width: '14px',
      height: '14px',
      backgroundColor: '#D50000',
      clipPath: 'polygon(0 0, 10% 0, 100% 100%, 90% 100%)'
    }
  };
})(ColorPicker);