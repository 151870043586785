"use strict";

var _construct = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/construct");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InspectFeatureInteractionFactory = void 0;

var _overlays = require("../overlays");

var _FeatureAdapter = require("../FeatureAdapter");

var _Cartographer = require("../Cartographer");

var _ActiveFeatureManager = require("../tools/ActiveFeatureManager");

var _InspectFeature = require("./InspectFeature");

var _FeatureDetailsState = require("../state/FeatureDetailsState");

var _EventBus = require("../EventBus");

var _SupportedFeatureChecker = require("../tools/SupportedFeatureChecker");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var InspectFeatureInteractionFactory = function InspectFeatureInteractionFactory() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  _classCallCheck(this, InspectFeatureInteractionFactory);

  this.create = function (_ref) {
    var onEdit = _ref.onEdit;
    return _construct(_InspectFeature.InspectFeature, [{
      onEdit: onEdit
    }].concat(args));
  };
};

exports.InspectFeatureInteractionFactory = InspectFeatureInteractionFactory;

_defineProperty(InspectFeatureInteractionFactory, "inject", function () {
  return [_InspectFeature.InspectFeatureConfig, _overlays.OverlayManager, _FeatureAdapter.FeatureAdapter, _Cartographer.CartographerProvider, _ActiveFeatureManager.ActiveFeatureManager, _FeatureDetailsState.FeatureDetailsState, _EventBus.EventBus, _SupportedFeatureChecker.SupportedFeatureChecker];
});