export class PackingListFieldsBuilder {

    constructor() {

        this.build = ({ packingList, hideEmptyFields = false }) => {
            const { visibleFields = [], groupBy } = packingList;

            // if visibleFields not set, show all by default (e.g. for team lists)
            const isVisible = name => !visibleFields.length || visibleFields.includes(name);

            let fields = {
                name: { isVisible: true },
                qty: { isVisible: isVisible('qty') },
                notes: { isVisible: isVisible('notes') },
                weight: { isVisible: isVisible('weight') },
                isShared: { isVisible: isVisible('isShared') },
                category: { isVisible: isVisible('category') && groupBy !== 'category' },
                location: { isVisible: isVisible('location') && groupBy !== 'location' },
            };

            if (hideEmptyFields) {
                fields = hideEmptyFieldsFilter({ packingList, fields });
            }

            return fields;
        };

        const hideEmptyFieldsFilter = ({ packingList, fields }) => {
            const isEmpty = fieldName => packingList.items.filter(item => item[fieldName]).length === 0;

            Object.keys(fields)
                .filter(isEmpty)
                .forEach(emptyFieldName => {
                    fields[emptyFieldName].isVisible = false;
                })

            return fields;
        };

    }

}
