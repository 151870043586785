"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripDateStats = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _moment = _interopRequireDefault(require("moment"));

var _AccessTime = _interopRequireDefault(require("@material-ui/icons/AccessTime"));

var _core = require("@wanderlost-sdk/core");

var _typography = require("../typography");

var _core2 = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

var _pluralize = _interopRequireDefault(require("pluralize"));

var _components = require("../components");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles() {
  return {
    container: {
      display: 'flex',
      alignItems: 'center'
    }
  };
};

var TripDateStatsBase = function TripDateStatsBase(_ref) {
  var trip = _ref.trip,
      classes = _ref.classes,
      className = _ref.className,
      _ref$noIcon = _ref.noIcon,
      noIcon = _ref$noIcon === void 0 ? false : _ref$noIcon,
      other = _objectWithoutProperties(_ref, ["trip", "classes", "className", "noIcon"]);

  return _react.default.createElement(_typography.P, _extends({
    color: "textSecondary",
    className: (0, _classnames.default)(classes.container, className)
  }, other), !noIcon && _react.default.createElement(_AccessTime.default, {
    fontSize: "small",
    style: {
      marginRight: '5px'
    }
  }), formatDuration(trip), _react.default.createElement(_components.Display, {
    screen: true
  }, formatDaysUntil(trip)));
};

TripDateStatsBase.propTypes = {
  trip: _propTypes.default.object.isRequired,
  classes: _propTypes.default.object.isRequired,
  className: _propTypes.default.string,
  noIcon: _propTypes.default.bool
};
var TripDateStats = (0, _core2.withStyles)(styles)(TripDateStatsBase);
exports.TripDateStats = TripDateStats;

var formatDuration = function formatDuration(_ref2) {
  var durationInDays = _ref2.durationInDays;
  return "".concat(durationInDays, " ").concat((0, _pluralize.default)('day', durationInDays));
};

var formatDaysUntil = function formatDaysUntil(_ref3) {
  var startDate = _ref3.startDate;
  var numberOfDaysUntilTripStart = (0, _core.daysBetween)((0, _moment.default)().format('YYYY-MM-DD'), startDate);

  if (numberOfDaysUntilTripStart > 0) {
    return ", in ".concat(numberOfDaysUntilTripStart, " ").concat((0, _pluralize.default)('day', numberOfDaysUntilTripStart));
  }

  return '';
};