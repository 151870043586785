"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GlobalTrailStyler = void 0;

var _ZIndexManager = require("../ZIndexManager");

var _TrailStyler = require("./TrailStyler");

var _style = require("ol/style.js");

var _FeatureColorGenerator = require("./FeatureColorGenerator");

var _memoize = _interopRequireDefault(require("lodash/fp/memoize"));

var _EventBus = require("../EventBus");

var _hexToRgba = require("./hexToRgba");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var GlobalTrailStyler = function GlobalTrailStyler(_ref, zIndexManager, featureColorGenerator, eventBus) {
  var GLOBAL_COLORS = _ref.GLOBAL;

  _classCallCheck(this, GlobalTrailStyler);

  _defineProperty(this, "styleKey", 'GLOBAL');

  var colorGenerator = featureColorGenerator.create(GLOBAL_COLORS);
  /**
   * Changing opacity via layer.setOpacity doesn't work for the global trails layer
   * So we have to do this via rgba on the styles, so styleCache changes with opacity
   * See: https://github.com/openlayers/openlayers/issues/11284
   */

  var styleCache;
  eventBus.on('layer:opacityChanged', function (_ref2) {
    var layerId = _ref2.layerId,
        opacity = _ref2.opacity;

    if (layerId !== 'layer-global-trails') {
      return;
    } // We are coupled to this being called before we render the map
    // Otherwise styleCache won't be defined


    updateStyleCache({
      opacity: opacity
    });
  });

  var buildStyles = function buildStyles(_ref3) {
    var opacity = _ref3.opacity;
    return {
      HIGHLIGHT: function HIGHLIGHT(_ref4) {
        var zIndex = _ref4.zIndex;

        if (!opacity) {
          return [];
        }

        return [new _style.Style({
          stroke: new _style.Stroke({
            color: "rgba(255, 210, 0, ".concat(opacity * 0.35, ")"),
            width: 14
          }),
          zIndex: zIndex
        })];
      },
      DETAILED_OUTER_STROKE: (0, _memoize.default)(function (color) {
        return new _style.Stroke({
          color: (0, _hexToRgba.hexToRgbaString)(color, opacity),
          width: 8
        });
      }),
      DETAILED_INNER_STROKE: (0, _memoize.default)(function () {
        return new _style.Stroke({
          color: (0, _hexToRgba.hexToRgbaString)('#ffffff', opacity),
          width: 3
        });
      }),
      SIMPLIFIED_STYLE: (0, _memoize.default)(function (color) {
        return [new _style.Style({
          stroke: new _style.Stroke({
            color: (0, _hexToRgba.hexToRgbaString)(color, opacity),
            width: 8
          }),
          zIndex: 2
        }), new _style.Style({
          stroke: new _style.Stroke({
            color: (0, _hexToRgba.hexToRgbaString)('#ffffff', opacity),
            width: 3
          }),
          zIndex: 2
        })];
      }),
      UBER_SIMPLIFIED_STYLE: (0, _memoize.default)(function (color) {
        return [new _style.Style({
          stroke: new _style.Stroke({
            color: (0, _hexToRgba.hexToRgbaString)(color, opacity),
            width: 5
          }),
          zIndex: 2
        }), new _style.Style({
          stroke: new _style.Stroke({
            color: (0, _hexToRgba.hexToRgbaString)('#ffffff', opacity),
            width: 2
          }),
          zIndex: 2
        })];
      })
    };
  };

  var updateStyleCache = function updateStyleCache(_ref5) {
    var opacity = _ref5.opacity;
    styleCache = buildStyles({
      opacity: opacity
    });
    eventBus.emit('styles:changed');
  };

  this.getUberSimplifiedStyle = function (feature, _resolution) {
    var styleOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var styles = typeof styleOptions.opacity !== 'undefined' ? buildStyles({
      opacity: styleOptions.opacity
    }) : styleCache;
    return styles.UBER_SIMPLIFIED_STYLE(colorGenerator.getColor(feature));
  };

  this.getSimplifiedStyle = function (feature, _resolution) {
    var styleOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var styles = typeof styleOptions.opacity !== 'undefined' ? buildStyles({
      opacity: styleOptions.opacity
    }) : styleCache;
    return styles.SIMPLIFIED_STYLE(colorGenerator.getColor(feature));
  };

  this.getDetailedStyle = function (feature, _resolution) {
    var styleOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var styles = typeof styleOptions.opacity !== 'undefined' ? buildStyles({
      opacity: styleOptions.opacity
    }) : styleCache;
    var zIndex = zIndexManager.getZIndexForMapRender(feature);
    var featureColor = colorGenerator.getColor(feature);
    return [new _style.Style({
      stroke: styles.DETAILED_OUTER_STROKE(featureColor),
      zIndex: zIndex
    }), new _style.Style({
      stroke: styles.DETAILED_INNER_STROKE(featureColor),
      zIndex: zIndex
    })];
  };

  this.getHighlightStyle = function (_ref6) {
    var zIndex = _ref6.zIndex,
        styleOptions = _objectWithoutProperties(_ref6, ["zIndex"]);

    var styles = typeof styleOptions.opacity !== 'undefined' ? buildStyles({
      opacity: styleOptions.opacity
    }) : styleCache;
    return styles.HIGHLIGHT({
      zIndex: zIndex
    });
  };
};

exports.GlobalTrailStyler = GlobalTrailStyler;

_defineProperty(GlobalTrailStyler, "inject", function () {
  return [_TrailStyler.TrailColors, _ZIndexManager.ZIndexManager, _FeatureColorGenerator.FeatureColorGeneratorFactory, _EventBus.EventBus];
});