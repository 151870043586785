import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SectionV2, H3, P, Button } from '@wanderlost-sdk/components';
import { withStyles } from '@material-ui/core';

let CTACard = ({ classes, title, description, to, buttonText, imageURL }) => {
    return (
        <div className={classes.container} style={{ backgroundImage: `url(${imageURL})` }}>
            <div className={classes.wrapper}>
                <H3 className={classes.title}>{title}</H3>
                <P className={classes.description}>{description}</P>
                <Button to={to} variant="outlined" size="large" className={classes.button}>
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

CTACard.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    imageURL: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
};

CTACard = withStyles(theme => ({
    container: {
        width: '100%',
        textAlign: 'center',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        borderRadius: 8,
        backgroundColor: '#fff',
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.04)',
        padding: theme.spacing(4),
        position: 'relative',
        minHeight: 250,
        display: 'flex',
        alignItems: 'center',
        '&:after': {
            borderRadius: 8,
            content: "' '",
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.55)',
            zIndex: 1,
        }
    },
    wrapper: {
        width: '100%',
        position: 'relative',
        zIndex: 2,
    },
    title: {
        ...theme.wanderlost.CTACard.title,
    },
    description: {
        ...theme.wanderlost.CTACard.description,
    },
    button: {
        marginTop: theme.spacing(2)
    }
}))(CTACard);

export let LandingPageCTAs = ({ classes }) => {
    return (
        <SectionV2
            title={'Explore Wanderlost Content'}
            iconImageURL={'/images/section-icons/explore.svg'}
        >
            <Grid container spacing={3}>
                <Grid item xs={4} className={classes.item}>
                    <CTACard
                        title={'Maps'}
                        description={'Trails and points of interests to explore on your trips'}
                        imageURL={'/images/home/cta-maps.png'}
                        buttonText={'Go to Map'}
                        to={'maps'}
                    />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    <CTACard
                        title={'Recipes'}
                        description={'Breakfast, lunch, dinner, snacks and drinks'}
                        imageURL={'/images/home/cta-recipes.png'}
                        buttonText={'Explore Recipes'}
                        to={'recipes'}
                    />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    <CTACard
                        title={'Gear'}
                        description={'Keep track of gear and create lists based on recommendations'}
                        imageURL={'/images/home/cta-gear.png'}
                        buttonText={'Gear Lists'}
                        to={'gearLists'}
                    />
                </Grid>
            </Grid>
        </SectionV2>
    );
};

LandingPageCTAs.propTypes = {
    classes: PropTypes.object.isRequired,
};

LandingPageCTAs = withStyles(theme => ({
    item: {
        display: 'flex',
        alignItems: 'stretch'
    },
    description: {
        marginBottom: theme.spacing(4)
    }
}))(LandingPageCTAs);
