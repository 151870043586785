import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Layout, featureFlags } from '@wanderlost-sdk/components';
import withStyles from '@material-ui/core/styles/withStyles';

const supportedFlagNames = [
    'recipes',
    'packingLists',
    'mealRecipes',
];

export let FeatureFlagsPage = ({ classes }) => {
    const [flags, setFlags] = useState(featureFlags);
    const [dirty, setDirty] = useState(false);

    const save = () => {
        const enabledFlags = Object.keys(flags).filter(k => Boolean(flags[k]));
        window.__wlFeatureFlags.disable();
        window.__wlFeatureFlags.enable(...enabledFlags);
        setDirty(false);
        window.location.reload();
    };

    return (
        <Layout
            BannerProps={{
                title: 'Feature Flags',
                size: 'small'
            }}
        >
            <div className={classes.flags}>
                {supportedFlagNames.map(flagName => (
                    <div key={flagName}>
                        <FormControlLabel
                            label={flagName}
                            classes={classes}
                            control={
                                <Checkbox
                                    name={flagName}
                                    label={flagName}
                                    color="primary"
                                    checked={flags[flagName] || false}
                                    onChange={(e) => {
                                        setFlags({ ...flags, [flagName]: !flags[flagName] });
                                        setDirty(true);
                                    }}
                                />
                            }
                        />
                    </div>
                ))}
            </div>
            <Button variant="contained" color="primary" disabled={!dirty} onClick={save}>Save</Button>
        </Layout>
    );
}

FeatureFlagsPage.propTypes = {
    classes: PropTypes.object.isRequired
};

FeatureFlagsPage = withStyles(theme => ({
    flags: { margin: '40px 0' },
    label: { fontSize: '24px' }
}))(FeatureFlagsPage);
