"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripState = void 0;

var TripState = function TripState() {
  _classCallCheck(this, TripState);
};

exports.TripState = TripState;