import * as Sentry from '@sentry/browser';
import { getEnvFromHost } from '../EnvConfiguration';

/**
 * Set the sentry user so its attached to logged data
 * This is a no-op in a dev build as we don't initialize sentry
 */
export let setSentryUser = () => {};

const blacklistedFromSentry = (error, user) => {
    if (!error) { // if there is no error, then there is nothing to send to Sentry
        return true;
    }

    // as far as we can tell, the only way to get these errorCodes is
    // if the request is aborted (they are navigating away) or a CORS
    // misconfiguration. The aborted request is too noisy (false positives)
    // and a CORS misconfiguration will fail our tests, so we're going to ignore
    if ([ 'fetch.failed', 'fetch.missingBody' ].includes(error.errorCode)){
        // but... we DO want to know if we failed to fetch a tile from our own s3 bucket for a non-test user
        // (test users trigger this frequently with aborted requests due to moving too fast)
        const isTestUser = (user && user.id && user.id.indexOf('auto_test') > -1);
        if (isTestUser || !error.message.includes('GET https://tiles.')) {
            return true;
        }
    }

    // google analytics has started throwing this from inside analytics.js when a user performs rapid page transitions
    // since it's beyond our control, we are just suppressing it so we don't see it in sentry or slack
    if (error.message.includes('readyState') && error.stack.includes('analytics.js')) {
        return true;
    }

    return false;
};

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        environment: getEnvFromHost(),
        dsn: 'https://0bf64cc407904c4e9130bcc40899f56d@sentry.io/1312509',
        beforeSend : (sentryEvent, hint) => {
            if (blacklistedFromSentry(hint.originalException, sentryEvent.user)){
                return null;
            }
            return sentryEvent;
        }
    });

    setSentryUser = ({ userId, name }) => {
        Sentry.configureScope(
            scope => scope.setUser({ id: userId, name })
        )
    }
}
