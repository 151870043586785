import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from '@wanderlost-sdk/components';

export const PackingListsLayout = ({ children, page, breadcrumbs, ...props }) => (
    <Layout
        page={page}
        breadcrumbs={breadcrumbs}
        BannerProps={{
            size: 'small',
            title: 'Gear',
            imageURL: '/images/banners/gear.jpg',
            ...props
        }}
    >
        {children}
    </Layout>
);

PackingListsLayout.propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.string,
    breadcrumbs: PropTypes.node,
};

