import React from 'react';
import { InjectAndSubscribe } from '@symbiotic/green-state';
import { TripsState } from './TripsState';
import { TripsList } from './TripsList';
import { P } from '@wanderlost-sdk/components';

export const UpcomingTripsList = () => (
    <InjectAndSubscribe diKey={TripsState}>
        {({ upcomingTrips }) =>
            upcomingTrips.length > 0 ?
                <TripsList trips={upcomingTrips.slice(0,3)} /> :
                <P>You have no upcoming trips, you should plan a trip!</P>
        }
    </InjectAndSubscribe>
);
