import React, { useState } from 'react';
import cn from 'classnames';
import { useInstance, Subscribe } from '@symbiotic/green-state';
import { makeStyles, Checkbox, FormControlLabel, TextField, FormControl, FormLabel } from '@material-ui/core';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';
import { Layout, H2, Button } from '@wanderlost-sdk/components';
import { WanderLostPrincipal } from './WanderLostPrincipal';
import { FormState } from '@wanderlost-sdk/components';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center'
    },
    formFields: {
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
        marginBottom: theme.spacing(2),
        '&>*': {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1)
        }
    },
    checkbox: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25)
    },
    legend: {
        paddingBottom: theme.spacing(0.5)
    },
    buttons: {
        marginLeft: theme.spacing(18),
        marginRight: theme.spacing(18),
        textAlign: 'center'
    },
    submitButton: {
        width: '100%'
    },
    saving: {
        cursor: 'wait'
    },
    helperText: {
        color: theme.palette.secondary.main
    }
}));

export const UserConsentPage = () => {

    const principal = useInstance(WanderLostPrincipal);
    const apiClient = useInstance(WanderLostApiClient);

    const [saving, setSaving] = useState(false);

    const classes = useStyles();

    const isValid = (userInfo = {}) => {
        return userInfo.name && userInfo.name.trim().length > 0;
    }

    const submitConsent = async (formData) => {

        // combine additional fields into moreInfo property
        const { sources, detail, ...userInfo } = formData;
        userInfo.moreInfo = { detail, sources: Object.keys(sources) }

        if (!isValid(userInfo)) {
            return;
        }

        setSaving(true);

        const entry = await apiClient.entry();
        await apiClient.post(entry.links.consent, userInfo);

        // reload the app because setup might cause user data to change
        window.location.reload();

        // wait here forever while page reloads to avoid render jank
        await new Promise(() => {});
    };

    const sources = [
        { name: 'instagram', label: 'Instagram' },
        { name: 'youtube', label: 'YouTube' },
        { name: 'friend', label: 'Friend' },
        { name: 'event', label: 'Event' },
        { name: 'other', label: 'Other' }
    ];

    // user setup will have defaulted the name to the email for auth0 db users
    // in this case, we ignore that value and prompt for a real name
    const name = (principal.name === principal.email ? '' : principal.name);

    return (
        <Layout
            page="user-consent-page"
            BannerProps={{
                size: 'small',
                title: 'Thank you for joining the Wanderlost Project!',
                center: true,
                hideNav: true
            }}
            narrow
        >
            <div className={cn(classes.container, {[classes.saving] : saving})}>
                <H2 className={classes.heading}>Can you take a moment and tell us...</H2>

                <Subscribe to={() => new FormState({ ...principal, name, emailConsent: true, sources: {}, detail: '' })}>
                    {({ handleUpdates, isDirty, values }) => (
                        <form name="user-setup-form" onSubmit={() => submitConsent(values)}>

                            <div className={classes.formFields}>

                                {!name &&
                                    <TextField
                                        placeholder="Your name*"
                                        name="name"
                                        value={values.name}
                                        onChange={(e) => handleUpdates({ name: e.target.value })}
                                        helperText={isDirty && !values.name ? 'Please enter your name' : ''}
                                        FormHelperTextProps={{className: classes.helperText}}
                                        autoFocus
                                        fullWidth
                                    />
                                }

                                <FormControl component="fieldset" fullWidth={true} className={classes.fieldset}>
                                    <FormLabel className={classes.legend} component="legend">How did you find the Wanderlost Project?</FormLabel>
                                    {sources.map(({ name, label }) => (
                                        <FormControlLabel
                                            key={name}
                                            control={<Checkbox className={classes.checkbox} checked={Boolean(values.sources[name])} onChange={() => handleUpdates({ sources: { ...values.sources, [name]: !values.sources[name] } })} name={name} />}
                                            label={label}
                                        />
                                    ))}
                                </FormControl>

                                <FormControl component="fieldset" fullWidth={true} className={classes.fieldset}>
                                    <FormLabel className={classes.legend} component="legend">We'd appreciate any detail you want to share!</FormLabel>
                                    <TextField
                                        name="detail"
                                        value={values.detail}
                                        onChange={(e) => handleUpdates({ detail: e.target.value })}
                                        multiline
                                        variant="outlined"
                                        rows={2}
                                        size="small"
                                    />
                                </FormControl>

                                <FormControlLabel
                                    label="We'd love for you to be part of our community... can we email you?"
                                    name="email-consent"
                                    control={<Checkbox className={classes.checkbox} checked={values.emailConsent} onChange={(e) => handleUpdates({ emailConsent: e.target.checked })} />}
                                />

                            </div>

                            <div className={classes.buttons}>
                                <Button name="submit-consent" className={classes.submitButton} disabled={saving} size="large" color="primary" variant="contained" type="submit" onClick={() => { handleUpdates(); submitConsent(values); }}>Let's get started!</Button>
                            </div>
                        </form>
                    )}
                </Subscribe>
            </div>
        </Layout>
    );
};
