import { URLBuilder } from '@wanderlost-sdk/components';

export class WanderLostURLBuilderConfig {
    BASE_URL = ''
}

const buildFilterParams = (filters) => {
    if (!filters || filters === {}) {
        return '';
    }
    const params = [];
    for (const key of Object.keys(filters)) {
        const values = filters[key];
        for (const value of values) {
            params.push(`${key}=${value}`);
        }
    }
    return `?${params.join('&')}`;
}

export class WanderLostURLBuilder {
    static inject = [WanderLostURLBuilderConfig];

    constructor({ URL_BASE }) {
        const ensurePrefixedWithSlash = url => url[0] === '/' ? url : `/${url}`;

        const build = url => new URLBuilder(URL_BASE, ensurePrefixedWithSlash(url));

        this.tripDetails = ({ tripId }) => build(`/trips/${tripId}`);
        this.tripPackingLists = ({ tripId }) => build(`/trips/${tripId}/packing-lists/manage`);
        this.tripList = () => build('/trips');
        this.exploreTrips = () => build('/explore/trips');
        this.tripMap = ({ tripId, zoomToFolderId, addRouteToDayNumber }) => build(`/trips/${tripId}/map${zoomToFolderId ? `/${zoomToFolderId}` : ''}${addRouteToDayNumber ? `?addRouteToDayNumber=${addRouteToDayNumber}` : ''}`);
        this.tripMeals = ({ tripId }) => build(`/trips/${tripId}/meals`);
        this.tripMealsManage = ({ tripId, from }) => build(`/trips/${tripId}/meals/manage${from ? `?from=${from}` : ''}`);
        this.tripTeam = ({ tripId }) => build(`/trips/${tripId}/team`);
        this.tripPrint = ({ tripId }) => build(`/trips/${tripId}/print`);
        this.tripLog = ({ tripId }) => build(`/trips/${tripId}/log`);
        this.maps = () => build(`/maps`);
        this.meals = () => build(`/meals`);
        this.recipesHome = () => build('/recipes/home');
        this.recipes = (filters) => build('/recipes' + buildFilterParams(filters));
        this.recipeDetails = ({ recipeId }) => build(`/recipes/${recipeId}`);
        this.cookbookDetails = ({ cookbookId }) => build(`/cookbooks/${cookbookId}`);
        this.contentPage = ({ pageId }) => build(`/pages/${pageId}`);
        this.gearLists = () => build(`/gear-lists`);
        this.gearList = ({ packingListId }) => build(`/gear-lists/${packingListId}`);
        this.team = () => build(`/team`);
        this.settings = () => build(`/settings`);
    }
}
