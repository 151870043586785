"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WaypointTool = void 0;

var _react = _interopRequireDefault(require("react"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

var _HelpTextDivider = require("./HelpTextDivider");

var _AddLocation = _interopRequireDefault(require("@material-ui/icons/AddLocation"));

var _MarkerFeatureTool = require("./MarkerFeatureTool");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var WaypointToolConfig = function WaypointToolConfig() {
  _classCallCheck(this, WaypointToolConfig);

  _defineProperty(this, "featureTypeLabel", 'Waypoint');

  _defineProperty(this, "featureType", 'waypoint');

  _defineProperty(this, "arrowPosition", 'waypoint');

  _defineProperty(this, "defaultColor", '#000000');

  _defineProperty(this, "defaultProperties", {
    icon: 'marker',
    color: '#000000',
    name: '',
    url: '',
    description: ''
  });

  _defineProperty(this, "rememberProperties", ['color', 'icon']);

  _defineProperty(this, "previewProperties", ['color', 'icon']);

  _defineProperty(this, "canCopyToRoute", true);
};

var WaypointTool = function WaypointTool(toolFactory, config) {
  _classCallCheck(this, WaypointTool);

  _defineProperty(this, "toolId", 'waypoint');

  _defineProperty(this, "icon", _react.default.createElement(_AddLocation.default, null));

  _defineProperty(this, "tooltip", 'Add, edit & delete waypoints');

  _defineProperty(this, "helpTextComponent", function (_ref) {
    var text = _ref.classes.text;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Typography.default, {
      className: text
    }, "Click to add a waypoint"), _react.default.createElement(_HelpTextDivider.HelpTextDivider, null), _react.default.createElement(_Typography.default, {
      className: text
    }, "You will be prompted to enter information and select a color"), _react.default.createElement(_HelpTextDivider.HelpTextDivider, null), _react.default.createElement(_Typography.default, {
      className: text
    }, "Right click a waypoint to edit or delete"), _react.default.createElement(_HelpTextDivider.HelpTextDivider, null), _react.default.createElement(_Typography.default, {
      className: text
    }, "Click and drag to move (only after adding)"));
  });

  var tool = toolFactory.create({
    config: config
  });
  this.addToMap = tool.addToMap;
};

exports.WaypointTool = WaypointTool;

_defineProperty(WaypointTool, "inject", function () {
  return [_MarkerFeatureTool.MarkerFeatureToolFactory, WaypointToolConfig];
});