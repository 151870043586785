"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Button = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _greenState = require("@symbiotic/green-state");

var _urls = require("../../urls");

var _reactRouterDom = require("react-router-dom");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var Button = function Button(_ref) {
  var children = _ref.children,
      className = _ref.className,
      classes = _ref.classes,
      to = _ref.to,
      bare = _ref.bare,
      icon = _ref.icon,
      onClick = _ref.onClick,
      autoClick = _ref.autoClick,
      _ref$routeParams = _ref.routeParams,
      routeParams = _ref$routeParams === void 0 ? {} : _ref$routeParams,
      _ref$link = _ref.link,
      link = _ref$link === void 0 ? false : _ref$link,
      rest = _objectWithoutProperties(_ref, ["children", "className", "classes", "to", "bare", "icon", "onClick", "autoClick", "routeParams", "link"]);

  var navigator = (0, _greenState.useInstance)(_urls.WanderLostNavigator);
  var handleClick = onClick;
  var toRoute;

  if (to) {
    toRoute = navigator[to](routeParams); // Call the method now so if its a bad route name we'll error during render instead of onClick

    handleClick = function handleClick(e) {
      e.preventDefault();
      toRoute.go();
      onClick && onClick(e);
    };
  }

  var ButtonComponent;
  var ButtonProps = rest;

  if (link) {
    // must be Link (not 'a') or router won't wait for <Prompt> before updating url
    ButtonComponent = _reactRouterDom.Link;
    ButtonProps.to = toRoute ? toRoute.path() : '/';
    handleClick = onClick;
  } else if (icon) {
    ButtonComponent = _core.IconButton;
  } else if (bare) {
    ButtonComponent = _core.ButtonBase;
  } else if (ButtonProps.variant === 'fab') {
    ButtonComponent = _core.Fab;
    delete ButtonProps.variant;
  } else {
    ButtonComponent = _core.Button;
  }

  var _ref2 = (0, _react.useState)(false),
      _ref3 = _slicedToArray(_ref2, 2),
      autoClicked = _ref3[0],
      setAutoClicked = _ref3[1];

  (0, _react.useEffect)(function () {
    if (autoClick && !autoClicked) {
      setAutoClicked(true);
      handleClick();
    }
  }); // Ensure that handleClick increments click count to force re-render to fix safari sticky pseudo :hover

  var _ref4 = (0, _react.useState)(0),
      _ref5 = _slicedToArray(_ref4, 2),
      clickCount = _ref5[0],
      setClickCount = _ref5[1];

  var handleClickSafariFix = function handleClickSafariFix() {
    setClickCount(clickCount + 1);
    handleClick && handleClick.apply(void 0, arguments);
  };

  return _react.default.createElement(ButtonComponent, _extends({
    key: clickCount,
    onClick: handleClickSafariFix
  }, ButtonProps, {
    className: (0, _classnames.default)(className, _defineProperty({}, classes.bare, bare))
  }), children);
};

exports.Button = Button;
Button.propTypes = {
  to: _propTypes.default.string,
  bare: _propTypes.default.bool,
  link: _propTypes.default.bool,
  onClick: _propTypes.default.func,
  autoClick: _propTypes.default.bool,
  classes: _propTypes.default.object.isRequired,
  routeParams: _propTypes.default.object,
  children: _propTypes.default.node.isRequired,
  className: _propTypes.default.string
};
exports.Button = Button = (0, _core.withStyles)({
  bare: {
    color: 'inherit',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit'
  }
})(Button);