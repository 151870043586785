"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Section = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _typography = require("../../typography");

var _core = require("@material-ui/core");

var _Display = require("../Display");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var sectionStyles = function sectionStyles(theme) {
  return {
    container: {},
    noDivider: {
      '& $titleContainer': {
        paddingBottom: 0
      }
    },
    gutterBottom: {
      '&$container': {
        marginBottom: "".concat(theme.spacing(3), "px")
      }
    },
    titleContainer: {
      display: 'flex',
      paddingBottom: "".concat(theme.spacing(1), "px")
    },
    titleContent: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    title: {
      marginBottom: 0
    },
    subTitle: {
      marginLeft: "".concat(theme.spacing(2), "px")
    },
    content: {
      paddingTop: "".concat(theme.spacing(2), "px")
    }
  };
};

var Section = function Section(_ref) {
  var _ref2;

  var classes = _ref.classes,
      children = _ref.children,
      title = _ref.title,
      subTitle = _ref.subTitle,
      titleActions = _ref.titleActions,
      _ref$gutterBottom = _ref.gutterBottom,
      gutterBottom = _ref$gutterBottom === void 0 ? true : _ref$gutterBottom,
      _ref$noDivider = _ref.noDivider,
      noDivider = _ref$noDivider === void 0 ? false : _ref$noDivider,
      rest = _objectWithoutProperties(_ref, ["classes", "children", "title", "subTitle", "titleActions", "gutterBottom", "noDivider"]);

  return _react.default.createElement("div", _extends({
    className: (0, _classnames.default)(classes.container, (_ref2 = {}, _defineProperty(_ref2, classes.noDivider, noDivider), _defineProperty(_ref2, classes.gutterBottom, gutterBottom), _ref2))
  }, rest), _react.default.createElement("div", {
    className: classes.titleContainer
  }, _react.default.createElement("div", {
    className: classes.titleContent
  }, _react.default.createElement(_typography.H2, {
    className: classes.title
  }, title, subTitle && _react.default.createElement(_typography.SMALL, {
    className: classes.subTitle,
    color: "textSecondary"
  }, subTitle))), Boolean(titleActions) && _react.default.createElement(_Display.Display, {
    screen: true
  }, titleActions)), !noDivider && _react.default.createElement(_core.Divider, null), _react.default.createElement("div", {
    className: classes.content
  }, children));
};

exports.Section = Section;
Section.propTypes = {
  gutterBottom: _propTypes.default.bool,
  noDivider: _propTypes.default.bool,
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired,
  title: _propTypes.default.node.isRequired,
  subTitle: _propTypes.default.node,
  titleActions: _propTypes.default.node
};
exports.Section = Section = (0, _core.withStyles)(sectionStyles)(Section);