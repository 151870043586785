"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InitialFeaturesLoader = void 0;

var InitialFeaturesLoader = function InitialFeaturesLoader() {
  var load = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {
    return [];
  };

  _classCallCheck(this, InitialFeaturesLoader);

  this.load = load;
};

exports.InitialFeaturesLoader = InitialFeaturesLoader;