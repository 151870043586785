"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipeCarousel = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactCoverflow = _interopRequireDefault(require("react-coverflow"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _contentfulHelpers = require("./contentfulHelpers");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}
/* globals window:readonly */


var RecipeCarousel = function RecipeCarousel(_ref) {
  var recipes = _ref.recipes,
      meal = _ref.meal,
      _onClick = _ref.onClick,
      _ref$readOnly = _ref.readOnly,
      readOnly = _ref$readOnly === void 0 ? false : _ref$readOnly,
      _ref$activeIndex = _ref.activeIndex,
      activeIndex = _ref$activeIndex === void 0 ? 0 : _ref$activeIndex,
      classes = _ref.classes;

  // There is a bug in the CoverFlow component: it registers a resize listener, but doesn't remove it on unload!
  // So it continues to fire after leaving the page, causing "Cannot read property 'offsetWidth' of null" exceptions.
  // Browsers don't provide access to registered listeners, so we wrap window.addEventListener() globally so we can
  // intercept all listeners from all instances of the component on the page. (We assume the listener name is unique
  // enough to distinguish it from listeners that could be registered by other unrelated components.)
  if (window.addEventListener && !window.__carouselResizeListeners) {
    window.addEventListener = function (nativeHandler) {
      window.__carouselResizeListeners = [];
      return function (type, listener) {
        if (type === 'resize' && listener.name === 'bound updateDimensions') {
          window.__carouselResizeListeners.push(listener);
        }

        for (var _len = arguments.length, otherArgs = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
          otherArgs[_key - 2] = arguments[_key];
        }

        nativeHandler.apply(void 0, [type, listener].concat(otherArgs));
      };
    }(window.addEventListener);
  } // Define an "unmount" handler where we remove all the "resize" listeners captured by the wrapper above.
  // We don't remove the wrapper, just reset the listener array, so it can be reused if we return to this page.
  // NOTE: This relies on the assumption that all instances of the component will unmount at the same time.


  (0, _react.useEffect)(function () {
    return function () {
      var listeners = window.__carouselResizeListeners;
      window.__carouselResizeListeners = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = listeners[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var listener = _step.value;
          window.removeEventListener('resize', listener);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    };
  }, []);
  var recipeImages = [];
  var defaultImage = {
    title: _react.default.createElement("span", {
      className: "title-empty"
    }, "Select Recipe"),
    src: (0, _contentfulHelpers.buildCarouselUrl)(meal.images[0])
  };

  if (!readOnly && recipes.length < 3) {
    recipeImages.push(defaultImage);
  }

  if (recipes) {
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = recipes[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var recipe = _step2.value;
        var name = recipe.name,
            images = recipe.images;
        recipeImages.push({
          recipe: recipe,
          title: name,
          src: images ? (0, _contentfulHelpers.buildCarouselUrl)(images[0]) : defaultImage.src
        });
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }
  }

  return _react.default.createElement("span", {
    className: classes.recipe
  }, _react.default.createElement(_reactCoverflow.default, {
    width: "350",
    height: "200",
    displayQuantityOfSide: 1,
    enableScroll: false,
    active: activeIndex,
    clickable: false,
    currentFigureScale: 1.5,
    otherFigureScale: 1
  }, recipeImages.map(function (_ref2, index) {
    var recipe = _ref2.recipe,
        title = _ref2.title,
        src = _ref2.src;
    return _react.default.createElement("img", {
      key: index,
      title: title,
      role: "button",
      onClick: function onClick() {
        return _onClick({
          recipe: recipe,
          index: index
        });
      },
      "data-type": recipe ? 'recipe' : 'no-recipe',
      alt: _react.default.createElement("span", {
        className: "title"
      }, title),
      src: src
    });
  })));
};

exports.RecipeCarousel = RecipeCarousel;
RecipeCarousel.propTypes = {
  recipes: _propTypes.default.array,
  meal: _propTypes.default.object.isRequired,
  onClick: _propTypes.default.func.isRequired,
  activeIndex: _propTypes.default.number,
  readOnly: _propTypes.default.bool,
  classes: _propTypes.default.object.isRequired
}; // TODO: The next release of react-coverflow will provide easier access to style classes.
// (see: https://github.com/andyyou/react-coverflow/commit/0db93b3c7d6970e130cd3edd61070559c3415109)

exports.RecipeCarousel = RecipeCarousel = (0, _withStyles.default)(function (theme) {
  return _defineProperty({
    recipe: {
      // container
      '& >div >div >div': {
        backgroundColor: 'transparent',
        height: 'calc(100vw * 0.15)!important',
        maxHeight: '210px',
        minHeight: '152px'
      },
      '& .title': {
        display: 'block',
        width: '100%',
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: '12px',
        lineHeight: 1.2,
        fontWeight: 'bold',
        color: '#fff',
        textShadow: '0 0 4px rgba(0, 0, 0, 0.8)'
      },
      '& .title-empty': {
        textTransform: 'uppercase'
      },
      '& figure': {
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.04)',
        opacity: '1 !important',
        '-webkit-box-reflect': 'unset',
        '& img': {
          '& +div': {
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 40%)',
            padding: '22px 4px 12px',
            maxHeight: '100%',
            overflow: 'hidden',
            pointerEvents: 'none',
            display: 'flex',
            width: '100%',
            alignItems: 'flex-end'
          },
          '&[data-type=no-recipe]': {
            opacity: 0.3,
            '& +div': {
              top: 'calc(50% - 30px)',
              padding: 0,
              maxWidth: '60px',
              margin: '0 auto',
              background: 'none',
              alignItems: 'center'
            }
          },
          '&[data-type=no-recipe]:hover': {
            '& +div': {
              color: theme.palette.grey[900]
            }
          }
        }
      }
    }
  }, "@media screen and (min-width: ".concat(theme.breakpoints.values.lg, "px)"), {
    recipe: {
      '& .title': {
        fontSize: '14px'
      }
    }
  });
})(RecipeCarousel);