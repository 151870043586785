import React from 'react';
import PropTypes from 'prop-types';
import { P, Button, InjectLoadAndSubscribe } from '@wanderlost-sdk/components';
import { RouteCard } from './RouteCard';
import { TripRoutesState } from '@wanderlost-sdk/cartographer';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    routeList: {
        minWidth: '300px',
        paddingTop: 0,
    },
    route: {
        padding: `${theme.spacing(1)}px 0`
    },
    icon: {
        margin: 0
    }
});

export let TripRoutesList = ({ classes, trip: { tripId } }) => (
    <InjectLoadAndSubscribe diKey={TripRoutesState} methodName="ensureLoaded">
        {({ routes }) => {
            const hasRoutes = routes.length > 0;

            if (!hasRoutes) {
                return (
                    <P>
                        This trip doesn't have any routes yet, it looks like you need to{' '}
                        <Button to="tripMap" link routeParams={{ tripId }} name="add-route-button">add a route</Button>!
                    </P>
                );
            }

            return (
                <Grid container spacing={3} className={classes.container} name="route-list">
                    {routes.map(route => (
                        <Grid item md={4} key={route.routeId}>
                            <RouteCard route={route} tripId={tripId} />
                        </Grid>
                    ))}
                </Grid>
            );
        }}
    </InjectLoadAndSubscribe>
);

TripRoutesList.propTypes = {
    trip: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
}

TripRoutesList = withStyles(styles)(TripRoutesList);
