export class FocusManager {

    constructor() {

        const onFocusCallbacksById = {};

        const focusTimeoutMS = 500;

        this.focus = async id => {
            // Wait at least until next tick, consumer might have just done something to trigger a re-render
            // So lets make sure that any other synchronous code is run before we try
            await new Promise(resolve => setTimeout(resolve, 0));

            const startMS = Date.now();
            while (true) {
                const elapsedMS = Date.now() - startMS;
                if (elapsedMS > focusTimeoutMS) {
                    console.log(`Waited ${focusTimeoutMS} ms to focus ${id} but didn't find onFocusCallback`);
                    break;
                }

                const onFocusCallback = onFocusCallbacksById[id];
                if (onFocusCallback) {
                    onFocusCallback();
                    break;
                }

                // wait and try again
                await new Promise(resolve => setTimeout(resolve, 50));
            }
        };

        this.onFocus = (id, onFocus) => {
            onFocusCallbacksById[id] = onFocus;
        };

    }

}
