import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { IconButton, Checkbox, FormControlLabel, FormGroup, Divider, RadioGroup, Radio } from '@material-ui/core';
import { H4 } from '@wanderlost-sdk/components';

export let ListConfigurator = ({ visibleFields, setVisibleFields, groupBy, setGroupBy, classes }) => {
    const anchorRef = React.useRef(null);

    const [isOpen, setIsOpen] = React.useState(false);

    const handleClickAway = () => {
        // wait for event loop in case this is triggered by toggle icon button
        setTimeout(() => setIsOpen(false));
    };

    const handleChange = ({ e, name }) => {
        const value = e.target.checked;
        if (visibleFields.includes(name) && !value) {
            setVisibleFields(visibleFields.filter(f => f !== name));
        }
        if (!visibleFields.includes(name) && value) {
            setVisibleFields([ ...visibleFields, name ]);
        }
    };

    const Field = ({ name, label, disabled = false, classes }) => (
        <>
            <FormControlLabel
                label={label}
                control={<Checkbox
                    name={name}
                    disabled={disabled}
                    className={classes.item}
                    checked={visibleFields.includes(name)}
                    onChange={e => handleChange({ e, name })}
                />}
            />
        </>
    );

    return (
        <>
            <IconButton name="packing-list-configurator-button" title="Configure List" onClick={() => setIsOpen(!isOpen)} buttonRef={node => {anchorRef.current = node;}} disableRipple>
                <SettingsIcon />
            </IconButton>
            <Popper
                className={classes.popper}
                anchorEl={anchorRef.current}
                open={isOpen}
                placement="bottom-start"
                transition
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: 'center top' }}
                        >
                            <Paper name="packing-list-configurator" className={classes.paper}>

                                <div style={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <H4 className={classes.sectionHeading}>Group List By</H4>
                                    <IconButton onClick={() => setIsOpen(false)} name="close" variant="fab" size="small"><CloseIcon fontSize="small" /></IconButton>
                                </div>

                                <RadioGroup data-id="group-by" className={classes.sectionBody} value={groupBy || ''} onChange={e => setGroupBy(e.target.value || undefined)}>
                                    <FormControlLabel label="Category" value="category" control={<Radio className={classes.item} />} />
                                    <FormControlLabel label="Packed Location" value="location" control={<Radio className={classes.item}  />} />
                                    <FormControlLabel label="No Grouping" value={''} control={<Radio className={classes.item}  />} />
                                </RadioGroup>

                                <Divider />

                                <H4 className={classes.sectionHeading}>Show/Hide Columns</H4>

                                <FormGroup data-id="columns" className={classes.sectionBody}>
                                    <Field name="name" label="Name" disabled={true} classes={classes} />
                                    <Field name="category" label="Category" disabled={groupBy === 'category'} classes={classes} />
                                    <Field name="notes" label="Notes" classes={classes} />
                                    <Field name="location" label="Packed Location" disabled={groupBy === 'location'} classes={classes} />
                                    <Field name="weight" label="Weight" classes={classes} />
                                    <Field name="qty" label="Quantity" classes={classes} />
                                    <Field name="isShared" label="Shared with Team" classes={classes} />
                                </FormGroup>

                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

ListConfigurator.propTypes = {
    visibleFields: PropTypes.array.isRequired,
    setVisibleFields: PropTypes.func.isRequired,
    groupBy: PropTypes.string,
    setGroupBy: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

ListConfigurator = withStyles(theme => ({
    popper: {
        zIndex: 1101 // above app bar
    },
    paper: {
        padding: '5px'
    },
    sectionHeading: {
        margin: '12px 10px'
    },
    sectionBody: {
        padding: '0 10px 10px'
    },
    item: {
        paddingTop: '1px',
        paddingBottom: '1px'
    }
}))(ListConfigurator);
