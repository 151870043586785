"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentPageCard = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../typography");

var _urls = require("../urls");

var _greenState = require("@symbiotic/green-state");

var _contentfulHelpers = require("./contentfulHelpers");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    card: {
      '&:hover': {
        cursor: 'pointer'
      },
      '&:hover $title': {
        color: theme.palette.primary.main
      }
    },
    titleContainer: {
      height: '20px',
      margin: 0
    },
    title: {
      color: theme.typography.h1.color,
      fontWeight: 'bold',
      lineHeight: 1.3,
      fontSize: 14
    },
    shortDesc: {},
    authorBy: {
      fontWeight: 'normal'
    },
    image: {
      width: '100%'
    },
    content: {
      paddingTop: theme.spacing(1)
    }
  };
};

var ContentPageCardBase = function ContentPageCardBase(_ref) {
  var _ref$page = _ref.page,
      pageId = _ref$page.pageId,
      pageTitle = _ref$page.pageTitle,
      heroImage = _ref$page.heroImage,
      classes = _ref.classes;
  var navigator = (0, _greenState.useInstance)(_urls.WanderLostNavigator);
  return _react.default.createElement("div", {
    className: classes.card,
    "data-type": "page-card",
    onClick: function onClick() {
      navigator.contentPage({
        pageId: pageId
      }).go();
    }
  }, _react.default.createElement("img", {
    src: (0, _contentfulHelpers.buildCardImageUrl)(heroImage),
    alt: pageTitle,
    className: classes.image
  }), _react.default.createElement("div", {
    className: classes.content
  }, _react.default.createElement(_typography.P, {
    className: classes.title
  }, pageTitle)));
};

ContentPageCardBase.propTypes = {
  page: _propTypes.default.object.isRequired,
  classes: _propTypes.default.object.isRequired
};
var ContentPageCard = (0, _core.withStyles)(styles)(ContentPageCardBase);
exports.ContentPageCard = ContentPageCard;