import React from 'react';
import PropTypes from 'prop-types';
import Map from 'ol/Map.js';
import VectorSource from 'ol/source/Vector.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { withDependencies } from '@symbiotic/green-state';
import { BaseLayerFactory, LAYER_ZINDEXES } from '@wanderlost-sdk/cartographer';
import View from 'ol/View.js';
import 'ol/ol.css';
import { FeatureAdapter, ExtentAdapter } from '@wanderlost-sdk/cartographer';
import { RouteStyler } from '@wanderlost-sdk/cartographer/dist/styles/RouteStyler';

class RoutePreviewBase extends React.Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        baseLayerFactory: PropTypes.instanceOf(BaseLayerFactory).isRequired,
        featureAdapter: PropTypes.instanceOf(FeatureAdapter).isRequired,
        extentAdapter: PropTypes.instanceOf(ExtentAdapter).isRequired,
        routeStyler: PropTypes.instanceOf(RouteStyler).isRequired,
    };

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    async componentDidMount() {
        const target = this.mapRef.current;
        const { route } = this.props;
        const { extent } = route;

        const features = await this.props.featureAdapter.apiToFeatures(route.features);
        const olExtent = this.props.extentAdapter.apiToView(extent);

        let getZoom;
        const previewLayer = new VectorLayer({
            source: new VectorSource({
                features
            }),
            style: feature => {
                if (feature.get('type') !== 'route') {
                    return [];
                }

                return this.props.routeStyler.getPreviewStyle({ feature, zoom: getZoom() });
            }
        });
        previewLayer.setZIndex(LAYER_ZINDEXES.otherLayers);

        const map = new Map({
            layers: [
                previewLayer,
            ],
            target,
            controls: [],
            interactions: [],
            view: new View()
        });
        getZoom = () => map.getView().getZoom();
        await this.props.baseLayerFactory.create('maptiler-topographique').addToMap(map);

        map.getView().fit(
            olExtent,
            {
                padding: [30, 30, 30, 30],
                maxZoom: 16
            }
        );
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '200px',
                }}
                ref={this.mapRef}
            />
        );
    }

}

export const RoutePreview = withDependencies({
    baseLayerFactory: BaseLayerFactory,
    featureAdapter: FeatureAdapter,
    extentAdapter: ExtentAdapter,
    routeStyler: RouteStyler,
})(RoutePreviewBase);
