import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress } from '@material-ui/core';
import { H2, Page } from '@wanderlost-sdk/components';

const pageStyles = theme => ({
    page: {
        textAlign: 'center'
    },
    progress: {
        marginBottom: `${theme.spacing(2)}px`,
    }
});

// TODO: Better loading page with new layout?
export class LoadingPage extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        message: PropTypes.string
    }

    render() {
        const {
            classes,
            message = 'Loading',
            ...rest
        } = this.props;

        return (
            <Page
                {...rest}
                className={classes.page}
            >
                <CircularProgress className={classes.progress} />
                <H2 color="textSecondary">{message}</H2>
            </Page>
        );
    }

}

LoadingPage = withStyles(pageStyles)(LoadingPage);
