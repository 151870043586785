"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryStrings = void 0;

var _History = require("./History");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var QueryStrings = function QueryStrings(history) {
  var _this = this;

  _classCallCheck(this, QueryStrings);

  // gets the FIRST value with the given key
  this.get = function (key) {
    return new URLSearchParams(history.location.search).get(key);
  }; // true if query param is present (with any value)


  this.has = function (key) {
    return _this.get(key) !== null;
  }; // get all values with the given key, as an array


  this.getAll = function (key) {
    return new URLSearchParams(history.location.search).getAll(key);
  }; // adds a "key=value" pair (leaving any existing value(s) for the same key)


  this.append = function (key, value) {
    var qs = new URLSearchParams(history.location.search);
    qs.append(key, value);
    history.replace({
      search: qs.toString()
    });
  }; // remove values matching the given key (and optionally value)


  this.remove = function (key, value) {
    var qs = new URLSearchParams(history.location.search);

    if (value === undefined) {
      // delete entries matching key
      qs.delete(key);
    } else {
      // delete entries matching both key and value
      var values = qs.getAll(key);
      qs.delete(key);
      values.filter(function (v) {
        return v !== value;
      }).forEach(function (v) {
        qs.append(key, v);
      });
    }

    history.replace({
      search: qs.toString()
    });
  };
};

exports.QueryStrings = QueryStrings;

_defineProperty(QueryStrings, "inject", [_History.History]);