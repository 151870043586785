"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProjectionConfig = void 0;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var ProjectionConfig = function ProjectionConfig() {
  _classCallCheck(this, ProjectionConfig);

  _defineProperty(this, "viewProjection", 'EPSG:3857');

  _defineProperty(this, "apiProjection", 'EPSG:4326');
};

exports.ProjectionConfig = ProjectionConfig;