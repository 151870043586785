export class PackingListGrouper {
    constructor() {

        // map of sort order by groupBy
        const sortOrderMap = {};

        // map of visible placeholder ids by groupBy
        const visibleItemIdsMap = {};

        // helper for accessing and initializing the above maps
        const getOrCreateInMap = (map, groupBy, defaultEntry) => {
            const key = groupBy || undefined;
            if (!map[key]) {
                map[key] = defaultEntry;
            }
            return map[key];
        }

        // Remember the sort order so the item doesnt move
        this.onRename = ({ oldName, newName, groupBy }) => {
            const sortOrderByGroupName = getOrCreateInMap(sortOrderMap, groupBy, {});
            sortOrderByGroupName[newName] = sortOrderByGroupName[oldName];
            delete sortOrderByGroupName[oldName];
        }

        const isEmptyGroupName = name => !name;

        // Unnamed group should sort to the top so just prefix it with aaaaaaaa
        // (New groups are still added at the bottom)
        const formatGroupNameForSort = name => !isEmptyGroupName(name) ? name : `aaaaaaaa${name || ''}`;

        const buildGroups = ({ packingList, groupBy }) => {
            // No grouping? Just one group with all the items.
            if (!groupBy) {
                return [
                    { items: packingList.items }
                ];
            }

            const itemsByGroupValue = packingList.items.reduce(
                (accum, item) => {
                    const groupByValue = (item.formState ? item.formState.field(groupBy) : item[groupBy]) || '';
                    accum[groupByValue] = accum[groupByValue] || [];
                    accum[groupByValue].push(item);
                    return accum;
                },
                {}
            );

            const sortOrderByGroupName = getOrCreateInMap(sortOrderMap, groupBy, {});

            const groupNames = Object.keys(itemsByGroupValue);
            let maxSort = Object.keys(sortOrderByGroupName).length ? Math.max(...Object.keys(sortOrderByGroupName).map(k => sortOrderByGroupName[k])) : 0;
            groupNames
                .filter(groupName => !sortOrderByGroupName[groupName])
                .sort((a, b) => formatGroupNameForSort(a).localeCompare(formatGroupNameForSort(b)))
                .forEach((groupName, index) => {
                    sortOrderByGroupName[groupName] = maxSort + 1;
                    maxSort = sortOrderByGroupName[groupName];
                });

            return Object.keys(itemsByGroupValue)
                .sort((a, b) => sortOrderByGroupName[a] - sortOrderByGroupName[b])
                .map(groupName => {
                    return {
                        name: groupName,
                        items: itemsByGroupValue[groupName]
                    };
                });
        };

        const isEmpty = item => {
            const values = item.formState ? item.formState.get().values : item;
            return !values.name && !values.notes && !values.category && !values.location && (!values.weight || !values.weight.value);
        };

        const isPlaceholder = item => {
            // true if no name, and a groupable field is set
            const values = item.formState ? item.formState.get().values : item;
            return !values.name && (values.category || values.location);
        }

        this.group = ({ packingList, groupBy }) => {
            let groups = buildGroups({ packingList, groupBy });

            const unnamedGroup = groups.find(group => !group.name);
            if (unnamedGroup) {
                // strip placeholder items from the unnamed group
                // if this leaves only empty items, and there is at least one other group, strip those too
                // if this leaves no items, remove unnamed group
                const visibleItemIds = getOrCreateInMap(visibleItemIdsMap, groupBy, []);
                const nonPlaceholder = unnamedGroup.items.filter(item => !isPlaceholder(item) || visibleItemIds.includes(item.itemId));
                const nonEmpty = nonPlaceholder.filter(item => !isEmpty(item) || visibleItemIds.includes(item.itemId));
                unnamedGroup.items = groups.length > 1 ? nonEmpty : nonPlaceholder;

                // HACK: After I was deep into this, tests showed that legitimate items were getting
                // stripped when the user set the cat or loc without setting the name, so the array
                // visibleItemIds is now being used to preserve items once they have been shown. :(
                // TODO: Consider re-architecting how we store groups in state
                visibleItemIds.push(...unnamedGroup.items.map(i => i.itemId));

                if (unnamedGroup.items.length === 0) {
                    groups = groups.filter(g => g !== unnamedGroup);
                }
            }

            return groups;
        };

    }

}
