import React from 'react';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/core/styles/withStyles';
import { Display } from '@wanderlost-sdk/components';

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(6),
    },
    button: {
        width: 300,
        textAlign: 'left',
        backgroundColor: '#fff',
        border: `1px solid #e5e5e5`,
        borderRadius: 10,
        padding: theme.unit,
        '& > span': {
            justifyContent: 'space-between',
        }
    },
    buttonLabel: {
        fontSize: 18,
        lineHeight: 1.5
    },
    popper: {
        width: 280,
        margin: '0 10px',
        zIndex: 2000 // must be > AppBar zIndex (1100)
    }
});

export let TripPackingListsFilter = ({ classes, principal, activeFilter, setActiveFilter, packingLists }) => {
    const otherTeamMembers = packingLists.map(l => l.owner).filter(teamMember => teamMember.userId !== principal.userId);

    // I am not necessarily on this trip (it could be a featured trip)
    const tripIncludesMe = (packingLists.find(l => l.userId === principal.userId));

    const anchorRef = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const openMenu = () => setIsOpen(true);
    const closeMenu = () => setIsOpen(false);
    const setFilter = filter => { setActiveFilter(filter); closeMenu(); };

    const handleClick = (e) => {
        // if this was a click on a menuitem inside the popper, don't open menu
        if (e.target.getAttribute('role') !== 'menuitem') {
            openMenu();
        }
    }

    // the drop-down is hidden when printing (because buttons are hidden globally),
    // so we need to create a substitute heading to use for printing
    // TODO eliminate redundancy with menu item logic below
    const getPrintHeading = filterId => {
        if (filterId === 'my-packing-list') {
            return 'My Packing List';
        } else if (filterId === 'shared-team-gear') {
            return 'Shared Team Items';
        } else if (filterId === 'all-team-gear') {
            return 'All Team Items';
        } else {
            const person = otherTeamMembers.find(m => filterId === `user:${m.userId}`);
            return person ? person.name : '';
        }
    };

    return (
        <div className={classes.root}>
            <Display print>
                <h1>{getPrintHeading(activeFilter.filterId)}</h1>
            </Display>
            <IconButton onClick={handleClick} buttonRef={node => {anchorRef.current = node;}} disableRipple className={classes.button} name="packing-list-filter">
                <span className={classes.buttonLabel}>{activeFilter.label}</span> <ExpandMoreIcon />
                <Popper
                    name="filter-options"
                    anchorEl={anchorRef.current}
                    open={isOpen}
                    onClose={closeMenu}
                    placement="bottom-start"
                    transition
                    className={classes.popper}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: 'center top' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={closeMenu}>
                                    <MenuList dense={true}>
                                        {tripIncludesMe &&
                                            <MenuItem
                                                selected={activeFilter.filterId === 'my-packing-list'}
                                                onClick={() => setFilter({ filterId: 'my-packing-list', label: 'My Packing List' })}
                                            >
                                                My Packing List
                                            </MenuItem>
                                        }
                                        {otherTeamMembers.length > 0 &&
                                            <MenuItem
                                                selected={activeFilter.filterId === 'shared-team-gear'}
                                                onClick={() => setFilter({ filterId: 'shared-team-gear', label: 'Shared Team Items' })}
                                            >
                                                Shared Team Items
                                            </MenuItem>
                                        }
                                        {otherTeamMembers.length > 0 &&
                                            <MenuItem
                                                selected={activeFilter.filterId === 'all-team-gear'}
                                                onClick={() => setFilter({ filterId: 'all-team-gear', label: 'All Team Items' })}
                                            >
                                                All Team Items
                                            </MenuItem>
                                        }
                                        <MenuItem dense style={{ backgroundColor: 'transparent' }}>
                                            <strong style={{ fontSize: '90%', textTransform: 'uppercase', opacity: 0.8 }}>Team Packing Lists</strong>
                                        </MenuItem>
                                        {otherTeamMembers.length === 0 &&
                                            <MenuItem style={{ backgroundColor: 'transparent', paddingLeft: '36px' }}>
                                                <em>No one else has added any items</em>
                                            </MenuItem>
                                        }
                                        {otherTeamMembers.map(teamMember => {
                                            const filter = { filterId: `user:${teamMember.userId}`, label: teamMember.name, userId: teamMember.userId };
                                            return (
                                                <MenuItem
                                                    key={teamMember.userId}
                                                    selected={activeFilter.filterId === filter.filterId}
                                                    onClick={() => setFilter(filter)}
                                                    style={{ paddingLeft: '36px' }}
                                                >
                                                    {filter.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </IconButton>
        </div>
    );
}

TripPackingListsFilter.propTypes = {
    classes: PropTypes.object.isRequired,
    activeFilter: PropTypes.object.isRequired,
    setActiveFilter: PropTypes.func.isRequired,
    packingLists: PropTypes.array.isRequired,
    principal: PropTypes.object.isRequired,
};

TripPackingListsFilter = withStyles(styles)(TripPackingListsFilter);
