import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withDependencies } from '@symbiotic/green-state';
import { InjectTripDetails } from '../InjectTripDetails';
import { TripPageHeading, TripBreadcrumbs, TripBreadcrumbItem, TripMealsList } from '@wanderlost-sdk/components';
import { Button, QueryStrings } from '@wanderlost-sdk/components';
import { TripDetailsLayout } from '../TripDetailsLayout';

export class TripMealsPage extends Component {
    render() {
        const { queryStrings } = this.props;
        const from = queryStrings.get('from');

        return (
            <InjectTripDetails>
                {({ trip }) => {
                    return (
                        <TripDetailsLayout
                            trip={trip}
                            breadcrumbs={
                                <TripBreadcrumbs trip={trip} routeName={from ? from : 'tripMeals'}>
                                    <TripBreadcrumbItem>Plan Meals</TripBreadcrumbItem>
                                </TripBreadcrumbs>
                            }
                            page="trip-meals-page"
                        >
                            <TripPageHeading
                                actionsComponent={
                                    <Button to="tripDetails" routeParams={{tripId: trip.tripId}} variant="outlined" name="manage-meals">Plan Meals By Day</Button>
                                }
                            >
                                Plan Meals
                            </TripPageHeading>

                            <TripMealsList trip={trip} />
                        </TripDetailsLayout>
                    );
                }}
            </InjectTripDetails>
        );
    }
}

TripMealsPage.propTypes = {
    queryStrings: PropTypes.instanceOf(QueryStrings),
}

TripMealsPage = withDependencies({
    queryStrings: QueryStrings
})(TripMealsPage);
