"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyboardShortcutManager = void 0;
/* globals window:readonly, document:readonly */

var KeyboardShortcutManager = function KeyboardShortcutManager() {
  _classCallCheck(this, KeyboardShortcutManager);

  var keyIdsToCharCodes = {
    space: 32,
    shift: 16
  };
  var handlersByKeyId = {};

  var createKeyHandler = function createKeyHandler(keyId) {
    var handler = {
      listeners: [],
      listen: function listen(listener) {
        handler.listeners.push(listener);

        var unsubscribe = function unsubscribe() {
          handler.listeners = handler.listeners.filter(function (l) {
            return l !== listener;
          });
        };

        return unsubscribe;
      },
      publish: function publish(state, event) {
        handler.listeners.forEach(function (listener) {
          return listener(state, event);
        });
      }
    };

    var getCharCodeFromEvent = function getCharCodeFromEvent(e) {
      return typeof e.which == 'number' ? e.which : e.keyCode;
    };

    document.addEventListener('keydown', function (e) {
      e = e || window.event;
      var charCode = keyIdsToCharCodes[keyId];
      var actualCharCode = getCharCodeFromEvent(e);

      if (actualCharCode === charCode) {
        handler.publish(true, e);
      }
    });
    document.addEventListener('keyup', function (e) {
      e = e || window.event;
      var charCode = typeof e.which == 'number' ? e.which : e.keyCode;
      var actualCharCode = getCharCodeFromEvent(e);

      if (actualCharCode === charCode) {
        handler.publish(false, e);
      }
    });
    return handler;
  };

  this.listen = function (keyId, listener) {
    var handler = handlersByKeyId[keyId];

    if (!handler) {
      handler = handlersByKeyId[keyId] = createKeyHandler(keyId);
    }

    return handler.listen(listener);
  };
};

exports.KeyboardShortcutManager = KeyboardShortcutManager;