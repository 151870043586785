import React from 'react';
import { TripBreadcrumbs, TripBreadcrumbItem } from '@wanderlost-sdk/components';
import { InjectTripDetails } from './InjectTripDetails';
import { TripSettings } from './TripSettings';
import { TripActivityList } from './activity';
import { TripDetailsLayout } from './TripDetailsLayout';

export let TripLogPage = () => (
    <InjectTripDetails>
        {({ trip }) => {
            /* 404 is handled in DetailsContainer, but when a trip is deleted this code can run momentarily and should not render */
            if (!trip) {
                return null;
            }

            return (
                <TripDetailsLayout
                    trip={trip}
                    page="trip-log"
                    breadcrumbs={
                        <TripBreadcrumbs trip={trip}>
                            <TripBreadcrumbItem>Change Log</TripBreadcrumbItem>
                        </TripBreadcrumbs>
                    }
                >

                    <TripActivityList trip={trip} />
                    <TripSettings trip={trip} />

                </TripDetailsLayout>
            );
        }}
    </InjectTripDetails>
);
