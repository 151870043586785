"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DrawerStatePlugin = void 0;

var _EventBus = require("./EventBus");

var _Cartographer = require("./Cartographer");

var _state = require("./state");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
} // When the drawer is open, cartographer width needs to change


var DrawerStatePlugin = function DrawerStatePlugin(drawerState, cartographerProvider, eventBus) {
  _classCallCheck(this, DrawerStatePlugin);

  var onDispose = function onDispose() {};

  this.start = function () {
    eventBus.on('cartographer:ready', function () {
      var lastActiveTabId;
      onDispose = drawerState.subscribe(function (_ref) {
        var activeTabId = _ref.activeTabId;
        // If it was open and now its not or vice versa, need to recalculate map size or OL will keep rendering like it has the previous size
        // This needs to happen after the render which changes the style, so we put it on a timeout
        // We can't subscribe until cartographer is ready since we need to call updateSize on it
        // If we subscribe immediately in start then we'll get called back immediately with the initial DrawerState.state before cartographer is ready
        var openedOrClosed = Boolean(activeTabId) !== Boolean(lastActiveTabId);

        if (openedOrClosed) {
          setTimeout(function () {
            // Fire event, so map overlays can re-position themselves.
            // (Do this before we update the map, otherwise we have a race condition
            // whereby subscribers may see an inconsistent map size.)
            eventBus.emit('Drawer.openedOrClosed', {
              lastActiveTabId: lastActiveTabId,
              activeTabId: activeTabId
            });
            cartographerProvider.get().updateSize();
          }, 0);
        }

        lastActiveTabId = activeTabId;
      });
    });
  };

  this.dispose = function () {
    onDispose();
  };
};

exports.DrawerStatePlugin = DrawerStatePlugin;

_defineProperty(DrawerStatePlugin, "inject", function () {
  return [_state.DrawerState, _Cartographer.CartographerProvider, _EventBus.EventBus];
});