import { State } from '@symbiotic/green-state';
import { ActionQueue } from '@wanderlost-sdk/core';
import moment from 'moment';

class Itinerary {
    constructor({ save, onChange, getTripDateByDayNumber, ...data }) {
        Object.assign(this, data);

        Object.defineProperties(this, {
            date: {
                get: () => getTripDateByDayNumber(this.dayNumber),
            }
        });

        const queue = new ActionQueue(data);

        this.save = async ({ descriptionHTML }) => {
            try {
                const updatedItinerary = await queue.enqueue(itinerary => save({ ...itinerary, descriptionHTML }));
                onChange(clone({ ...updatedItinerary, error: null }));
                return true;
            } catch (error) {
                onChange(clone({ error }));
                return false;
            }
        };

        const clone = overrides => new Itinerary({
            ...data,
            ...overrides,
            save,
            onChange,
            getTripDateByDayNumber
        });
    }
}

export class TripItinerariesState extends State {
    // getTrip instead of trip reference so we always have the newest reference to trip without the need to subscribe
    constructor(apiClient, getTrip) {
        super();

        const getTripDateByDayNumber = dayNumber => moment(getTrip().startDate).add(dayNumber - 1, 'day').toISOString();

        this.load = async () => {
            const itineraries = await apiClient.get(getTrip().links.itineraries, 'application/wander-lost.trip-itinerary+json');

            this.setState({
                itineraries: itineraries.map(decorateItinerary)
            });
        };

        this.isLoading = () => !this.state;

        const save = async (itinerary) => {
            const headers = {
                'content-type': 'application/wander-lost.trip-itinerary+json',
                'accept': 'application/wander-lost.trip-itinerary+json'
            };
            return await apiClient.put(itinerary, itinerary, headers);
        }

        const decorateItinerary = itinerary =>
            new Itinerary({
                ...itinerary,
                onChange: refreshItinerary,
                getTripDateByDayNumber,
                save
            });

        const getItineraryId = ({ dayNumber }) => `${dayNumber}`;

        const refreshItinerary = itinerary => {
            const itineraryId = getItineraryId(itinerary);
            const { itineraries } = this.get();

            this.setState({
                itineraries: itineraries.map(_itinerary =>
                    getItineraryId(_itinerary) === itineraryId ? itinerary : _itinerary
                )
            });
        };

    }
}
