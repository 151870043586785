"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipeDetailsPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _components = require("../components");

var _RecipesLayout = require("./RecipesLayout");

var _RecipesNotFoundPage = require("./RecipesNotFoundPage");

var _InjectRecipesState = require("./InjectRecipesState");

var _RecipeDetails = require("./RecipeDetails");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RecipeDetailsPage = function RecipeDetailsPage(_ref) {
  var recipeId = _ref.recipeId;
  return _react.default.createElement(_InjectRecipesState.InjectRecipesState, null, function (_ref2) {
    var recipes = _ref2.recipes,
        toggleFavorite = _ref2.toggleFavorite;
    var recipe = recipes.find(function (r) {
      return r.recipeId === recipeId;
    });

    if (!recipe) {
      return _react.default.createElement(_RecipesNotFoundPage.RecipesNotFoundPage, null);
    }

    return _react.default.createElement(_RecipesLayout.RecipesLayout, {
      page: "recipe-details-page",
      breadcrumbs: _react.default.createElement(_components.Breadcrumbs, null, _react.default.createElement(_components.BreadcrumbItem, {
        to: "recipesHome"
      }, "Recipes"), _react.default.createElement(_components.BreadcrumbItem, {
        to: "recipes"
      }, "All Recipes"), _react.default.createElement(_components.BreadcrumbItem, null, recipe.name)),
      actions: _react.default.createElement(_components.Display, {
        screen: true
      }, _react.default.createElement(_components.PrintButton, {
        tooltip: "Print recipe",
        title: "Wanderlost Recipe - ".concat(recipe.name)
      }))
    }, _react.default.createElement(_RecipeDetails.RecipeDetails, {
      recipe: recipe,
      toggleFavorite: toggleFavorite
    }));
  });
};

exports.RecipeDetailsPage = RecipeDetailsPage;
RecipeDetailsPage.propTypes = {
  recipeId: _propTypes.default.string.isRequired
};