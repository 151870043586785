"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LayerVisibilityControls = exports.LayerVisibilityConfig = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _components = require("@wanderlost-sdk/components");

var _LayerOpacityControl = require("./LayerOpacityControl");

var _greenState = require("@symbiotic/green-state");

var _LayerOpacityState = require("../state/LayerOpacityState");

var _KeyboardArrowUp = _interopRequireDefault(require("@material-ui/icons/KeyboardArrowUp"));

var _KeyboardArrowDown = _interopRequireDefault(require("@material-ui/icons/KeyboardArrowDown"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var LayerVisibilityConfig = function LayerVisibilityConfig() {
  _classCallCheck(this, LayerVisibilityConfig);

  _defineProperty(this, "layerIdsToShow", ['layer-non-trails', 'layer-user-trails', 'layer-global-trails', 'active-layers']);

  _defineProperty(this, "layerLabels", {
    'layer-non-trails': 'All Waypoints / Points of Interest',
    'layer-user-trails': 'Trails (My Layers, Shared with Me)',
    'layer-global-trails': 'Featured Trails, Public Roads & Trails',
    'active-layers': 'Favorites / Recent'
  });
};

exports.LayerVisibilityConfig = LayerVisibilityConfig;

var LayerVisibilityControls = function LayerVisibilityControls(_ref) {
  var classes = _ref.classes;

  var _ref2 = (0, _greenState.useInstance)(LayerVisibilityConfig),
      layerIdsToShow = _ref2.layerIdsToShow,
      layerLabels = _ref2.layerLabels;

  var layerOpacityStateInstance = (0, _greenState.useInstance)(_LayerOpacityState.LayerOpacityState);
  var layerOpacityState = (0, _greenState.useSubscription)(function () {
    return layerOpacityStateInstance;
  });

  var _ref3 = (0, _react.useState)(false),
      _ref4 = _slicedToArray(_ref3, 2),
      isOpen = _ref4[0],
      setIsOpen = _ref4[1];

  if (!layerOpacityState) {
    return null;
  }

  var _setOpacity = layerOpacityState.setOpacity,
      getOpacitiesByLayerId = layerOpacityState.getOpacitiesByLayerId;
  var opacityByLayerId = getOpacitiesByLayerId();
  var TitleIconComponent = isOpen ? _KeyboardArrowDown.default : _KeyboardArrowUp.default;
  return _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement(_components.H3, {
    variant: "h4",
    className: classes.title,
    onClick: function onClick() {
      return setIsOpen(!isOpen);
    },
    gutterBottom: false,
    name: "layer-opacity-toggle"
  }, _react.default.createElement(TitleIconComponent, {
    fontSize: "small",
    className: classes.titleIcon
  }), "Layer Visibility"), isOpen && _react.default.createElement("div", {
    className: classes.content
  }, layerIdsToShow.map(function (layerId) {
    return _react.default.createElement("div", {
      "data-layer-opacity-id": layerId,
      key: layerId
    }, _react.default.createElement(_LayerOpacityControl.LayerOpacityControl, {
      opacity: opacityByLayerId[layerId],
      setOpacity: function setOpacity(opacity) {
        return _setOpacity(layerId, opacity);
      },
      label: layerLabels[layerId]
    }));
  })));
};

exports.LayerVisibilityControls = LayerVisibilityControls;
LayerVisibilityControls.propTypes = {
  classes: _propTypes.default.object.isRequired
};
exports.LayerVisibilityControls = LayerVisibilityControls = (0, _withStyles.default)(function (theme) {
  return {
    container: {
      fontWeight: 'bold',

      /**
       * Workaround for bug in slider causing horizontal scrollbars
       * Need left/right padding so the halo around the thumb
       */
      overflow: 'hidden'
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      border: "2px solid ".concat(theme.palette.map.icon.color),
      color: theme.palette.map.icon.color,
      borderRadius: '0.5rem',
      borderBottomWidth: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      textAlign: 'center',
      padding: theme.spacing(1),
      textTransform: 'uppercase',
      cursor: 'pointer',
      margin: '0 auto',
      width: '50%',
      // on top of content, and pulled down to obscure part of top border of content and create tabbed effect
      position: 'relative',
      zIndex: 2,
      top: 2,
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    titleIcon: {
      marginTop: -1,
      marginLeft: -6,
      marginRight: 2
    },
    content: {
      padding: theme.spacing(4),
      position: 'relative',
      zIndex: 1,
      backgroundColor: '#fff',
      borderTop: "2px solid ".concat(theme.palette.map.icon.color)
    }
  };
})(LayerVisibilityControls);