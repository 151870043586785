"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureAnimationManager = void 0;

var _style = require("ol/style.js");

var _Observable = require("ol/Observable.js");

var _easing = require("ol/easing.js");

var _render = require("ol/render");

var FeatureAnimationManager = function FeatureAnimationManager() {
  _classCallCheck(this, FeatureAnimationManager);

  this.flash = function (_ref) {
    var map = _ref.map,
        features = _ref.features,
        colors = _ref.colors,
        _ref$startOpacity = _ref.startOpacity,
        startOpacity = _ref$startOpacity === void 0 ? 0.5 : _ref$startOpacity,
        _ref$duration = _ref.duration,
        duration = _ref$duration === void 0 ? 500 : _ref$duration;
    var layer = map.getEditingLayer();
    var start = new Date().getTime();
    var listenerKey = layer.on('postrender', function (event) {
      var vectorContext = (0, _render.getVectorContext)(event);
      var frameState = event.frameState;
      var elapsed = frameState.time - start;
      var elapsedRatio = elapsed / duration;
      var opacity = Math.max(startOpacity * (0, _easing.easeOut)(1 - elapsedRatio), 0);
      var styles = colors.map(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 3),
            red = _ref3[0],
            green = _ref3[1],
            blue = _ref3[2];

        return new _style.Style({
          stroke: new _style.Stroke({
            color: "rgba(".concat(red, ", ").concat(green, ", ").concat(blue, ", ").concat(opacity, ")"),
            width: 14
          }),
          zIndex: Infinity
        });
      });
      features.forEach(function (feature, index) {
        var style = styles[index];
        var f = feature.clone();
        vectorContext.drawFeature(f, style);
      });

      if (elapsed > duration) {
        (0, _Observable.unByKey)(listenerKey);
        return;
      } // tell OpenLayers to continue postcompose animation


      map.render();
    });
  };
};

exports.FeatureAnimationManager = FeatureAnimationManager;