"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipesNotFoundPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _typography = require("../typography");

var _RecipesLayout = require("./RecipesLayout");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RecipesNotFoundPage = function RecipesNotFoundPage() {
  return _react.default.createElement(_RecipesLayout.RecipesLayout, {
    page: "not-found"
  }, _react.default.createElement(_typography.H1, null, "Page Not Found"), _react.default.createElement(_typography.P, null, "Wander too far and you may get lost."));
};

exports.RecipesNotFoundPage = RecipesNotFoundPage;