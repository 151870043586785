export class EnvConfiguration{}

// this is intentionally async so we could move this to fetch from an API instead
export const getConfiguration = async () => {

    // Set by google tag manager as well, see comment in ioc.js
    const intercomTestAppId = "sr5kesc2";
    const coviewTestProjectKey = 'AD0Ar-FnIc8';

    const buildCoverPhotoURLs = baseURL => Array(18).fill().map((_, i) => `${baseURL}/images/stock-trip-cover-photos/trip-cover-${i + 1}.jpg`)

    const config = {
        live: {
            auth0ClientId: 'XrpVPEW7EEXbRd3mXd3CUGW1ggZdr7j1',
            auth0RedirectURL: 'https://app.thewanderlostproject.com/',
            auth0LogoutRedirectURL: 'https://app.thewanderlostproject.com/',
            apiURL : 'https://api.thewanderlostproject.com/v1',
            stockTripCoverPhotos: buildCoverPhotoURLs('https://app.thewanderlostproject.com'),
            intercomAppId: 'vn2k92vo',
            coviewProjectKey: 'ILc9HO7f8I4',
            contentfulBaseURL: 'https://cdn.contentful.com/spaces/xagbxvwm4t8z',
            contentfulAccessToken: 'KzGcmLYlHXiJpUCcIFu7lbdGZfHgIPsLSZ6BsbKZV2g',
            // this user is meant to be public, and only has permission to create xray traces
            publicAWSUser: {
                accessKeyId: 'AKIATA63VB4PTEWBUHMN',
                secretAccessKey: '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',
            },
            hotjarId: 2111431
        },
        staging : {
            auth0ClientId: 'Klna5VHRkGiOgW3YJMRgjY8Aw4f4joGx',
            auth0RedirectURL: 'https://app.staging.thewanderlostproject.com/',
            auth0LogoutRedirectURL: 'https://app.staging.thewanderlostproject.com/',
            apiURL: 'https://api.staging.thewanderlostproject.com/v1',
            stockTripCoverPhotos: buildCoverPhotoURLs('https://app.staging.thewanderlostproject.com'),
            intercomAppId: intercomTestAppId,
            coviewProjectKey: coviewTestProjectKey,
            contentfulBaseURL: 'https://preview.contentful.com/spaces/xagbxvwm4t8z',
            contentfulAccessToken: 'mctid5AAp73ilEjv4wZAPb8eQGLTzvnqyVh3jPu_sKk',
            // this user is meant to be public, and only has permission to create xray traces
            publicAWSUser: {
                accessKeyId: 'AKIAURABXWMZLGJRDPNQ',
                secretAccessKey: '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',
            },
            hotjarId: 2109595
        },
        local : {
            auth0ClientId: 'Klna5VHRkGiOgW3YJMRgjY8Aw4f4joGx',
            auth0RedirectURL: 'http://localhost:3000/',
            auth0LogoutRedirectURL: 'http://localhost:3000/',
            apiURL: 'https://api.staging.thewanderlostproject.com/v1',
            stockTripCoverPhotos: buildCoverPhotoURLs('https://app.staging.thewanderlostproject.com'),
            intercomAppId: intercomTestAppId,
            coviewProjectKey: coviewTestProjectKey,
            contentfulBaseURL: 'https://preview.contentful.com/spaces/xagbxvwm4t8z',
            contentfulAccessToken: 'mctid5AAp73ilEjv4wZAPb8eQGLTzvnqyVh3jPu_sKk'
        }
    };

    const env = getEnvFromHost();

    return {
        ...config[env],

        // uncomment when running locally against staging api
        // apiURL: config.staging.apiURL
    };
}

// This is also used for the sentry environment
export function getEnvFromHost(){
    const hostMap = {
        'localhost:3000' : 'local',
        'app.staging.thewanderlostproject.com' : 'staging',
        'app.thewanderlostproject.com' : 'live'
    };
    return hostMap[window.location.host] || 'local';
}
