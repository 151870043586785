"use strict";

var _toConsumableArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseLayerCollection = void 0;

var _SettingsStore = require("../SettingsStore");

var _BaseLayerFactory = require("./BaseLayerFactory");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var BaseLayerCollection = function BaseLayerCollection(baseLayerFactory, settingsStore) {
  var _this = this;

  _classCallCheck(this, BaseLayerCollection);

  this.getSavedCustomLayers = function () {
    try {
      var saved = JSON.parse(settingsStore.getItem('wl-custom-layers'));

      if (Array.isArray(saved)) {
        return saved;
      }
    } catch (e) {// ignore
    }

    return [];
  };

  this.addCustomLayer = function (_ref) {
    var name = _ref.name,
        styleURL = _ref.styleURL,
        _ref$remember = _ref.remember,
        remember = _ref$remember === void 0 ? false : _ref$remember;
    var layerId = "custom-".concat(Date.now());
    var definition = {
      layerId: layerId,
      name: name,
      styleURL: styleURL
    };

    if (remember) {
      var customLayers = _this.getSavedCustomLayers();

      customLayers.push(definition);
      settingsStore.setItem('wl-custom-layers', JSON.stringify(customLayers));
    }

    var layer = baseLayerFactory.createCustom(definition);
    baseLayers.push(layer);
    return layer;
  }; // why are we calling create and getting these unique instances?
  // b/c when doing a preview it will use the factory to get its own instance...
  // so that it does NOT get the same instance used by the map


  var baseLayers = [baseLayerFactory.create('maptiler-topographique'), baseLayerFactory.create('usgs-topo'), baseLayerFactory.create('maptiler-hybrid'), baseLayerFactory.create('maptiler-streets'), baseLayerFactory.create('maptiler-basic'), baseLayerFactory.create('public-lands')].concat(_toConsumableArray(this.getSavedCustomLayers().map(baseLayerFactory.createCustom))); // Proxy to array base layer values

  this[Symbol.iterator] = function () {
    return baseLayers[Symbol.iterator]();
  };
};

exports.BaseLayerCollection = BaseLayerCollection;

_defineProperty(BaseLayerCollection, "inject", function () {
  return [_BaseLayerFactory.BaseLayerFactory, _SettingsStore.SettingsStore];
});