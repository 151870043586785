"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripDistance = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _typography = require("../typography");

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

var _core2 = require("@wanderlost-sdk/core");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles() {
  return {
    container: {
      display: 'flex',
      alignItems: 'center'
    }
  };
};

var TripDistanceBase = function TripDistanceBase(_ref) {
  var trip = _ref.trip,
      classes = _ref.classes,
      className = _ref.className,
      other = _objectWithoutProperties(_ref, ["trip", "classes", "className"]);

  var distance = trip.routes ? trip.routes.reduce(function (s, r) {
    return s + (r.distanceMiles || 0);
  }, 0) : 0;
  return _react.default.createElement(_typography.P, _extends({
    color: "textSecondary",
    className: (0, _classnames.default)(classes.container, className)
  }, other), (0, _core2.formatMiles)(distance), " miles");
};

TripDistanceBase.propTypes = {
  trip: _propTypes.default.object.isRequired,
  classes: _propTypes.default.object.isRequired,
  className: _propTypes.default.string
};
var TripDistance = (0, _core.withStyles)(styles)(TripDistanceBase);
exports.TripDistance = TripDistance;