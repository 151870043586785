"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeaturesLoadingIndicator = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _greenState = require("@symbiotic/green-state");

var _state = require("./state");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LOCATION_SEARCH_HEIGHT = '40px';

var styles = function styles(theme) {
  return {
    root: {
      position: 'absolute',
      top: "calc(".concat(theme.wanderlost.editingToolbarHeight, " + ").concat(LOCATION_SEARCH_HEIGHT, " + ").concat(theme.spacing(2), "px)"),
      left: theme.spacing(2),
      zIndex: 1000
      /* must be on top of all the map layers */

    }
  };
}; // TODO: I added data-id="features-loading-indicator" so as to make a non-breaking change
//       that data-id="features-loading-indicator" is always in the dom and data-is-loading
//       will be true or false. Uses of data-id="loading-indicator" should be refactored to this pattern
//       this is because testing for something not to exist is an antipattern


var FeaturesLoadingIndicator = function FeaturesLoadingIndicator(_ref) {
  var classes = _ref.classes;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _state.FeaturesLoadingState
  }, function (_ref2) {
    var isLoading = _ref2.isLoading;
    return _react.default.createElement("div", {
      "data-id": "features-loading-indicator",
      "data-is-loading": isLoading() ? 'true' : 'false'
    }, isLoading() && _react.default.createElement("div", {
      className: classes.root,
      "data-id": "loading-indicator"
    }, _react.default.createElement(_core.CircularProgress, {
      fontSize: "large"
    })));
  });
};

exports.FeaturesLoadingIndicator = FeaturesLoadingIndicator;
FeaturesLoadingIndicator.propTypes = {
  classes: _propTypes.default.object.isRequired
};
exports.FeaturesLoadingIndicator = FeaturesLoadingIndicator = (0, _core.withStyles)(styles)(FeaturesLoadingIndicator);