import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { Card, CardContent, CardActionArea, Typography } from '@material-ui/core';
import { formatTripDate, hasAction } from '@wanderlost-sdk/core';
import { TripBreadcrumbs, TripNotes, H1, P, Button } from '@wanderlost-sdk/components';
import { TripMealsDayPlan } from './meals/TripMealsDayPlan';
import { InjectTripDetails } from './InjectTripDetails';
import { TripItinerary } from './itineraries/TripItinerary';
import { DailyTripRoutesList } from './routes/DailyTripRoutesList';
import { withStyles } from '@material-ui/core';
import { TripDetailsLoadingIndicator } from './TripDetailsLoadingIndicator';
import { TripPackingListSummary } from '../packing-lists/TripPackingListSummary';
import { TripDetailsLayout } from './TripDetailsLayout';
import { isTouchDevice } from '@wanderlost-sdk/components';

export let TripDetailsPage = ({ classes }) => {
    return (
        <InjectTripDetails>
            {({ trip, activeDayNumber, setActiveDayNumber }) => {
                /* 404 is handled in DetailsContainer, but when a trip is deleted this code can run momentarily and should not render */
                if (!trip) {
                    return null;
                }

                const tripDates = [...Array(trip.durationInDays).keys()].map(index => moment.utc(trip.startDate).add(index, 'd'));
                const canEdit = hasAction(trip, 'self', 'put');

                return (
                    <TripDetailsLayout
                        page="trip-detail"
                        trip={trip}
                        classes={{
                            content: classes.mainContent
                        }}
                        noPadding
                    >

                        <div className={classes.container}>
                            <div className={classes.navColumn}>
                                <div name="trip-days-nav" data-active-day-number={activeDayNumber} className={classes.tripDayNav}>

                                    <div className={classes.planCardWrapper + ' ' + classes.planCardWrapperBeforeYouGo} data-id="before-card">
                                        <CardActionArea disableRipple={true} onClick={() => {setActiveDayNumber(0)}} >
                                            <Card elevation={0} className={cn(classes.planCard, {[classes.planCardActive]: !activeDayNumber})}>
                                                <div className={classes.planCardText}>
                                                    <CardContent className={classes.planCardContent}>
                                                        <Typography className={classes.planCardLabel} component="h6" variant="h6">
                                                            Before You Go
                                                            <Typography variant="h6" color="textSecondary" component="span" className={classes.planCardSubLabel}>
                                                                Notes & Packing List
                                                            </Typography>
                                                        </Typography>
                                                    </CardContent>
                                                </div>
                                            </Card>
                                        </CardActionArea>
                                    </div>

                                    {tripDates.map((dayDate, index) => (
                                        <div className={classes.planCardWrapper} key={index} data-id={`day-card-${index + 1}`} name="trip-day-nav">
                                            <CardActionArea disableRipple={true} onClick={() => {setActiveDayNumber(index + 1)}} navtarget={`day-${index + 1}`}>
                                                <Card elevation={0} className={cn(classes.planCard, {[classes.planCardActive]: activeDayNumber === index + 1})}>
                                                    <div className={classes.planCardText}>
                                                        <CardContent className={classes.planCardContent}>
                                                            <Typography className={classes.planCardLabel} component="h6" variant="h6">
                                                                Day {index + 1}
                                                                <Typography variant="h6" color="textSecondary" component="span" className={classes.planCardSubLabel}>
                                                                    {dayDate.format('ddd, MMM Do')}
                                                                </Typography>
                                                            </Typography>
                                                        </CardContent>
                                                    </div>
                                                </Card>
                                            </CardActionArea>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className={classes.contentColumn}>
                                <TripBreadcrumbs trip={trip} />

                                {!activeDayNumber &&
                                    <div data-id="before-detail">

                                        <div className={classes.titleContainer}>
                                            <H1 className={classes.title}>
                                                Before You Go
                                            </H1>
                                        </div>

                                        <div className={classes.content}>
                                            <TripNotes readOnly={!canEdit} />

                                            <TripPackingListSummary trip={trip} canEdit={canEdit} gutterBottom={true} />

                                            {!isTouchDevice() && // crashes on ipad
                                                <div className={classes.printTip}>
                                                    <P>
                                                        <b>Tip:</b>&nbsp;
                                                        <Button link to="tripPrint" routeParams={{ tripId: trip.tripId }}>Print your trip</Button>&nbsp;
                                                        before you leave so you have a back up on the road!
                                                    </P>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                }
                                {activeDayNumber > 0 &&
                                    <div data-id={`day-detail-${activeDayNumber}`}>
                                        <div className={classes.titleContainer}>
                                            <H1 className={classes.title}>
                                                Day {activeDayNumber}
                                                <Typography className={classes.subTitle} variant="h3" color="textSecondary" component="span">
                                                    {formatTripDate(tripDates[activeDayNumber - 1])}
                                                </Typography>
                                            </H1>
                                        </div>

                                        <div key={`day-detail-${activeDayNumber}`} className={classes.content}>
                                            <div style={{ marginBottom: '-8px' }}>
                                                <TripItinerary dayNumber={activeDayNumber} canEdit={canEdit} />
                                            </div>

                                            <DailyTripRoutesList dayNumber={activeDayNumber} trip={trip} canEdit={canEdit} />

                                            <TripMealsDayPlan trip={trip} dayNumber={activeDayNumber} canEdit={canEdit} />
                                        </div>

                                    </div>
                                }

                                <TripDetailsLoadingIndicator />
                            </div>
                        </div>

                    </TripDetailsLayout>
                );
            }}
        </InjectTripDetails>
    );
};

TripDetailsPage.propTypes = {
    classes: PropTypes.object.isRequired
};

TripDetailsPage = withStyles(theme => ({
    container: {
        width: '100%',
    },
    navColumn: { },
    '@media print': {
        navColumn: {
            display: 'none'
        },
        content: {
            paddingTop: `${theme.spacing(2)}px`
        }
    },
    contentColumn: {
        padding: theme.spacing(5),
        paddingTop: theme.spacing(2),
    },
    mainContent: {
        display: 'flex',
        alignItems: 'stretch'
    },
    tripDayNav: {
        paddingTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        display: 'flex',
        overflowX: 'auto',
    },
    planCardWrapper: {
        marginRight: '20px',
        flexShrink: 0,
        width: '150px',
        '&:last-child': {
            marginBottom: '10px'
        }
    },
    planCardWrapperBeforeYouGo: {
        width: 'auto',
    },
    '@media screen and (min-width: 1025px)': {
        container: {
            display: 'flex',
        },
        planCard: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            backgroundColor: 'transparent',
        },
        navColumn: {
            position: 'relative',
            backgroundColor: theme.palette.primary.light,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            width: '210px',
            minHeight: '400px', // so it doesnt start out very small while content is loading
            flexShrink: 0,
            flexGrow: 0,
        },
        contentColumn: {
            paddingTop: theme.spacing(3),
            width: 'calc(100% - 210px)',
            flexShrink: 0,
            flexGrow: 1
        },
        tripDayNav: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingTop: theme.spacing(1),
            margin: 0,
            // Let the trip day nav dictate the height within the viewport, but don't let it cause vertical scrolling of the page
            // Subtract the full header height
            overflowY: 'auto',
            display: 'block',
        },
        planCardWrapper: {
            width: 'auto',
            marginRight: 0
        }
    },
    planCard: {
        display: 'flex',
        '&:hover': {
            backgroundColor: '#E2E7E5'
        }
    },
    planCardActive: {
        '& $planCardLabel': {
            color: theme.palette.primary.main
        }
    },
    planCardLabel: {
        justifyContent: 'space-between',
        alignItems: 'center',
        lineHeight: 1.1,
    },
    planCardSubLabel: {
        fontSize: '75%',
        display: 'block',
        fontWeight: 300
    },
    planCardText: {
        width: '100%',
    },
    planCardContent: {
        '&, &:last-child': {
            padding: theme.spacing(2)
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1
    },
    subTitle: {
        marginLeft: `${theme.spacing(2)}px`,
    },
    content: {
        paddingTop: `${theme.spacing(6)}px`,
    },
    printTip: {
        marginTop: `${theme.spacing(4)}px`,
        marginBottom: `${theme.spacing(3)}px`,
    },
}))(TripDetailsPage);
