import React from 'react';
import { DependencyContainerContext } from '@symbiotic/green-state';
import { TripDetailsState } from './TripDetailsState';
import { TripsState } from './TripsState';
import { RouteCreator, ExistingTripRouteCreator, GPXRouteImporter, DrawerStateConfig, DrawerConfig, FolderSharingState, FolderSharingStateFactory, InitialFeaturesLoader, TripRoutesState, LayerVisibilityConfig, FoldersState, FolderProvider } from '@wanderlost-sdk/cartographer';
import { WanderLostNavigator, MealTypeMealGrouper, TripMealsState, TripNotesState } from '@wanderlost-sdk/components';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';
import { LoadingPage } from "../LoadingPage";
import { NotFoundPage } from "../NotFoundPage";
import { WanderLostPrincipal } from '../users';
import { TripItinerariesState } from './itineraries/state/TripItinerariesState';
import { TripPackingListsState } from '../packing-lists/state';
import { FocusManager } from '../FocusManager';

export class TripDetailsContainer extends DependencyContainerContext {
    async containerMounted(container) {
        const { tripId } = this.props;
        const tripsState = container.get(TripsState);

        let tripDetailsState;
        try {
            tripDetailsState = await tripsState.getTripDetailsState(tripId);
            container.registerInstance(TripDetailsState, tripDetailsState);
        } catch (e) {
            // If the trip isnt found (or you dont have access to it), stop
            if ([404, 403].includes(e.statusCode)) {
                this.setState({ tripNotFound: true });
                return;
            }

            throw e;
        }

        const apiClient = container.get(WanderLostApiClient);

        const { trip } = tripDetailsState.get();
        const notes = await apiClient.get(trip.links.notes, 'application/wander-lost.trip-itinerary+json');
        container.registerInstance(TripNotesState, new TripNotesState(apiClient, notes));

        // Routes
        const tripRoutesState = tripDetailsState.getTripRoutesState();
        container.registerInstance(TripRoutesState, tripRoutesState);
        container.registerInstance(InitialFeaturesLoader, new InitialFeaturesLoader(tripRoutesState.getAllFeatures));

        // Folder Provider should be aware of routes
        container.registerInstance(FolderProvider, new FolderProvider(container.get(FoldersState), container.get(TripRoutesState)));

        // Packing Lists
        const principal = container.get(WanderLostPrincipal);
        const focusManager = container.get(FocusManager);
        container.registerInstance(TripPackingListsState, new TripPackingListsState({ apiClient, trip, principal, focusManager }));

        // Meals
        const tripMealsState = new TripMealsState(apiClient, () => tripDetailsState.get().trip, container.get(MealTypeMealGrouper));
        container.registerInstance(TripMealsState, tripMealsState);

        // Itineraries
        const tripItinerariesState = new TripItinerariesState(apiClient, () => tripDetailsState.get().trip);
        container.registerInstance(TripItinerariesState, tripItinerariesState);

        // Whenever team members change, ensure meals/routes data from is updated
        tripsState.onTripDatesChange(async (trip) => {
            tripDetailsState.onTripDatesChange(trip);
            await Promise.all([
                tripMealsState.load(),
                tripItinerariesState.load(),
                tripRoutesState.load(),
            ]);
        });

        // Whenever team members change, ensure meals data from previous teammates is removed
        const onTeamMembersChanged = async () => {
            await Promise.all([
                trip.refresh(),
                tripMealsState.load(),
            ]);
        };

        const navigator = container.get(WanderLostNavigator);
        const folderSharingStateFactory = container.get(FolderSharingStateFactory);
        const folderSharingState = folderSharingStateFactory.create({
            apiClient,
            folder: trip,
            onChange: onTeamMembersChanged,
            onRemoveSelf: async () => {
                // User no longer has access to the trip, refresh the trip list
                // Don't wait for this to complete as it could cause a re-render of the page the user shouldn't see anymore
                tripsState.load();

                // immediately send them to the trip list
                navigator.tripList().go();
            }
        });

        container.registerInstance(FolderSharingState, folderSharingState);

        container.registerInstance(DrawerConfig, {
            TAB_IDS: ['favorites', 'my-maps', 'routes', 'featured-trails', 'points-of-interest', 'public-roads', 'base-layers', 'feature-details', 'edit-feature']
        });

        container.registerInstance(DrawerStateConfig, {
            // Don't want to override active tab history from the main map
            localStorageKey: null,
            defaultActiveTabId: 'routes',
        });

        container.registerInstance(LayerVisibilityConfig, {
            layerIdsToShow: [
                'layer-non-trails',
                'layer-user-trails',
                'layer-global-trails',
                'active-layers',
                'layer-routes',
            ],

            layerLabels: {
                'layer-non-trails': 'All Waypoints / Points of Interest',
                'layer-user-trails': 'Trails (My Layers, Shared with Me)',
                'layer-global-trails': 'Featured Trails, Public Roads & Trails',
                'active-layers': 'Favorites / Recent',
                'layer-routes': 'Routes',
            }
        });

        container.registerInstance(GPXRouteImporter, new GPXRouteImporter(...GPXRouteImporter.inject().map(k => container.get(k === RouteCreator ? ExistingTripRouteCreator : k))));

        // window.tripDetailsContainerInspector = new (require('../ioc').ContainerInspector)(container);
    }

    renderLoading() {
        return <LoadingPage message="Loading Trip Details" />
    }

    render(){
        if (this.state.tripNotFound) {
            return (
                <NotFoundPage />
            );
        }

        return super.render();
    }
}
