"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WanderLostNavigator = void 0;

var _WanderLostURLBuilder = require("./WanderLostURLBuilder");

var _History = require("./History");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var WanderLostNavigator = function WanderLostNavigator(urlBuilder, history) {
  var _this = this;

  _classCallCheck(this, WanderLostNavigator);

  // Create methods for each method in WanderLostURLBuilder that add a go() function to the builder
  Object.keys(urlBuilder).forEach(function (methodName) {
    _this[methodName] = function () {
      var _urlBuilder$methodNam = urlBuilder[methodName].apply(urlBuilder, arguments),
          path = _urlBuilder$methodNam.path,
          url = _urlBuilder$methodNam.url;

      return {
        path: path,
        url: url,
        go: function go() {
          return history.push(path());
        }
      };
    };
  });
};

exports.WanderLostNavigator = WanderLostNavigator;

_defineProperty(WanderLostNavigator, "inject", [_WanderLostURLBuilder.WanderLostURLBuilder, _History.History]);