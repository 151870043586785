"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DistanceCalculator = void 0;

var _sphere = require("ol/sphere");

var _FeatureAdapter = require("../FeatureAdapter");

var turf = _interopRequireWildcard(require("@turf/turf"));

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var DistanceCalculator = function DistanceCalculator(featureAdapter) {
  _classCallCheck(this, DistanceCalculator);

  /**
   * Takes coords in EPSG:3857 and returns the great circle distance (in meters)
   */
  this.getDistance = function (_ref) {
    var from = _ref.from,
        to = _ref.to;
    var fromLatLng = featureAdapter.coordsToApiCoords(from);
    var toLatLng = featureAdapter.coordsToApiCoords(to);
    return (0, _sphere.getDistance)(fromLatLng, toLatLng);
  };
  /**
   * Takes coords in api projection and returns the distance in meters
   */


  this.getLineLength = function (_ref2) {
    var coordinates = _ref2.coordinates;
    return coordinates.reduce(function (totalDistance, to, index) {
      // Skip calculation for the first point
      if (index === 0) {
        return totalDistance;
      }

      var from = coordinates[index - 1];
      return totalDistance + getElevationAwareDistance({
        from: from,
        to: to
      });
    }, 0);
  };
  /**
  * Takes coords in api projection and returns distance in meters
  * If the coords have z values then we adjust distance to take that into account
  * See https://github.com/symbioticlabs/scrumboard-tasks/issues/18284
  * See https://stackoverflow.com/questions/33111529/calculating-distance-between-two-points-using-latitude-longitude-and-altitude-e#:~:text=You%20can%20calculate%20distance%20between,change%20in%20elevation%20in%20meters
  */


  var getElevationAwareDistance = function getElevationAwareDistance(_ref3) {
    var from = _ref3.from,
        to = _ref3.to;
    var greatCircleDistanceMeters = turf.distance(turf.point(from), turf.point(to), {
      units: 'kilometers'
    }) * 1000;
    var fromZ = from[2];
    var toZ = to[2];

    if (!fromZ || !toZ) {
      return greatCircleDistanceMeters;
    }

    var elevationAwareDistanceMeters = Math.sqrt(Math.pow(greatCircleDistanceMeters, 2) + Math.pow(fromZ - toZ, 2));
    return elevationAwareDistanceMeters;
  };
};

exports.DistanceCalculator = DistanceCalculator;

_defineProperty(DistanceCalculator, "inject", function () {
  return [_FeatureAdapter.FeatureAdapter];
});