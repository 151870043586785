"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipesExplorer = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../typography");

var _RecipeCard = require("./RecipeCard");

var _FavoriteButton = require("./FavoriteButton");

var _Info = _interopRequireDefault(require("@material-ui/icons/Info"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var RecipesExplorer = function RecipesExplorer(_ref) {
  var initialFilters = _ref.initialFilters,
      cookbooks = _ref.cookbooks,
      filteredRecipes = _ref.filteredRecipes,
      meals = _ref.meals,
      tags = _ref.tags,
      tagHeadings = _ref.tagHeadings,
      setFilter = _ref.setFilter,
      setFilters = _ref.setFilters,
      isFiltered = _ref.isFiltered,
      _toggleFavorite = _ref.toggleFavorite,
      onSelectRecipe = _ref.onSelectRecipe,
      onRemoveRecipe = _ref.onRemoveRecipe,
      isSelected = _ref.isSelected,
      classes = _ref.classes,
      breadcrumbs = _ref.breadcrumbs;

  var _ref2 = (0, _react.useState)(false),
      _ref3 = _slicedToArray(_ref2, 2),
      initialized = _ref3[0],
      setInitialized = _ref3[1];

  (0, _react.useEffect)(function () {
    if (initialFilters) {
      // TODO: we need our own container, this is resetting global state
      setFilters(initialFilters);
    }

    setInitialized(true);
  }, []);

  if (!initialized) {
    return null;
  }

  return _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement("div", {
    className: classes.filters,
    "data-id": "recipe-filters"
  }, _react.default.createElement(_typography.P, {
    style: {
      fontSize: '80%'
    }
  }, _react.default.createElement("b", null, "FILTER RECIPES")), _react.default.createElement(_core.FormControl, {
    component: "fieldset",
    fullWidth: true,
    className: classes.fieldset + ' ' + classes.otherFilters
  }, _react.default.createElement(_core.FormGroup, null, _react.default.createElement(_core.FormControlLabel, {
    key: "featured",
    control: _react.default.createElement(_core.Checkbox, {
      className: classes.checkbox,
      checked: isFiltered('flag', 'featured'),
      onChange: function onChange(e) {
        return setFilter('flag', 'featured', e.target.checked);
      },
      name: 'featured'
    }),
    label: "Featured"
  })), _react.default.createElement(_core.FormGroup, null, _react.default.createElement(_core.FormControlLabel, {
    key: "favorite",
    control: _react.default.createElement(_core.Checkbox, {
      className: classes.checkbox,
      checked: isFiltered('flag', 'favorite'),
      onChange: function onChange(e) {
        return setFilter('flag', 'favorite', e.target.checked);
      },
      name: 'favorite'
    }),
    label: "Favorites"
  }))), _react.default.createElement(_core.FormControl, {
    component: "fieldset",
    fullWidth: true,
    className: classes.fieldset
  }, _react.default.createElement(_core.FormLabel, {
    className: classes.legend,
    component: "legend"
  }, "COOKBOOK"), _react.default.createElement(_core.FormGroup, null, cookbooks.map(function (cookbook) {
    return _react.default.createElement(_core.FormControlLabel, {
      key: cookbook.cookbookId,
      control: _react.default.createElement(_core.Checkbox, {
        className: classes.checkbox,
        checked: isFiltered('cookbook', cookbook.cookbookId),
        onChange: function onChange(e) {
          return setFilter('cookbook', cookbook.cookbookId, e.target.checked);
        },
        name: cookbook.cookbookId
      }),
      label: cookbook.shortName
    });
  }))), _react.default.createElement(_core.FormControl, {
    component: "fieldset",
    fullWidth: true,
    className: classes.fieldset
  }, _react.default.createElement(_core.FormLabel, {
    className: classes.legend,
    component: "legend"
  }, "MEAL"), _react.default.createElement(_core.FormGroup, null, meals.map(function (meal) {
    return _react.default.createElement(_core.FormControlLabel, {
      key: meal.mealId,
      control: _react.default.createElement(_core.Checkbox, {
        className: classes.checkbox,
        checked: isFiltered('meal', meal.mealId),
        onChange: function onChange(e) {
          return setFilter('meal', meal.mealId, e.target.checked);
        },
        name: meal.mealId
      }),
      label: meal.name
    });
  }))), tagHeadings.map(function (heading) {
    return _react.default.createElement(_core.FormControl, {
      key: heading,
      component: "fieldset",
      fullWidth: true,
      className: classes.fieldset
    }, _react.default.createElement(_core.FormLabel, {
      className: classes.legend,
      component: "legend"
    }, heading.toUpperCase()), _react.default.createElement(_core.FormGroup, null, tags.filter(function (t) {
      return t.tagHeading === heading;
    }).map(function (tag) {
      return _react.default.createElement(_core.FormControlLabel, {
        key: tag.tagId,
        control: _react.default.createElement(_core.Checkbox, {
          className: classes.checkbox,
          checked: isFiltered('tag', tag.tagId),
          onChange: function onChange(e) {
            return setFilter('tag', tag.tagId, e.target.checked);
          },
          name: tag.tagId
        }),
        label: tag.name
      });
    })));
  })), _react.default.createElement("div", {
    className: classes.content,
    "data-id": "recipe-listing"
  }, Boolean(breadcrumbs) && breadcrumbs, isFiltered('flag', 'favorite') && filteredRecipes.length > 0 && !filteredRecipes[0].favorite && _react.default.createElement(_core.Paper, {
    className: classes.favoritesMessage
  }, _react.default.createElement(_Info.default, {
    fontSize: "large",
    color: "primary"
  }), _react.default.createElement(_typography.SPAN, {
    className: classes.message
  }, "You don't have any favorite recipes yet... select one below!")), !filteredRecipes.length && _react.default.createElement(_typography.SPAN, {
    className: classes.message
  }, "Sorry, no recipes matched your filters."), _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 3
  }, filteredRecipes.map(function (recipe) {
    return _react.default.createElement(_core.Grid, {
      item: true,
      className: classes.card,
      key: recipe.recipeId,
      xs: 12,
      md: 6,
      lg: 4
    }, _react.default.createElement(_RecipeCard.RecipeCard, {
      recipe: recipe,
      toggleFavorite: function toggleFavorite() {
        return _toggleFavorite(recipe);
      },
      onSelect: onSelectRecipe && !isSelected(recipe) ? onSelectRecipe : undefined,
      onRemove: onRemoveRecipe && isSelected(recipe) ? onRemoveRecipe : undefined
    }), _react.default.createElement("div", {
      className: classes.favoriteButton
    }, _react.default.createElement(_FavoriteButton.FavoriteButton, {
      selected: recipe.favorite,
      onClick: function onClick() {
        return _toggleFavorite(recipe);
      }
    })));
  }))));
};

exports.RecipesExplorer = RecipesExplorer;
RecipesExplorer.propTypes = {
  breadcrumbs: _propTypes.default.node,
  initialFilters: _propTypes.default.object,
  cookbooks: _propTypes.default.array,
  filteredRecipes: _propTypes.default.array,
  meals: _propTypes.default.array,
  tags: _propTypes.default.array,
  tagHeadings: _propTypes.default.array,
  setFilter: _propTypes.default.func,
  setFilters: _propTypes.default.func,
  isFiltered: _propTypes.default.func,
  toggleFavorite: _propTypes.default.func,
  onSelectRecipe: _propTypes.default.func,
  onRemoveRecipe: _propTypes.default.func,
  isSelected: _propTypes.default.func,
  classes: _propTypes.default.object
};
exports.RecipesExplorer = RecipesExplorer = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      display: 'flex'
    },
    filters: {
      width: 310,
      padding: theme.spacing(4),
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      backgroundColor: theme.palette.primary.light,
      flexShrink: 0,
      flexGrow: 0
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4)
    },
    checkbox: {
      position: 'relative',
      top: '-2px',
      padding: '0 4px 0 12px'
    },
    legend: {
      padding: '12px 0'
    },
    card: {
      position: 'relative',
      width: '275px'
    },
    fieldset: {
      marginBottom: theme.spacing(1.5)
    },
    otherFilters: {
      marginTop: theme.spacing(2)
    },
    favoriteButton: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      width: '60px',
      height: '60px'
    },
    favoritesMessage: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '40px',
      padding: '20px 25px',
      '& span': {
        marginLeft: '20px'
      }
    },
    message: {
      fontSize: '18px'
    }
  };
})(RecipesExplorer);