import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '@wanderlost-sdk/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { P, ErrorMessage, Section } from '@wanderlost-sdk/components';
import { withStyles } from '@material-ui/core';
import { withDependencies } from '@symbiotic/green-state';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';

export class TripActivityList extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        trip: PropTypes.object.isRequired,
        apiClient: PropTypes.object.isRequired
    };

    state = {
        activityLogs: [],
        isLoading: true,
        error: null,
    };

    async componentDidMount() {
        const { trip, apiClient } = this.props;

        try {
            const activityLogs = await apiClient.get(trip.links.activityLogs);
            this.setState({ activityLogs });
        } catch (e) {
            let wasHandled = false, error;
            if (e.displayMessage) {
                error = e.displayMessage;
                wasHandled = true;
            } else {
                error = 'Sorry, we failed to load activity for this trip! Please reload the page.';
            }
            this.setState({ error });

            if (!wasHandled || e.statusCode >= 500) {
                throw e;
            }
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { error, isLoading, activityLogs } = this.state;
        const { classes } = this.props;

        return (
            <Section
                title="Changes"
            >
                {isLoading && <P>Loading....</P>}
                {error && <ErrorMessage>{error}</ErrorMessage>}
                {!isLoading && activityLogs.length === 0 &&
                    <P>There hasn't been any activity on this trip yet!</P>
                }
                {!isLoading && activityLogs.length > 0 &&
                    <List dense name="activity-list" className={classes.list}>
                        {activityLogs.map(activity => (
                            <ListItem key={activity.activityId} disableGutters>
                                <Avatar src={activity.pictureURL} />
                                <ListItemText primary={activity.displayMessage} secondary={formatDate(activity.createdTime)} />
                            </ListItem>
                        ))}
                    </List>
                }
            </Section>
        );
    }
}

const activityToShowWithoutScrollbars = 10;

TripActivityList = withDependencies({
    apiClient: WanderLostApiClient,
})(TripActivityList);

TripActivityList = withStyles(() => ({
    list: {
        maxHeight: `${activityToShowWithoutScrollbars * 3.9}rem`,
        overflowY: 'auto'
    }
}))(TripActivityList);
