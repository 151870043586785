"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Banner = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../typography");

var _classnames = _interopRequireDefault(require("classnames"));

var _LayoutContainer = require("./LayoutContainer");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    root: {
      '&::before': {
        content: '""',
        backgroundColor: 'rgba(0, 0, 0, 0.55)',
        zIndex: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      '&::after': {
        content: '""',
        backgroundColor: 'rgba(152, 211, 196, 0.25)',
        zIndex: 2,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      position: 'relative',
      zIndex: 1,
      backgroundSize: 'cover',
      backgroundPosition: '50% 60%',
      height: 290,
      color: '#fff',
      // Vertically/Horizontally center the content
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      // Content should be centered between the bottom of header and top of content area
      // Content area is pulled upward to overlap
      paddingTop: theme.wanderlost.appBarHeight,
      paddingBottom: theme.wanderlost.mainContentPullTopPixels,
      '@media print': {
        height: 125,
        color: theme.palette.text.secondary,
        paddingTop: 15,
        paddingBottom: 0
      }
    },
    rootCenter: {
      '& $wrapper': {
        justifyContent: 'center'
      },
      '& $inner': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    rootLarge: {
      height: 460
    },
    rootMini: {
      padding: 0,
      height: 64
    },
    rootSmall: {
      height: 200
    },
    rootHasNav: {
      '& $inner': {
        paddingBottom: 40 // Include nav height

      }
    },
    title: theme.wanderlost.banner.title,
    subTitle: theme.wanderlost.banner.subTitle,
    wrapper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      position: 'relative',
      zIndex: 3,
      // Above the background images
      display: 'flex',
      alignItems: 'center'
    },
    inner: {},
    content: {},
    nav: {
      position: 'absolute',
      bottom: 0,
      left: 0
    },
    actions: {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(4)
    },
    noPrint: {
      '@media print': {
        display: 'none'
      }
    }
  };
};

var Banner = function Banner(_ref) {
  var _ref2;

  var title = _ref.title,
      subTitle = _ref.subTitle,
      renderContent = _ref.renderContent,
      classes = _ref.classes,
      nav = _ref.nav,
      actions = _ref.actions,
      fullWidth = _ref.fullWidth,
      _ref$imageURL = _ref.imageURL,
      imageURL = _ref$imageURL === void 0 ? '/images/banners/stars.jpg' : _ref$imageURL,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 'medium' : _ref$size,
      _ref$center = _ref.center,
      center = _ref$center === void 0 ? false : _ref$center,
      _ref$shouldPrint = _ref.shouldPrint,
      shouldPrint = _ref$shouldPrint === void 0 ? false : _ref$shouldPrint;
  return _react.default.createElement("div", {
    style: {
      backgroundImage: "url(".concat(imageURL, ")")
    },
    className: (0, _classnames.default)(classes.root, (_ref2 = {}, _defineProperty(_ref2, classes.noPrint, !shouldPrint), _defineProperty(_ref2, classes.rootCenter, center), _defineProperty(_ref2, classes.rootLarge, size === 'large'), _defineProperty(_ref2, classes.rootSmall, size === 'small'), _defineProperty(_ref2, classes.rootMini, size === 'mini'), _defineProperty(_ref2, classes.rootHasNav, nav || actions), _ref2))
  }, _react.default.createElement(_LayoutContainer.LayoutContainer, {
    classes: {
      root: classes.wrapper
    },
    fullWidth: fullWidth
  }, _react.default.createElement("div", {
    className: (0, _classnames.default)(classes.inner)
  }, _react.default.createElement(_typography.P, {
    variant: "h1",
    color: "inherit",
    className: classes.title
  }, title), Boolean(subTitle) && _react.default.createElement(_typography.P, {
    color: "inherit",
    className: classes.subTitle
  }, subTitle), Boolean(renderContent) && _react.default.createElement("div", {
    className: classes.content
  }, renderContent())), Boolean(nav) && _react.default.createElement("div", {
    className: classes.nav
  }, nav), Boolean(actions) && _react.default.createElement("div", {
    className: classes.actions
  }, actions)));
};

exports.Banner = Banner;
Banner.propTypes = {
  title: _propTypes.default.node,
  subTitle: _propTypes.default.node,
  nav: _propTypes.default.node,
  actions: _propTypes.default.node,
  renderContent: _propTypes.default.func,
  classes: _propTypes.default.object.isRequired,
  center: _propTypes.default.bool,
  size: _propTypes.default.string,
  imageURL: _propTypes.default.string,
  fullWidth: _propTypes.default.bool,
  shouldPrint: _propTypes.default.bool
};
exports.Banner = Banner = (0, _core.withStyles)(styles)(Banner);