import React from 'react';
import cn from 'classnames';
import { Display, RichTextContent } from '@wanderlost-sdk/components';
import withStyles from '@material-ui/core/styles/withStyles';

export let TripMealNotes = ({ meal, classes }) => (
    <>
        <Display screen>
            <div className={cn(classes.notes, classes.notesTruncated)}>
                <RichTextContent html={meal.notesHTML} />
            </div>
        </Display>
        <Display print>
            <div className={classes.notes}>
                <RichTextContent html={meal.notesHTML} />
            </div>
        </Display>
    </>
)

TripMealNotes = withStyles(() => ({
    notes: {
        opacity: 0.6,
    },
    notesTruncated: {
        overflow: 'hidden',
        maxHeight: '3.3rem',
    },
}))(TripMealNotes);
