"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EditTripButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _BannerButton = require("../components/Button/BannerButton");

var _react2 = require("../framework/react");

var _EditTripDialog = require("./EditTripDialog");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Edit = _interopRequireDefault(require("@material-ui/icons/Edit"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var EditTripButton = function EditTripButton(_ref) {
  var trip = _ref.trip,
      className = _ref.className;
  return _react.default.createElement(_react2.InjectTogglable, {
    initialValue: false
  }, function (_ref2) {
    var isEditTripDialogVisible = _ref2.isOn,
        showEditTripDialog = _ref2.on,
        hideEditTripDialog = _ref2.off;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_BannerButton.BannerButton, {
      onClick: showEditTripDialog,
      name: "show-edit-trip",
      tooltip: "Edit trip name/dates",
      className: className,
      icon: _react.default.createElement(_Edit.default, null),
      label: "Edit"
    }), isEditTripDialogVisible && _react.default.createElement(_EditTripDialog.EditTripDialog, {
      trip: trip,
      onDone: hideEditTripDialog
    }));
  });
};

exports.EditTripButton = EditTripButton;
EditTripButton.propTypes = {
  trip: _propTypes.default.object.isRequired,
  className: _propTypes.default.string
};