"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureDetailsAdapter = exports.FeatureDetailsAdapterTranformDictionary = exports.FeatureDetailsAdapterConfig = void 0;

var _FeatureAttributeRenderStrategyCollection = require("./FeatureAttributeRenderStrategyCollection");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FeatureDetailsAdapterConfig = function FeatureDetailsAdapterConfig() {
  _classCallCheck(this, FeatureDetailsAdapterConfig);

  _defineProperty(this, "transforms", [{
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    field: 'name',
    strategyId: 'dictionary'
  }, {
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    field: 'districtna',
    strategyId: 'dictionary'
  }, {
    sources: ['motor-vehicle-use-roads'],
    field: 'surfacetyp',
    strategyId: 'dictionary'
  }, {
    sources: ['motor-vehicle-use-trails'],
    field: 'trailclass',
    strategyId: 'dictionary'
  }]);

  _defineProperty(this, "calculatedFields", [{
    field: 'subtitle',
    getValue: function getValue(_ref) {
      var _ref$attributes = _ref.attributes,
          id = _ref$attributes.id,
          importSourceId = _ref$attributes.importSourceId,
          folder_name = _ref$attributes.folder_name;

      if (importSourceId === 'motor-vehicle-use-trails') {
        return "Forest Service Trail ".concat(id, " (MVUM)");
      } else if (importSourceId === 'motor-vehicle-use-roads') {
        return "Forest Service Road ".concat(id, " (MVUM)");
      }

      return folder_name;
    }
  }, {
    field: 'open_dates',
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    getValue: function getValue(_ref2) {
      var _ref2$attributes = _ref2.attributes,
          highclea_1 = _ref2$attributes.highclea_1,
          passenge_1 = _ref2$attributes.passenge_1,
          fourwd_g_1 = _ref2$attributes.fourwd_g_1,
          motorcyc_1 = _ref2$attributes.motorcyc_1,
          atv_dateso = _ref2$attributes.atv_dateso;
      return [{
        label: '4WD wider than 50"',
        value: fourwd_g_1
      }, {
        label: 'High clearance vehicles',
        value: highclea_1
      }, {
        label: 'ATVs',
        value: atv_dateso
      }, {
        label: 'Motorcycles',
        value: motorcyc_1
      }, {
        label: 'Passenger vehicles',
        value: passenge_1
      }].filter(function (a) {
        return a.value;
      });
    }
  }]);

  _defineProperty(this, "renderStrategies", [{
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    field: 'forestname',
    strategyId: 'key-value',
    FeatureAttributeProps: {
      marginTop: false
    }
  }, {
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    field: 'districtna',
    strategyId: 'key-value',
    FeatureAttributeProps: {
      marginTop: false
    }
  }, {
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    field: 'seg_length',
    strategyId: 'key-value',
    label: 'Segment length (miles)'
  }, {
    sources: ['motor-vehicle-use-roads'],
    field: 'sbs_symbol',
    strategyId: 'key-value',
    label: 'Road classification'
  }, {
    sources: ['motor-vehicle-use-trails'],
    field: 'trailclass',
    strategyId: 'key-value',
    label: 'Trail class'
  }, {
    sources: ['motor-vehicle-use-roads'],
    field: 'surfacetyp',
    strategyId: 'key-value',
    label: 'Surface type'
  }, {
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    field: 'open_dates',
    strategyId: 'mvum-open-dates',
    label: 'Open dates'
  }]);
};

exports.FeatureDetailsAdapterConfig = FeatureDetailsAdapterConfig;

var FeatureDetailsAdapterTranformDictionary = function FeatureDetailsAdapterTranformDictionary() {
  var _this = this;

  _classCallCheck(this, FeatureDetailsAdapterTranformDictionary);

  _defineProperty(this, "values", [{
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    field: 'name',
    // the UI has opinions about what the default value should be if no name is present, so just set these meaningless values to null so we get the default
    translations: {
      'Unamed': null,
      'Unnamed': null,
      'Un Named': null,
      'Un- Named': null,
      'Noname': null,
      'No Name': null
    }
  }, {
    sources: ['motor-vehicle-use-roads', 'motor-vehicle-use-trails'],
    field: 'districtna',
    translations: {
      null: 'No Ranger District assigned'
    }
  }, {
    sources: ['motor-vehicle-use-trails'],
    field: 'trailclass',
    translations: {
      null: 'Class not assigned',
      'TC1 - MINIMALLY DEVELOPED': '1- Primitive/Undeveloped: Primitive trail, minimum to nonexistent constructed features',
      'TC2 - MODERATELY DEVELOPED': '2 - Simple/Minor Development: Simple trail, minor development, constructed features for trail',
      'TC3 - DEVELOPED': '3 - Developed/Improved: Substantial trail, structures common, designed for user',
      'TC4 - HIGHLY DEVELOPED': '4 - Highly Developed: High standard trail, significant structures, tread hardening',
      'TC5 - FULLY DEVELOPED': '5 - Fully Developed: Highest standard trail, significant structures, likely hardened'
    }
  }, {
    sources: ['motor-vehicle-use-roads'],
    field: 'surfacetyp',
    translations: {
      null: 'Not specified',
      'AC - ASPHALT': 'Pavement',
      'NATIVE MATERIAL': 'Dirt',
      'OTHER - OTHER': 'Other',
      'P - PAVED': 'Pavement',
      'PCC - PORTLAND CEMENT CONCRETE': 'Other',
      'PIT - PIT RUN SHOT ROCK': 'Other',
      'SOD - GRASS': 'Dirt',
      'AGG - CRUSHED AGGREGATE OR GRAVEL': 'Gravel',
      'AGG - LIMESTONE': 'Gravel',
      'AGG - SCORIA': 'Gravel',
      'BST - BITUMINOUS SURFACE TREATMENT': 'Pavement (bituminous)',
      'CSOIL - COMPACTED SOIL': 'Dirt',
      'FSOIL - FROZEN SOIL': 'Other',
      'IMP - IMPROVED NATIVE MATERIAL': 'Dirt (improved)',
      'NAT - NATIVE MATERIAL': 'Dirt'
    }
  }]);

  this.translate = function (_ref3) {
    var field = _ref3.field,
        source = _ref3.source,
        value = _ref3.value;

    var _ref4 = _this.values.find(function (translationCandidate) {
      return translationCandidate.field === field && (!translationCandidate.sources || translationCandidate.sources.includes(source));
    }) || {},
        _ref4$translations = _ref4.translations,
        translations = _ref4$translations === void 0 ? {} : _ref4$translations;

    return value in translations ? translations[value] : value;
  };
};

exports.FeatureDetailsAdapterTranformDictionary = FeatureDetailsAdapterTranformDictionary;

var FeatureDetailsAdapter = function FeatureDetailsAdapter(config, transformDictionary, renderStrategiesCollection) {
  _classCallCheck(this, FeatureDetailsAdapter);

  this.adapt = function (featureDetails) {
    // We want to ignore rules that specify a source that DOESNT match the feature
    var isSourceMatching = function isSourceMatching(_ref5) {
      var sources = _ref5.sources;
      return !sources || sources.includes(featureDetails.attributes.importSourceId);
    };

    featureDetails.rawAttributes = {};
    var transforms = config.transforms.filter(isSourceMatching);
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = transforms[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var transformConfig = _step.value;

        if (transformConfig.strategyId !== 'dictionary') {
          throw new Error("Only support dictionary transform strategyId, got ".concat(strategyId));
        }

        var field = transformConfig.field,
            strategyId = transformConfig.strategyId; // Don't transform fields that aren't present
        // This could cause the field ot be set to undefined (instead of not being present, causing it to render later)

        if (!(field in featureDetails.attributes)) {
          continue;
        }

        var rawValue = featureDetails.attributes[field];
        featureDetails.rawAttributes[field] = rawValue;
        featureDetails.attributes[field] = transformDictionary.translate({
          field: field,
          source: featureDetails.attributes.importSourceId,
          value: rawValue
        });
      } // all features that go through this adapter (not routes) have more info

    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    featureDetails.attributes.hasMoreInfo = true; // Calculated fields, AFTER field transformations so they can use the transformed values if applicable

    var calculatedFields = config.calculatedFields.filter(isSourceMatching);
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = calculatedFields[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var calculatedFieldConfig = _step2.value;
        var field = calculatedFieldConfig.field,
            getValue = calculatedFieldConfig.getValue;
        featureDetails.attributes[field] = getValue(featureDetails);
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    var renderers = [];
    var renderStrategies = config.renderStrategies.filter(isSourceMatching);
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      var _loop = function _loop() {
        var renderStrategyConfig = _step3.value;

        var strategyId = renderStrategyConfig.strategyId,
            renderParams = _objectWithoutProperties(renderStrategyConfig, ["strategyId"]);

        var field = renderStrategyConfig.field; // Don't render fields where we have no value

        if (!(field in featureDetails.attributes)) {
          return "continue";
        }

        var renderStrategy = renderStrategiesCollection.getById(strategyId);
        var value = featureDetails.attributes[field];
        renderers.push({
          field: field,
          render: function render() {
            return renderStrategy.render(_objectSpread({
              featureDetails: featureDetails,
              value: value
            }, renderParams));
          }
        });
      };

      for (var _iterator3 = renderStrategies[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var _ret = _loop();

        if (_ret === "continue") continue;
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    featureDetails.renderers = renderers;
    return featureDetails;
  };
};

exports.FeatureDetailsAdapter = FeatureDetailsAdapter;

_defineProperty(FeatureDetailsAdapter, "inject", function () {
  return [FeatureDetailsAdapterConfig, FeatureDetailsAdapterTranformDictionary, _FeatureAttributeRenderStrategyCollection.FeatureAttributeRenderStrategyCollection];
});