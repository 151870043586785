import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';
import { Input, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { InjectDelayedTogglable } from '@wanderlost-sdk/components';

export const GroupNameField = ({ groupBy, group }) => {
    const debouncedOnChange = React.useRef(
        debounce(
            newName => {
                // Don't allow renaming to an empty group
                // This could cause it to merge with the empty category
                // Most likely this is caused by someone deleting the contents, then thinking what to type
                // We don't want to rename to empty at that point
                if (newName) {
                    group.rename(newName);
                }
            },
            1000
        )).current;

    return (
        <div>
            <InjectDelayedTogglable delayOnMS={0} delayOffMS={200} initialValue={false}>
                {({ isOn: isHovered, on: onMouseEnter, off: onMouseLeave }) => (
                    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ position: 'relative' }}>
                        {group.delete &&
                            <IconButton
                                style={{ visibility: isHovered ? 'visible' : 'hidden', padding: '0.25em', position: 'absolute', top: 0, left: '-4px', transform: 'translateX(-100%)' }}
                                onClick={group.delete}
                                name="delete-group"
                            >
                                <DeleteIcon color="error" fontSize="small" />
                            </IconButton>
                        }
                        <Input
                            disableUnderline={true}
                            name="group-name"
                            defaultValue={group.name}
                            placeholder={groupBy ? (groupBy === 'category' ? 'Unnamed Category' : 'Unnamed Location') : undefined}
                            onChange={e => debouncedOnChange(e.target.value)}
                            onBlur={e => {
                                // If the person deletes the contents and doesn't enter a name, just reset back to old contents
                                if (!e.target.value) {
                                    e.target.value = group.name;
                                }
                            }}
                            inputRef={ref => ref && group.onFocus(() => { ref.focus(); ref.select(); })}
                            style={{
                                fontSize: '24px'
                            }}
                        />
                    </div>
                )}
            </InjectDelayedTogglable>
        </div>
    );
};

GroupNameField.propTypes = {
    group: PropTypes.object.isRequired,
    groupBy: PropTypes.string,
};
