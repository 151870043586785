"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureLayersStrategy = exports.FeatureLayersStrategyDAO = void 0;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/* globals window:readonly */
// Consumer must provide implementation
// OR override FeatureLayersStrategy so that it doesn't use this


var FeatureLayersStrategyDAO = function FeatureLayersStrategyDAO() {
  _classCallCheck(this, FeatureLayersStrategyDAO);

  _defineProperty(this, "getGlobalTrailsTile", function () {
    throw new Error('Not implemented');
  });

  _defineProperty(this, "getUserTrailsByExtent", function () {
    throw new Error('Not implemented');
  });

  _defineProperty(this, "getNonTrailsByExtent", function () {
    throw new Error('Not implemented');
  });

  _defineProperty(this, "getRoutes", function () {
    throw new Error('Not implemented');
  });

  _defineProperty(this, "getFeatureDetails", function () {
    throw new Error('Not implemented');
  });
};

exports.FeatureLayersStrategyDAO = FeatureLayersStrategyDAO;

var FeatureLayersStrategy = function FeatureLayersStrategy(featureLayersStrategyDAO) {
  _classCallCheck(this, FeatureLayersStrategy);

  this.featureLayers = [{
    layerId: "layer-global-trails",
    minZoomToBeVisible: Number(window.localStorage.getItem('global-trails.minZoomToBeVisible')) || 9,
    layerType: 'vector-tile',
    getGlobalTrailsTile: featureLayersStrategyDAO.getGlobalTrailsTile,
    canContainEditableFeatures: false
  }, {
    layerId: "layer-user-trails",
    minZoomToBeVisible: 7.0,
    featureLoader: {
      strategy: 'extent',
      minZoomToRequestFeatures: 7.0,
      loadFeatures: featureLayersStrategyDAO.getUserTrailsByExtent
    },
    canContainEditableFeatures: true
  }, // We moved this layer before routes because we want global waypoints to be underneath routes and active layers.
  // But note that this also means trail highlights are under routes (which is not ideal).
  {
    layerId: "layer-non-trails",
    minZoomToBeVisible: 9.0,
    featureLoader: {
      strategy: 'extent',
      minZoomToRequestFeatures: 9.0,
      loadFeatures: featureLayersStrategyDAO.getNonTrailsByExtent
    },
    canContainEditableFeatures: true
  }, // Must be below the editing layer or else active layers can be above edited content
  {
    layerId: "active-layers",
    featureLoader: {
      strategy: 'all',
      loadFeatures: function loadFeatures() {
        // TODO: need a new strategy which is empty or self managed or something
        return [];
      }
    },
    canContainEditableFeatures: true,
    styleOptions: {
      opacity: 1
    }
  }, {
    layerId: 'layer-routes',
    featureLoader: {
      strategy: 'all',
      loadFeatures: featureLayersStrategyDAO.getRoutes
    },
    canContainEditableFeatures: true
  }, {
    layerId: "editing",
    featureLoader: {
      strategy: 'all',
      loadFeatures: function loadFeatures() {
        // Little strategy to use the all strategy for this but it's not harmful
        return [];
      }
    },
    canContainEditableFeatures: true
  }];
};

exports.FeatureLayersStrategy = FeatureLayersStrategy;

_defineProperty(FeatureLayersStrategy, "inject", function () {
  return [FeatureLayersStrategyDAO];
});