"use strict";

var _toConsumableArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.arrayToMap = function (array, getKey) {
  return array.reduce(function (map, item) {
    map[getKey(item)] = item;
    return map;
  }, {});
};

function first(arr) {
  return arr[0];
}

exports.first = first;

function last(arr) {
  return arr[arr.length - 1];
}

exports.last = last;

function fillArray(size, obj) {
  return new Array(size).fill(null).map(function (e) {
    return Object.assign({}, obj);
  });
}

exports.fillArray = fillArray;
;

function dedupeArray(arr) {
  return _toConsumableArray(new Set(arr));
}

exports.dedupeArray = dedupeArray;
;