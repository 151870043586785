"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EditFeature = void 0;

var _react = _interopRequireDefault(require("react"));

var _greenState = require("@symbiotic/green-state");

var _state = require("../state");

var _forms = require("../forms");

var _FeatureConverter = require("../tools/FeatureConverter");

var _DrawerContent = require("../folders/DrawerContent");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var EditFeatureComponentsByType = {
  'trail': _forms.TrailForm,
  'waypoint': _forms.MarkerFeatureForm,
  'text-annotation': _forms.MarkerFeatureForm
};

var EditFeature = function EditFeature() {
  var editingFeatureStateInstance = (0, _greenState.useInstance)(_state.EditingFeatureState);
  var featureConverter = (0, _greenState.useInstance)(_FeatureConverter.FeatureConverter);
  var editingFeaturesState = (0, _greenState.useSubscription)(function () {
    return editingFeatureStateInstance;
  });

  if (!editingFeaturesState || !editingFeaturesState.feature) {
    return null;
  }

  var olFeature = editingFeaturesState.feature,
      title = editingFeaturesState.title,
      save = editingFeaturesState.save,
      cancel = editingFeaturesState.cancel,
      onChange = editingFeaturesState.onChange,
      FeatureFormProps = editingFeaturesState.FeatureFormProps;
  var feature = featureConverter.fromOpenLayerToAPI(olFeature);
  var EditFeatureComponent = EditFeatureComponentsByType[feature.attributes.type];

  if (!EditFeatureComponent) {
    throw new Error("Could not find feature form for type ".concat(feature.attributes.type));
  }

  return _react.default.createElement(_DrawerContent.DrawerContent, {
    title: title
  }, _react.default.createElement(EditFeatureComponent, _extends({
    feature: feature,
    onSubmit: save,
    onCancel: cancel,
    onChange: onChange ? function (properties) {
      return onChange({
        olFeature: olFeature,
        properties: properties
      });
    } : undefined
  }, FeatureFormProps)));
};

exports.EditFeature = EditFeature;