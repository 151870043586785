"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DrawerButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _components = require("@wanderlost-sdk/components");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var DrawerButton = function DrawerButton(_ref) {
  var classes = _ref.classes,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ["classes", "children"]);

  return _react.default.createElement(_components.Button, _extends({
    className: classes.root,
    size: "small",
    variant: "outlined",
    color: "primary"
  }, props), children);
};

exports.DrawerButton = DrawerButton;
exports.DrawerButton = DrawerButton = (0, _withStyles.default)(function () {
  return {
    root: {
      lineHeight: 1,
      alignSelf: 'center',
      padding: '4px 8px',
      minWidth: 0,
      fontSize: '0.7rem'
    }
  };
})(DrawerButton);