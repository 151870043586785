"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WaypointIconCollection = void 0;

var WaypointIconCollection = function WaypointIconCollection() {
  _classCallCheck(this, WaypointIconCollection);

  var fontAwesomeIconFontSize = 14;
  var icons = [{
    iconId: 'marker',
    label: 'Marker',
    text: "\uF3C5",
    iconHint: 'fas fa-map-marker-alt',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'water',
    label: 'Water',
    text: "\uE005",
    iconHint: 'fas fa-faucet',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    gpxSymbol: 'Drinking Water',
    isUserSelectable: true
  }, {
    iconId: 'fuel',
    label: 'Fuel',
    text: "\uF52F",
    iconHint: 'fas fa-gas-pump',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'toilets',
    label: 'Toilets',
    text: "\uF7BD",
    iconHint: 'fas fa-restroom',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'campground',
    label: 'Campground',
    text: "\uF6BB",
    iconHint: 'fas fa-campground',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'shower',
    label: 'Shower',
    text: "\uF2CC",
    iconHint: 'fas fa-shower',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'restaurant',
    label: 'Restaurant',
    text: "\uF2E7",
    iconHint: 'fas fa-utensils',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'groceries',
    label: 'Groceries',
    text: "\uF07A",
    iconHint: 'fas fa-shopping-cart',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'cafe',
    label: 'Cafe',
    text: "\uF0F4",
    iconHint: 'fas fa-coffee',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'drinks',
    label: 'Drinks',
    text: "\uF0FC",
    iconHint: 'fas fa-beer',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'road-closed',
    label: 'Road closed',
    text: "\uF056",
    iconHint: 'fas fa-minus-circle',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'danger',
    label: 'Danger',
    text: "\uF714",
    iconHint: 'fas fa-skull-crossbones',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'cell-coverage',
    label: 'Cell coverage',
    text: "\uF012",
    iconHint: 'fas fa-signal',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'hiking',
    label: 'Hiking',
    text: "\uF6EC",
    iconHint: 'fas fa-hiking ',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'swimming',
    label: 'Swimming',
    text: "\uF5C4",
    iconHint: 'fas fa-swimmer',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'biking',
    label: 'Biking',
    text: "\uF84A",
    iconHint: 'fas fa-biking',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'fishing',
    label: 'Fishing',
    text: "\uF578",
    iconHint: 'fas fa-fish',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'hot-springs',
    label: 'Hot Springs',
    text: "\uF593",
    iconHint: 'fas fa-hot-tub',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'supplies',
    label: 'Supplies',
    text: "\uF54F",
    iconHint: 'fas fa-store-alt',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    isUserSelectable: true
  }, {
    iconId: 'vista',
    label: 'Scenic Vista',
    text: "\uF03E",
    iconHint: 'fas fa-image',
    fontFamily: 'Font Awesome 5 Free',
    fontSize: fontAwesomeIconFontSize,
    gpxSymbol: 'Scenic Area',
    isUserSelectable: true
  }, {
    iconId: 'whole-foods',
    label: 'Whole Foods',
    text: "B",
    iconHint: 'custom-marker-wf',
    fontFamily: 'wanderlost custom markers',
    fontSize: 20,
    isUserSelectable: false
  }, {
    iconId: 'rei',
    label: 'REI',
    text: "A",
    iconHint: 'custom-marker-rei',
    fontFamily: 'wanderlost custom markers',
    fontSize: 24,
    isUserSelectable: false
  }];

  this.getById = function (iconId) {
    return icons.find(function (icon) {
      return icon.iconId === iconId;
    });
  };

  this[Symbol.iterator] = function () {
    return icons[Symbol.iterator]();
  };
};

exports.WaypointIconCollection = WaypointIconCollection;