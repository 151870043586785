import auth0 from 'auth0-js';
import { QueryStrings } from '@wanderlost-sdk/components';
import { Log } from '../debugging';

export class Auth0AuthServiceConfig {
    LOGOUT_REDIRECT_URL = ''
    SESSION_STORAGE_KEY = ''
    CLAIM_NAMESPACE = ''
}

export class Auth0AuthService {
    static inject(){ return [auth0.WebAuth, Auth0AuthServiceConfig, QueryStrings, Log]; }
    constructor(auth0, { LOGOUT_REDIRECT_URL, SESSION_STORAGE_KEY, CLAIM_NAMESPACE }, queryStrings, log){
        this.login = () => {
            if (isOnAuth0CallbackURL()){
                return loginFromAuth0CallbackURL();
            }
            else if (isAutomatedTestsBypassingLogin()){
                // return never ending promise, test suite will reload after setting a session in local storage
                return new Promise(resolve => {});
            }
            else if (isAlreadyLoggedIn()){
                return loginFromLocalStorage();
            }
            else {
                // authPage can be 'signup', 'login', 'forgot-password'
                const initialPage = queryStrings.get('authPage') || 'login';
                queryStrings.remove('authPage');
                return redirectToAuth0({ initialPage });
            }
        };

        this.forceLogin = () => {
            console.log('Got 401 from API, likely token is expired. Forcing a login.') // eslint-disable-line no-console
            removeUserFromLocalStorage();
            redirectToAuth0();
            // Return a promise that never resolves since we are forcing a login
            return new Promise(resolve => {});
        }

        this.switchLogin = ({ accessToken }) => {
            window.localStorage.setItem(SESSION_STORAGE_KEY,  JSON.stringify({ accessToken }));
        };

        this.logout = ({ redirectToAuth0 = true} = {}) => {
            removeUserFromLocalStorage();
            if (redirectToAuth0){
                auth0.logout({
                    returnTo: LOGOUT_REDIRECT_URL
                });
            }
        };

        function removeUserFromLocalStorage(){
            window.localStorage.removeItem(SESSION_STORAGE_KEY);
        }

        function isAlreadyLoggedIn(){
            return Boolean(window.localStorage.getItem(SESSION_STORAGE_KEY));
        }

        function isOnAuth0CallbackURL(){
            return window.location.hash.indexOf('access_token') > -1;
        }

        function loginFromAuth0CallbackURL () {
            return new Promise(resolve => {
                // We observed the callback to parseHash being invoked multiple times (but NOT on localhost)
                // This caused the redirect to not work (because the second time it always set the hash to '')
                // We confirmed that loginFromAuth0CallbackURL was NOT being called more than once, just the parseHash callback
                // So we assumed this was some kind of bug in auth0 and not working digging into if this fixes it, but if we have more trouble with this we should dig in
                let wasHandled = false;
                auth0.parseHash((err, authResult) => {
                    if (wasHandled) {
                        return;
                    }

                    wasHandled = true;

                    if (authResult && authResult.accessToken && authResult.idToken) {
                        const accessToken = authResult.accessToken;
                        window.localStorage.setItem(SESSION_STORAGE_KEY,  JSON.stringify({ accessToken }));
                        // cache the hash to redirect after login is complete
                        const hash = window.localStorage.getItem('previous-hash') || '';
                        window.localStorage.removeItem('previous-hash');
                        window.location.hash = hash;
                        const userInfo = convertAuthResultToUser(authResult);
                        resolve({ accessToken, userInfo });
                    } else if (err) {
                        // There are many ways this can happen
                        // 1) you refresh with the access token in the url, more reason never to leave the access token in the url ;)
                        // 2) you bookmark or re-use a url with an access token that has already been used
                        // 3) you log out and sit on the login page on auth0 long enough then log in
                        // 4) you start a login and then dont finish it and come back to it after enough time on auth0
                        // We could reduce the likelihood by NOT redirecting to auth0 after logout (stay on our site) so you arent on an expiring page
                        // And we could potentially improve the user experience by explaining before redirecting, although that's debatable not an improvement b/c you have to click an extra button
                        // We at least want to be notified so we can tell how frequently this is happening
                        // We never call resolve/reject so the app doesnt continue booting, we are redirecting anyway
                        log.error(new Error(`Login failed. ${err.error} ${err.errorDescription}`), { err });
                        redirectToAuth0();
                    }
                });
            });
        };

        function convertAuthResultToUser(authResult){
            const token = authResult.idTokenPayload;

            const user = {
                userId: token.sub,
                name: token.name,
                pictureURL: token.picture,
                email: token.email,
                emailVerified: token.email_verified
            };

            // drop name if it matches email so we don't overwrite real name provided in consent form
            if (!user.name || user.name === user.email) {
                delete user.name;
            }

            return user;
        }

        function loginFromLocalStorage(){
            const { accessToken } = JSON.parse(window.localStorage.getItem(SESSION_STORAGE_KEY));
            return { accessToken };
        }

        function redirectToAuth0({ initialPage = 'login' } = {}){
            // cache the hash to redirect after login is complete
            window.localStorage.setItem('previous-hash', window.location.hash.substring(1));

            // We send the param `mode` to auth0 to force a different initial page.
            // Note that auth0 only passes recognized params (e.g. "mode") through
            // to `config.extraParams` in our custom template, so we can't use an
            // arbitrary param key like `initialPage`.
            auth0.authorize({
                mode: initialPage,
                prompt: 'login' // Force/allow the user to choose which account
            });

            // login will never be complete - b/c the page is about to redirect to auth0
            return new Promise(resolve => {});
        }

        function isAutomatedTestsBypassingLogin(){
            return window.location.search === '?__test_no_login_redirect';
        }
    }
}
