"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToolInstructions = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _Paper = _interopRequireDefault(require("@material-ui/core/Paper"));

var _classnames = _interopRequireDefault(require("classnames"));

var _Help = _interopRequireDefault(require("@material-ui/icons/Help"));

var _DrawingManager = require("./DrawingManager");

var _components = require("@wanderlost-sdk/components");

var _greenState = require("@symbiotic/green-state");

var _MapIconButton = require("./MapIconButton");

var _ElevationProfileState = require("./state/ElevationProfileState");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    container: {
      position: 'absolute',
      bottom: '1rem',
      left: "1rem",
      zIndex: theme.wanderlost.zIndex.mapControls
    },
    containerAboveElevationProfile: {
      bottom: "calc(1rem + ".concat(theme.wanderlost.elevationProfileHeight, " + ").concat(theme.spacing(1), "px)")
    },
    containerAboveElevationProfileCollapsed: {
      bottom: "calc(1rem + ".concat(theme.wanderlost.elevationProfileHeightCollapsed, " + ").concat(theme.spacing(1), "px)")
    },
    content: {
      padding: theme.spacing(2)
    },
    subTitle: {
      marginBottom: "".concat(theme.spacing(2), "px")
    }
  };
};

var ToolInstructions = function ToolInstructions(_ref) {
  var classes = _ref.classes;
  var drawingManagerStateInstance = (0, _greenState.useInstance)(_DrawingManager.DrawingManager);
  var drawingManagerState = (0, _greenState.useSubscription)(function () {
    return drawingManagerStateInstance;
  });
  var elevationProfileStateInstance = (0, _greenState.useInstance)(_ElevationProfileState.ElevationProfileState);
  var elevationProfileState = (0, _greenState.useSubscription)(function () {
    return elevationProfileStateInstance;
  }) || {
    isVisible: false,
    isCollapsed: false
  };

  if (!drawingManagerState) {
    return null;
  }

  var content = drawingManagerState.getHelpText();

  if (!content) {
    return null;
  }

  return _react.default.createElement(_components.InjectDelayedTogglable, {
    delayOnMS: 50,
    delayOffMS: 500,
    initialValue: false
  }, function (_ref2) {
    var _ref3;

    var isOpen = _ref2.isOn,
        open = _ref2.on,
        close = _ref2.off,
        clearCloseTimeout = _ref2.clearTimeout;
    return _react.default.createElement("div", {
      id: "tool-instructions",
      className: (0, _classnames.default)(classes.container, (_ref3 = {}, _defineProperty(_ref3, classes.containerAboveElevationProfile, elevationProfileState.isVisible), _defineProperty(_ref3, classes.containerAboveElevationProfileCollapsed, elevationProfileState.isVisible && elevationProfileState.isCollapsed), _ref3))
    }, isOpen ? _react.default.createElement(_Paper.default, {
      className: classes.content,
      onMouseLeave: close,
      onMouseEnter: clearCloseTimeout
    }, _react.default.createElement(_components.H3, {
      color: "textSecondary",
      className: classes.subTitle
    }, "Instructions"), content({
      classes: classes
    })) : _react.default.createElement(_MapIconButton.MapIconButton, {
      onMouseOver: open
    }, _react.default.createElement(_Help.default, null)));
  });
};

exports.ToolInstructions = ToolInstructions;
ToolInstructions.propTypes = {
  classes: _propTypes.default.object.isRequired
}; // eslint-disable-next-line no-class-assign

exports.ToolInstructions = ToolInstructions = (0, _withStyles.default)(styles)(ToolInstructions);