"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SectionV2 = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../../typography");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles(theme) {
  return {
    container: {
      marginBottom: theme.spacing(8)
    },
    content: {},
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    },
    title: {
      margin: 0
    },
    actions: {
      marginLeft: 'auto'
    },
    image: {
      marginRight: theme.spacing(2),
      width: 50,
      '& img': {
        maxWidth: '100%'
      }
    },
    '@media (min-width: 1025px)': {
      content: {
        marginLeft: 25
      }
    }
  };
};

var SectionV2 = function SectionV2(_ref) {
  var classes = _ref.classes,
      title = _ref.title,
      actions = _ref.actions,
      children = _ref.children,
      iconImageURL = _ref.iconImageURL,
      rest = _objectWithoutProperties(_ref, ["classes", "title", "actions", "children", "iconImageURL"]);

  return _react.default.createElement("div", _extends({
    className: classes.container
  }, rest), _react.default.createElement("div", {
    className: classes.titleContainer
  }, Boolean(iconImageURL) && _react.default.createElement("div", {
    className: classes.image
  }, _react.default.createElement("img", {
    src: iconImageURL
  })), _react.default.createElement(_typography.H2, {
    className: classes.title
  }, title), Boolean(actions) && _react.default.createElement("div", {
    className: classes.actions
  }, actions)), _react.default.createElement("div", {
    className: classes.content
  }, children));
};

exports.SectionV2 = SectionV2;
SectionV2.propTypes = {
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired,
  title: _propTypes.default.node.isRequired,
  actions: _propTypes.default.node,
  iconImageURL: _propTypes.default.string.isRequired
};
exports.SectionV2 = SectionV2 = (0, _core.withStyles)(styles)(SectionV2);