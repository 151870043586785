import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { formatWeight } from '@wanderlost-sdk/core';
import { P, H2, ColumnHeading } from '@wanderlost-sdk/components';
import { Divider, Checkbox } from '@material-ui/core';
import { PackingListTotalWeight } from './PackingListTotalWeight';
import Avatar from '@material-ui/core/Avatar';
import { SimplePackingListSummary } from './SimplePackingListSummary';
import cn from 'classnames';

export let PackingList = ({ packingList, sortBy, sortDirection, onSort, classes, groupHeadingProps = {}, print = false, showOwner = false }) => {
    React.useEffect(() => {
        if (print) {
            window.print();
        }
    }, [/* on mount only */]);

    const sortProps = { sortBy, sortDirection, onSort };
    const isAdvancedList = packingList.items.some(item => item.category || item.notes || item.location || item.weight);

    if (!isAdvancedList && !showOwner) {
        // The filter in TripPackingList that loads this component is float:left so other components below appear
        // to the right of it. But here in simple view, there's nothing to the right, so we need clear:both.
        return (
            <div style={{clear: 'both'}}>
                <SimplePackingListSummary packingList={packingList} />
            </div>
        );
    }

    const fields = packingList.getFields();
    const groups = packingList.getGroups();
    const visibleColumnCount = (showOwner ? 1 : 0) + Object.keys(fields).filter(f => fields[f].isVisible).length;

    return (
        <table style={{ clear: 'both' }}>
            <thead data-type="column-headings" className={classes.headerRow}>
                <tr>
                    <td colSpan={visibleColumnCount} className={classes.weight + ' ' + classes.cell}>
                        <PackingListTotalWeight items={packingList.items} />
                    </td>
                </tr>
                <tr>
                    <th className={classes.checkColumn + ' ' + classes.headerCell + ' ' + classes.cell}>
                        <ColumnHeading>&nbsp;</ColumnHeading>
                    </th>
                    {showOwner &&
                        <th className={classes.ownerColumn + ' ' + classes.headerCell + ' ' + classes.cell}>
                            <ColumnHeading name="owner" {...sortProps}>&nbsp;</ColumnHeading>
                        </th>
                    }
                    {fields.name.isVisible &&
                        <th className={classes.nameColumn + ' ' + classes.headerCell + ' ' + classes.cell}>
                            <ColumnHeading name="name" {...sortProps}>Item Name</ColumnHeading>
                        </th>
                    }
                    {fields.category.isVisible &&
                        <th className={classes.categoryColumn + ' ' + classes.headerCell + ' ' + classes.cell}>
                            <ColumnHeading name="category" {...sortProps}>Category</ColumnHeading>
                        </th>
                    }
                    {fields.location.isVisible &&
                        <th className={classes.locationColumn + ' ' + classes.headerCell + ' ' + classes.cell}>
                            <ColumnHeading name="location" {...sortProps}>Packed Location</ColumnHeading>
                        </th>
                    }
                    {fields.weight.isVisible &&
                        <th className={classes.weightColumn + ' ' + classes.headerCell + ' ' + classes.cell}>
                            <ColumnHeading name="weight" {...sortProps}>Weight</ColumnHeading>
                        </th>
                    }
                    {fields.qty.isVisible &&
                        <th className={classes.qtyColumn + ' ' + classes.headerCell + ' ' + classes.cell}>
                            <ColumnHeading name="qty" {...sortProps}>Qty</ColumnHeading>
                        </th>
                    }
                </tr>
            </thead>
            {packingList.items.length === 0 &&
                <tr>
                    <td colSpan={visibleColumnCount}>
                        <P style={{ textAlign: 'center', fontSize: '120%', marginTop: '12px', borderTop: '1px solid #e5e5e5', padding: '12px 0' }}>No items added.</P>
                    </td>
                </tr>
            }
            {groups.map(group => {
                // Skip rendering groups that have no items in them
                const items = group.items.filter(item => Boolean(item.name));

                return (
                    <tbody name="item-group" key={group.name || 'none'} data-grouping={group.name || ''}>
                        {'name' in group &&
                            <tr>
                                <td colSpan={visibleColumnCount} className={classes.groupHeader}>
                                    <H2 gutterBottom={false} {...groupHeadingProps}>{group.name || `Unnamed ${packingList.groupBy === 'category' ? 'Category' : 'Location'}`}</H2>
                                    <Divider style={{ margin: '4px 0 8px' }} />
                                </td>
                            </tr>
                        }
                        {items.length === 0 &&
                            <tr>
                                <td colSpan={visibleColumnCount}>
                                    <P><em>No items added.</em></P>
                                </td>
                            </tr>
                        }
                        {items.map(item => (
                            <React.Fragment key={item.itemId}>
                                <tr name="packing-list-item" className={classes.itemRow + ' ' + (fields.notes.isVisible && Boolean(item.notes) ? classes.itemRowNotes : '')}>
                                    <td className={classes.checkColumn + ' ' + classes.cell}>
                                        <Checkbox size="small" className={classes.checkbox} />
                                    </td>
                                    {showOwner &&
                                        <td style={{ width: '46px' }} name="owner" className={classes.cell + ' ' + classes.ownerColumn}>
                                            <Avatar
                                                alt={item.owner.name}
                                                src={item.owner.pictureURL}
                                                style={{ width: '30px', height: '30px', position: 'relative', top: '-4px' }}
                                            />
                                        </td>
                                    }
                                    {fields.name.isVisible &&
                                        <td name="name" className={classes.nameColumn + ' ' + classes.cell}>
                                            {item.name}
                                        </td>
                                    }
                                    {fields.category.isVisible &&
                                        <td name="category" className={classes.categoryColumn + ' ' + classes.cell}>
                                            {item.category}
                                        </td>
                                    }
                                    {fields.location.isVisible &&
                                        <td name="location" className={classes.locationColumn + ' ' + classes.cell}>
                                            {item.location}
                                        </td>
                                    }
                                    {fields.weight.isVisible &&
                                        <td name="weight" className={classes.weightColumn + ' ' + classes.cell}>
                                            {item.weight ? formatWeight(item.weight) : ''}
                                        </td>
                                    }
                                    {fields.qty.isVisible &&
                                        <td className={classes.qtyColumn + ' ' + classes.cell} name="qty">
                                            {item.qty}
                                        </td>
                                    }
                                </tr>
                                {fields.notes.isVisible && Boolean(item.notes) &&
                                    <tr>
                                        <td colSpan={visibleColumnCount} className={cn(classes.notesRow)}>
                                            <span name="notes" className={cn(classes.notes, { [classes.notesShowOwner]: showOwner })}>{item.notes}</span>
                                        </td>
                                    </tr>
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                );
            })}
        </table>
    );
};

PackingList.propTypes = {
    groupHeadingProps: PropTypes.object,
    packingList: PropTypes.object.isRequired,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    onSort: PropTypes.func,
    classes: PropTypes.object.isRequired,
    print: PropTypes.bool,
};

PackingList = withStyles((theme) => ({
    groupHeader: {
        marginTop: theme.spacing(4)
    },
    cell: {
        lineHeight: 1.2,
        padding: 6,
        paddingRight: 16,
        paddingLeft: 0,
        verticalAlign: 'top',
    },
    itemRowNotes: {},
    '$itemRowNotes $cell':  {
        paddingBottom: 0
    },
    headerCell: {
        textAlign: 'left',
    },
    nameColumn: {
        minWidth: '20%',
        maxWidth: 300,
    },
    categoryColumn: {
        minWidth: '20%',
        maxWidth: 300,
    },
    locationColumn: {
        minWidth: '20%',
        maxWidth: 300,
    },
    weightColumn: {
        minWidth: '15%',
        maxWidth: 200,
        whiteSpace: 'nowrap',
    },
    ownerColumn: { paddingBottom: 0, width: '46px', textAlign: 'center', '& svg': { left: '-10px' } },
    qtyColumn: { width: '100px', textAlign: 'center' },
    notesRow: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
        marginBottom: theme.spacing(2),
        paddingTop: 0,
    },
    notes: {
        marginLeft: '1px',
        paddingLeft: '10px',
        borderLeft: '5px solid rgba(0, 0, 0, 0.05)',
    },
    notesShowOwner: {
        marginLeft: '46px',
    },
    checkColumn: { paddingTop: 2, display: 'none', },

    // when printing, the heading cols don't align with the item cols unless we set all their widths :(
    '@media print': {
        checkbox: { padding: 0, position: 'relative', top: '3px', left: '4px', '& svg': { width: '20px' }},
        checkColumn: { display: 'table-cell', width: '20px' },
        qtyColumn: { width: '50px' },
        nameColumn: { width: '180px' },
        categoryColumn: { width: '140px' },
        locationColumn: { width: '190px' },
        notesRow: { marginLeft: '20px' },
        weightColumn: { width: '120px' },
        sharedColumn: { width: '140px' },
        headerRow: { position: 'relative', left: '-20px' },
        itemRow: { position: 'relative', left: '-20px' },
    }
}))(PackingList);
