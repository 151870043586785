"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CenterOnEditedFeaturePlugin = void 0;

var _EditingFeatureState = require("../state/EditingFeatureState");

var _Cartographer = require("../Cartographer");

var _EventBus = require("../EventBus");

var _theme = require("../theme");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var CenterOnEditedFeaturePlugin = function CenterOnEditedFeaturePlugin(editingFeatureState, eventBus, cartographerProvider, theme) {
  _classCallCheck(this, CenterOnEditedFeaturePlugin);

  var onDispose = function onDispose() {};

  this.start = function () {
    eventBus.on('Drawer.openedOrClosed', function (_ref) {
      var activeTabId = _ref.activeTabId;
      // Pad the drawer width b/c the buttons that float on the map can also cover the feature
      var drawerWidth = parseInt(theme.wanderlost.drawerWidth);

      var _editingFeatureState$ = editingFeatureState.get(),
          olFeature = _editingFeatureState$.feature; // If drawer isn't open
      // ...or there is no feature being edited
      // ...or the feature being edited isnt a point
      // We don't support non-point geometries b/c there is a high likelihood that part of a trail will remain visible
      // And keeping the part that you originally clicked on to edit visible requires we track where you clicked when you started editing


      if (!activeTabId || !olFeature || olFeature.getGeometry().getType() !== 'Point') {
        return;
      } // Otherwise, if the edited feature is off the map, move the map center so it's visible
      // This can happen if you edit a feature on the left or right edge of the screen when the drawer is closed
      // We aren't concerned about y b/c the drawer reduces the width of the map, not the height


      var cartographer = cartographerProvider.get();

      var map = cartographer.__getOLMap({
        hideWarning: true
      });

      var _map$getSize = map.getSize(),
          _map$getSize2 = _slicedToArray(_map$getSize, 1),
          mapWidth = _map$getSize2[0];

      var minAllowed = drawerWidth / 2 + 30; // 1/2 drawer plus it shouldnt be too close to the edge

      var maxAllowed = mapWidth - drawerWidth / 2 - 100; // 1/2 drawer plus it should be too close to edge or under the drawer controls

      var _map$getPixelFromCoor = map.getPixelFromCoordinate(olFeature.getGeometry().getCoordinates()),
          _map$getPixelFromCoor2 = _slicedToArray(_map$getPixelFromCoor, 1),
          positionX = _map$getPixelFromCoor2[0];

      var offset = 0; // too far to the left? push the center to the left (positive offset)

      if (positionX < minAllowed) {
        offset = positionX - minAllowed; // too far to the right? pull the center to the left (negative offset)
      } else if (positionX > maxAllowed) {
        offset = -(maxAllowed - positionX);
      } // pan the map


      if (offset !== 0) {
        var center = map.getView().getCenter();
        var centerInPx = map.getPixelFromCoordinate(center);
        var newCenterInPx = [centerInPx[0] + offset, centerInPx[1]];
        var newCenter = map.getCoordinateFromPixel(newCenterInPx);
        map.getView().setCenter(newCenter);
      }
    });
  };

  this.dispose = function () {
    onDispose();
  };
};

exports.CenterOnEditedFeaturePlugin = CenterOnEditedFeaturePlugin;

_defineProperty(CenterOnEditedFeaturePlugin, "inject", function () {
  return [_EditingFeatureState.EditingFeatureState, _EventBus.EventBus, _Cartographer.CartographerProvider, _theme.Theme];
});