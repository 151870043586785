"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureStylerCollection = void 0;

var _TrailStyler = require("./TrailStyler");

var _TrailHighlightStyler = require("./TrailHighlightStyler");

var _WaypointStyler = require("./WaypointStyler");

var _TextAnnotationStyler = require("./TextAnnotationStyler");

var _RouteStyler = require("./RouteStyler");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FeatureStylerCollection = function FeatureStylerCollection() {
  for (var _len = arguments.length, stylers = new Array(_len), _key = 0; _key < _len; _key++) {
    stylers[_key] = arguments[_key];
  }

  _classCallCheck(this, FeatureStylerCollection);

  this.getForFeature = function (feature) {
    return stylers.find(function (styler) {
      return styler.featureType === feature.get('type');
    });
  };
};

exports.FeatureStylerCollection = FeatureStylerCollection;

_defineProperty(FeatureStylerCollection, "inject", function () {
  return [_TrailStyler.TrailStyler, _TrailHighlightStyler.TrailHighlightStyler, _WaypointStyler.WaypointStyler, _TextAnnotationStyler.TextAnnotationStyler, _RouteStyler.RouteStyler];
});