"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipeDetails = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _greenState = require("@symbiotic/green-state");

var _components = require("../components");

var _typography = require("../typography");

var _urls = require("../urls");

var _ContentfulRichText = require("./ContentfulRichText");

var _RecipeTag = require("./RecipeTag");

var _RecipeImage = require("./RecipeImage");

var _RecipeAttribute = require("./RecipeAttribute");

var _ChefIcon = require("../components/icons/ChefIcon");

var _AccessTime = _interopRequireDefault(require("@material-ui/icons/AccessTime"));

var _People = _interopRequireDefault(require("@material-ui/icons/People"));

var _OutdoorGrill = _interopRequireDefault(require("@material-ui/icons/OutdoorGrill"));

var _FavoriteButton = require("./FavoriteButton");

var _RecipeHeadings = require("./RecipeHeadings");

var _AddToMealButton = require("./AddToMealButton");

var _RemoveFromMealButton = require("./RemoveFromMealButton");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RecipeDetails = function RecipeDetails(_ref) {
  var recipe = _ref.recipe,
      toggleFavorite = _ref.toggleFavorite,
      onSelect = _ref.onSelect,
      onRemove = _ref.onRemove,
      isSelected = _ref.isSelected,
      classes = _ref.classes;
  var name = recipe.name,
      description = recipe.description,
      meal = recipe.meal,
      difficulty = recipe.difficulty,
      time = recipe.time,
      servings = recipe.servings,
      instructions = recipe.instructions,
      ingredientList = recipe.ingredientList,
      notes = recipe.notes,
      specialEquipment = recipe.specialEquipment,
      tag = recipe.tag;
  var navigator = (0, _greenState.useInstance)(_urls.WanderLostNavigator);
  return _react.default.createElement(_core.Grid, {
    container: true,
    className: classes.container
  }, _react.default.createElement(_core.Grid, {
    item: true,
    className: classes.topRow
  }, _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 3,
    justify: "space-between"
  }, _react.default.createElement(_core.Grid, {
    "data-id": "top-left",
    item: true,
    xs: 6,
    className: classes.metadataColumn
  }, _react.default.createElement(_RecipeHeadings.H1, null, name), _react.default.createElement("div", {
    "data-id": "tags"
  }, tag && tag.map(function (t) {
    return _react.default.createElement(_RecipeTag.RecipeTag, {
      key: t.tagId,
      label: t.name,
      color: t.color || '#999999',
      onClick: onSelect ? undefined : function () {
        return navigator.recipes({
          tag: [t.tagId]
        }).go();
      }
    });
  }), meal && meal.map(function (m) {
    return _react.default.createElement(_RecipeTag.RecipeTag, {
      key: m.mealId,
      label: m.name,
      color: m.color || '#666666',
      onClick: onSelect ? undefined : function () {
        return navigator.recipes({
          meal: [m.mealId]
        }).go();
      }
    });
  })), _react.default.createElement("div", {
    "data-id": "description"
  }, _react.default.createElement(_ContentfulRichText.ContentfulRichText, {
    className: classes.description,
    content: description
  })), _react.default.createElement("div", {
    "data-id": "attributes",
    className: classes.attributes
  }, _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 5
  }, _react.default.createElement(_core.Grid, {
    item: true,
    sm: 6
  }, _react.default.createElement(_RecipeAttribute.RecipeAttribute, {
    label: "Time",
    text: time,
    icon: _AccessTime.default
  })), _react.default.createElement(_core.Grid, {
    item: true,
    sm: 6
  }, _react.default.createElement(_RecipeAttribute.RecipeAttribute, {
    label: "Difficulty",
    text: difficulty,
    icon: _ChefIcon.ChefIcon
  }))), _react.default.createElement(_core.Divider, {
    className: classes.attributesDivider
  }), _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 5
  }, _react.default.createElement(_core.Grid, {
    item: true,
    sm: 6
  }, _react.default.createElement(_RecipeAttribute.RecipeAttribute, {
    label: "Serves",
    text: servings,
    icon: _People.default
  })), specialEquipment && _react.default.createElement(_core.Grid, {
    item: true,
    sm: 6
  }, _react.default.createElement(_RecipeAttribute.RecipeAttribute, {
    label: "Special equipment",
    text: specialEquipment,
    icon: _OutdoorGrill.default
  }))))), _react.default.createElement(_core.Grid, {
    "data-id": "recipe-image",
    item: true,
    xs: 6,
    className: classes.imageColumn
  }, _react.default.createElement("div", {
    style: {
      position: 'relative'
    }
  }, onSelect && !isSelected(recipe) && _react.default.createElement(_AddToMealButton.AddToMealButton, {
    onClick: function onClick() {
      return onSelect(recipe);
    }
  }), onRemove && isSelected(recipe) && _react.default.createElement(_RemoveFromMealButton.RemoveFromMealButton, {
    onClick: function onClick() {
      return onRemove(recipe);
    }
  }), _react.default.createElement(_RecipeImage.RecipeImage, {
    recipe: recipe,
    preventNavigation: Boolean(onSelect)
  }), _react.default.createElement("div", {
    className: classes.favoriteButton
  }, _react.default.createElement(_components.Display, {
    screen: true
  }, _react.default.createElement(_FavoriteButton.FavoriteButton, {
    selected: recipe.favorite,
    onClick: function onClick() {
      return toggleFavorite(recipe);
    }
  }))))))), _react.default.createElement(_core.Grid, {
    item: true,
    style: {
      paddingTop: '30px'
    }
  }, _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 5
  }, _react.default.createElement(_core.Grid, {
    "data-id": "bottom-left",
    item: true,
    xs: 5
  }, _react.default.createElement(_core.Paper, {
    className: classes.ingredients
  }, _react.default.createElement(_typography.H2, {
    className: classes.sectionHeading
  }, "Ingredients"), _react.default.createElement(_ContentfulRichText.ContentfulRichText, {
    content: ingredientList
  }))), _react.default.createElement(_core.Grid, {
    "data-id": "bottom-right",
    item: true,
    xs: 7
  }, _react.default.createElement("div", {
    className: classes.directions
  }, _react.default.createElement(_typography.H2, {
    className: classes.sectionHeading
  }, "Directions"), _react.default.createElement(_ContentfulRichText.ContentfulRichText, {
    content: instructions
  })), notes && _react.default.createElement("div", {
    className: classes.notes
  }, _react.default.createElement(_typography.H3, {
    gutterBottom: false,
    className: classes.sectionHeading
  }, "Notes"), _react.default.createElement(_ContentfulRichText.ContentfulRichText, {
    content: notes
  }))))));
};

exports.RecipeDetails = RecipeDetails;
RecipeDetails.propTypes = {
  recipe: _propTypes.default.object.isRequired,
  toggleFavorite: _propTypes.default.func.isRequired,
  onSelect: _propTypes.default.func,
  onRemove: _propTypes.default.func,
  isSelected: _propTypes.default.func,
  classes: _propTypes.default.object.isRequired
};
exports.RecipeDetails = RecipeDetails = (0, _core.withStyles)(function () {
  return {
    metadataColumn: {
      maxWidth: '600px'
    },
    imageColumn: {
      maxWidth: '600px'
    },
    topRow: {
      width: '100%'
    },
    description: {
      margin: '20px 0 10px',
      lineHeight: 1.2,
      letterSpacing: 0
    },
    attributes: {
      margin: '30px 0 0'
    },
    attributesDivider: {
      margin: '14px 0'
    },
    ingredients: {
      '& ul': {
        paddingLeft: '0 !important'
      },
      '& ul li': {
        listStyle: 'none !important',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
      },
      '& ul li p': {
        margin: '0.2em 0'
      },
      padding: '24px 32px 6px',
      '@media print': {
        boxShadow: 'none'
      }
    },
    directions: {
      marginTop: '24px'
    },
    notes: {
      marginTop: '20px'
    },
    sectionHeading: {
      fontWeight: 'bold',
      marginBottom: '20px'
    },
    favoriteButton: {
      position: 'absolute',
      width: '80px',
      height: '80px',
      top: '10px',
      right: '10px'
    }
  };
})(RecipeDetails);