"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentfulRichText = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _richTextTypes = require("@contentful/rich-text-types");

var _richTextReactRenderer = require("@contentful/rich-text-react-renderer");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var embeddedAsset = function embeddedAsset() {
  return function (node) {
    var _node$data$target = node.data.target,
        title = _node$data$target.title,
        description = _node$data$target.description,
        file = _node$data$target.file;
    var mimeType = file.contentType;

    if (mimeType.startsWith('image')) {
      return _react.default.createElement("img", {
        title: title,
        alt: description,
        src: file.url
      });
    }
  };
};

var embeddedEntry = function embeddedEntry() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$inline = _ref.inline,
      inline = _ref$inline === void 0 ? false : _ref$inline;

  return function (node) {
    var contentType = node.data.target.contentType;

    if (contentType === 'media') {
      var _node$data$target2 = node.data.target,
          mediaName = _node$data$target2.mediaName,
          mediaFile = _node$data$target2.mediaFile,
          width = _node$data$target2.width,
          alignment = _node$data$target2.alignment;
      var mimeType = mediaFile.file.contentType;
      var display = inline ? 'inline-block' : 'block';
      var marginTop = '10px';
      var marginBottom = '10px';
      var marginLeft, marginRight, float, clear;

      if (inline) {
        float = alignment === 'center' ? undefined : alignment;
        marginLeft = alignment === 'left' ? 0 : '20px';
        marginRight = '20px';
      } else {
        clear = 'both';
        marginLeft = alignment !== 'left' ? 'auto' : undefined;
        marginRight = alignment !== 'right' ? 'auto' : undefined;
      }

      if (mimeType.startsWith('image')) {
        return _react.default.createElement("img", {
          title: mediaName,
          alt: mediaName,
          src: mediaFile.file.url,
          width: width,
          style: {
            float: float,
            clear: clear,
            display: display,
            marginTop: marginTop,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight
          }
        });
      }
    }
  };
};

var ContentfulRichText = function ContentfulRichText(_ref2) {
  var _renderNode;

  var content = _ref2.content,
      className = _ref2.className,
      classes = _ref2.classes;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)(classes.richText, className)
  }, (0, _richTextReactRenderer.documentToReactComponents)(content, {
    renderNode: (_renderNode = {}, _defineProperty(_renderNode, _richTextTypes.BLOCKS.EMBEDDED_ASSET, embeddedAsset()), _defineProperty(_renderNode, _richTextTypes.BLOCKS.EMBEDDED_ENTRY, embeddedEntry()), _defineProperty(_renderNode, _richTextTypes.INLINES.EMBEDDED_ENTRY, embeddedEntry({
      inline: true
    })), _renderNode)
  }));
};

exports.ContentfulRichText = ContentfulRichText;
ContentfulRichText.propTypes = {
  content: _propTypes.default.object,
  className: _propTypes.default.string,
  classes: _propTypes.default.object.isRequired
};
exports.ContentfulRichText = ContentfulRichText = (0, _core.withStyles)(function (theme) {
  return {
    richText: _objectSpread({}, theme.typography.body2, {
      fontSize: 'inherit',
      '& ul': {
        margin: '0.5em 0'
      },
      '& ol': {
        margin: '0.5em 0'
      },
      '& li p': {
        margin: '0.8em 0'
      },
      '& p': {
        fontSize: '115%'
      },
      '& h6': {
        fontSize: '120%',
        margin: '1em 0 0'
      },
      '& h5': {
        fontSize: '125%',
        margin: '1em 0 0'
      },
      '& h4': {
        fontSize: '130%',
        margin: '1em 0 0'
      },
      '& h3': {
        fontSize: '135%',
        margin: '1em 0 0'
      },
      '& h2': {
        fontSize: '140%',
        margin: '1em 0 0'
      },
      '& h1': {
        fontSize: '145%',
        margin: '1em 0 0'
      }
    })
  };
})(ContentfulRichText);