"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CoordinateSnapper = void 0;

var _geom = require("ol/geom");

var CoordinateSnapper = function CoordinateSnapper(_ref) {
  var map = _ref.map,
      featureFilter = _ref.featureFilter,
      _ref$hitTolerance = _ref.hitTolerance,
      hitTolerance = _ref$hitTolerance === void 0 ? 50 : _ref$hitTolerance;

  _classCallCheck(this, CoordinateSnapper);

  /**
   * Take a coordinate in the view projection
   * And find the closest point of any feature in the view layer
   * Within hitTolerance pixels
   * And which matches the filter
   */
  this.snapToClosestPoint = function (coordinate) {
    var nearestFeatures = map.getViewFeaturesNearCoordinate({
      coordinate: coordinate,
      hitTolerance: hitTolerance,
      featureFilter: featureFilter
    });

    if (nearestFeatures.length === 0) {
      return coordinate;
    } // Sort by distance and take the closest


    var _nearestFeatures$map$ = nearestFeatures.map(function (feature) {
      // eslint-disable-next-line no-shadow
      var closestCoordinate = feature.getGeometry().getClosestPoint(coordinate);
      return {
        closestCoordinate: closestCoordinate,
        distance: new _geom.LineString([coordinate, closestCoordinate]).getLength()
      };
    }).sort(function (l, r) {
      return l.distance - r.distance;
    }),
        _nearestFeatures$map$2 = _slicedToArray(_nearestFeatures$map$, 1),
        closestCoordinate = _nearestFeatures$map$2[0].closestCoordinate;

    return closestCoordinate;
  };
};

exports.CoordinateSnapper = CoordinateSnapper;