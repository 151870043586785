"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var WanderlostError_1 = require("../errors/WanderlostError");

var FolderMoveValidator = function FolderMoveValidator() {
  var _this = this;

  _classCallCheck(this, FolderMoveValidator);

  this.buildPath = function (_ref) {
    var folderId = _ref.folderId,
        folders = _ref.folders,
        _ref$path = _ref.path,
        path = _ref$path === void 0 ? [] : _ref$path;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = folders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var folder = _step.value;

        if (folder.folderId === folderId) {
          return path.concat(folder.folderId);
        }

        var match = _this.buildPath({
          folderId: folderId,
          folders: folder.folders,
          path: path.concat(folder.folderId)
        });

        if (match.length > 0) {
          return match;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return [];
  };

  this.canMoveValidators = {
    'valid-parent-folder-types': function validParentFolderTypes(_ref2) {
      var parent = _ref2.parent,
          rule = _ref2.rule;
      return rule.validTypes.includes(parent.folderType);
    },
    'cannot-be': function cannotBe(_ref3) {
      var parent = _ref3.parent,
          rule = _ref3.rule;
      return !rule.folderIds.includes(parent.folderId);
    },
    'cannot-be-in-hierarchy': function cannotBeInHierarchy(_ref4) {
      var parent = _ref4.parent,
          rule = _ref4.rule,
          folders = _ref4.folders;

      var path = _this.buildPath({
        folderId: parent.folderId,
        folders: folders
      });

      return !path.includes(rule.folderId);
    },
    'must-be-in-hierarchy': function mustBeInHierarchy(_ref5) {
      var parent = _ref5.parent,
          rule = _ref5.rule,
          folders = _ref5.folders;

      var path = _this.buildPath({
        folderId: parent.folderId,
        folders: folders
      });

      return path.includes(rule.folderId);
    }
  };

  this.canMoveAggregateValidator = function (_ref6) {
    var parent = _ref6.parent,
        rules = _ref6.rules,
        folders = _ref6.folders;
    var isValid = true;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = rules[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var rule = _step2.value;

        if (!_this.canMoveValidators[rule.type]({
          rule: rule,
          parent: parent,
          folders: folders
        })) {
          isValid = false;
        }
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    return isValid;
  };

  this.getReadOnlyParentFolders = function (folders) {
    var readOnlyFolders = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = folders[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var folder = _step3.value;

        if (folder.role === 'reader') {
          readOnlyFolders = readOnlyFolders.concat(folder);
        } else {
          readOnlyFolders = _this.getReadOnlyParentFolders(folder.folders, readOnlyFolders);
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    return readOnlyFolders;
  };

  this.buildCanMoveRules = function (_ref7) {
    var folderId = _ref7.folderId,
        folders = _ref7.folders;

    var path = _this.buildPath({
      folderId: folderId,
      folders: folders
    });

    var index = path.indexOf(folderId);
    var parentFolderId = path[index - 1];
    var rules = [{
      type: 'valid-parent-folder-types',
      validTypes: ['layer-folder']
    }, {
      type: 'cannot-be',
      folderIds: [parentFolderId]
    }, {
      type: 'cannot-be-in-hierarchy',
      folderId: folderId
    }];

    if (path[0] === 'shared-with-me') {
      rules.push({
        type: 'must-be-in-hierarchy',
        folderId: path[1]
      });
    } else if (path[0] === 'my-layers') {
      rules.push({
        type: 'must-be-in-hierarchy',
        folderId: 'my-layers'
      });
    }

    if (path[0] === 'shared-with-me') {
      var readOnlySharedWithMeFolders = _this.getReadOnlyParentFolders(folders[1].folders);

      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = readOnlySharedWithMeFolders[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var readOnlyFolder = _step4.value;
          rules.push({
            type: 'cannot-be-in-hierarchy',
            folderId: readOnlyFolder.folderId
          });
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }
    }

    return rules;
  };

  this.getRulesFor = function (_ref8) {
    var folderId = _ref8.folderId,
        folders = _ref8.folders;
    return _this.buildCanMoveRules({
      folderId: folderId,
      folders: folders
    });
  };

  this.canMove = function (_ref9) {
    var folder = _ref9.folder,
        parentFolder = _ref9.parentFolder,
        folders = _ref9.folders;
    var rules = folder.links.self.moveRules;
    return _this.canMoveAggregateValidator({
      parent: parentFolder,
      rules: rules,
      folders: folders
    });
  };

  this.validateMove = function (_ref10) {
    var folderId = _ref10.folderId,
        folders = _ref10.folders,
        parentFolder = _ref10.parentFolder;

    var rules = _this.buildCanMoveRules({
      folderId: folderId,
      folders: folders
    });

    var isValid = _this.canMoveAggregateValidator({
      parent: parentFolder,
      rules: rules,
      folders: folders
    });

    if (!isValid) {
      throw new WanderlostError_1.WanderlostError({
        statusCode: 400,
        errorCode: 'parent.isNotValidForMove',
        message: 'Folder cannot be moved'
      });
    }
  };
};

exports.FolderMoveValidator = FolderMoveValidator;