"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShareRole = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _components = require("@wanderlost-sdk/components");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RoleLabel = function RoleLabel(_ref) {
  var availableRoles = _ref.availableRoles,
      classes = _ref.classes,
      share = _ref.share;

  if (share.role === 'owner') {
    return 'Owner';
  }

  var _availableRoles$find = availableRoles.find(function (_ref2) {
    var role = _ref2.role;
    return role === share.role;
  }),
      label = _availableRoles$find.label;

  var roleLabel = label;

  if (share.inherited) {
    roleLabel = _react.default.createElement("span", null, roleLabel, _react.default.createElement(_components.SMALL, {
      color: "textSecondary",
      className: classes.inherited
    }, "Inherited"));
  }

  return roleLabel;
};

var ShareRole = function ShareRole(_ref3) {
  var availableRoles = _ref3.availableRoles,
      classes = _ref3.classes,
      share = _ref3.share;
  return _react.default.createElement(_Typography.default, {
    variant: "body1",
    className: classes.container
  }, _react.default.createElement(RoleLabel, {
    availableRoles: availableRoles,
    share: share,
    classes: classes
  }));
};

exports.ShareRole = ShareRole;
ShareRole.propTypes = {
  classes: _propTypes.default.object.isRequired,
  share: _propTypes.default.object.isRequired,
  availableRoles: _propTypes.default.array.isRequired
};
exports.ShareRole = ShareRole = (0, _withStyles.default)({
  container: {
    lineHeight: 1.2
  },
  inherited: {
    textTransform: 'uppercase',
    fontSize: '0.7em'
  }
})(ShareRole);