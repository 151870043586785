"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var helpers_1 = require("@turf/helpers");

var distance_1 = require("@turf/distance");

var along_1 = require("@turf/along");

var LineStringSampler = function LineStringSampler() {
  var _this = this;

  _classCallCheck(this, LineStringSampler);

  this.densify = function (_ref) {
    var startCoord = _ref.startCoord,
        endCoord = _ref.endCoord,
        thresholdMeters = _ref.thresholdMeters;
    var from = helpers_1.point(startCoord);
    var to = helpers_1.point(endCoord);
    var coords = [startCoord];
    var thresholdKm = thresholdMeters / 1000;

    var arePointsWithinDistanceThreshold = function arePointsWithinDistanceThreshold(a, b) {
      return distance_1.default(a, b, {
        units: 'kilometers'
      }) < thresholdKm;
    };

    while (!arePointsWithinDistanceThreshold(from, to)) {
      var newPoint = along_1.default(helpers_1.lineString([from.geometry.coordinates, to.geometry.coordinates]), thresholdKm, {
        units: 'kilometers'
      });
      from = newPoint;
      coords.push(newPoint.geometry.coordinates);
    }

    coords.push(endCoord);
    return coords;
  };

  this.sample = function (_ref2) {
    var coords = _ref2.coords,
        thresholdMeters = _ref2.thresholdMeters;

    if (coords.length === 1) {
      return coords;
    }

    return coords.reduce(function (accum, coord, index) {
      var nextCoord = coords[index + 1];

      if (!nextCoord) {
        return accum;
      }

      return accum.concat(_this.densify({
        startCoord: coord,
        endCoord: nextCoord,
        thresholdMeters: thresholdMeters
      }));
    }, []);
  };
};

exports.LineStringSampler = LineStringSampler;