"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Quote = void 0;

var _react = _interopRequireDefault(require("react"));

var _icons = require("../icons");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../../typography");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * WARNING: Not ready for use in web-app.
 */


var Quote = function Quote(_ref) {
  var classes = _ref.classes,
      content = _ref.content,
      attribution = _ref.attribution;
  return _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement(_icons.QuoteIcon, {
    classes: {
      root: classes.icon
    },
    color: "secondary"
  }), _react.default.createElement(_typography.P, {
    color: "textSecondary",
    className: classes.content
  }, content), _react.default.createElement(_typography.P, {
    className: classes.attribution
  }, attribution));
};

exports.Quote = Quote;
Quote.propTypes = {
  classes: _propTypes.default.object.isRequired,
  content: _propTypes.default.node,
  attribution: _propTypes.default.node
};
exports.Quote = Quote = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      textAlign: 'center',
      padding: "0 ".concat(theme.spacing(2), "px")
    },
    content: {
      fontSize: '1.8rem',
      fontStyle: 'italic',
      lineHeight: 1.2,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    attribution: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: theme.palette.tertiary.dark
    },
    icon: {
      opacity: 0.5,
      width: 100,
      height: 100
    }
  };
})(Quote);