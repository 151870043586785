"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MapLoadingIndicator = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _state = require("./state");

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    root: {
      position: 'absolute',
      padding: "".concat(theme.spacing(4), "px"),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 4
    },
    rootDrawerOpen: {
      left: "calc(50% - (".concat(theme.wanderlost.drawerWidth, " / 2))")
    }
  };
};

var MapLoadingIndicator = function MapLoadingIndicator(_ref) {
  var classes = _ref.classes,
      isDrawerOpen = _ref.isDrawerOpen;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _state.MapEditingLoadingState
  }, function (_ref2) {
    var isLoading = _ref2.isOn;

    if (!isLoading) {
      return null;
    }

    return _react.default.createElement(_core.Paper, {
      className: (0, _classnames.default)(classes.root, _defineProperty({}, classes.rootDrawerOpen, isDrawerOpen))
    }, _react.default.createElement(_core.CircularProgress, {
      className: classes.progress,
      size: 60
    }));
  });
};

exports.MapLoadingIndicator = MapLoadingIndicator;
MapLoadingIndicator.propTypes = {
  classes: _propTypes.default.object.isRequired,
  isDrawerOpen: _propTypes.default.bool.isRequired
};
exports.MapLoadingIndicator = MapLoadingIndicator = (0, _core.withStyles)(styles)(MapLoadingIndicator);