"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InjectField = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _state = require("../state");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var InjectField = function InjectField(_ref) {
  var initialValue = _ref.initialValue,
      children = _ref.children;
  return _react.default.createElement(_greenState.Subscribe, {
    to: function to() {
      return new _state.FieldState(initialValue);
    }
  }, children);
};

exports.InjectField = InjectField;
InjectField.propTypes = {
  initialValue: _propTypes.default.any,
  children: _propTypes.default.func.isRequired
};