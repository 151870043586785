"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LayerOpacityPlugin = void 0;

var _LayerOpacityState = require("../state/LayerOpacityState");

var _Cartographer = require("../Cartographer");

var _EventBus = require("../EventBus");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var LayerOpacityPlugin = function LayerOpacityPlugin(layerOpacityState, eventBus, cartographerProvider) {
  _classCallCheck(this, LayerOpacityPlugin);

  var onDispose = function onDispose() {};

  this.start = function () {
    eventBus.on('cartographer:ready', function () {
      var cartographer = cartographerProvider.get();
      var lastOpacityByLayerId = {};
      onDispose = layerOpacityState.subscribe(function () {
        var opacityByLayerId = layerOpacityState.getOpacitiesByLayerId();
        Object.keys(opacityByLayerId).forEach(function (layerId) {
          var opacity = opacityByLayerId[layerId]; // Don't do anything if the opacity hasn't changed

          if (opacity === lastOpacityByLayerId[layerId]) {
            return;
          }

          var layer = cartographer.getLayerById(layerId); // layer might not exist, specifically routes when viewing the regular map
          // this could cover up bugs of us using the wrong name, but that should be covered with tests

          if (layer) {
            layer.setOpacity(opacity);
          }

          eventBus.emit('layer:opacityChanged', {
            layerId: layerId,
            opacity: opacity
          });
        });
        lastOpacityByLayerId = opacityByLayerId;
      });
    });
  };

  this.dispose = function () {
    onDispose();
  };
};

exports.LayerOpacityPlugin = LayerOpacityPlugin;

_defineProperty(LayerOpacityPlugin, "inject", function () {
  return [_LayerOpacityState.LayerOpacityState, _EventBus.EventBus, _Cartographer.CartographerProvider];
});