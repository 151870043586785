"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ActionQueue = function ActionQueue(initialValue) {
  var _this = this;

  _classCallCheck(this, ActionQueue);

  this.enqueue = function (action) {
    _this.pendingPromise = _this.pendingPromise.catch(function (e) {
      return e.lastValue;
    }).then(function (value) {
      return Promise.resolve().then(function () {
        return action(value);
      }).catch(function (e) {
        e.lastValue = value;
        throw e;
      });
    });
    return _this.pendingPromise;
  };

  this.pendingPromise = Promise.resolve(initialValue);
};

exports.ActionQueue = ActionQueue;