"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureAttributeRenderStrategyCollection = void 0;

var _FeatureAttributeKeyValueRenderStrategy = require("./FeatureAttributeKeyValueRenderStrategy");

var _MVUMOpenDatesRenderStrategy = require("./MVUMOpenDatesRenderStrategy");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FeatureAttributeRenderStrategyCollection = function FeatureAttributeRenderStrategyCollection() {
  for (var _len = arguments.length, strategies = new Array(_len), _key = 0; _key < _len; _key++) {
    strategies[_key] = arguments[_key];
  }

  _classCallCheck(this, FeatureAttributeRenderStrategyCollection);

  this.getById = function (strategyId) {
    for (var _i = 0; _i < strategies.length; _i++) {
      var strategy = strategies[_i];

      if (strategy.strategyId === strategyId) {
        return strategy;
      }
    }

    throw new Error("Unsupported render strategy ".concat(strategyId));
  };
};

exports.FeatureAttributeRenderStrategyCollection = FeatureAttributeRenderStrategyCollection;

_defineProperty(FeatureAttributeRenderStrategyCollection, "inject", function () {
  return [_FeatureAttributeKeyValueRenderStrategy.FeatureAttributeKeyValueRenderStrategy, _MVUMOpenDatesRenderStrategy.MVUMOpenDatesRenderStrategy];
});