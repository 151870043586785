import React from 'react';
import 'reflect-metadata'; // Required by aurelia-dependency-injection
import { WanderLostPrincipal, Auth0AuthService, Auth0AuthServiceConfig } from './users';
import { setSentryUser } from './debugging';
import { XRayServiceConfig } from './debugging/XRayService';
import { XRayServiceToHttpClientBridge } from './debugging/XRayServiceToHttpClientBridge';
import auth0 from 'auth0-js';
import { EnvConfiguration, getConfiguration } from './EnvConfiguration';
import { createMuiTheme } from '@material-ui/core/styles';
import { hasAction } from '@wanderlost-sdk/core';
import { Theme as ThemeBase } from '@wanderlost-sdk/components';
import { Theme } from './theme';
import { WanderLostURLBuilder, WanderLostURLBuilderConfig } from './urls/WanderLostURLBuilder';
import { WanderLostURLBuilder as WanderlostComponentsURLBuilder, History, TripsState as ComponentsTripsState } from '@wanderlost-sdk/components';
import { TripRoutesState, TripsState as CartographerTripsState, GPXRouteImportState } from '@wanderlost-sdk/cartographer';
import { FoldersDAO, SharingDAO, FeatureImporterDAO, SmartRouterDAO } from '@wanderlost-sdk/cartographer';
import { createHashHistory } from "history";
import { TripsState, TripsStateConfig } from './trips/TripsState';
import { DependencyContainerContext, DependencyContainerReactContext } from '@symbiotic/green-state';
import { NoOpState } from './framework/state';
import { WanderLostApiClient, HTTPClientConfig } from '@wanderlost-sdk/api-client';
import { Intercom, IntercomConfig } from './Intercom';
import { RecipesState, WanderLostPrincipal as ComponentsWanderLostPrincipal, AuthProvider } from '@wanderlost-sdk/components';
import { WebRecipesState } from './recipes/WebRecipesState';

async function initializeContainer(container) {
    const envConfig = await getConfiguration();
    container.registerInstance(EnvConfiguration, envConfig);
    container.registerInstance(TripsStateConfig, envConfig);
    container.registerInstance(XRayServiceConfig, envConfig);

    container.registerInstance(auth0.WebAuth, new auth0.WebAuth({
        domain: 'auth.thewanderlostproject.com',
        clientID: envConfig.auth0ClientId,
        redirectUri: envConfig.auth0RedirectURL,
        responseType: 'token id_token',
        scope: 'openid profile email',
        audience: 'wanderlost-api'
    }));

    container.registerInstance(IntercomConfig, {
        appId: envConfig.intercomAppId,
        coviewProjectKey: envConfig.coviewProjectKey,
        /**
         * Disable intercom when running via automated tests
         * We might want a more sophisticated solution in the future where we disable intercom only for test users
         * But we don't have a concept of test users instrumented
         * So this is a quick fix that prevents us from publishing lots of data to intercom (new users)
         * and causing unwated side effects (e.g. slack notifications) due to automated tests
         * Note that we also use the test workspace/appId in local/staging which also prevents slack notifications
         * So this flag only changes the behavior in live when tests are run
         */
        isDisabled: 'Cypress' in window
    });

    // TripRoutesState is provided by TripContainer for the TripMap, but the Drawer always subscribes to it
    // Registering a no-op state version makes it explicit that the only way to get a useful TripRoutesState is to create it/put it in a child container
    container.registerInstance(TripRoutesState, {
        ...new NoOpState(),
        asFolders() { return { folders: [] } },
        onDistanceChanged: () => {}
    });

    container.registerInstance(Auth0AuthServiceConfig, {
        LOGOUT_REDIRECT_URL: envConfig.auth0LogoutRedirectURL,

        // When changing this key, don't forget to updated cypress tests
        SESSION_STORAGE_KEY: 'wl-auth0-v2',
        CLAIM_NAMESPACE: 'http://thewanderlostproject.com/'
    });

    const history = createHashHistory();
    container.registerInstance(History, history);

    history.listen(() => {
        /**
         * This is needed to make the initial page render. Otherwise, when we change the hash to '/' after login, the browser
         * url updates, but the app attempts to render the prior route ('/accessToken=...'), which shows our "Not Found" page.
         * We don't understand why this fixes it, but don't see the value in investing time trying to figure it out.
         */
    });

    const theme = createMuiTheme(new Theme());
    container.registerInstance(ThemeBase, theme);
    container.registerInstance(Theme, theme);

    container.registerInstance(WanderLostURLBuilderConfig, { URL_BASE: `${window.location.protocol}//${window.location.host}/#` });
    const urlBuilder = container.get(WanderLostURLBuilder);
    container.registerInstance(WanderlostComponentsURLBuilder, urlBuilder);

    const authService = container.get(Auth0AuthService);
    container.registerInstance(AuthProvider, authService);

    // Invoke login() to get access token. If this involves a round trip through the IDP, userInfo will contain
    // the latest user data from the IDP. (If we use an access token from localStorage, userInfo will be undefined.)
    const { accessToken, userInfo } = await authService.login();

    container.registerInstance(HTTPClientConfig, {
        accessToken: accessToken,
        baseURL: envConfig.apiURL,
        // It would be better not to force a login without notifying the user that their data will be lost
        // The only time we can safely force a login for expired tokens is on app load (or perhaps the first time we request entry resource)
        on401Callback: authService.forceLogin
    });

    // Now we have HTTPClientConfig, we can use the api client and instantiate services that depend on it
    const apiClient = container.get(WanderLostApiClient);
    const entry = await apiClient.entry();

    // If the user still needs to complete account setup, complete the setup and reload the app
    // This is because setup might cause user data to change so we don't want to render the app
    const mustCompleteSetup = hasAction(entry, 'accountSetup', 'complete');
    if (mustCompleteSetup) {
        const existingUser = await apiClient.post(entry.links.accountSetup);
        if (existingUser) {
            // this is a social login, and there was an existing db user with the same email,
            // so we need to switch to that one before we reload the page
            const { accessToken } = existingUser;
            const authService = container.get(Auth0AuthService);
            authService.switchLogin({ accessToken });
        };
        window.location.reload();
        await new Promise(() => {}); // wait here forever while page reloads to avoid render jank
    }

    // If account setup not needed, we still need to sync user info from the IDP with the Users table in our database
    // (If we authenticated using an access token from localStorage, then userInfo will be undefined, so nothing to do.)
    if (userInfo) {
        await apiClient.patch(entry.links.user, userInfo);
    }

    const principal = { ...entry.user, ...userInfo };
    container.registerInstance(WanderLostPrincipal, principal);
    container.registerInstance(ComponentsWanderLostPrincipal, principal)

    setSentryUser(principal);

    // Don't start intercom until the page reloads after setup completes. Otherwise, if user is logging
    // in with a second auth provider, we'll send intercom the second userId before we link it, and the
    // user will see the welcome dialog a second time. Also wait until consent form has been submitted,
    // so the user doesn't see the Intercom welcome at the same time.
    if (principal.isConsentSubmitted) {
        container.get(Intercom).setup(container);
    }

    // subscribe http calls to XRay
    container.get(XRayServiceToHttpClientBridge).start();

    // Implementations of Cartographer DAOs using apiClient
    // TODO: Consider avoiding using these DAOs outside cartographer,
    // enabling these registrations to be moved to MapContainerContext
    container.registerInstance(FoldersDAO, {
        getFolders: async () => {
            const entry = await apiClient.entry();
            return await apiClient.get({ href: entry.links.folders }, 'application/wander-lost.folders.v3+json');
        },
        createLayer: async (parent, { name }) => {
            if (!parent.links.folders) {
                throw new Error(`creating a layer is not supported on this folder`);
            }
            return await apiClient.post({ href: parent.links.folders }, { name, folderType: 'layer' }, { 'content-type' : 'application/json' } );
        },
        createFolder: async (parent, { name }) => {
            if (!parent.links.folders) {
                throw new Error(`creating a folder is not supported on this folder`);
            }
            return await apiClient.post({ href: parent.links.folders }, { name, folderType: 'layer-folder' }, { 'content-type' : 'application/json' } );
        },
        rename: async (folder, { name, notes }) => {
            if (folder.links.route) {
                return await apiClient.patch(folder.links.route, { name, notes });
            } else if (!notes) {
                return await apiClient.patch(folder, { name });
            }
            throw new Error('Notes cannot be updated on this folder');
        },
        delete: async(folder) => {
            return await apiClient.delete(folder);
        },
        moveTo: async (folder, parent) => {
            return await apiClient.patch(folder, { parent: parent.folderId });
        },
        getFeatures: async (folder) => {
            return await apiClient.get(folder.links.features, 'application/json');
        },
        updateFeatures: async (folder, { features, extent }) => {
            return await apiClient.put(folder.links.features, { features, extent }, { 'content-type': 'application/wander-lost.update-features.v1+json' } );
        },
        getRoutes: async (trip) => {
            return await apiClient.get(trip.links.routes, 'application/vnd.wander-lost.route-with-features+json');
        },
        createRoute: async (route, trip) => {
            return await apiClient.post(trip.links.routes, { ...route, tripId: trip.tripId });
        },
        updateRoute: async (route, updates) => {
            return await apiClient.patch(route.links.route, updates);
        }
    });

    container.registerInstance(SharingDAO, {
        getShares: async (folder) => {
            return await apiClient.get(folder.links.shares, 'application/json');
        },
        create: async (share, folder) => {
            return await apiClient.post(folder.links.shares, share,  { 'content-type': 'application/vnd.wander-lost.folder-share+json' })
        },
        setRole: async (share, role) => {
            return await apiClient.patch(share, { role }, { 'content-type': 'application/json' });
        },
        delete: async (share) => {
            return await apiClient.delete(share);
        }
    });

    container.registerInstance(FeatureImporterDAO, {
        uploadFile: apiClient.uploadFile
    })

    container.registerInstance(SmartRouterDAO, {
        smartRoute: apiClient.smartRoute
    });

    const tripsState = container.get(TripsState);
    container.registerInstance(CartographerTripsState, tripsState);
    container.registerInstance(ComponentsTripsState, tripsState);

    // Want to have a single instance of import state so that it can be shared when moving b/w container contexts
    container.registerInstance(GPXRouteImportState, container.get(GPXRouteImportState));

    container.registerInstance(RecipesState, container.get(WebRecipesState));

    // window.rootContainerInspector = new (require('./ioc').ContainerInspector)(container);
}

/**
 * Long-term we intend for some form of this code to live in green-state
 * But we'll leave it here until we can prioritize the time to figure out what the API should look like
 * Should it be a wrapper class? Should it just be part of Container's interface?
 * What else can/should it do?
 */
export class ContainerInspector {

    constructor(container) {

        this.container = container;

        this.getServices = () => {
            return [...container.wrappedContainer._resolvers.entries()]
                .map(([key, value]) => ({ key, resolver: value }));
        };

        this.getServiceByConstructorName = constructorName => {
            return this.getServices()
                // eslint-disable-next-line array-callback-return
                .reduce((accum, { key }) => {
                    if (accum) {
                        return accum;
                    }

                    if (key.constructor && key.name === constructorName) {
                        return container.get(key);
                    }
                }, null);
        };

        this.buildGraphForKey = key => {
            return container.buildDepGraph(key);
        };

        this.findGraphsWithKey = key => {
            let graphsWithKey = [];
            this.getServices().forEach(service => {
                const rootDepGraph = this.buildGraphForKey(service.key);
                const nodeDependingOnKey = (function recursivelyFindNodeDependingOnKey(nodes) {
                    for (const node of nodes) {
                        const deps = node.getDeps();
                        console.log(`Checking deps ${node.key.name}`);
                        if (deps.find(d => d.key === key)) {
                            console.log(`Found ${key.name}!`);
                            return node;
                        }

                        return recursivelyFindNodeDependingOnKey(deps);
                    }
                })(rootDepGraph);

                if (nodeDependingOnKey) {
                    graphsWithKey.push({
                        ...service,
                        nodeDependingOnKey,
                        graph: rootDepGraph,
                    });
                }
            });

            return graphsWithKey;
        };

    }

}

export class AppContainer extends DependencyContainerContext {
    state = { error: null }

    async containerMounted(container){
        try {
            await initializeContainer(container);
        } catch (error) {
            this.setState({ error });
        }
    }

    // Copied from DependencyContainerContext.render, throwing if this.state.error is the only difference
    render() {
        const { doneInjecting, error } = this.state;
        const { children } = this.props;

        /**
         * Rethrow error so it can be caught by error boundaries
         * This is a hack and if we invest more here we should implement something like ErrorService.show(error)
         * that can be called from anywhere and is more explicit
         */
        if (error) {
            throw error;
        }

        if (!doneInjecting) {
            return this.renderLoading();
        }

        return (
            <DependencyContainerReactContext.Provider value={{ container: this.container }}>
                {children}
            </DependencyContainerReactContext.Provider>
        );
    }
}

/**
 * Takes a list of services from ContainerInspector.getServices and returns the ones in B that are not in A
 */
// eslint-disable-next-line no-unused-vars
function diffServices(a, b) {
    const aKeys = new Set(a.map(a => a.key));
    const newBServices = [];
    b.forEach(service => {
        if (!aKeys.has(service.key)) {
            newBServices.push(service);
        }
    });

    return newBServices;
}
