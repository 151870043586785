"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildCarouselUrl = exports.buildAvatarUrl = exports.buildThumbnailUrl = exports.buildCardImageUrl = exports.buildRecipeImageUrl = exports.buildImageUrl = void 0;

var buildImageUrl = function buildImageUrl(image, width, height) {
  return "".concat(image.file.url, "??w=").concat(width, "&h=").concat(height, "&fit=fill");
}; // some standard sizes to improve consistency and browser cachine


exports.buildImageUrl = buildImageUrl;

var buildRecipeImageUrl = function buildRecipeImageUrl(image) {
  return buildImageUrl(image, 820, 600);
};

exports.buildRecipeImageUrl = buildRecipeImageUrl;

var buildCardImageUrl = function buildCardImageUrl(image) {
  return buildImageUrl(image, 410, 300);
};

exports.buildCardImageUrl = buildCardImageUrl;

var buildThumbnailUrl = function buildThumbnailUrl(image) {
  return buildImageUrl(image, 230, 170);
};

exports.buildThumbnailUrl = buildThumbnailUrl;

var buildAvatarUrl = function buildAvatarUrl(image) {
  return buildImageUrl(image, 100, 100);
};

exports.buildAvatarUrl = buildAvatarUrl;

var buildCarouselUrl = function buildCarouselUrl(image) {
  return buildImageUrl(image, 250, 250);
};

exports.buildCarouselUrl = buildCarouselUrl;