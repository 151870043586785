"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextAnnotationTool = void 0;

var _react = _interopRequireDefault(require("react"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

var _HelpTextDivider = require("./HelpTextDivider");

var _TextFields = _interopRequireDefault(require("@material-ui/icons/TextFields"));

var _MarkerFeatureTool = require("./MarkerFeatureTool");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var TextAnnotationToolConfig = function TextAnnotationToolConfig() {
  _classCallCheck(this, TextAnnotationToolConfig);

  _defineProperty(this, "featureTypeLabel", 'Text Annotation');

  _defineProperty(this, "featureType", 'text-annotation');

  _defineProperty(this, "arrowPosition", 'text-annotation');

  _defineProperty(this, "defaultProperties", {
    name: '',
    url: '',
    description: ''
  });

  _defineProperty(this, "rememberProperties", []);

  _defineProperty(this, "previewProperties", ['name']);

  _defineProperty(this, "canCopyToRoute", false);
};

var TextAnnotationTool = function TextAnnotationTool(toolFactory, config) {
  _classCallCheck(this, TextAnnotationTool);

  _defineProperty(this, "toolId", 'text-annotation');

  _defineProperty(this, "icon", _react.default.createElement(_TextFields.default, null));

  _defineProperty(this, "tooltip", 'Add, edit & delete text on the map');

  _defineProperty(this, "helpTextComponent", function (_ref) {
    var text = _ref.classes.text;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Typography.default, {
      className: text
    }, "Click to add a text annotation"), _react.default.createElement(_HelpTextDivider.HelpTextDivider, null), _react.default.createElement(_Typography.default, {
      className: text
    }, "Right click to delete or edit details"), _react.default.createElement(_HelpTextDivider.HelpTextDivider, null), _react.default.createElement(_Typography.default, {
      className: text
    }, "Click and drag to move (only after adding)"));
  });

  var tool = toolFactory.create({
    config: config
  });
  this.addToMap = tool.addToMap;
};

exports.TextAnnotationTool = TextAnnotationTool;

_defineProperty(TextAnnotationTool, "inject", function () {
  return [_MarkerFeatureTool.MarkerFeatureToolFactory, TextAnnotationToolConfig];
});