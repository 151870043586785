import * as Sentry from '@sentry/browser';

// until we get the 4 log classes consolidated and driven by one config to enable debug logging... use this const...
const showDebugMessages = false;

export class Log {

    constructor() {
        this.info = (message, extras) => console.log(message, extras);

        this.debug = (message, extras) => {
            if (!showDebugMessages) {
                return;
            }
            try {
                if (extras) {
                    console.log(message, extras);
                } else {
                    console.log(message);
                }
            } catch (err) {
                // do nothing so that logging isn't the cause of app failures
            }
        }

        this.error = (error, extras) => {
            try {
                console.error(error, extras); // eslint-disable-line no-console

                Sentry.withScope(scope => {
                    if (extras) {
                        Object.keys(extras).forEach(key => {
                            scope.setExtra(key, extras[key]);
                        });
                    }
                    Sentry.captureException(error);
                });

            } catch (ignore) {
                // don't ever want to throw anything from this class!
            }
        };

    }

}
