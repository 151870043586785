"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThemeProvider = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styles = require("@material-ui/core/styles");

var _CssBaseline = _interopRequireDefault(require("@material-ui/core/CssBaseline"));

var _greenState = require("@symbiotic/green-state");

var _Theme = require("./Theme");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ThemeProvider = function ThemeProvider(_ref) {
  var children = _ref.children;
  var theme = (0, _greenState.useInstance)(_Theme.Theme);
  return _react.default.createElement(_styles.MuiThemeProvider, {
    theme: theme
  }, _react.default.createElement(_CssBaseline.default, null), _react.default.createElement(GlobalStyles, null), children);
};

exports.ThemeProvider = ThemeProvider;
ThemeProvider.propTypes = {
  children: _propTypes.default.node.isRequired
}; // TODO: lots of styles here from web-app that maybe we don't want...
// This component doesnt render anything, it just injects some global styles

var GlobalStyles = (0, _styles.withStyles)(function (theme) {
  return {
    '@global': {
      'a:link': {
        color: theme.palette.primary.main
      },
      'a:visited': {
        color: theme.palette.primary.dark
      },
      'a:focus, a:hover, a:active': {
        color: theme.palette.secondary.main
      },

      /**
       * Global styles for ol-contextmenu (see RouteTool)
       * Ideally this should be much closer to the tool but it wasn't a priority to figure out how to inject global styles from a non-react component
       */
      '.ol-ctx-menu-container': {
        left: '-999em' // Make sure it's not visible when it's not open, when it's open it will have inline styles for top|left|right|bottom

      },
      '.ol-ctx-menu-container li': _objectSpread({}, theme.typography.body1, {
        paddingLeft: '1rem',
        marginLeft: '-0.5rem',
        marginRight: '-0.5rem'
      }),
      '.ol-ctx-menu-container li:not(.ol-ctx-menu-separator):hover': {
        backgroundColor: theme.palette.background.highlight,
        color: theme.palette.text.primary
      },
      '.ol-ctx-menu-container .ol-ctx-menu-title': {
        fontWeight: 'bold',
        paddingLeft: '0.5rem'
      },
      '.ol-ctx-menu-container .ol-ctx-menu-title:hover': {
        cursor: 'default !important',
        backgroundColor: 'transparent !important',
        color: theme.palette.text.primary
      },

      /**
       * Global styles for scale line (See MapFactory)
       * Ideally this would be closer to the code but plumbing cssinjs classes to the mapfactory or an HTMLElement to render to is not worth the effort
       */
      '.map-scale-control': {
        position: 'absolute',
        bottom: '1.75rem',
        right: '5.75rem',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        fontFamily: 'Roboto, sans-serif',
        borderRadius: '4px',
        padding: '2px'
      },
      '.map-scale-control-inner': {
        border: '2px solid #000',
        textShadow: '0 0 5px #fff',
        borderTop: 'none',
        color: '#000',
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'center',
        margin: '1px',
        willChange: 'contents, width'
      },
      '.ql-toolbar.ql-snow + .ql-container.ql-snow': {
        borderTop: '1px solid #ccc !important'
      },
      '.ql-toolbar.ql-snow': {
        borderBottom: '0'
      },

      /**
       * Global print styles
       * See individual components for their custom print styles
       */
      '@media print': {
        html: {
          fontSize: '12px'
        },
        button: {
          display: 'none !important'
        }
      }
    }
  };
})(function () {
  return null;
});