import { State } from '@symbiotic/green-state';
import { toOunces } from '@wanderlost-sdk/core';
import { PackingListGrouper } from './PackingListGrouper';
import { PackingListFieldsBuilder } from './PackingListFieldsBuilder';

const getFormValueOrValue = (item, propName) => item.formState ? item.formState.state.values[propName] : item[propName];

const itemComparator = ({ propName, sortDirection, defaultValue, compare, accessor = (v => v) }) => (item1, item2) => {
    const value1 = getFormValueOrValue(item1, propName);
    const derived1 = (value1 ? accessor(value1) : defaultValue);
    const value2 = getFormValueOrValue(item2, propName);
    const derived2 = (value2 ? accessor(value2) : defaultValue);
    return compare(derived1, derived2) * (sortDirection === 'desc' ? -1 : 1);
};

const numericSort = (propName, sortDirection, accessor) => {
    return itemComparator({
        propName,
        sortDirection,
        accessor,
        defaultValue: 0,
        compare: (v1, v2) => v1 - v2
    });
};

const stringSort = (propName, sortDirection, accessor) => {
    return itemComparator({
        propName,
        sortDirection,
        accessor,
        defaultValue: '',
        compare: (v1, v2) => v1.localeCompare(v2)
    });
};

const sortList = ({ packingList, sortBy, sortDirection }) => {
    let comparator;
    if (sortBy === 'weight') {
        comparator = numericSort(sortBy, sortDirection, toOunces);

    } else if (sortBy === 'owner') {
        const ownerSort = stringSort(sortBy, sortDirection, v => v.name);
        const nameSort = stringSort('name', sortDirection);
        comparator = (i1, i2) => ownerSort(i1, i2) || nameSort(i1, i2);

    } else if (['qty', 'isShared'].includes(sortBy)) {
        comparator = numericSort(sortBy, sortDirection);

    } else {
        comparator = stringSort(sortBy, sortDirection);
    }

    // IMPORTANT: mutate items without changing packingList object reference
    // otherwise we'll break decorator methods getGroup() and getFields() below
    packingList.items.sort(comparator);

    return packingList;
};

// TODO: We might want to rename this like BasePackingListState
export class SortablePackingListState extends State {

    constructor({ packingList, sortBy = 'name', sortDirection = 'asc', ...other }) {

        const grouper = new PackingListGrouper();

        const fieldsBuilder = new PackingListFieldsBuilder();

        const decorateList = (list) => {
            // mutate in place so list references in getGroups() and getFields() remain consistent
            if (!list.getGroups) {
                list.getGroups = () => grouper.group({ packingList: list, groupBy: list.groupBy });
            }
            if (!list.getFields) {
                list.getFields = () => fieldsBuilder.build({ packingList: list, hideEmptyFields: true });
            }
            return list;
        };

        super({ sortBy, sortDirection, packingList: sortList({ packingList: decorateList(packingList), sortBy, sortDirection }), ...other });

        this.onSort = (name, newSortDirection) => {
            let { sortBy, sortDirection, packingList } = this.state;
            if (sortBy === name) {
                sortDirection = newSortDirection ? newSortDirection : (sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
                sortBy = name;
                sortDirection = 'asc';
            }
            this.setState({ sortBy, sortDirection, packingList: sortList({ packingList, sortBy, sortDirection }) });
        };

        // When items have changed, should sort again using the same sort order
        this.reSort = () => this.onSort(this.state.sortBy, this.state.sortDirection);
    }
}
