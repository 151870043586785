import { DependencyContainerContext } from "@symbiotic/green-state";

/**
 * This provides a child container that can wrap any component(s) that need to be isolated.
 * The container will auto-register dependencies that are not found in the parent container,
 * allowing them to be disposed cleanly when the component unloads. This helps prevent
 * unintended duplicate instances and/or memory leaks.
 */
export class ChildContainerContext extends DependencyContainerContext {
    async containerMounted(container) {
        // window.childContainerInspector = new (require('./ioc').ContainerInspector)(container);
    } // must implement this abstract method, even though we don't need to do anything
}
