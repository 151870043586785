"use strict";

var _toConsumableArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconOptions = void 0;

var _WaypointIconCollection = require("../styles/WaypointIconCollection");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var IconOptions = function IconOptions(iconCollection) {
  _classCallCheck(this, IconOptions);

  var markerColors = [{
    label: 'Black',
    color: '#000000'
  }, {
    label: 'Gray',
    color: '#808080'
  }, {
    label: 'Red',
    color: '#D33115'
  }, {
    label: 'Orange',
    color: '#FE9200'
  }, {
    label: 'Yellow',
    color: '#FCC400'
  }, {
    label: 'Green',
    color: '#68BC00'
  }, {
    label: 'Teal',
    color: '#16A5A5'
  }, {
    label: 'Blue',
    color: '#0062B1'
  }, {
    label: 'Purple',
    color: '#7B64FF'
  }, {
    label: 'Pink',
    color: '#FA28FF'
  }];
  var marker = iconCollection.getById('marker');
  var markerIcons = markerColors.map(function (_ref) {
    var color = _ref.color,
        label = _ref.label;
    return _objectSpread({}, marker, {
      label: "".concat(marker.label, " (").concat(label, ")"),
      color: color
    });
  });

  var nonMarkerIcons = _toConsumableArray(iconCollection).filter(function (icon) {
    return icon !== marker && icon.isUserSelectable;
  }).map(function (icon) {
    return _objectSpread({}, icon, {
      color: '#1c4a41'
    });
  });

  var icons = markerIcons.concat(nonMarkerIcons);

  this[Symbol.iterator] = function () {
    return icons[Symbol.iterator]();
  };
};

exports.IconOptions = IconOptions;

_defineProperty(IconOptions, "inject", function () {
  return [_WaypointIconCollection.WaypointIconCollection];
});