"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripsState = void 0; // Overriden by ioc

var TripsState = function TripsState() {
  _classCallCheck(this, TripsState);
};

exports.TripsState = TripsState;