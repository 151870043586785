import { State } from '@symbiotic/green-state';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';
import { EditablePackingListState } from './EditablePackingListState';
import { FocusManager } from '../../FocusManager';

export class GearListsState extends State {

    static inject = () => [WanderLostApiClient, FocusManager];
    constructor(apiClient, focusManager) {
        super({ gearLists: undefined });

        this.load = async () => {
            this.entry = await apiClient.entry();

            const myItems = await apiClient.get(this.entry.links.packingListItems);
            const allLists = await apiClient.get(this.entry.links.packingLists);

            const gearLists = allLists.filter(l => l.type === 'gear');

            this.setState({
                gearLists,
                myItems
            });
        };

        this.createGearList = async ({ name, items = [] }) => {
            const listRef = await apiClient.post(this.entry.links.packingLists, { name, items });
            const newList = await apiClient.get(listRef);
            const newGearLists = [
                ...this.state.gearLists,
                newList
            ];
            newGearLists.sort((listA, listB) => listA.name.localeCompare(listB.name));
            this.setState({
                gearLists: newGearLists
            })
            return newList;
        };

        this.getEditableListState = ({ packingListId }) => {
            return new EditablePackingListState({
                apiClient,
                packingList: this.state.gearLists.find(l => l.packingListId === packingListId),
                myItems: this.state.myItems,
                createGearList: this.createGearList,
                focusManager,
                afterDelete: () => {
                    this.setState({
                        gearLists: this.state.gearLists.filter(list => list.packingListId !== packingListId)
                    })
                },
            });
        }
    }
}

