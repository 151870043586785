"use strict";

var _toConsumableArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SmartRouteRequestParamBuilder = void 0;

var _HiddenFoldersState = require("../folders/HiddenFoldersState");

var _LayerOpacityState = require("../state/LayerOpacityState");

var _ActiveFoldersState = require("../folders/ActiveFoldersState");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var SmartRouteRequestParamBuilder = function SmartRouteRequestParamBuilder(hiddenFoldersState, layerOpacityState, activeFoldersState) {
  _classCallCheck(this, SmartRouteRequestParamBuilder);

  this.buildRouteParams = function () {
    var _hiddenFoldersState$g = hiddenFoldersState.get(),
        hiddenFolders = _hiddenFoldersState$g.hiddenFolders;

    var _layerOpacityState$ge = layerOpacityState.get(),
        opacityByLayerId = _layerOpacityState$ge.opacityByLayerId;

    var _activeFoldersState$g = activeFoldersState.get(),
        activeFoldersById = _activeFoldersState$g.activeFoldersById,
        favoritesById = _activeFoldersState$g.favoritesById;

    var includeFolderIds = [];

    if (opacityByLayerId['active-layers'] > 0) {
      includeFolderIds = _toConsumableArray(new Set(Object.keys(activeFoldersById).concat(Object.keys(favoritesById))));
    }

    return {
      excludeFolderIds: hiddenFolders,
      excludeUserTrails: opacityByLayerId['layer-user-trails'] === 0,
      excludeGlobalTrails: opacityByLayerId['layer-global-trails'] === 0,
      includeFolderIds: includeFolderIds
    };
  };
};

exports.SmartRouteRequestParamBuilder = SmartRouteRequestParamBuilder;

_defineProperty(SmartRouteRequestParamBuilder, "inject", function () {
  return [_HiddenFoldersState.HiddenFoldersState, _LayerOpacityState.LayerOpacityState, _ActiveFoldersState.ActiveFoldersState];
});