"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseLayerControls = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _greenState = require("@symbiotic/green-state");

var _BaseLayersState = require("../base-layers/BaseLayersState");

var _classnames = _interopRequireDefault(require("classnames"));

var _components = require("@wanderlost-sdk/components");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var BaseLayerControls = function BaseLayerControls(_ref) {
  var classes = _ref.classes;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _BaseLayersState.BaseLayersState
  }, function (_ref2) {
    var baseLayers = _ref2.baseLayers,
        enableBaseLayer = _ref2.enableBaseLayer,
        isVisible = _ref2.isVisible;
    baseLayers = baseLayers.filter(function (l) {
      return !l.hideInMenu;
    });
    return _react.default.createElement(_react.default.Fragment, null, baseLayers.map(function (_ref3) {
      var layerId = _ref3.layerId,
          name = _ref3.name,
          _ref3$description = _ref3.description,
          description = _ref3$description === void 0 ? '' : _ref3$description,
          _ref3$recommendation = _ref3.recommendation,
          recommendation = _ref3$recommendation === void 0 ? '' : _ref3$recommendation,
          imageURL = _ref3.imageURL;
      return _react.default.createElement(_components.Button, {
        bare: true,
        onClick: function onClick() {
          return enableBaseLayer(layerId);
        },
        key: layerId,
        className: (0, _classnames.default)(classes.layer, _defineProperty({}, classes.layerCurrent, isVisible(layerId))),
        "data-base-layer-id": layerId,
        "data-base-layer-visible": isVisible(layerId) ? 1 : 0
      }, _react.default.createElement("img", {
        alt: name,
        src: imageURL,
        className: classes.layerImage
      }), _react.default.createElement("div", {
        className: classes.layerText
      }, _react.default.createElement(_components.H3, {
        variant: "h4",
        color: "textPrimary",
        className: classes.layerName
      }, name), _react.default.createElement(_components.P, {
        className: classes.layerDescription,
        color: "textSecondary",
        gutterBottom: false
      }, description), _react.default.createElement(_components.P, {
        className: classes.layerRecommendation,
        color: "textSecondary",
        gutterBottom: false
      }, recommendation)));
    }));
  });
};

exports.BaseLayerControls = BaseLayerControls;
BaseLayerControls.propTypes = {
  classes: _propTypes.default.object.isRequired
};
exports.BaseLayerControls = BaseLayerControls = (0, _withStyles.default)(function (theme) {
  return {
    layer: {
      display: 'flex',
      width: '100%',
      marginTop: "".concat(theme.spacing(2), "px"),
      alignItems: 'center',
      textAlign: 'left',
      border: '1px solid transparent',
      '&:hover $layerName, &:active $layerName': {
        color: theme.palette.secondary.main
      },
      '&:hover $layerImage, &:active $layerImage': {
        borderColor: theme.palette.secondary.main
      }
    },
    layerName: {},
    layerCurrent: {
      '& $layerName': {
        color: theme.palette.primary.main
      },
      '& $layerImage, & $layerImage': {
        borderColor: theme.palette.primary.main
      }
    },
    layerText: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        margin: 0
      }
    },
    layerDescription: {
      fontSize: '0.8rem',
      lineHeight: 1.2
    },
    layerRecommendation: {
      fontSize: '0.8rem',
      lineHeight: 1.2,
      fontStyle: 'italic'
    },
    layerImage: {
      border: '1px solid #ccc',
      borderRadius: '0.2rem',
      marginRight: "".concat(theme.spacing(1.5), "px"),
      maxWidth: '50px'
    }
  };
})(BaseLayerControls);