"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.H2 = exports.H1 = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _typography = require("../typography");

var _core = require("@material-ui/core");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var H1Base = function H1Base(_ref) {
  var classes = _ref.classes,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["classes", "children"]);

  return _react.default.createElement(_typography.H1, _extends({
    className: classes.root
  }, rest), children);
};

H1Base.propTypes = {
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired
};
var H1 = (0, _core.withStyles)(function (theme) {
  return {
    root: {
      color: theme.typography.h1.color,
      fontSize: 34,
      lineHeight: 1.2,
      fontWeight: 'bold',
      marginTop: 0,
      marginBottom: theme.spacing(1.5),
      '@media print': {
        fontSize: 24,
        fontWeight: 400,
        marginTop: theme.spacing(1)
      }
    }
  };
})(H1Base);
exports.H1 = H1;

var H2Base = function H2Base(_ref2) {
  var classes = _ref2.classes,
      children = _ref2.children;
  return _react.default.createElement(_typography.H2, {
    className: classes.root
  }, children);
};

H2Base.propTypes = {
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired
};
var H2 = (0, _core.withStyles)(function (theme) {
  return {
    root: {
      color: theme.typography.h1.color,
      fontSize: 24,
      lineHeight: 1.2,
      fontWeight: 'bold',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1.5)
    }
  };
})(H2Base);
exports.H2 = H2;