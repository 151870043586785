"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MVUMOpenDatesRenderStrategy = void 0;

var _react = _interopRequireDefault(require("react"));

var _FeatureAttribute = require("./FeatureAttribute");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var MVUMOpenDatesRenderStrategy = function MVUMOpenDatesRenderStrategy() {
  _classCallCheck(this, MVUMOpenDatesRenderStrategy);

  _defineProperty(this, "strategyId", 'mvum-open-dates');

  this.render = function (_ref) {
    var values = _ref.value,
        label = _ref.label;
    return _react.default.createElement(_FeatureAttribute.FeatureAttribute, {
      label: label,
      value: values.length ? values.map(function (openDate) {
        return _react.default.createElement("span", {
          key: openDate.label
        }, openDate.label, ": ", openDate.value, _react.default.createElement("br", null));
      }) : 'No open dates assigned'
    });
  };
};

exports.MVUMOpenDatesRenderStrategy = MVUMOpenDatesRenderStrategy;