import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { P, Dialog, InjectTogglable } from '@wanderlost-sdk/components';

export class SupportWarning extends Component {
    state = {
        shouldDisplay: false
    }

    componentDidMount() {
        this.setState({ shouldDisplay: isSafari() });
    }

    render() {
        if (!this.state.shouldDisplay) {
            return null;
        }

        return (
            <InjectTogglable initialValue={true} cacheKey="SafariSupportWarning">
                {({ isOn: isOpen, off: close }) => (
                    <Dialog
                        isOpen={isOpen}
                        title="Looks like you're using the Safari browser"
                        actionsComponent={
                            <>
                                <Button onClick={close}>
                                    OK
                                </Button>
                            </>
                        }
                    >
                        <P>Wanderlost is not optimized for Safari.</P>
                        <P>The good news is, you should still be able to view your trip!</P>
                        <P>If you plan to use the map editing features such as route planning, then we highly recommend using the Google Chrome browser instead.</P>
                    </Dialog>
                )}
            </InjectTogglable>
        );
    }
}

const isSafari = () => {
    try {
        const browser = new window.UAParser().getBrowser();
        return browser.name === 'Safari' || browser.name === 'Mobile Safari';
    } catch (err) {
        console.log('error while checking for browser support', err);
        return false;
    }
}
