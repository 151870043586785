import React from 'react';
import { InjectAndSubscribe } from '@symbiotic/green-state';
import { TripsState } from './TripsState';
import { TripsList } from './TripsList';
import { Button, ButtonGroup, P, SectionV2 } from '@wanderlost-sdk/components';
import { Grid } from '@material-ui/core';
import { GPXImport } from '@wanderlost-sdk/cartographer';
import { ChildContainerContext } from '../ChildContainerContext';

export let AllTripsList = () => {
    const [tripsFilter, setTripsFilter] = React.useState('upcoming');
    const showUpcoming = () => setTripsFilter('upcoming');
    const showPast = () => setTripsFilter('past');

    return (
        <InjectAndSubscribe diKey={TripsState}>
            {({ upcomingTrips, pastTrips }) =>
                <SectionV2
                    title={tripsFilter === 'upcoming' ? 'Your Upcoming Trips' : 'Your Past Trips'}
                    iconImageURL={`/images/section-icons/trips.svg`}
                    actions={
                        <ButtonGroup value={tripsFilter}>
                            <Button size="small" value="upcoming" onClick={showUpcoming} variant="outlined" show-upcoming-trips-button="true">Upcoming Trips</Button>
                            <Button size="small" value="past" onClick={showPast} variant={tripsFilter === 'past' ? 'contained' : 'outlined'} show-past-trips-button="true">Past Trips</Button>
                        </ButtonGroup>
                    }
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={7} md={8} lg={9}>
                            {tripsFilter === 'upcoming' &&
                                <>
                                    {upcomingTrips.length > 0 ?
                                        <TripsList trips={upcomingTrips} /> :
                                        <P>You have no upcoming trips, you should plan a trip!</P>
                                    }
                                </>
                            }
                            {tripsFilter === 'past' &&
                                <TripsList trips={pastTrips} past-trips-list="true" />
                            }
                        </Grid>
                        <Grid xs={6} sm={5} md={4} lg={3} item style={{ marginLeft: 'auto' }}>
                            <div style={{ marginLeft: 'auto', maxWidth: 250 }}>
                                <ChildContainerContext>
                                    <GPXImport
                                        onSuccess={({ trip }) => {
                                            window.location.hash = trip ? `/trips/${trip.tripId}/map` : `/maps`;
                                        }}
                                    />
                                </ChildContainerContext>
                            </div>
                        </Grid>
                    </Grid>
                </SectionV2>
            }
        </InjectAndSubscribe>
    );
};
