import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { P, H1, H2, H3, InjectLoadAndSubscribe } from '@wanderlost-sdk/components';
import { AddExistingListState } from './state/AddExistingListState';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import { PackingList } from './PackingList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import cn from 'classnames';
import { PackingListsLayout } from './PackingListsLayout';

const StarterLists = ({ starterLists, classes }) => {
    const [selectedList, selectList] = useState(starterLists[0]);
    return (
        <PackingListsLayout page="gear-lists-page">
            <div className={classes.header}>
                <H1>Wanderlost Starter Kits</H1>
            </div>

            <P className={classes.help}>
                These starter kits are collections of gear items we have put together for you, to help you plan your own trip packing lists.
            </P>

            <Grid container spacing={5} className={classes.container}>
                <Grid item xs={2}>
                    <H2>Select Kit</H2>
                    <div className={classes.panel}>
                        <List className={classes.list}>
                            {starterLists && starterLists.map(starterList => (
                                <ListItem
                                    button
                                    key={starterList.packingListId}
                                    name="select-starter-list"
                                    onClick={() => selectList(starterList)}
                                    selected={selectedList && selectedList.packingListId === starterList.packingListId}
                                    className={classes.listItem}
                                >
                                    <ListItemText className={classes.listItemText}>{starterList.name}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <H2>Kit Preview</H2>
                    <div className={cn(classes.panel, classes.preview)}>
                        {!selectedList &&
                            <P color="textSecondary"><em>Select a list to preview items</em></P>
                        }
                        {selectedList &&
                            <div name="packing-list-preview">
                                <H3 style={{position: 'absolute'}}>{selectedList.name}</H3>
                                {selectedList.items.length === 0 &&
                                    <P><i>List contains no items</i></P>
                                }
                                <PackingList packingList={selectedList} />
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
        </PackingListsLayout>
    );

};

StarterLists.propTypes = {
    starterLists: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
};

export let StarterListsPage = ({ classes }) => (
    <InjectLoadAndSubscribe diKey={AddExistingListState}>
        {({ starterLists }) => (starterLists ? <StarterLists starterLists={starterLists} classes={classes} /> : null)}
    </InjectLoadAndSubscribe>
);

StarterListsPage.propTypes = {
    classes: PropTypes.object.isRequired
};

StarterListsPage = withStyles((theme) => ({
    container: {
        minHeight: 'calc(100vh - 200px)'
    },
    help: {
        marginBottom: '30px'
    },
    header: {
        marginBottom: theme.spacing(4),
    },
    list: {
        maxHeight: 200,
        overflowY: 'auto'
    },
    panel: {
        border: `1px solid #e5e5e5`,
        padding: theme.spacing(2),
        height: 'calc(100% - 30px)',
        overflowY: 'auto'
    },
    listItem: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        lineHeight: 1,
        letterSpacing: 0
    },
    listItemText: {
        padding: 0,
        '& span': {
            fontSize: '20px'
        }
    }
}))(StarterListsPage);
