"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.History = void 0; // Must be replace via IOC

var History = function History() {
  _classCallCheck(this, History);
};

exports.History = History;