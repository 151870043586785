"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Log = void 0;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
} // until we get the 4 log classes consolidated and driven by one config to enable debug logging... use this const...


var showDebugMessages = false;

var Log = function Log() {
  _classCallCheck(this, Log);

  _defineProperty(this, "info", function (message, extras) {
    return console.log(message, extras);
  });

  _defineProperty(this, "error", function (error, extras) {
    return console.error(error, extras);
  });

  _defineProperty(this, "debug", function (message, extras) {
    if (!showDebugMessages) {
      return;
    }

    try {
      if (extras) {
        console.log(message, extras);
      } else {
        console.log(message);
      }
    } catch (err) {// do nothing so that logging isn't the cause of app failures
    }
  });
};

exports.Log = Log;