"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fixToEPSG4326 = fixToEPSG4326;
exports.default = replaceToEPSG4326;

var _proj = require("ol/proj");

var _epsg = require("ol/proj/epsg3857");

var _transforms = require("ol/proj/transforms");
/**
 * Transformation from EPSG:3857 to EPSG:4326.
 *
 * This is a replacement for the OpenLayers toEPSG4326() function.
 * Ours will delegate to the original, but also fix longitudes that have gone
 * out of bounds from crossing the international date line.
 *
 * @see https://github.com/openlayers/openlayers/issues/3899
 *
 * @param {Array<number>} input Input array of coordinate values.
 * @param {Array<number>=} optOutput Output array of coordinate values.
 * @param {number=} optDimension Dimension (default is `2`).
 * @return {Array<number>} Output array of coordinate values.
 */


function fixToEPSG4326(input, optOutput, optDimension) {
  var output = (0, _epsg.toEPSG4326)(input, optOutput, optDimension);
  var length = output.length;
  var dimension = optDimension > 1 ? optDimension : 2;

  for (var i = 0; i < length; i += dimension) {
    output[i] %= 360;

    if (output[i] < -180) {
      output[i] += 360;
    }

    if (output[i] > 180) {
      output[i] -= 360;
    }
  }

  return output;
}
/**
 * Replace the function for transforming from EPSG:3857 to EPSG:4326 with ours.
 */


function replaceToEPSG4326() {
  var epsg3857 = (0, _proj.get)('EPSG:3857');
  var epsg4326 = (0, _proj.get)('EPSG:4326');
  (0, _transforms.remove)(epsg3857, epsg4326);
  (0, _transforms.add)(epsg3857, epsg4326, fixToEPSG4326);
}