"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Breadcrumbs = exports.BreadcrumbItem = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _Button = require("../Button");

var _Display = require("../Display");

var _typography = require("../../typography");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var BreadcrumbItem = function BreadcrumbItem(_ref) {
  var to = _ref.to,
      routeParams = _ref.routeParams,
      children = _ref.children;
  return to ? _react.default.createElement(_Button.Button, {
    to: to,
    routeParams: routeParams,
    link: true
  }, children) : children;
};

exports.BreadcrumbItem = BreadcrumbItem;
BreadcrumbItem.propTypes = {
  to: _propTypes.default.string,
  routeParams: _propTypes.default.object,
  children: _propTypes.default.node.isRequired
};

var BreadcrumbSeparator = _react.default.createElement("span", null, ' ', ">", ' ');

var Breadcrumbs = function Breadcrumbs(_ref2) {
  var classes = _ref2.classes,
      children = _ref2.children;
  return _react.default.createElement(_Display.Display, {
    screen: true
  }, _react.default.createElement(_typography.P, {
    className: classes.container,
    "data-type": "breadcrumbs"
  }, _react.default.createElement(_typography.SMALL, null, _react.default.Children.map(children, function (child, i) {
    return _react.default.createElement("span", {
      key: i
    }, i > 0 && BreadcrumbSeparator, _react.default.cloneElement(child));
  }))));
};

exports.Breadcrumbs = Breadcrumbs;
Breadcrumbs.propTypes = {
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node
};
exports.Breadcrumbs = Breadcrumbs = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      opacity: 0.8,
      marginBottom: theme.spacing(3),
      marginTop: -theme.spacing(1),
      '& a:link, & a:visited': {
        color: '#6D837E'
      }
    }
  };
})(Breadcrumbs);