import React from 'react';
import { Layout } from '@wanderlost-sdk/components';
import { AllTripsList } from './AllTripsList';
import { CreateTripBannerForm } from './CreateTripBannerForm';

export const TripsPage = () => (
    <Layout
        page="trips-page"
        BannerProps={{
            size: 'large',
            title: "Trips",
            imageURL: "/images/banners/stars.jpg",
            center: true,
            renderContent: () => (
                <CreateTripBannerForm />
            )
        }}
    >
        <AllTripsList />
    </Layout>
);
