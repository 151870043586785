import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withDependencies } from '@symbiotic/green-state';
import { Theme } from './Theme';

export class ThemeProvider extends Component {
    static propTypes = {
        theme: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
    }

    render() {
        const { theme, children } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                {children}
            </MuiThemeProvider>
        );
    }
}

ThemeProvider = withDependencies({ theme: Theme })(ThemeProvider);

// This component doesnt render anything, it just injects some global styles
const GlobalStyles = withStyles(theme => ({
    '@global': {
        'a:link': { color: theme.palette.primary.medium },
        'a:visited': { color: theme.palette.primary.medium },
        'a:focus, a:hover, a:active': { color: theme.palette.secondary.main },

        /**
         * Global styles for ol-contextmenu (see RouteTool)
         * Ideally this should be much closer to the tool but it wasn't a priority to figure out how to inject global styles from a non-react component
         */
        '.ol-ctx-menu-container': {
            left: '-999em', // Make sure it's not visible when it's not open, when it's open it will have inline styles for top|left|right|bottom
        },
        '.ol-ctx-menu-container li': {
            ...theme.typography.body1,
            paddingLeft: '1rem',
            marginLeft: '-0.5rem',
            marginRight: '-0.5rem'
        },
        '.ol-ctx-menu-container li:not(.ol-ctx-menu-separator):hover': {
            backgroundColor: theme.palette.background.highlight,
            color: theme.palette.text.primary,
        },
        '.ol-ctx-menu-container .ol-ctx-menu-title': {
            fontWeight: 'bold',
            paddingLeft: '0.5rem'
        },
        '.ol-ctx-menu-container .ol-ctx-menu-title:hover': {
            cursor: 'default !important',
            backgroundColor: 'transparent !important',
            color: theme.palette.text.primary
        },

        /**
         * Global styles for scale line
         * Ideally this would be closer to the code but plumbing cssinjs classes to the mapfactory or an HTMLElement to render to is not worth the effort
         */
        '.map-scale-control': {
            position: 'absolute',
            bottom: '1.75rem',
            right: '5.75rem',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            fontFamily: 'Roboto, sans-serif',
            borderRadius: '4px',
            padding: '2px',
        },
        '.map-scale-control-inner': {
            border: '2px solid #000',
            textShadow: '0 0 5px #fff',
            borderTop: 'none',
            color: '#000',
            fontSize: '12px',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: '1px',
            willChange: 'contents, width',
        },
        '.ql-toolbar.ql-snow + .ql-container.ql-snow': {
            borderTop: '1px solid #ccc !important',
        },
        '.ql-toolbar.ql-snow': {
            borderBottom: '0'
        },

        /**
         * Global print styles
         * See individual components for their custom print styles
         */
        '@media print': {
            html: {
                fontSize: '12px'
            },
            button: {
                display: 'none !important'
            }
        },
    },
}))(() => null);
