import React from 'react';
import PropTypes from 'prop-types';
import { P, H1, InjectLoadAndSubscribe } from '@wanderlost-sdk/components';
import { GearListsState } from './state/GearListsState';
import { AddGearListButton } from './AddGearListButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import { PackingListsLayout } from './PackingListsLayout';
import { PackingListCard } from './PackingListCard';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
    header: {
        marginBottom: theme.spacing(4),
    },
    list: {
        display: 'flex',
        alignItems: 'stretch',
        width: '100%'
    },
});

export let GearListsPage = ({ classes }) => {
    return (
        <PackingListsLayout page="gear-lists-page">
            <InjectLoadAndSubscribe diKey={GearListsState}>
                {({ gearLists, myItems, createGearList }) => {
                    return (
                        <>
                            <div className={classes.header}>
                                <Grid container justify="space-between">
                                    <Grid item>
                                        <H1>
                                            My Gear Lists
                                        </H1>
                                    </Grid>
                                    <Grid item>
                                        <AddGearListButton onSubmit={async (list) => await createGearList(list)} />
                                    </Grid>
                                </Grid>
                            </div>

                            {!gearLists && <CircularProgress fontSize="large" />}

                            {gearLists && !gearLists.length &&
                                <>
                                    <P>Looks like you haven't created any gear lists yet!</P>
                                    <P>
                                        <AddGearListButton buttonLabel="Add one now!" buttonProps={{link: true}} onSubmit={async (list) => await createGearList(list)} /> &nbsp;
                                        {myItems.length > 0 && <i>(you can add items from existing trip packing lists)</i>}
                                    </P>
                                </>
                            }

                            {gearLists && gearLists.length > 0 &&
                                <Grid container spacing={5}>
                                    {gearLists.map(packingList => (
                                        <Grid item md={6} lg={4} key={packingList.packingListId} className={classes.list} data-type="gear-list">
                                            <PackingListCard packingList={packingList} />
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                        </>
                    );
                }}
            </InjectLoadAndSubscribe>
        </PackingListsLayout>
    );
};

GearListsPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

GearListsPage = withStyles(styles)(GearListsPage);
