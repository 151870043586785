"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElevationProfile = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _core = require("@material-ui/core");

var _recharts = require("recharts");

var _greenState = require("@symbiotic/green-state");

var _core2 = require("@wanderlost-sdk/core");

var _components = require("@wanderlost-sdk/components");

var _ElevationProfileState = require("./state/ElevationProfileState");

var _KeyboardArrowDown = _interopRequireDefault(require("@material-ui/icons/KeyboardArrowDown"));

var _KeyboardArrowRight = _interopRequireDefault(require("@material-ui/icons/KeyboardArrowRight"));

var _EventBus = require("./EventBus");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ElevationProfile = function ElevationProfile(_ref) {
  var _ref2;

  var isDrawerOpen = _ref.isDrawerOpen,
      classes = _ref.classes;
  var eventBus = (0, _greenState.useInstance)(_EventBus.EventBus);
  var color = (0, _greenState.useInstance)(_components.Theme).palette.primary.main;
  var elevationProfileStateInstance = (0, _greenState.useInstance)(_ElevationProfileState.ElevationProfileState);
  var elevationProfileState = (0, _greenState.useSubscription)(function () {
    return elevationProfileStateInstance;
  });

  if (!elevationProfileState || !elevationProfileState.elevationProfile) {
    return null;
  }

  var elevationProfile = elevationProfileState.elevationProfile,
      totalElevationGain = elevationProfileState.totalElevationGain,
      totalElevationLoss = elevationProfileState.totalElevationLoss,
      minElevation = elevationProfileState.minElevation,
      maxElevation = elevationProfileState.maxElevation,
      maxDistance = elevationProfileState.maxDistance,
      totalDistance = elevationProfileState.totalDistance,
      xAxisTicks = elevationProfileState.xAxisTicks,
      yAxisTicks = elevationProfileState.yAxisTicks,
      toggleCollapsed = elevationProfileState.toggleCollapsed,
      isCollapsed = elevationProfileState.isCollapsed;
  var ExpandCollapseIconComponent = isCollapsed ? _KeyboardArrowRight.default : _KeyboardArrowDown.default;
  return _react.default.createElement(_core.Paper, {
    name: "elevation-profile",
    className: (0, _classnames.default)(classes.wrapper, (_ref2 = {}, _defineProperty(_ref2, classes.wrapperDrawerOpen, isDrawerOpen), _defineProperty(_ref2, classes.wrapperCollapsed, isCollapsed), _ref2))
  }, _react.default.createElement(_components.P, {
    gutterBottom: false,
    className: classes.heading,
    "data-id": "elevation-heading",
    onClick: toggleCollapsed,
    name: "toggle-elevation-profile",
    "data-collapsed": isCollapsed
  }, _react.default.createElement(ExpandCollapseIconComponent, {
    fontSize: "small"
  }), " ", (0, _core2.round)(totalDistance, 2), " miles", _react.default.createElement("span", {
    name: "elevation-gain-loss",
    className: classes.gainLoss
  }, "+", Math.round(totalElevationGain).toLocaleString(), " ft / -", Math.round(totalElevationLoss).toLocaleString(), " ft")), !elevationProfileState.isCollapsed && _react.default.createElement(_recharts.ResponsiveContainer, {
    width: "100%",
    height: 90
  }, _react.default.createElement(_recharts.AreaChart, {
    data: elevationProfile,
    margin: {
      top: 10,
      right: 10,
      left: -15,
      bottom: -10
    }
  }, _react.default.createElement("defs", null, _react.default.createElement("linearGradient", {
    id: "color",
    x1: "0",
    y1: "0",
    x2: "0",
    y2: "1"
  }, _react.default.createElement("stop", {
    offset: "5%",
    stopColor: color,
    stopOpacity: 0.8
  }), _react.default.createElement("stop", {
    offset: "95%",
    stopColor: color,
    stopOpacity: 0
  }))), _react.default.createElement(_recharts.XAxis, {
    dataKey: "accumulatedDistanceInMiles",
    type: "number",
    ticks: xAxisTicks,
    domain: [0, maxDistance]
  }), _react.default.createElement(_recharts.YAxis, {
    type: "number",
    ticks: yAxisTicks,
    allowDecimals: false,
    domain: [minElevation, maxElevation]
  }), _react.default.createElement(_recharts.CartesianGrid, {
    strokeDasharray: "3 3"
  }), _react.default.createElement(_recharts.Tooltip, {
    offset: 20,
    cursor: {
      stroke: 'red',
      strokeWidth: 1
    },
    wrapperStyle: {
      padding: 0,
      margin: '5px 0'
    },
    separator: "",
    content: function content(event) {
      var payload = event && event.payload && event.payload[0] && event.payload[0].payload;

      if (!payload) {
        eventBus.emit('ElevationProfile.tooltipClosed');
        return false;
      } else {
        var elevationInFeet = payload.elevationInFeet,
            accumulatedDistanceInMiles = payload.accumulatedDistanceInMiles;
        eventBus.emit('ElevationProfile.tooltipOpened', {
          x: payload.x,
          y: payload.y
        });
        return _react.default.createElement("div", {
          className: classes.tooltip
        }, _react.default.createElement("div", null, _react.default.createElement("strong", null, "Distance:"), " ", (0, _core2.round)(accumulatedDistanceInMiles, 2), " mi"), _react.default.createElement("div", null, _react.default.createElement("strong", null, "Elevation:"), " ", Math.round(elevationInFeet), " ft"));
      }
    }
  }), _react.default.createElement(_recharts.Area, {
    type: "monotone",
    dataKey: "elevationInFeet",
    stroke: color,
    fillOpacity: 1,
    fill: "url(#color)"
  }))));
};

exports.ElevationProfile = ElevationProfile;
ElevationProfile.propTypes = {
  isDrawerOpen: _propTypes.default.bool.isRequired,
  classes: _propTypes.default.object.isRequired
};
exports.ElevationProfile = ElevationProfile = (0, _core.withStyles)(function (theme) {
  return {
    wrapper: _objectSpread({}, theme.typography.caption, {
      position: 'absolute',
      left: '1rem',
      bottom: '1rem',
      width: 'calc(100% - 220px)',
      maxWidth: 600,
      height: theme.wanderlost.elevationProfileHeight,
      zIndex: theme.wanderlost.zIndex.mapControls
    }),
    wrapperCollapsed: {
      width: 'auto !important',
      height: theme.wanderlost.elevationProfileHeightCollapsed
    },
    wrapperDrawerOpen: {
      width: "calc(100% - 220px - ".concat(theme.wanderlost.drawerWidth, ")")
    },
    // maxWidth so that we don't overlap the scale line which can change widths depending on zoom
    '@media (max-width: 1300px)': {
      wrapper: {
        maxWidth: 300
      }
    },
    heading: {
      paddingRight: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      textAlign: 'center',
      width: '100%',
      fontSize: '0.8rem',
      fontWeight: 600,
      margin: '0 0 -5px',
      padding: 0
    },
    gainLoss: {
      marginLeft: theme.spacing(1)
    },
    tooltip: {
      lineHeight: 1.2,
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
      color: theme.palette.text.primary
    }
  };
})(ElevationProfile);