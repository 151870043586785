import React from 'react';
import { P, Layout } from '@wanderlost-sdk/components';

export const NotFoundPage = () => (
    <Layout
        page="not-found"
        BannerProps={{
            title: 'Page Not Found',
            size: 'small'
        }}
    >
        <P>Wander too far and you may get lost.</P>
    </Layout>
);

