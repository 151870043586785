"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExtentAdapter = void 0;

var _ProjectionConfig = require("./ProjectionConfig");

var _proj = require("ol/proj");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var ExtentAdapter = function ExtentAdapter(_ref) {
  var viewProjection = _ref.viewProjection,
      apiProjection = _ref.apiProjection;

  _classCallCheck(this, ExtentAdapter);

  this.viewToApi = function (extent) {
    var _ref2 = (0, _proj.transformExtent)(extent, viewProjection, apiProjection),
        _ref3 = _slicedToArray(_ref2, 4),
        xmin = _ref3[0],
        ymin = _ref3[1],
        xmax = _ref3[2],
        ymax = _ref3[3];

    return {
      xmin: xmin,
      ymin: ymin,
      xmax: xmax,
      ymax: ymax
    };
  };

  this.apiToView = function (extent) {
    var xmin = extent.xmin,
        ymin = extent.ymin,
        xmax = extent.xmax,
        ymax = extent.ymax;
    return (0, _proj.transformExtent)([xmin, ymin, xmax, ymax], apiProjection, viewProjection);
  };
};

exports.ExtentAdapter = ExtentAdapter;

_defineProperty(ExtentAdapter, "inject", function () {
  return [_ProjectionConfig.ProjectionConfig];
});