"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonGroup = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    root: {
      display: 'flex',
      border: "1px solid ".concat(theme.palette.primary.main),
      borderRadius: 8,
      padding: theme.spacing(1)
    },
    button: {
      borderWidth: 0,
      borderRadius: 8,
      marginRight: theme.spacing(1),
      '&:last-child': {
        marginRight: 0
      }
    },
    buttonSelected: {
      backgroundColor: "".concat(theme.palette.primary.main, " !important"),
      color: '#fff'
    }
  };
};

var ButtonGroup = function ButtonGroup(_ref) {
  var classes = _ref.classes,
      children = _ref.children,
      value = _ref.value;
  return _react.default.createElement("div", {
    className: classes.root
  }, _react.default.Children.map(children, function (child, i) {
    return _react.default.cloneElement(child, {
      key: i,
      className: (0, _classnames.default)(classes.button, _defineProperty({}, classes.buttonSelected, child.props.value === value))
    });
  }));
};

exports.ButtonGroup = ButtonGroup;
ButtonGroup.propTypes = {
  value: _propTypes.default.any.isRequired,
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired
};
exports.ButtonGroup = ButtonGroup = (0, _core.withStyles)(styles)(ButtonGroup);