import React from 'react';
import PropTypes from 'prop-types';
import { FullWidthLayout, Button } from '@wanderlost-sdk/components';
import { TripRoutesState, SelectedFolderState } from '@wanderlost-sdk/cartographer';
import { Map } from '../maps';
import { InjectTripDetails } from './InjectTripDetails';
import { Inject, useInstance } from '@symbiotic/green-state';

export const TripMapPage = ({ zoomToFolderId }) => {
    const selectedFolderState = useInstance(SelectedFolderState);

    return (
        <InjectTripDetails>
            {({ trip }) => (
                <Inject diKey={TripRoutesState}>
                    {tripRoutesState => (
                        <FullWidthLayout page="trip-map">
                            <Map
                                onMount={async (cartographer) => {
                                    const zoomToRoute = zoomToFolderId ? tripRoutesState.get().routes.find(route => route.routeId === zoomToFolderId) : null;

                                    if (zoomToRoute && zoomToRoute.extent) {
                                        await cartographer.zoomToExtent(zoomToRoute.extent);
                                    } else {
                                        // There might not be any features
                                        const layerExtent = await cartographer.getLayerExtent('layer-routes');
                                        if (layerExtent) {
                                            await cartographer.zoomToExtent(layerExtent);
                                        }
                                    }

                                    // Need to wait for at least layer-routes to be ready or else setting active feature on map will fail b/c its not in the map yet
                                    if (zoomToRoute) {
                                        await cartographer.isReady();
                                        selectedFolderState.selectFolder({ folder: tripRoutesState.getByIdAsFolder(zoomToFolderId) });
                                    }
                                }}
                                toolbarBreadcrumb={
                                    <Button bare to="tripDetails" routeParams={{ tripId: trip.tripId }}>
                                        Back to Trip
                                    </Button>
                                }
                                toolbarDefaultTitle={trip.name}
                            />
                        </FullWidthLayout>
                    )}
                </Inject>
            )}
        </InjectTripDetails>
    );
};

TripMapPage.propTypes = {
    zoomToFolderId: PropTypes.string,
};
