"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CategoryCard = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _greenState = require("@symbiotic/green-state");

var _typography = require("../typography");

var _urls = require("../urls");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    card: {
      '&:hover': {
        cursor: 'pointer'
      },
      '&:hover $title': {
        color: theme.palette.primary.main
      }
    },
    titleContainer: {
      height: '20px',
      margin: 0
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: 0,
      textAlign: 'center',
      color: theme.typography.h1.color,
      lineHeight: 1.5,
      fontSize: 14
    },
    image: {
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '50%',
      width: 100,
      height: 100
    }
  };
};

var CategoryCardBase = function CategoryCardBase(_ref) {
  var title = _ref.title,
      imageURL = _ref.imageURL,
      filters = _ref.filters,
      classes = _ref.classes;
  var navigator = (0, _greenState.useInstance)(_urls.WanderLostNavigator);
  return _react.default.createElement("div", {
    className: classes.card,
    "data-type": "category-card",
    onClick: function onClick() {
      navigator.recipes(filters).go();
    }
  }, _react.default.createElement("div", {
    className: classes.image,
    style: {
      backgroundImage: "url(".concat(imageURL || '/images/recipes-banner.png', ")")
    }
  }), _react.default.createElement(_typography.P, {
    className: classes.title
  }, title));
};

CategoryCardBase.propTypes = {
  title: _propTypes.default.string.isRequired,
  imageURL: _propTypes.default.string,
  filters: _propTypes.default.object.isRequired,
  classes: _propTypes.default.object
};
var CategoryCard = (0, _core.withStyles)(styles)(CategoryCardBase);
exports.CategoryCard = CategoryCard;