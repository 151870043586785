"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batch = void 0;

var _fp = require("lodash/fp");

var batch = function batch(items, count) {
  return (0, _fp.chunk)(Math.ceil(items.length / count), items);
};

exports.batch = batch;