import './debugging/sentry'; // sentry first, so we catch all errors
import replaceToEPSG4326 from './replaceToEPSG4326';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';

// this file should be committed empty, but if sdk fails to build
// error-monitor script will write a syntax error to it, crashing us
import './dev-errors';

// Fixes bug where zooming far out or panning across worlds results in invalid lat lngs
// Then features can't be loaded and the user can't easily recover
replaceToEPSG4326();

const startApp = () => {
    ReactDOM.render(<App />, document.getElementById('root'));
};

if (document.body.getAttribute('app-ready')){
    startApp();
} else {
    document.body.addEventListener('app-ready', () => startApp());
}

if (module.hot) {
    module.hot.accept();
}
