"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _typography = require("../typography");

var _InjectRecipesState = require("./InjectRecipesState");

var _components = require("../components");

var _RecipesLayout = require("./RecipesLayout");

var _ContentfulRichText = require("./ContentfulRichText");

var _RecipeHeadings = require("./RecipeHeadings");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ContentPage = function ContentPage(_ref) {
  var pageId = _ref.pageId;
  return _react.default.createElement(_InjectRecipesState.InjectRecipesState, null, function (_ref2) {
    var pages = _ref2.pages;

    var _pages$find = pages.find(function (p) {
      return p.pageId === pageId;
    }),
        pageTitle = _pages$find.pageTitle,
        author = _pages$find.author,
        content = _pages$find.content;

    return _react.default.createElement(_RecipesLayout.RecipesLayout, {
      page: "content-page",
      breadcrumbs: _react.default.createElement(_components.Breadcrumbs, null, _react.default.createElement(_components.BreadcrumbItem, {
        to: "recipesHome"
      }, "Recipes"), _react.default.createElement(_components.BreadcrumbItem, null, pageTitle))
    }, _react.default.createElement(_RecipeHeadings.H1, null, pageTitle), author && _react.default.createElement(_typography.P, {
      gutterBottom: false
    }, "Author: ", author.name), _react.default.createElement(_ContentfulRichText.ContentfulRichText, {
      content: content
    }));
  });
};

exports.ContentPage = ContentPage;
ContentPage.propTypes = {
  pageId: _propTypes.default.string.isRequired,
  classes: _propTypes.default.object.isRequired
};