import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { ArrayState, useSubscription, Subscribe, Inject, useInstance } from '@symbiotic/green-state';
import { Typography, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import { dedupeArray, formatTripDate } from '@wanderlost-sdk/core';
import { Dialog, TripBanner, TripNotes, H1, H2, Display, Button, P, ProgressBar, WanderLostNavigator } from '@wanderlost-sdk/components';
import { TripRoutesState } from '@wanderlost-sdk/cartographer';
import { TripMealsDaySummary } from './meals/TripMealsDaySummary';
import { TripMealsState, InjectLoadAndSubscribe } from '@wanderlost-sdk/components';
import { TripMealsSummaryTable } from './meals/TripMealsSummaryTable';
import { InjectTripDetails } from './InjectTripDetails';
import { TripItinerary } from './itineraries/TripItinerary';
import { RoutesListPrint } from './routes/RoutesListPrint';
import { withStyles } from '@material-ui/core';
import logoPrint from '../logo.png';
import { TripPackingListSummary } from '../packing-lists/TripPackingListSummary';
import { TripPackingListsState } from '../packing-lists/state';
import { RecipeDetails, RecipesState, TripDates, TripDateStats, TripDistance } from '@wanderlost-sdk/components';

const TripPrintHeader = ({ trip, classes }) => (
    <>
        <Display print>
            <div className={classes.header}>
                <img className={classes.logo} src={logoPrint} alt="The Wanderlost Project" />
                <H2 gutterBottom={false}>{trip.name}</H2>
                <TripDates trip={trip} className={cn(classes.tripDate)} gutterBottom={false} color="inherit" />
                <TripDateStats trip={trip} className={cn(classes.tripDateStats)} gutterBottom={false} noIcon color="inherit" />
                {trip.routes &&
                    <TripDistance trip={trip} style={{ fontWeight: 'bold' }} gutterBottom={false} color="inherit" />
                }
            </div>
        </Display>
    </>
);

const DayPrintHeader = ({ trip, date, dayNumber, continued = false, classes }) => (
    <>
        <div>&nbsp;</div> {/* this space fixes alignment of image in TripPrintHeader */}
        <TripPrintHeader trip={trip} classes={classes} />
        <H1>
            Day {dayNumber}
            <Typography className={classes.subTitle} variant="h3" color="textSecondary" component="span">
                {formatTripDate(date)} {continued ? ' (continued)' : ''}
            </Typography>
        </H1>
    </>
);

const RecipePrintHeader = ({ classes }) => (
    <>
        <Display print>
            <div className={classes.header}>
                <img className={classes.logo} src={logoPrint} alt="The Wanderlost Project" />
            </div>
        </Display>
    </>
);

const Overlay = ({ trip, includeRecipes, setIncludeRecipes, progress, debug, classes }) => {
    const navigator = useInstance(WanderLostNavigator);

    // In addition to "percentDone" indicating routes are done, we also
    // need to wait for other slow state instances to finish loading
    const packingListsState = useInstance(TripPackingListsState);
    const stateLoaded = Boolean(useSubscription(() => packingListsState));

    const isReadyToPrint = (progress === undefined || progress >= 100) && stateLoaded;

    // After user clicks print button we must wait for one more render loop so this dialog does
    // not print. We should simply be able to invoke window.print() inside a window.setTimeout().
    // But that crashes Chrome (see https://github.com/facebook/react/issues/16734).
    // As a workaround until React 17, we set state in a timeout, and abort this render:
    const [isPrinting, setPrinting] = useState(false);
    const onPrint = () => {
        window.setTimeout(() => {
            setPrinting(true);
            return null;
        })
    }

    useEffect(() => {
        if (isPrinting) {
            if (!debug) {
                window.onafterprint = () => {
                    window.onafterprint = null;
                    navigator.tripDetails({ tripId: trip.tripId }).go();
                }
            }
            document.title = trip.name;
            window.print();
        }
    });

    return (
        <>
            <div className={cn(classes.overlay, {[classes.overlayDebug]: debug})}>
                <div className={classes.wrapper}>
                    <TripBanner trip={trip} size="small" noNav noActions />
                    {!isPrinting &&
                        <Dialog
                            title="Print Trip"
                            isOpen={true}
                            className={classes.printDialog}
                            actionsComponent={
                                <>
                                    <Button to="tripDetails" routeParams={{tripId: trip.tripId}}>Cancel</Button>
                                    <Button color="primary" variant="contained" disabled={!isReadyToPrint} onClick={onPrint}>Show Print Preview</Button>
                                </>
                            }
                        >
                            <>
                                <P>
                                    This will print a trip summary, including your notes, packing list, and meal plans, then a page
                                    for each day of your trip, showing your itinerary, meals and route maps. If you check the box below,
                                    a "Trip Cookbook" will also be included, containing any recipes you have attached to your meals plans.
                                </P>

                                <div className={classes.options}>
                                    <FormControlLabel
                                        label="Include &quot;Trip Cookbook&quot;?"
                                        control={
                                            <Checkbox
                                                name="inclue-recipes"
                                                color="primary"
                                                value={includeRecipes}
                                                onClick={() => setIncludeRecipes(!includeRecipes)}
                                            />
                                        }
                                    />
                                </div>

                                {progress !== undefined &&
                                    <div className={classes.progressBar}>
                                        <P>Preparing route maps...</P>
                                        <ProgressBar value={progress} />
                                    </div>
                                }
                            </>
                        </Dialog>
                    }
                </div>
            </div>
        </>
    );
};

const TripPrintPageBase = ({ classes }) => {
    // append "?__debug" to the end of the trip "/print" route to watch the rendering in the browser
    const debug = (window.location.hash.includes('__debug'));

    const [includeRecipes, setIncludeRecipes] = useState(false);

    const renderedRoutesState = useSubscription(() => new ArrayState([]));
    if (!renderedRoutesState) {
        return null;
    }

    const onRouteRendered = ({ route }) => {
        renderedRoutesState.push(route);
    };

    return (
        <InjectTripDetails>
            {({ trip }) => (
                <Inject diKey={TripRoutesState}>
                    {tripRoutesStateInjected => (
                        <Subscribe to={async () => await tripRoutesStateInjected.ensureLoaded()}>
                            {({ routes: allRoutes }) => {
                                const routes = allRoutes.filter(r => r.features.length > 0);

                                // If we try to render a long trip all at once, the openlayers maps get hung up, and
                                // randomly fail to render tiles. So we only render a few routes at a time, moving
                                // forward as the onRouteRendered event updates renderedRoutesState.
                                const notYetRendered = routes.filter(r => !renderedRoutesState.values.includes(r));
                                const renderUntilRoute = notYetRendered[1]; // two ahead

                                const renderUntilDay = (renderUntilRoute ? renderUntilRoute.dayNumber : trip.durationInDays);
                                const tripDates = [...Array(trip.durationInDays).keys()].map(index => moment.utc(trip.startDate).add(index, 'd')).slice(0, renderUntilDay);

                                const progress = routes.length ? (renderedRoutesState.values.length / routes.length * 100 || 5) : undefined;

                                return (
                                    <div className={classes.root}>
                                        <Display screen>
                                            <Overlay trip={trip} includeRecipes={includeRecipes} setIncludeRecipes={setIncludeRecipes} progress={progress} debug={debug} classes={classes} />
                                        </Display>

                                        <TripPrintHeader trip={trip} classes={classes} />

                                        <H1>Before You Go</H1>

                                        <Divider />

                                        <div className={classes.content}>
                                            <TripNotes readOnly={true} />

                                            <TripPackingListSummary trip={trip} canEdit={false} />

                                            <div className={classes.mealSummary}>
                                                <H2>Meal Summary</H2>
                                                <InjectLoadAndSubscribe diKey={TripMealsState} methodName="ensureLoaded">
                                                    {({ getMealSummaryState }) => {
                                                        const { mealTypes, datesWithMeals } = getMealSummaryState().get();
                                                        return <TripMealsSummaryTable mealTypes={mealTypes} datesWithMeals={datesWithMeals} />
                                                    }}
                                                </InjectLoadAndSubscribe>
                                            </div>
                                        </div>

                                        {tripDates.map((date, dateIndex) => {
                                            const dayNumber = dateIndex + 1;
                                            const dayRoutes = routes.filter(r => r.dayNumber === dayNumber);

                                            return (
                                                <div key={`day-detail-${dayNumber}`} className={classes.dayDetail}>

                                                    <DayPrintHeader trip={trip} date={date} dayNumber={dayNumber} classes={classes} />

                                                    <Divider />

                                                    <div className={classes.content}>
                                                        <TripItinerary dayNumber={dayNumber} canEdit={false} />
                                                        <TripMealsDaySummary trip={trip} dayNumber={dayNumber} gutterBottom={true} canEdit={false} />

                                                        <RoutesListPrint dayNumber={dayNumber} routes={dayRoutes} onRouteRendered={onRouteRendered} header={
                                                            <DayPrintHeader trip={trip} dayNumber={dayNumber} classes={classes} continued={true} />
                                                        }/>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        <InjectLoadAndSubscribe diKey={TripMealsState} methodName="ensureLoaded">
                                            {({ meals }) => (
                                                <InjectLoadAndSubscribe diKey={RecipesState}>
                                                    {({ recipes }) => {
                                                        if (!includeRecipes) {
                                                            return null;
                                                        }
                                                        const recipeIds = meals.map(m => m.recipeIds).flat();
                                                        const mealRecipes = dedupeArray(recipeIds)
                                                            .map(id => recipes.find(r => r.recipeId === id))
                                                            .sort((r1, r2) => r1.name.localeCompare(r2.name));
                                                        return mealRecipes.map(recipe => (
                                                            <div className={classes.recipe} key={recipe.recipeId}d>
                                                                <RecipePrintHeader trip={trip} classes={classes} />
                                                                <RecipeDetails recipe={recipe} />
                                                            </div>
                                                        ));
                                                    }}
                                                </InjectLoadAndSubscribe>
                                            )}
                                        </InjectLoadAndSubscribe>
                                    </div>
                                );
                            }}
                        </Subscribe>
                    )}
                </Inject>
            )}
        </InjectTripDetails>
    );
};

TripPrintPageBase.propTypes = {
    classes: PropTypes.object.isRequired
};

// HACK: There is a print logo in the App class for when the user triggers a manual print of a specific page.
// But here we want to insert the logo on every page manually, so we need to hide it globally.
export const TripPrintPage = withStyles(theme => ({
    '@global': {
        '.print-logo': {
            display: 'none'
        }
    },
    root: {
        padding: theme.spacing(4)
    },
    subTitle: {
        marginLeft: `${theme.spacing(2)}px`,
    },
    dayDetail: {
        pageBreakBefore: 'always'
    },
    content: {
        paddingTop: `${theme.spacing(2)}px`
    },
    mealSummary: {
        paddingTop: `${theme.spacing(2)}px`
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '60px',
        color: theme.palette.text.primary,
    },
    logo: {
        height: '40px'
    },
    recipe: {
        pageBreakBefore: 'always'
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'white',
        zIndex: 50,
    },
    overlayDebug: {
        position: 'static',
        height: '230px',
        width: '100%'
    },
    wrapper: {
    },
    options: {
        margin: '10px 0 0'
    },
    progressBar: {
        margin: '10px 0 0'
    },
    printDialog: {
        maxWidth: '600px'
    }
}))(TripPrintPageBase);
