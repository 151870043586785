import { State } from '@symbiotic/green-state';
import { TripRoutesState } from '@wanderlost-sdk/cartographer';
import { TripDetailsState } from './TripDetailsState';
import { TripItinerariesState } from './itineraries/state/TripItinerariesState';
import { TripMealsState, TripNotesState } from '@wanderlost-sdk/components';
import { TripPackingListsState } from '../packing-lists/state';

export class TripDetailsLoadingState extends State {
    static inject = () => [TripDetailsState, TripRoutesState, TripItinerariesState, TripMealsState, TripPackingListsState, TripNotesState];
    constructor(detailsState, routesState, itinerariesState, mealsState, packingListsState, tripNotesState) {
        super({ isLoading: true });

        const updateIsLoading = () => {
            const { activeDayNumber } = detailsState.get();

            // Before you go only shows trip notes and packing lists
            if (activeDayNumber === 0) {
                this.setState({
                    isLoading: tripNotesState.isLoading() || packingListsState.isLoading()
                });

            // Day tabs shows everything else
            } else {
                this.setState({
                    isLoading:
                        itinerariesState.isLoading()
                        || mealsState.isLoading()
                        || routesState.isLoading()
                });
            }
        };

        const onDisposeCallbacks = [];
        [detailsState, routesState, itinerariesState, mealsState, packingListsState].forEach(state => {
            onDisposeCallbacks.push(state.subscribe(updateIsLoading));
        })

        const superDispose = this.dispose;
        this.dispose = () => {
            superDispose();
            onDisposeCallbacks.forEach(onDispose => onDispose());
        };
    }
}
