"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _FontAwesome = require("./FontAwesome");

Object.keys(_FontAwesome).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _FontAwesome[key];
    }
  });
});

var _QuoteIcon = require("./QuoteIcon");

Object.keys(_QuoteIcon).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _QuoteIcon[key];
    }
  });
});

var _ChefIcon = require("./ChefIcon");

Object.keys(_ChefIcon).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ChefIcon[key];
    }
  });
});