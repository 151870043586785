"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _toConsumableArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ItemGrouper = function ItemGrouper(options) {
  var _this = this;

  _classCallCheck(this, ItemGrouper);

  this.options = options;

  this.group = function (_ref) {
    var items = _ref.items,
        groups = _ref.groups;
    var _this$options = _this.options,
        getGroupId = _this$options.getGroupId,
        getItemGroupId = _this$options.getItemGroupId,
        groupKey = _this$options.groupKey,
        _this$options$singula = _this$options.singular,
        singular = _this$options$singula === void 0 ? false : _this$options$singula;
    var itemsByGroupId = items.reduce(function (byGroupId, item) {
      var groupId = getItemGroupId(item);

      if (!byGroupId.has(groupId)) {
        return byGroupId;
      }

      byGroupId.get(getItemGroupId(item)).push(item);
      return byGroupId;
    }, new Map(groups.map(function (group) {
      return [getGroupId(group), []];
    })));

    if (singular) {
      itemsByGroupId = new Map(_toConsumableArray(itemsByGroupId).map(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
            id = _ref3[0],
            _ref3$ = _slicedToArray(_ref3[1], 1),
            firstItem = _ref3$[0];

        return [id, firstItem];
      }));
    }

    return groups.map(function (group) {
      return Object.assign(Object.assign({}, group), _defineProperty({}, groupKey, itemsByGroupId.get(getGroupId(group))));
    });
  };
};

exports.ItemGrouper = ItemGrouper;