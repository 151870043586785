"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripNotes = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _TripNotesState = require("./TripNotesState");

var _ItineraryForm = require("../ItineraryForm");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TripNotes = function TripNotes(_ref) {
  var readOnly = _ref.readOnly;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _TripNotesState.TripNotesState
  }, function (_ref2) {
    var tripNotes = _ref2.tripNotes;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ItineraryForm.ItineraryForm, {
      title: "Trip Notes",
      itinerary: tripNotes,
      readOnly: readOnly // remount if the version changes, otherwise we'll get stuck with old data in the ItineraryForm
      // more consideration needed for this in desktop (as you don't want them to lose what they were working on)
      ,
      key: readOnly ? tripNotes.version : 'never-remount'
    }));
  });
};

exports.TripNotes = TripNotes;
TripNotes.propTypes = {
  readOnly: _propTypes.default.bool
};