"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Slider = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../../typography");

var _Slider = _interopRequireDefault(require("@material-ui/core/Slider"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles() {
  return {
    container: {
      display: 'flex',
      margin: '0 -10px',
      alignItems: 'center'
    },
    label: {
      padding: '0 10px'
    },
    control: {
      flexGrow: 1
    }
  };
};

var Slider = function Slider(_ref) {
  var classes = _ref.classes,
      minLabel = _ref.minLabel,
      maxLabel = _ref.maxLabel,
      min = _ref.min,
      max = _ref.max,
      step = _ref.step,
      onChange = _ref.onChange,
      value = _ref.value;
  return _react.default.createElement("div", {
    className: classes.container
  }, Boolean(minLabel) && _react.default.createElement(_typography.P, {
    className: classes.label,
    color: "textSecondary",
    gutterBottom: false
  }, minLabel), _react.default.createElement("div", {
    className: classes.control
  }, _react.default.createElement(_Slider.default, {
    value: value,
    min: min,
    max: max,
    step: step,
    onChange: onChange
  })), Boolean(maxLabel) && _react.default.createElement(_typography.P, {
    className: classes.label,
    color: "textSecondary",
    gutterBottom: false
  }, maxLabel));
};

exports.Slider = Slider;
Slider.propTypes = {
  classes: _propTypes.default.object.isRequired,
  minLabel: _propTypes.default.string,
  maxLabel: _propTypes.default.string,
  min: _propTypes.default.number.isRequired,
  max: _propTypes.default.number.isRequired,
  step: _propTypes.default.number,
  onChange: _propTypes.default.func.isRequired,
  value: _propTypes.default.number.isRequired
};
exports.Slider = Slider = (0, _core.withStyles)(styles)(Slider);