import React from 'react';
import { Button } from '@wanderlost-sdk/components';

export const ViewAllTripsButton = () => (
    <Button
        variant="contained"
        color="primary"
        view-all-trips-button="true"
        to="tripList"
    >
        View All Trips
    </Button>
);
