"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripBreadcrumbs = exports.TripBreadcrumbItem = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _components = require("../components");

var _core = require("@material-ui/core");

var _typography = require("../typography");

var _greenState = require("@symbiotic/green-state");

var _urls = require("../urls");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TripBreadcrumbItem = function TripBreadcrumbItem(_ref) {
  var to = _ref.to,
      tripId = _ref.tripId,
      children = _ref.children;
  return to ? _react.default.createElement(_components.Button, {
    to: to,
    routeParams: {
      tripId: tripId
    },
    link: true
  }, children) : children;
};

exports.TripBreadcrumbItem = TripBreadcrumbItem;
TripBreadcrumbItem.propTypes = {
  to: _propTypes.default.string,
  tripId: _propTypes.default.string,
  // Always provided by TripBreadcrumbs
  children: _propTypes.default.node.isRequired
};

var BreadcrumbSeparator = _react.default.createElement("span", null, ' ', ">", ' ');

var initCap = function initCap(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
/**
 * Builds a list of breadcrumb links for a specified route name (used when routeName prop is provided).
 * This doesn't work perfectly (because it derives the label from the path, e.g. "Manage" for /manage)
 * but it is useful when we want to use a different parent routeName depending on where we came from.
 */


var buildLinksForRoute = function buildLinksForRoute(_ref2) {
  var trip = _ref2.trip,
      routeName = _ref2.routeName,
      navigator = _ref2.navigator;
  var breadcrumbLinks = [];
  var path = navigator[routeName]({
    tripId: trip.tripId
  }).path();
  var parts = path.split('/');

  var _loop = function _loop(i) {
    var part = parts[i];
    var label = part === trip.tripId ? trip.name : initCap(part);
    var partPath = parts.slice(0, i + 1).join('/');
    var to = Object.keys(navigator).find(function (r) {
      return navigator[r]({
        tripId: trip.tripId
      }).path() === partPath;
    });
    breadcrumbLinks.push({
      to: to,
      label: label
    });
  };

  for (var i = 2; i < parts.length; i++) {
    _loop(i);
  }

  return breadcrumbLinks;
};

var TripBreadcrumbs = function TripBreadcrumbs(_ref3) {
  var trip = _ref3.trip,
      _ref3$routeName = _ref3.routeName,
      routeName = _ref3$routeName === void 0 ? 'tripDetails' : _ref3$routeName,
      classes = _ref3.classes,
      children = _ref3.children;
  var tripId = trip.tripId,
      _trip$returnTo = trip.returnTo,
      returnTo = _trip$returnTo === void 0 ? 'tripList' : _trip$returnTo;
  var navigator = (0, _greenState.useInstance)(_urls.WanderLostNavigator);
  var links = buildLinksForRoute({
    trip: trip,
    routeName: routeName,
    navigator: navigator
  }); // if no children, last one should not be a link

  if (!children) {
    delete links[links.length - 1].to;
  }

  return _react.default.createElement(_components.Display, {
    screen: true
  }, _react.default.createElement(_typography.P, {
    className: classes.container,
    "data-type": "trip-breadcrumbs"
  }, _react.default.createElement(_typography.SMALL, null, _react.default.createElement(TripBreadcrumbItem, {
    to: returnTo
  }, "Trips"), links.map(function (_ref4, i) {
    var to = _ref4.to,
        label = _ref4.label;
    return _react.default.createElement(_react.default.Fragment, {
      key: i
    }, BreadcrumbSeparator, _react.default.createElement(TripBreadcrumbItem, {
      to: to,
      tripId: tripId
    }, label));
  }), _react.default.Children.map(children, function (child, i) {
    return _react.default.createElement("span", {
      key: i
    }, BreadcrumbSeparator, _react.default.cloneElement(child, {
      tripId: tripId
    }));
  }))));
};

exports.TripBreadcrumbs = TripBreadcrumbs;
TripBreadcrumbs.propTypes = {
  classes: _propTypes.default.object.isRequired,
  trip: _propTypes.default.object.isRequired,
  routeName: _propTypes.default.string,
  children: _propTypes.default.node
};
exports.TripBreadcrumbs = TripBreadcrumbs = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      opacity: 0.8,
      marginBottom: theme.spacing(3),
      '& a:link, & a:visited': {
        color: '#6D837E'
      }
    }
  };
})(TripBreadcrumbs);