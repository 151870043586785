import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { H2, P, Dialog, Button, InjectLoadAndSubscribe } from '@wanderlost-sdk/components';
import { PackingList } from './PackingList';
import { AddExistingListState } from './state/AddExistingListState';
import withStyles from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';

const styles = theme => ({
    help: {
        marginBottom: theme.spacing(4)
    },
    section: {
        border: `1px solid #e5e5e5`,
        padding: theme.spacing(2)
    },
    preview: {
        height: 300,
        overflowY: 'auto'
    },
    list: {
        '& li': {
            paddingTop: '4px',
            paddingBottom: '4px'
        }
    },
    subHeader: {
        position: 'relative',
        lineHeight: '36px',
        textTransform: 'uppercase'
    }
});

export let AddExistingListDialog = ({ classes, name = 'add-existing-list', buttonLabel = 'Add Existing List', buttonProps = {}, handleCopyList, currentListId }) => {
    return (
        <InjectLoadAndSubscribe diKey={AddExistingListState}>
            {({ tripPackingLists, gearLists, starterLists, isOpen, open, close, selectPackingList, selectedPackingList }) => {
                const copyList = packingList => {
                    handleCopyList(packingList);
                    close();
                };

                return (
                    <>
                        <Button size="large" variant="outlined" name={name} {...buttonProps} onClick={open}>{buttonLabel}</Button>
                        <Dialog
                            name="add-existing-list-dialog"
                            isOpen={isOpen}
                            title="Copy Items from Existing List"
                            closeButtonProps={{ name: 'close-add-existing-list-button' }}
                            fullWidth
                            maxWidth="md"
                            actionsComponent={
                                <>
                                    <Button
                                        onClick={close}
                                        size="large"
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={!selectedPackingList}
                                        onClick={() => copyList(selectedPackingList)}
                                        name="copy-items"
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                    >
                                        Copy Items
                                    </Button>
                                </>
                            }
                        >
                            <div>
                                <div className={classes.help}>
                                    <P>Select a list below to copy the items to the current Packing List. This will not change any items already in your list.</P>
                                    <P>Note: this will be a copy, and changes you make in this trip will not change your other list. You may add items from more than one list, if there are duplicate items they will not be added. </P>
                                </div>

                                <Select
                                    data-type="select-packing-list"
                                    value={selectedPackingList || "none"}
                                    style={{ width: '300px', marginBottom: '2rem' }}
                                    MenuProps={{ classes: { paper: classes.list } }}
                                    onChange={e => {
                                        selectPackingList(e.target.value === 'none' ? null : e.target.value);
                                    }}
                                >
                                    <MenuItem value="none">
                                        <em>Select a List</em>
                                    </MenuItem>
                                    <Divider />
                                    <ListSubheader className={classes.subHeader}>Starter Kits</ListSubheader>
                                    {starterLists && starterLists.map(starterList => (
                                        <MenuItem
                                            key={starterList.packingListId}
                                            value={starterList}
                                            name="add-starter-list"
                                        >
                                            {starterList.name}
                                        </MenuItem>
                                    ))}
                                    <Divider style={{ marginTop: '8px' }} />
                                    <ListSubheader className={classes.subHeader}>Your Gear Lists</ListSubheader>
                                    {gearLists && gearLists.filter(l => l.packingListId !== currentListId).map(gearList => (
                                        <MenuItem
                                            key={gearList.packingListId}
                                            value={gearList}
                                            name="add-existing-gear-list"
                                        >
                                            {gearList.name}
                                        </MenuItem>
                                    ))}
                                    <Divider style={{ marginTop: '8px' }} />
                                    <ListSubheader className={classes.subHeader}>Your Trips</ListSubheader>
                                    {tripPackingLists && tripPackingLists.filter(l => l.packingListId !== currentListId).map(packingList => (
                                        <MenuItem
                                            key={packingList.packingListId}
                                            value={packingList}
                                            name="add-existing-list-from-trip"
                                        >
                                            {packingList.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <H2>List Preview</H2>
                                <div className={cn(classes.section, classes.preview)}>
                                    {!selectedPackingList &&
                                        <P color="textSecondary"><em>Select a list to preview items</em></P>
                                    }
                                    {selectedPackingList &&
                                        <div name="packing-list-preview">
                                            <PackingList packingList={selectedPackingList} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </Dialog>
                    </>
                );
            }}
        </InjectLoadAndSubscribe>
    );
};

AddExistingListDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    buttonLabel: PropTypes.string,
    handleCopyList: PropTypes.func.isRequired,
    currentListId: PropTypes.number,
};

AddExistingListDialog = withStyles(styles)(AddExistingListDialog);
