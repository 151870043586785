"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PbfSnapshotSerializer = void 0;

var _pbf = _interopRequireDefault(require("pbf"));

var _geobuf = _interopRequireDefault(require("geobuf"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var PbfSnapshotSerializer = function PbfSnapshotSerializer() {
  _classCallCheck(this, PbfSnapshotSerializer);

  /**
   * Takes a snapshotObject (GeoJSONFeatureCollection)
   * And returns an serialized snapshot (Uint8Array)
   */
  this.serialize = function (snapshotObject) {
    var geobuffer = _geobuf.default.encode(snapshotObject, new _pbf.default());

    return geobuffer;
  };
  /**
   * Takes a serialized snapshot (Uint8Array)
   * And returns a snapshotObject (GeoJSONFeatureCollection)
   */


  this.deserialize = function (serializedSnapshot) {
    var decoded = _geobuf.default.decode(new _pbf.default(serializedSnapshot));

    return decoded;
  };
};

exports.PbfSnapshotSerializer = PbfSnapshotSerializer;