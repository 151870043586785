"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripNav = exports.TripNavConfig = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _HeaderNav = require("../nav/HeaderNav");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var NAV_ITEMS = [{
  label: 'Daily Plan & Routes',
  route: 'tripDetails',
  navtarget: 'summary'
}, {
  label: 'Team',
  route: 'tripTeam',
  navtarget: 'team'
}, {
  label: 'Meals',
  route: 'tripMeals',
  navtarget: 'meals'
}, {
  label: 'Packing Lists',
  route: 'tripPackingLists',
  navtarget: 'gear'
}, {
  label: 'Change Log',
  route: 'tripLog',
  navtarget: 'log'
}]; // TODO: get this injected in the story... then in mobile-web

var TripNavConfig = function TripNavConfig() {
  _classCallCheck(this, TripNavConfig);

  _defineProperty(this, "hideLabels", false);
};

exports.TripNavConfig = TripNavConfig;

var TripNav = function TripNav(_ref) {
  var tripId = _ref.trip.tripId;

  var _ref2 = (0, _greenState.useInstance)(TripNavConfig),
      hideLabels = _ref2.hideLabels;

  var navItems = NAV_ITEMS.map(function (nav) {
    return _objectSpread({}, nav, {
      routeParams: {
        tripId: tripId
      }
    });
  });
  return _react.default.createElement(_HeaderNav.HeaderNav, {
    name: "trip-nav",
    navItems: navItems,
    hideLabels: hideLabels
  });
};

exports.TripNav = TripNav;
TripNav.propTypes = {
  trip: _propTypes.default.object.isRequired
};