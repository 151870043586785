"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RefreshButton = void 0;

var _react = _interopRequireWildcard(require("react"));

var _Button = require("../Button");

var _typography = require("../../typography");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var RefreshButton = function RefreshButton(_ref) {
  var asyncRefresh = _ref.asyncRefresh,
      asOf = _ref.asOf,
      renderLabel = _ref.renderLabel,
      _ref$style = _ref.style,
      style = _ref$style === void 0 ? {} : _ref$style,
      rest = _objectWithoutProperties(_ref, ["asyncRefresh", "asOf", "renderLabel", "style"]);

  var _ref2 = (0, _react.useState)({
    refreshState: 'never-refreshed'
  }),
      _ref3 = _slicedToArray(_ref2, 2),
      refreshState = _ref3[0].refreshState,
      setState = _ref3[1];

  var isRefreshing = refreshState === 'refreshing';
  var props = {};

  if (isRefreshing) {
    props.disabled = true;
  } // onClick we call asyncRefresh
  // while that is incomplete, we disable the button
  // safari on touch devices leaves the pseudo-class :hover on all elements after "touch" until the next "touch".
  // This was causing us to have the primary background color while disabled :(
  // This is in part because material ui has no disabled :hover background color
  // because :hover a disabled component isn't possible (presumably in all browsers). Shame on safari to leave our
  // component in this unreasonable state. The work around here is to remount (abandoning safari's state and relying on
  // only our react state).


  var onClick = function onClick() {
    if (!isRefreshing) {
      setState({
        refreshState: 'refreshing'
      });
      asyncRefresh().then(function () {
        setState({
          refreshState: 'refreshed'
        });
      });
    }
  };

  return _react.default.createElement("div", {
    style: {
      display: 'flex',
      alignItems: 'center'
    }
  }, asOf && !isRefreshing && _react.default.createElement(_typography.SMALL, {
    color: "textSecondary",
    style: {
      lineHeight: 1.2,
      fontSize: '0.65rem',
      textAlign: 'right'
    }
  }, "Last Updated", _react.default.createElement("br", null), (0, _moment.default)(asOf).format('ddd, MMM DD h:mm A')), _react.default.createElement(_Button.Button, _extends({
    variant: "outlined",
    color: "primary",
    size: "small",
    style: _objectSpread({
      margin: '0 0 0 0.5em'
    }, style),
    "data-refresh-state": refreshState,
    onClick: onClick
  }, props, rest), renderLabel({
    isRefreshing: isRefreshing
  })));
};

exports.RefreshButton = RefreshButton;
RefreshButton.propTypes = {
  asyncRefresh: _propTypes.default.func.isRequired,
  asOf: _propTypes.default.string,
  renderLabel: _propTypes.default.func.isRequired
};