"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RichTextContent = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _sanitizeHtml = _interopRequireDefault(require("sanitize-html"));

var _RichTextStyles = require("./RichTextStyles");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var customSanitizeHtml = function customSanitizeHtml(htmlString) {
  return (0, _sanitizeHtml.default)(htmlString, {
    allowedTags: ['p', 'ul', 'ol', 'li', 'strong', 'em', 'a', 'br'],
    allowedAttributes: {
      'a': ['href', 'target']
    }
  });
};

var RichTextContent = function RichTextContent(_ref) {
  var html = _ref.html,
      other = _objectWithoutProperties(_ref, ["html"]);

  return _react.default.createElement(_RichTextStyles.RichTextStyles, null, _react.default.createElement("div", _extends({
    dangerouslySetInnerHTML: {
      __html: customSanitizeHtml(html)
    }
  }, other)));
};

exports.RichTextContent = RichTextContent;
RichTextContent.propTypes = {
  html: _propTypes.default.string.isRequired
};