"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Section = require("./Section");

Object.keys(_Section).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Section[key];
    }
  });
});

var _SectionV = require("./SectionV2");

Object.keys(_SectionV).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _SectionV[key];
    }
  });
});