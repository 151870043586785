"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Header = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _users = require("../users");

var _Nav = require("./Nav");

var _AppBar = _interopRequireDefault(require("@material-ui/core/AppBar"));

var _components = require("../components");

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

var _LayoutContainer = require("./LayoutContainer");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* globals window */


var styles = function styles(theme) {
  return {
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    },
    appBarBackground: {
      '&::before': {
        content: '""',
        backgroundImage: 'linear-gradient(180deg, rgba(0,0,0,0.55) 0%, rgba(0,0,0,0.55) 50%, rgba(0,0,0,0.75) 100%)',
        zIndex: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      '&::after': {
        content: '""',
        backgroundColor: 'rgba(152, 211, 196, 0.25)',
        zIndex: 2,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      backgroundSize: 'cover',
      backgroundPosition: '50% 60%',
      backgroundColor: '#0E1111',
      boxShadow: '0 0 4px #0E1111'
    },
    content: {
      position: 'relative',
      zIndex: 100,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between'
    },
    logo: {
      display: 'inline-flex',
      alignItems: 'center'
    },
    logoIcon: {
      height: '45px',
      marginRight: 8
    },
    logoText: {
      height: '24px',
      position: 'relative',
      top: 2
    }
  };
};

var Header = function Header(_ref) {
  var classes = _ref.classes,
      _ref$imageURL = _ref.imageURL,
      imageURL = _ref$imageURL === void 0 ? '/images/banners/stars.jpg' : _ref$imageURL,
      fullWidth = _ref.fullWidth,
      _ref$hideNav = _ref.hideNav,
      hideNav = _ref$hideNav === void 0 ? false : _ref$hideNav;

  var _react$default$useSta = _react.default.useState(0),
      _react$default$useSta2 = _slicedToArray(_react$default$useSta, 2),
      scrollTop = _react$default$useSta2[0],
      setScrollTop = _react$default$useSta2[1];

  var scroll = function scroll() {
    return setScrollTop(window.pageYOffset);
  };

  _react.default.useEffect(function () {
    scroll();
    window.addEventListener('scroll', scroll);
    return function () {
      return window.removeEventListener('scroll', scroll);
    };
  }, [scrollTop]);

  var showBackground = scrollTop > 0;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_components.Display, {
    screen: true
  }, _react.default.createElement(_AppBar.default, {
    position: showBackground ? 'fixed' : 'absolute',
    elevation: 3,
    className: (0, _classnames.default)(classes.appBar, _defineProperty({}, classes.appBarBackground, showBackground)),
    style: showBackground ? {
      backgroundImage: "url(".concat(imageURL, ")")
    } : {}
  }, _react.default.createElement(_LayoutContainer.LayoutContainer, {
    fullWidth: fullWidth,
    classes: {
      root: classes.content
    }
  }, _react.default.createElement("div", {
    style: {
      flexGrow: 1,
      position: 'relative',
      lineHeight: 0
    }
  }, _react.default.createElement(_reactRouterDom.Link, {
    to: "/",
    id: "home-button"
  }, _react.default.createElement("span", {
    className: classes.logo
  }, _react.default.createElement("img", {
    src: "/images/logo-icon.svg",
    alt: "Wanderlost Project Logo",
    className: classes.logoIcon
  }), _react.default.createElement("img", {
    src: "/images/logo-text.svg",
    alt: "Wanderlost Project",
    className: classes.logoText
  })))), _react.default.createElement("div", null, !hideNav && _react.default.createElement(_Nav.Nav, null)), _react.default.createElement("div", null, _react.default.createElement(_users.Profile, null))))), _react.default.createElement(_components.Display, {
    print: true
  }, _react.default.createElement("div", {
    className: "print-logo",
    style: {
      padding: '1em',
      marginBottom: '1em'
    }
  }, _react.default.createElement("img", {
    src: './images/logo.png',
    alt: "Wanderlost Project | Always Wandering, Never Lost",
    style: {
      height: '60px'
    }
  }))));
};

exports.Header = Header;
Header.propTypes = {
  classes: _propTypes.default.object.isRequired,
  fullWidth: _propTypes.default.bool,
  imageURL: _propTypes.default.string,
  hideNav: _propTypes.default.bool
};
exports.Header = Header = (0, _core.withStyles)(styles)(Header);