"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Theme = void 0;

var Theme = function Theme() {
  _classCallCheck(this, Theme);

  var fontFamily = "Roboto, Arial, sans-serif";
  var secondaryFontFamily = "'Roboto Slab', Arial, sans-serif";
  var editingToolbarHeight = '45px';
  var appBarHeight = '64px';
  this.wanderlost = {
    editingToolbarHeight: editingToolbarHeight,
    editingToolbar: {
      backgroundColor: '#1B3F37'
    },
    // These are the only difference from web theme (we should consolidate themes, and update tests)
    mapHeight: "calc(100vh - ".concat(editingToolbarHeight, ")"),
    drawerHeight: "calc(100vh - ".concat(editingToolbarHeight, ")"),
    drawerWidth: '300px',
    // End differences
    drawerWidthClosed: '2rem',
    elevationProfileHeight: '110px',
    elevationProfileHeightCollapsed: '20px',
    appBarHeight: appBarHeight,
    mainContentPullTopPixels: 26,
    zIndex: {
      mainContent: 10,
      nav: 10,
      mapControls: 1,
      mapAttribution: 2
    },
    Card: {
      title: {
        fontFamily: secondaryFontFamily,
        fontSize: 20,
        lineHeight: 1.1
        /* Prevents descenders from getting clipped */

      },
      description: {},
      // Only want white fonts on screen when there is a bg image
      '@media screen': {
        titleContainer: {
          textShadow: '0 0 4px rgba(0, 0, 0, 0.5)'
        },
        title: {
          color: '#fff'
        },
        description: {
          color: '#fff'
        }
      }
    },
    CTACard: {
      title: {
        fontFamily: secondaryFontFamily,
        fontSize: 30,
        color: '#fff',
        textShadow: '0 0 4px rgba(0, 0, 0, 0.5)'
      },
      description: {
        fontSize: 18,
        fontWeight: 700,
        color: '#fff',
        textShadow: '0 0 4px rgba(0, 0, 0, 0.5)'
      }
    },
    banner: {
      heights: {
        mini: 64,
        small: 200,
        medium: 290,
        large: 460
      },
      title: {
        fontFamily: secondaryFontFamily,
        marginBottom: 12,
        fontSize: 36,
        lineHeight: 1,
        '@media screen': {
          textShadow: '0 0 4px rgba(0, 0, 0, 0.5)'
        }
      },
      subTitle: {
        fontFamily: secondaryFontFamily,
        fontSize: 18,
        lineHeight: 1.2,
        '@media screen': {
          color: '#fff',
          textShadow: '0 0 4px rgba(0, 0, 0, 0.5)'
        }
      }
    }
  };
  var colors = {
    beige: {
      dark: '#DEDAC5',
      main: '#E8E5D6',
      light: '#FCFCFA',
      contrastText: '#303937'
    },
    green: {
      dark: '#0A1D19',
      main: '#12312B',
      light: '#E6E9E9',
      contrastText: '#E8E5D6'
    },
    teal: {
      dark: '#303937',
      main: '#5BAC9A',
      medium: '#498F7F',
      disabledText: '#B8DCD4',
      light: '#F5FBF9',
      bright: '#56C5AC',
      contrastText: '#fff'
    },
    orange: {
      dark: '#FF4000',
      main: '#E36E18',
      light: '#FFF0E6',
      contrastText: '#fff'
    },
    yellow: {
      dark: '#FF9D00',
      main: '#FFB900',
      light: '#FFF7E5',
      contrastText: '#12312B'
    }
  };
  var sharedHeadingStyles = {
    fontWeight: 700,
    color: colors.teal.dark
  };
  this.typography = {
    fontFamily: fontFamily,
    secondaryFontFamily: secondaryFontFamily,
    body2: {
      fontSize: '1rem'
    },
    h1: _objectSpread({}, sharedHeadingStyles, {
      fontSize: '2.25rem'
    }),
    h2: _objectSpread({}, sharedHeadingStyles, {
      fontSize: '1.5rem'
    }),
    h3: _objectSpread({}, sharedHeadingStyles, {
      fontSize: '1.25rem'
    }),
    h4: _objectSpread({}, sharedHeadingStyles, {
      fontSize: '1rem'
    }),
    caption: _objectSpread({}, sharedHeadingStyles, {
      color: colors.teal.main,
      textTransform: 'uppercase',
      fontSize: '0.875rem'
    }),
    h5: _objectSpread({}, sharedHeadingStyles),
    h6: _objectSpread({}, sharedHeadingStyles),
    gutterBottom: {
      marginBottom: '0.35em'
    }
  };
  this.palette = {
    primary: colors.teal,
    secondary: colors.orange,
    tertiary: colors.teal,
    // we don't intend to use tertiary
    success: {
      main: colors.teal.main
    },
    text: {
      primary: '#303937',
      secondary: '#6D837E'
    },
    background: {
      default: '#F0F4F3',
      highlight: colors.teal.light,
      lowlight: colors.beige.dark,
      paper: '#fff'
    },
    map: {
      icon: {
        color: colors.green.main
      }
    },
    nav: {
      drawer: {
        backgroundColor: colors.green.dark
      },
      divider: {
        color: colors.green.main
      },
      header: {
        backgroundColor: colors.green.dark
      },
      hover: {
        backgroundColor: '#1c4a41' // Slightly lighter than green.main/current color

      },
      current: {
        color: colors.yellow.main
      },
      icon: {
        color: colors.yellow.main
      },
      text: {
        color: '#fff'
      }
    },
    layout: {
      default: {
        backgroundColor: colors.teal.light
      }
    },
    action: {
      active: '#303937',
      hover: '#F5FBF9',
      disabled: 'rgba(190, 190, 190)',
      disabledBackground: 'rgba(235, 235, 235)'
    }
  };
  this.overrides = {
    MuiButton: {
      root: {
        borderRadius: 12,
        fontWeight: 'bold'
      },
      sizeSmall: {
        lineHeight: 0.1,
        padding: "12px"
      },
      contained: {
        boxShadow: 'none',
        '&$disabled': {
          backgroundColor: this.palette.primary.light,
          color: this.palette.primary.disabledText
        }
      },
      outlined: {
        backgroundColor: '#fff',
        borderColor: this.palette.primary.main,
        color: this.palette.primary.main,
        '&:hover': {
          backgroundColor: this.palette.primary.main,
          color: '#fff'
        }
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: this.palette.primary.main,
          color: '#fff'
        }
      }
    },
    MuiAppBar: {
      root: {
        height: appBarHeight,
        justifyContent: 'center'
      },
      colorDefault: {
        backgroundColor: colors.green.main,
        color: '#fff'
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: colors.green.main
        }
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: "2px solid ".concat(colors.green.main)
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        color: colors.teal.main,
        '&$checked': {
          color: colors.teal.main
        }
      }
    },
    MuiRadio: {
      colorSecondary: {
        color: colors.teal.main,
        '&$checked': {
          color: colors.teal.main
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: '24px',
        verticalAlign: 'top'
      },
      head: {
        fontSize: '1.1rem',
        padding: '24px 24px 16px',
        color: '#6D837E'
      },
      body: {
        fontSize: '1rem',
        color: '#303937'
      }
    },
    MuiTableRow: {
      head: {
        height: 'auto'
      }
    },
    MuiTypography: {
      gutterBottom: this.typography.gutterBottom
    },
    MuiListItemText: {
      root: {
        paddingLeft: '16px',
        paddingRight: '16px',
        marginTop: 0,
        marginBottom: 0
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0
      }
    }
  };
  this.breakpoints = {
    values: {
      xs: 0,
      sm: 800,
      md: 1024,
      lg: 1280,
      xl: 1600
    }
  };
};

exports.Theme = Theme;