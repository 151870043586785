"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InjectError = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var InjectError = function InjectError(_ref) {
  var initialMessage = _ref.initialMessage,
      children = _ref.children;
  return _react.default.createElement(_greenState.Subscribe, {
    to: function to() {
      return new _greenState.StringState(initialMessage);
    }
  }, function (_ref2) {
    var message = _ref2.value,
        set = _ref2.set,
        clear = _ref2.clear;
    return children({
      message: message,
      set: set,
      clear: clear
    });
  });
};

exports.InjectError = InjectError;
InjectError.propTypes = {
  initialMessage: _propTypes.default.string,
  children: _propTypes.default.func.isRequired
};