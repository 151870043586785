"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _extends2 = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/extends");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GPXImport = void 0;

var _react = _interopRequireWildcard(require("react"));

var _GPXRouteImport = require("./GPXRouteImport");

var _components = require("@wanderlost-sdk/components");

var _core = require("@material-ui/core");

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var DeferredValue = function DeferredValue() {
  var _this = this;

  _classCallCheck(this, DeferredValue);

  this.promise = new Promise(function (resolve) {
    _this.resolve = resolve;
  });
};

var GPXImport = function GPXImport(_ref) {
  var rest = _extends2({}, _ref);

  var _ref2 = (0, _react.useState)(null),
      _ref3 = _slicedToArray(_ref2, 2),
      importOptionsDeferred = _ref3[0],
      setImportOptionsDeferred = _ref3[1];

  var setImportOptionsValue = function setImportOptionsValue(value) {
    importOptionsDeferred.resolve(value);
    setImportOptionsDeferred(null); // close the dialog
  };

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_GPXRouteImport.GPXRouteImport, _extends({
    label: "Create a trip or map layer from GPX",
    getImportOptions: function getImportOptions() {
      var deferredValue = new DeferredValue();
      setImportOptionsDeferred(deferredValue);
      return deferredValue.promise;
    },
    onSuccess: function onSuccess(_ref4) {
      var trip = _ref4.trip;

      if (trip) {
        window.location.hash = "/trips/".concat(trip.tripId, "/map");
      }
    }
  }, rest)), _react.default.createElement(_components.Dialog, {
    name: "gpx-route-import-succeeded-dialog",
    isOpen: Boolean(importOptionsDeferred),
    title: "What would you like to do next?",
    onClose: function onClose() {
      return setImportOptionsValue(null);
    }
  }, _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 3,
    alignItems: "center",
    style: {
      marginBottom: '16px'
    }
  }, _react.default.createElement(_core.Grid, {
    item: true,
    sm: 9
  }, _react.default.createElement(_components.H4, null, "Generate a route and trip from GPX"), _react.default.createElement(_components.P, null, "View route on the map and have the option to edit, invite friends, plan gear, meals and itinerary.")), _react.default.createElement(_core.Grid, {
    item: true,
    sm: 3
  }, _react.default.createElement(_components.Button, {
    name: "import-create-trip",
    size: "large",
    onClick: function onClick() {
      return setImportOptionsValue({
        createTrip: true
      });
    },
    variant: "outlined",
    color: "primary",
    style: {
      width: '100%'
    }
  }, "New Trip"))), _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 3,
    alignItems: "center"
  }, _react.default.createElement(_core.Grid, {
    item: true,
    sm: 9
  }, _react.default.createElement(_components.H4, null, "Import GPX to My Layers"), _react.default.createElement(_components.P, null, "View on the map, edit to cut and delete tracks, add and edit waypoints, and more. Route over this data in future trips or share with friends.")), _react.default.createElement(_core.Grid, {
    item: true,
    sm: 3
  }, _react.default.createElement(_components.Button, {
    name: "import-create-layer",
    size: "large",
    onClick: function onClick() {
      return setImportOptionsValue({
        createRoute: false
      });
    },
    variant: "outlined",
    color: "primary",
    style: {
      width: '100%'
    }
  }, "Add to Map")))));
};

exports.GPXImport = GPXImport;