"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddRouteHelpDialog = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _components = require("@wanderlost-sdk/components");

var _core = require("@material-ui/core");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // NOTE: We use separate state for the checkbox, so we update the cached state only when the user closes the dialog


var AddRouteHelpDialog = function AddRouteHelpDialog(_ref) {
  var isActive = _ref.isActive,
      forceVisible = _ref.forceVisible,
      _onClose = _ref.onClose;
  return _react.default.createElement(_components.InjectTogglable, {
    cacheKey: "AddRouteHelpDialog.neverShow",
    initialValue: false
  }, function (_ref2) {
    var neverShow = _ref2.isOn,
        setNeverShow = _ref2.on,
        unsetNeverShow = _ref2.off;
    return _react.default.createElement(_greenState.InjectBoolean, {
      initialValue: neverShow
    }, function (_ref3) {
      var isChecked = _ref3.value,
          setChecked = _ref3.set;
      return _react.default.createElement(_components.Dialog, {
        name: "routing-help-dialog",
        title: "Welcome to the route planner!",
        isOpen: isActive && (!neverShow || forceVisible),
        onClose: function onClose() {
          isChecked ? setNeverShow() : unsetNeverShow();

          _onClose();
        }
      }, _react.default.createElement("div", {
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }
      }, _react.default.createElement("div", {
        style: {
          flexGrow: 1,
          marginBlockStart: 0
        }
      }, _react.default.createElement(_components.H4, null, "Helpful hints"), _react.default.createElement("ul", {
        style: {
          marginRight: '15px'
        }
      }, _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "You\u2019re in Follow Trails mode - this will try to build a route over lines like your imported GPX, Featured Trails and Forest Service Roads")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "If Trails mode can\u2019t find a connected route because of large gaps or lack of trails, it uses Roads which gets the best driving route for vehicles from a third party service (will not route over closed roads, bike trails, etc)")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "Switch any time between modes, e.g. to add a straight or highway segment")))), _react.default.createElement("img", {
        style: {
          width: '200px',
          marginTop: '-30px',
          alignSelf: 'center',
          boxShadow: '0px 5px 5px #ccc'
        },
        src: "./images/help/route-help-1.png"
      })), _react.default.createElement("div", {
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }
      }, _react.default.createElement("div", {
        style: {
          flexGrow: 1,
          marginBlockStart: 0
        }
      }, _react.default.createElement(_components.H4, null, "Get started"), _react.default.createElement("ul", null, _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "Click to start drawing a route")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "Drag the line or a point to modify the route")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "Right click on a point to delete it and reroute")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "Undo / Redo if you make a mistake")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "Switch to the Waypoint tool to add and edit waypoints")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "In the Waypoint tool, right click POIs to add to your route")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "Click DONE to finish editing")))), _react.default.createElement("img", {
        style: {
          width: '170px',
          marginRight: '15px',
          alignSelf: 'center',
          boxShadow: '0px 5px 5px #ccc'
        },
        src: "./images/help/route-help-2.png"
      })), _react.default.createElement("div", {
        style: {
          display: 'flex',
          justifyContent: 'flex-start'
        }
      }, _react.default.createElement("div", {
        style: {
          flexGrow: 1,
          marginBlockStart: 0
        }
      }, _react.default.createElement(_components.H4, null, "Once you\u2019re done editing"), _react.default.createElement("ul", null, _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "You can rename your route or export to GPX from the three dots")), _react.default.createElement("li", null, _react.default.createElement(_components.SMALL, null, "Drag routes in the list to move them to another day")))), _react.default.createElement("img", {
        style: {
          width: '200px',
          alignSelf: 'center',
          boxShadow: '0px 5px 5px #ccc'
        },
        src: "./images/help/route-help-3.png"
      })), _react.default.createElement("div", null, _react.default.createElement(_core.FormControlLabel, {
        name: "never-show",
        control: _react.default.createElement(_core.Checkbox, null),
        label: "Don't show me this again",
        checked: isChecked,
        onClick: function onClick() {
          return setChecked(!isChecked);
        }
      })));
    });
  });
};

exports.AddRouteHelpDialog = AddRouteHelpDialog;
AddRouteHelpDialog.propTypes = {
  isActive: _propTypes.default.bool.isRequired,
  forceVisible: _propTypes.default.bool,
  onClose: _propTypes.default.func
};