/* globals window:0 */
import { WanderLostPrincipal } from '../users/WanderLostPrincipal';

const AWS = require('aws-sdk');

// mostly derived from https://docs.aws.amazon.com/xray/latest/devguide/scorekeep-client.html
// after learning that aws-xray-sdk cannot be used in a browser (despite its terrible documentation
// that suggests that it can by confusing browser with server side rendering)
// https://docs.aws.amazon.com/xray/latest/devguide/xray-api-segmentdocuments.html
// https://docs.aws.amazon.com/xray/latest/devguide/xray-concepts.html#xray-concepts-tracingheader

export class XRayServiceConfig { }

export class XRayService {
    static inject = () => [XRayServiceConfig, WanderLostPrincipal];
    constructor({ publicAWSUser }, user) {
        const getHexId = function (length) {
            const bytes = new Uint8Array(length);
            crypto.getRandomValues(bytes);
            let hex = "";
            for (var i = 0; i < bytes.length; i++) {
                hex += bytes[i].toString(16);
            }
            return hex.substring(0, length);
        }

        const getEpochTime = () => new Date().getTime() / 1000;

        const getHexTime = () => Math.round(getEpochTime()).toString(16);

        const getLastTime = webSegment => {
            return webSegment.subsegments.map(s => {
                // return now if no end time, because it is still in flight
                return s.end_time || getEpochTime();
            })
                .concat(webSegment.start_time)
                .sort().reverse()[0];
        }

        let activeWebSegment;

        this.createSegment = () => {
            const webSegment = activeWebSegment = {
                trace_id: `1-${getHexTime()}-${getHexId(24)}`,
                id: getHexId(16),
                start_time: getEpochTime(),
                name: window.location.host,
                in_progress: false,
                user: `${user.userId}(${user.email})`,
                http: {
                    request: {
                        method: 'GET',
                        url: window.location.href
                    }
                },
                subsegments: []
            };

            // wait until all in flight requests are done, and no new requests in 500ms
            (function pollTillDone() {
                const lastTime = getLastTime(webSegment);
                if (getEpochTime() - lastTime > .5) { // 500 ms
                    webSegment.end_time = lastTime;
                    webSegment.in_progress = false;
                    webSegment.http.response = { status: 200 };

                    if (publicAWSUser) {
                        new AWS.XRay({
                            region: 'us-east-1',
                            ...publicAWSUser
                        }).putTraceSegments({ TraceSegmentDocuments: [JSON.stringify(webSegment)] })
                            .promise()
                            .catch(err => {
                                console.log('Got the following error on AWS.XRay.putTraceSegments')
                                console.log(err);
                            });
                    }

                    if (activeWebSegment === webSegment){
                        activeWebSegment = undefined;
                    }
                } else {
                    setTimeout(pollTillDone, 500);
                }
            })();

            return activeWebSegment;
        };

        // Wouldn't want this to cause us to fail to send data
        const tryParseHttpSegmentUrlHost = url => {
            try {
                return new URL(url).host;
            } catch (e) {
                console.warn(`Failed to parse host from ${url}. ${e.toString()}`);
                return 'api';
            }
        };

        this.createHttpSegment = ({ method, url }) => {
            const webSegment = activeWebSegment || this.createSegment();

            const httpSegment = {
                id: getHexId(16),
                start_time: getEpochTime(),
                name: tryParseHttpSegmentUrlHost(url),
                http: {
                    request: {
                        method,
                        url
                    }
                }
            };

            webSegment.subsegments.push(httpSegment);

            return {
                getTraceHeader: () => ['X-Amzn-Trace-Id', `Root=${webSegment.trace_id};Parent=${httpSegment.id};Sampled=1`],
                finally: async ({ error, status }) => {
                    httpSegment.end_time = getEpochTime();
                    httpSegment.in_progress = false;
                    httpSegment.http.response = { status: status || (error ? 555 : 200) };
                }
            }
        }
    }
}
