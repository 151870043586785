import React from 'react';
import PropTypes from 'prop-types';
import { CreateTripForm } from '@wanderlost-sdk/components';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        backgroundColor: '#fff',
        borderRadius: 8,
        minWidth: 700, // make room for dates on ipad
        maxWidth: 800  // gets very big on desktop safari
    },
    form: {
        display: 'flex',
        alignItems: 'flex-end',
        '& > *': {
            marginRight: theme.spacing(3)
        },
        '& > *:last-child': {
            marginRight: 0,
        }
    },
    submitButton: {
        minWidth: 200
    }
});

export let CreateTripBannerForm = ({ classes }) => {
    const { root, ...createTripFormClasses } = classes;

    return (
        <div className={classes.root}>
            <CreateTripForm
                classes={createTripFormClasses}
                saveButtonLabel="Plan Trip"
            />
        </div>
    );
};

CreateTripBannerForm.propTypes = {
    classes: PropTypes.object.isRequired
};

CreateTripBannerForm = withStyles(styles)(CreateTripBannerForm);
