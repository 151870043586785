"use strict";

var _toConsumableArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PADUSFeatureStyler = void 0;

var _style = require("ol/style.js");

var _memoize = _interopRequireDefault(require("lodash/fp/memoize"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var PADUSFeatureStyler = function PADUSFeatureStyler() {
  _classCallCheck(this, PADUSFeatureStyler);

  this.getStyle = function (feature) {
    var name = feature.get('Mang_Name');

    if (!(name in managerNamesToConfig)) {
      return [];
    }

    var style = managerNamesToConfig[name].style;

    if (!style) {
      return [];
    }

    return [style];
  };

  this.getLegend = (0, _memoize.default)(function () {
    var managerNames = Object.keys(managerNamesToConfig);
    var groupColorMap = new Map();
    managerNames.forEach(function (name) {
      var _managerNamesToConfig = managerNamesToConfig[name],
          label = _managerNamesToConfig.label,
          group = _managerNamesToConfig.group,
          style = _managerNamesToConfig.style;
      var groupNameOrLabel = group || label;

      if (!groupColorMap.has(groupNameOrLabel)) {
        groupColorMap.set(groupNameOrLabel, {
          label: groupNameOrLabel,
          color: style.getFill().getColor()
        });
      }
    });
    return _toConsumableArray(groupColorMap.values());
  }); // From: https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/Agency_Name_Feature_Layer/FeatureServer/0

  var managerNamesToConfig = {
    'DOD': {
      label: 'Department of Defense (DOD)',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'blue'
        })
      })
    },
    'BLM': {
      label: 'Bureau of Land Management (BLM)',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'red'
        })
      })
    },
    'NPS': {
      label: 'National Park Service (NPS)',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'brown'
        })
      })
    },
    'USFS': {
      label: 'Forest Service (USFS)',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'green'
        })
      })
    },
    'USACE': {
      label: 'Army Corps of Engineers (USACE)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'USBR': {
      label: 'Bureau of Reclamation (USBR)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'FWS': {
      label: 'U.S. Fish and Wildlife Service (FWS)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'BOEM': {
      label: 'Bureau of Ocean Energy Management (BOEM)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'NOAA': {
      label: 'National Oceanic and Atmospheric Administration (NOAA)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'NRCS': {
      label: 'Natural Resources Conservation Service (NRCS)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'BPA': {
      label: 'Other Federal (TVA, ARS, BPA, DOE, etc.)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'ARS': {
      label: 'Other Federal (TVA, ARS, BPA, DOE, etc.)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'DOE': {
      label: 'Other Federal (TVA, ARS, BPA, DOE, etc.)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'OTHF': {
      label: 'Other Federal (TVA, ARS, BPA, DOE, etc.)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'TVA': {
      label: 'Other Federal (TVA, ARS, BPA, DOE, etc.)',
      group: 'Federal',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'purple'
        })
      })
    },
    'SDOL': {
      label: 'State Trust Land',
      group: 'State',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'plum'
        })
      })
    },
    'SDNR': {
      label: 'State Trust Land',
      group: 'State',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'plum'
        })
      })
    },
    'SLB': {
      label: 'State Trust Land',
      group: 'State',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'plum'
        })
      })
    },
    'OTHS': {
      label: 'Other State (NHP, DOT, HS, etc.)',
      group: 'State',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'plum'
        })
      })
    },
    'SDC': {
      label: 'Other State (NHP, DOT, HS, etc.)',
      group: 'State',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'plum'
        })
      })
    },
    'SFW': {
      label: 'State Fish and Wildlife',
      group: 'State',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'plum'
        })
      })
    },
    'SPR': {
      label: 'State Park and Recreation',
      group: 'State',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'plum'
        })
      })
    },
    'CNTY': {
      label: 'County / Regional Agency Land',
      group: 'County',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'yellow'
        })
      })
    },
    'REG': {
      label: 'County / Regional Agency Land',
      group: 'County',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'yellow'
        })
      })
    },
    'RWD': {
      label: 'County / Regional Agency Land',
      group: 'County',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'yellow'
        })
      })
    },
    'CITY': {
      label: 'City Land',
      group: 'City',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'cyan'
        })
      })
    },
    'PVT': {
      label: 'Private Conservation; Private Corporation',
      group: 'Private Conservation, Corporation, NGO',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'orange'
        })
      })
    },
    'NGO': {
      label: 'Non-Governmental Organization',
      group: 'Private Conservation, Corporation, NGO',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'orange'
        })
      })
    },
    // Ignored for now, don't fit into the easy categorization
    'JNT': {
      label: 'Joint, Other, Unknown',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'gray'
        })
      })
    },
    'OTHR': {
      label: 'Joint, Other, Unknown',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'gray'
        })
      })
    },
    'UNKL': {
      label: 'Joint, Other, Unknown',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'gray'
        })
      })
    },
    'UNK': {
      label: 'Joint, Other, Unknown',
      style: new _style.Style({
        fill: new _style.Fill({
          color: 'gray'
        })
      })
    }
  };
};

exports.PADUSFeatureStyler = PADUSFeatureStyler;