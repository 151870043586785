"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripItineraryPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _TripHeader = require("./TripHeader");

var _ItineraryForm = require("./ItineraryForm");

var _TripPageHeading = require("./TripPageHeading");

var _core = require("@material-ui/core");

var _Quote = require("../components/Quote");

var _Page = require("../Page");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* eslint-disable react/prop-types */

/**
 * WARNING: Not ready for use in web-app.
 *
 * TODO: a few more things to be able to use this in web-app
 * a) Add TripBreadcrumbs... likely do this with a component as a prop... eg
 *      {TripSubHeader && <TripSubHeader />}
 *    and from web-app
 *      <TripItineraryPage
 *          ...
 *          TripSubHeader={() => (
 *              <TripBreadcrumbs trip={trip}>
 *                  <TripBreadcrumbItem>Itinerary</TripBreadcrumbItem>
 *              </TripBreadcrumbs>
 *          )}
 *      />
 * b) web-app's version has <InjectTrip> and <InjectTripItineraries>
 *   - simplest solution is we move those above TripItineraryPage so that by the time we render TripItineraryPage we have everything
 *   - this will be a "regression" as you will need to wait for itineraries to see anything
 *   - then.... either we make TripItineraryPage get itinerariesState ... or we just default iterinaries to []
 */


var TripItineraryPage = function TripItineraryPage(_ref) {
  var trip = _ref.trip,
      _ref$readOnly = _ref.readOnly,
      readOnly = _ref$readOnly === void 0 ? false : _ref$readOnly;
  return _react.default.createElement(_Page.Page, {
    page: "trip-itinerary-page"
  }, _react.default.createElement(_TripHeader.TripHeader, {
    trip: trip
  }), _react.default.createElement(_TripPageHeading.TripPageHeading, null, "Itinerary"), _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 3
  }, _react.default.createElement(_core.Grid, {
    item: true,
    sm: 9
  }, trip.itineraries.map(function (itinerary) {
    return _react.default.createElement(_ItineraryForm.ItineraryForm, {
      readOnly: readOnly,
      key: itinerary.dayNumber,
      itinerary: itinerary
    });
  })), _react.default.createElement(_core.Grid, {
    item: true,
    sm: 3
  }, _react.default.createElement(_Quote.Quote, {
    content: "Adventure is worthwhile in itself.",
    attribution: "Amelia Earhart"
  }))));
};

exports.TripItineraryPage = TripItineraryPage;