"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Display = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = {
  '@media screen': {
    hideScreen: {
      display: 'none'
    }
  },
  '@media print': {
    hidePrint: {
      display: 'none'
    }
  }
};

var Display = function Display(_ref) {
  var _ref2;

  var children = _ref.children,
      classes = _ref.classes,
      _ref$screen = _ref.screen,
      screen = _ref$screen === void 0 ? false : _ref$screen,
      _ref$print = _ref.print,
      print = _ref$print === void 0 ? false : _ref$print,
      other = _objectWithoutProperties(_ref, ["children", "classes", "screen", "print"]);

  return _react.default.createElement("span", _extends({
    className: (0, _classnames.default)((_ref2 = {}, _defineProperty(_ref2, classes.hidePrint, !print), _defineProperty(_ref2, classes.hideScreen, !screen), _ref2))
  }, other), children);
};

exports.Display = Display;
Display.propTypes = {
  children: _propTypes.default.node,
  classes: _propTypes.default.object.isRequired,
  print: _propTypes.default.bool,
  screen: _propTypes.default.bool
};
exports.Display = Display = (0, _core.withStyles)(styles)(Display);