import React from 'react';
import PropTypes from 'prop-types';
import 'ol/ol.css';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import ScaleLine from 'ol/control/ScaleLine';
import VectorSource from 'ol/source/Vector.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { withDependencies } from '@symbiotic/green-state';
import { BaseLayerFactory, LAYER_ZINDEXES } from '@wanderlost-sdk/cartographer';
import { FeatureAdapter, ExtentAdapter } from '@wanderlost-sdk/cartographer';
import { RouteStyler } from '@wanderlost-sdk/cartographer/dist/styles/RouteStyler';
import { BaseLayerAttribution } from '@wanderlost-sdk/cartographer/dist/controls/BaseLayerAttribution';
import { P, Display } from '@wanderlost-sdk/components';

const createRouteImage = ({ route, width, height }) => {
    const mapCanvas = document.createElement('canvas');
    mapCanvas.width = width;
    mapCanvas.height = height;
    const mapContext = mapCanvas.getContext('2d');
    [...document.querySelectorAll(`#route-${route.routeId} .ol-layer canvas`)].filter(c => c.width > 0).forEach(canvas => {
        const opacity = canvas.parentNode.style.opacity;
        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
        const matrix = canvas.style.transform.match(/^matrix\(([^(]*)\)$/)[1].split(',').map(Number);
        CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
        mapContext.drawImage(canvas, 0, 0);
    });
    return mapCanvas.toDataURL();
};

class RoutePrintBase extends React.Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        baseLayerFactory: PropTypes.instanceOf(BaseLayerFactory).isRequired,
        featureAdapter: PropTypes.instanceOf(FeatureAdapter).isRequired,
        extentAdapter: PropTypes.instanceOf(ExtentAdapter).isRequired,
        routeStyler: PropTypes.instanceOf(RouteStyler).isRequired,
        onRendered: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {};
        this.mounted = false;
    }
    async componentDidMount() {
        this.mounted = true;

        const target = this.mapRef.current;
        const { route, baseLayerFactory, featureAdapter, extentAdapter, routeStyler, onRendered } = this.props;
        const { extent } = route;

        const features = await featureAdapter.apiToFeatures(route.features);
        const olExtent = extentAdapter.apiToView(extent);

        let getZoom;
        const printLayer = new VectorLayer({
            source: new VectorSource({ features }),
            style: feature => (feature.get('type') !== 'route' ? [] : routeStyler.getPreviewStyle({ feature, zoom: getZoom() }))
        });
        printLayer.setZIndex(LAYER_ZINDEXES.otherLayers);

        const map = new Map({
            layers: [ printLayer ],
            target,
            controls: [ new ScaleLine({ className: 'map-scale-control', units: 'us' })],
            interactions: [],
            view: new View()
        });
        getZoom = () => map.getView().getZoom();

        map.once('rendercomplete', () => {
            if (this.mounted) {
                // move scale control from map to image
                const scaleDiv = document.querySelector(`#scale-${route.routeId}`);
                const scaleControl = document.querySelector(`#route-${route.routeId} .map-scale-control`);
                if (scaleControl) {
                    scaleDiv.appendChild(scaleControl);
                }

                // wait to be sure tiles have finished loading
                window.setTimeout(() => {
                    if (this.mounted) {
                        const [width, height] = map.getSize();
                        this.setState({ 'imageUrl': createRouteImage({ route, width, height }) });
                        onRendered(route);
                    }
                }, 5000)
            }
        });

        await baseLayerFactory.create('maptiler-topographique').addToMap(map);

        map.getView().fit(olExtent, { padding: [75, 50, 75, 50], maxZoom: 16 });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { route } = this.props;

        if (!route.features.length) {
            return (
                <P>Route contains no features.</P>
            );
        }

        return (
            <>
                <Display screen>
                    <div id={`route-${route.routeId}`} ref={this.mapRef} style={{ position: 'relative', width: '1200px', height: '900px' }} />
                </Display>

                <Display print>
                    <div style={{position: 'relative'}}>
                        <img src={this.state.imageUrl} alt={route.name} width="100%" />
                        <BaseLayerAttribution isDrawerOpen={false} />
                        <div id={`scale-${route.routeId}`} />
                    </div>
                </Display>
            </>
        );
    }

}

export const RoutePrint = withDependencies({
    baseLayerFactory: BaseLayerFactory,
    featureAdapter: FeatureAdapter,
    extentAdapter: ExtentAdapter,
    routeStyler: RouteStyler,
})(RoutePrintBase);
