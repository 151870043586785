import React from 'react';
import PropTypes from 'prop-types';
import { P, H2, WanderLostNavigator } from '@wanderlost-sdk/components';
import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { useInstance } from '@symbiotic/green-state';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';

const styles = theme => ({
    root: {
    },
    header: {
        marginBottom: theme.spacing(4),
    },
    list: {
        display: 'flex',
        alignItems: 'stretch',
        width: '100%'
    },
    card: {
        border: `1px solid #D9D9D9`,
        width: '100%',
        display: 'flex',
        alignItems: 'stretch'
    },
    cardActionArea: {
        display: 'flex',
        alignItems: 'stretch'
    },
    cardContent: {
        padding: 0,
        display: 'flex',
        alignItems: 'stretch',
        width: '100%',
    },
    content: {
        flexGrow: 1,
        alignSelf: 'center',
        padding: theme.spacing(3)
    },
    itemCountColumn: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.light,
        borderLeft: `1px solid #D9D9D9`,
    },
    itemCount: {
        flexGrow: 0,
        flexShrink: 0,
        width: 100,
        padding: theme.spacing(3),
        textAlign: 'center',
        fontSize: 16
    },
    itemCountNumber: {
        fontFamily: theme.typography.secondaryFontFamily,
        fontSize: 30,
        lineHeight: 1,
        fontWeight: 'bold',
        display: 'block',
    },
    itemCountLabel: {
        fontSize: 16,
        lineHeight: 1,
    }
});

const namedItemCount = items => items.filter(i => i.name).length;

export let PackingListCard = ({ classes, packingList }) => {
    const { packingListId, name, items, createdTime } = packingList;
    const navigator = useInstance(WanderLostNavigator);

    return (
        <Card className={classes.card} elevation={0}>
            <CardActionArea
                onClick={() => navigator.gearList({ packingListId }).go()}
                className={classes.cardActionArea}
            >
                <CardContent className={classes.cardContent}>
                    <div className={classes.content}>
                        <H2 variant="h3">{name}</H2>
                        <P color="textSecondary" gutterBottom={false}>
                            Created <strong>{moment(createdTime).format('MMM Do, YYYY')}</strong>
                        </P>
                    </div>
                    <div className={classes.itemCountColumn}>
                        <P gutterBottom={false} color="textSecondary" className={classes.itemCount}>
                            <span className={classes.itemCountNumber}>{namedItemCount(items)}</span>
                            <span className={classes.itemCountLabel}>{namedItemCount(items) === 1 ? 'item' : 'items'}</span>
                        </P>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

PackingListCard.propTypes = {
    classes: PropTypes.object.isRequired,
    packingList: PropTypes.object.isRequired,
};

PackingListCard = withStyles(styles)(PackingListCard);
