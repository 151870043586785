"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropHandler = exports.Draggable = exports.DragAndDropContext = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactDnd = require("react-dnd");

var _reactDndHtml5Backend = _interopRequireDefault(require("react-dnd-html5-backend"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var DragAndDropContext = (0, _reactDnd.DragDropContext)(_reactDndHtml5Backend.default)(function (_ref) {
  var children = _ref.children;
  return children;
});
exports.DragAndDropContext = DragAndDropContext;
var Draggable = (0, _reactDnd.DragSource)('Task', {
  canDrag: function canDrag(props) {
    if (typeof props.canDrag === 'boolean') {
      return props.canDrag;
    }

    return true;
  },
  // eslint-disable-next-line no-unused-vars
  beginDrag: function beginDrag(props, monitor, component) {
    // react dnd has some edge case where it wants payloads to be serializable
    // it throws if we return an item that is a class, we have to make it look like it isn't a class here
    return {
      item: props.item
    };
  },
  // eslint-disable-next-line no-unused-vars
  endDrag: function endDrag(props, monitor, component) {}
}, function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag()
  }; // eslint-disable-next-line no-unused-vars
})(function (_ref2) {
  var isDragging = _ref2.isDragging,
      canDrag = _ref2.canDrag,
      connectDragSource = _ref2.connectDragSource,
      children = _ref2.children;
  var style = canDrag ? {
    cursor: 'pointer'
  } : {};
  return connectDragSource(_react.default.createElement("div", {
    style: style
  }, children));
});
exports.Draggable = Draggable;
var DropHandler = (0, _reactDnd.DropTarget)('Task', {
  canDrop: function canDrop(props, monitor) {
    if (props.canDrop) {
      return props.canDrop(monitor.getItem().item);
    }

    return true;
  },
  // eslint-disable-next-line no-unused-vars
  drop: function drop(props, monitor, component) {
    // TODO: monitor.didDrop() should be based on a prop eg <DragHandler propogateDrops={true} or something like that
    // makes sense to me though, that this would not be "greedy" by default (good resource next time we're in here http://react-dnd.github.io/react-dnd/examples/nesting/drop-targets)
    if (!monitor.didDrop() && props.onDrop) {
      props.onDrop(monitor.getItem().item);
    }
  }
}, function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({
      shallow: true
    }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
})(function (_ref3) {
  var isOver = _ref3.isOver,
      isOverCurrent = _ref3.isOverCurrent,
      canDrop = _ref3.canDrop,
      connectDropTarget = _ref3.connectDropTarget,
      children = _ref3.children,
      className = _ref3.className,
      _ref3$style = _ref3.style,
      style = _ref3$style === void 0 ? {} : _ref3$style;
  return connectDropTarget(_react.default.createElement("div", {
    style: style,
    className: className
  }, children({
    isOver: isOver,
    canDrop: canDrop,
    isOverCurrent: isOverCurrent
  })));
});
exports.DropHandler = DropHandler;