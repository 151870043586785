"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToolSettings = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _core = require("@material-ui/core");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _DrawingManager = require("../DrawingManager");

var _DrawerState = require("../state/DrawerState");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ToolSettings = function ToolSettings(_ref) {
  var classes = _ref.classes;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _DrawerState.DrawerState
  }, function (_ref2) {
    var activeTabId = _ref2.activeTabId;
    return _react.default.createElement(_greenState.InjectAndSubscribe, {
      diKey: _DrawingManager.DrawingManager
    }, function (_ref3) {
      var renderSettings = _ref3.renderSettings;
      var settings = renderSettings();

      if (!settings) {
        return null;
      }

      return _react.default.createElement("div", {
        className: (0, _classnames.default)(classes.container, _defineProperty({}, classes.drawerOpen, activeTabId))
      }, settings);
    });
  });
};

exports.ToolSettings = ToolSettings;
ToolSettings.propTypes = {
  classes: _propTypes.default.object.isRequired
};
exports.ToolSettings = ToolSettings = (0, _core.withStyles)(function (theme) {
  var _drawerOpen;

  return {
    container: {
      position: 'absolute',
      zIndex: theme.wanderlost.zIndex.mapControls,
      top: "".concat(parseInt(theme.wanderlost.editingToolbarHeight) + theme.spacing(1), "px"),
      maxWidth: '310px',
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
      color: theme.palette.grey[600],
      border: "2px solid ".concat(theme.palette.grey[600]),
      borderRadius: '0.5rem',
      paddingLeft: '0.25rem',
      paddingRight: '0.25rem',
      paddingTop: '1px',
      paddingBottom: '2px'
    },
    drawerOpen: (_drawerOpen = {}, _defineProperty(_drawerOpen, theme.breakpoints.down('md'), {
      right: "".concat(parseInt(theme.wanderlost.drawerWidth) + 62, "px")
    }), _defineProperty(_drawerOpen, theme.breakpoints.down('sm'), {
      maxWidth: '125px'
    }), _drawerOpen)
  };
})(ToolSettings);