"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorMessage = exports.SPAN = exports.SMALL = exports.H4 = exports.H3 = exports.H2 = exports.H1 = exports.P = void 0;

var _react = _interopRequireDefault(require("react"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var P = function P(_ref) {
  var children = _ref.children,
      _ref$gutterBottom = _ref.gutterBottom,
      gutterBottom = _ref$gutterBottom === void 0 ? true : _ref$gutterBottom,
      other = _objectWithoutProperties(_ref, ["children", "gutterBottom"]);

  return (// eslint-disable-line react/prop-types
    _react.default.createElement(_Typography.default, _extends({
      gutterBottom: gutterBottom,
      variant: "body1"
    }, other), children)
  );
};

exports.P = P;

var H1 = function H1(_ref2) {
  var children = _ref2.children,
      other = _objectWithoutProperties(_ref2, ["children"]);

  return (// eslint-disable-line react/prop-types
    _react.default.createElement(_Typography.default, _extends({
      variant: "h1",
      gutterBottom: true
    }, other), children)
  );
};

exports.H1 = H1;

var H2 = function H2(_ref3) {
  var children = _ref3.children,
      other = _objectWithoutProperties(_ref3, ["children"]);

  return (// eslint-disable-line react/prop-types
    _react.default.createElement(_Typography.default, _extends({
      variant: "h2",
      gutterBottom: true
    }, other), children)
  );
};

exports.H2 = H2;

var H3 = function H3(_ref4) {
  var children = _ref4.children,
      other = _objectWithoutProperties(_ref4, ["children"]);

  return (// eslint-disable-line react/prop-types
    _react.default.createElement(_Typography.default, _extends({
      variant: "h3",
      gutterBottom: true
    }, other), children)
  );
};

exports.H3 = H3;

var H4 = function H4(_ref5) {
  var children = _ref5.children,
      other = _objectWithoutProperties(_ref5, ["children"]);

  return (// eslint-disable-line react/prop-types
    _react.default.createElement(_Typography.default, _extends({
      variant: "h4",
      gutterBottom: true
    }, other), children)
  );
};

exports.H4 = H4;

var SMALL = function SMALL(_ref6) {
  var children = _ref6.children,
      _ref6$style = _ref6.style,
      style = _ref6$style === void 0 ? {} : _ref6$style,
      other = _objectWithoutProperties(_ref6, ["children", "style"]);

  return (// eslint-disable-line react/prop-types
    _react.default.createElement(_Typography.default, _extends({
      component: "small",
      style: _objectSpread({}, style, {
        lineHeight: 'inherit'
      })
    }, other), children)
  );
};

exports.SMALL = SMALL;

var SPAN = function SPAN(_ref7) {
  var children = _ref7.children,
      _ref7$variant = _ref7.variant,
      variant = _ref7$variant === void 0 ? 'body1' : _ref7$variant,
      other = _objectWithoutProperties(_ref7, ["children", "variant"]);

  return (// eslint-disable-line react/prop-types
    _react.default.createElement(_Typography.default, _extends({
      component: "span",
      variant: variant
    }, other), children)
  );
};

exports.SPAN = SPAN;

var ErrorMessage = function ErrorMessage(_ref8) {
  var children = _ref8.children,
      other = _objectWithoutProperties(_ref8, ["children"]);

  return (// eslint-disable-line react/prop-types
    _react.default.createElement(P, _extends({
      color: "error"
    }, other), children)
  );
};

exports.ErrorMessage = ErrorMessage;