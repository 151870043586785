"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipeImage = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _core = require("@material-ui/core");

var _greenState = require("@symbiotic/green-state");

var _typography = require("../typography");

var _urls = require("../urls");

var _contentfulHelpers = require("./contentfulHelpers");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RecipeImage = function RecipeImage(_ref) {
  var recipe = _ref.recipe,
      _ref$preventNavigatio = _ref.preventNavigation,
      preventNavigation = _ref$preventNavigatio === void 0 ? false : _ref$preventNavigatio,
      classes = _ref.classes;
  var navigator = (0, _greenState.useInstance)(_urls.WanderLostNavigator);
  var name = recipe.name,
      author = recipe.author,
      cookbook = recipe.cookbook;
  var recipeImageURL = recipe.images ? (0, _contentfulHelpers.buildRecipeImageUrl)(recipe.images[0]) : recipe.meal ? (0, _contentfulHelpers.buildRecipeImageUrl)(recipe.meal[0].images[0]) : '/images/recipe-default.jpg';
  var authorImageURL = recipe.author.picture ? (0, _contentfulHelpers.buildAvatarUrl)(recipe.author.picture) : undefined;
  return _react.default.createElement("div", {
    className: classes.recipeImageWrapper
  }, _react.default.createElement("div", {
    className: classes.recipeImageGradient
  }, _react.default.createElement("img", {
    alt: name,
    className: classes.recipeImage,
    src: recipeImageURL
  })), _react.default.createElement("div", {
    className: classes.recipeImageContentSpace
  }), _react.default.createElement("div", {
    className: (0, _classnames.default)(classes.recipeImageContent, _defineProperty({}, classes.recipeImageContentLink, !preventNavigation)),
    onClick: preventNavigation ? undefined : function () {
      return navigator.cookbookDetails({
        cookbookId: cookbook.cookbookId
      }).go();
    }
  }, authorImageURL && _react.default.createElement(_core.Avatar, {
    alt: author.name,
    src: authorImageURL,
    style: {
      height: '100px',
      width: '100px'
    }
  }), _react.default.createElement("div", {
    style: {
      padding: '0 20px'
    }
  }, _react.default.createElement(_typography.P, {
    style: {
      color: 'inherit',
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: 1
    }
  }, "AUTHOR"), _react.default.createElement(_typography.P, {
    style: {
      color: 'inherit',
      fontSize: '18px',
      lineHeight: 1.5
    }
  }, author.name, ", ", _react.default.createElement("span", {
    style: {
      fontSize: '75%'
    }
  }, author.title)), _react.default.createElement(_typography.P, {
    style: {
      color: 'inherit',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.25
    },
    gutterBottom: false
  }, cookbook.name))));
};

exports.RecipeImage = RecipeImage;
RecipeImage.propTypes = {
  recipe: _propTypes.default.object.isRequired,
  preventNavigation: _propTypes.default.bool,
  classes: _propTypes.default.object.isRequired
};
exports.RecipeImage = RecipeImage = (0, _core.withStyles)(function (theme) {
  return {
    recipeImageWrapper: {
      display: 'block',
      position: 'relative',
      '@media print': {
        border: '1px solid #ccc',
        borderRadius: '2px'
      }
    },
    recipeImage: {
      display: 'block',
      width: '100%',
      overflow: 'hidden'
    },
    recipeImageGradient: {
      display: 'block',
      position: 'relative',
      '@media screen': {
        '&:after': {
          content: 'close-quote',
          position: 'absolute',
          top: '80%',
          left: 0,
          height: '20%',
          width: '100%',
          background: 'linear-gradient(to bottom, rgba(27, 63, 55, 0) 0%, rgba(27, 63, 55, 0.1) 20%, rgba(27, 63, 55, 1) 100%)'
        }
      }
    },
    recipeImageContentSpace: {
      height: '50px',
      backgroundColor: 'rgb(27, 63, 55)'
    },
    recipeImageContent: {
      display: 'flex',
      alignItems: 'flex-end',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      padding: '20px 33px',
      color: 'white',
      '@media print': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
      }
    },
    recipeImageContentLink: {
      cursor: 'pointer'
    }
  };
})(RecipeImage);