"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserTrailStyler = void 0;

var _ZIndexManager = require("../ZIndexManager");

var _TrailStyler = require("./TrailStyler");

var _style = require("ol/style.js");

var _FeatureColorGenerator = require("./FeatureColorGenerator");

var _memoize = _interopRequireDefault(require("lodash/fp/memoize"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var UserTrailStyler = function UserTrailStyler(_ref, zIndexManager, featureColorGenerator) {
  var USER_COLORS = _ref.USER;

  _classCallCheck(this, UserTrailStyler);

  _defineProperty(this, "styleKey", 'USER');

  var colorGenerator = featureColorGenerator.create(USER_COLORS);
  var styleCache = {
    DETAILED_INNER_STROKE: (0, _memoize.default)(function (color) {
      return new _style.Stroke({
        color: color,
        width: 4
      });
    }),
    DETAILED_OUTER_STROKE: (0, _memoize.default)(function () {
      return new _style.Stroke({
        color: '#ffffff',
        width: 8
      });
    }),
    SIMPLIFIED_STYLE: (0, _memoize.default)(function (color) {
      return [new _style.Style({
        stroke: new _style.Stroke({
          color: '#ffffff',
          width: 8
        }),
        zIndex: 2
      }), new _style.Style({
        stroke: new _style.Stroke({
          color: color,
          width: 4
        }),
        zIndex: 2
      })];
    }),
    UBER_SIMPLIFIED_STYLE: (0, _memoize.default)(function (color) {
      return [new _style.Style({
        stroke: new _style.Stroke({
          color: '#fff',
          width: 4
        }),
        zIndex: 2
      }), new _style.Style({
        stroke: new _style.Stroke({
          color: color,
          width: 2
        }),
        zIndex: 2
      })];
    })
  };

  this.getUberSimplifiedStyle = function (feature) {
    return styleCache.UBER_SIMPLIFIED_STYLE(colorGenerator.getColor(feature));
  };

  this.getSimplifiedStyle = function (feature) {
    return styleCache.SIMPLIFIED_STYLE(colorGenerator.getColor(feature));
  };

  this.getDetailedStyle = function (feature) {
    var zIndex = zIndexManager.getZIndexForMapRender(feature);
    var featureColor = colorGenerator.getColor(feature);
    return [new _style.Style({
      stroke: styleCache.DETAILED_OUTER_STROKE(featureColor),
      zIndex: zIndex
    }), new _style.Style({
      stroke: styleCache.DETAILED_INNER_STROKE(featureColor),
      zIndex: zIndex
    })];
  };

  this.getHighlightStyle = function (_ref2) {
    var zIndex = _ref2.zIndex;
    return [new _style.Style({
      stroke: new _style.Stroke({
        color: 'rgba(255, 210, 0, 0.35)',
        width: 14
      }),
      zIndex: zIndex
    })];
  };
};

exports.UserTrailStyler = UserTrailStyler;

_defineProperty(UserTrailStyler, "inject", function () {
  return [_TrailStyler.TrailColors, _ZIndexManager.ZIndexManager, _FeatureColorGenerator.FeatureColorGeneratorFactory];
});