"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddTripButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _react2 = require("../framework/react");

var _CreateTripDialog = require("./CreateTripDialog");

var _greenState = require("@symbiotic/green-state");

var _urls = require("../urls");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var AddTripButton = function AddTripButton(_ref) {
  var buttonProps = _ref.buttonProps,
      onClick = _ref.onClick,
      onDone = _ref.onDone,
      _ref$label = _ref.label,
      label = _ref$label === void 0 ? 'Plan a Trip' : _ref$label;
  return _react.default.createElement(_react2.InjectTogglable, {
    initialValue: false
  }, function (_ref2) {
    var isAddTripDialogVisible = _ref2.isOn,
        showAddTripDialog = _ref2.on,
        hideAddTripDialog = _ref2.off;
    return _react.default.createElement(_greenState.Inject, {
      diKey: _urls.WanderLostNavigator
    }, function (navigator) {
      var handleDone = function handleDone(_ref3) {
        var tripId = _ref3.tripId;
        onDone && onDone();
        hideAddTripDialog();
        navigator.tripDetails({
          tripId: tripId
        }).go();
      };

      var handleClick = function handleClick() {
        onClick && onClick();
        showAddTripDialog();
      };

      var onCancel = function onCancel() {
        hideAddTripDialog();
      };

      buttonProps = buttonProps || {
        variant: 'contained',
        color: 'primary',
        size: 'large'
      };
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Button.default, _extends({
        onClick: handleClick,
        name: "show-create-trip"
      }, buttonProps), label), isAddTripDialogVisible && _react.default.createElement(_CreateTripDialog.CreateTripDialog, {
        onDone: handleDone,
        onCancel: onCancel
      }));
    });
  });
};

exports.AddTripButton = AddTripButton;
AddTripButton.propTypes = {
  buttonProps: _propTypes.default.object,
  label: _propTypes.default.string,
  onDone: _propTypes.default.func,
  onClick: _propTypes.default.func
};