"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Favorites = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _greenState = require("@symbiotic/green-state");

var _DrawerContent = require("./DrawerContent");

var _ActiveFoldersState = require("./ActiveFoldersState");

var _Folder = require("./Folder");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
} // returns 'my-layers', 'shared-with-me' or 'global-folders'


var getTopLevelId = function getTopLevelId(folder) {
  var parent = folder;

  for (parent = folder; parent.parent() != null; parent = parent.parent()) {
    ;
  }

  return parent.folderId;
}; // returns a list of layers for one of the "favorites" sections


var filterByTopLevel = function filterByTopLevel(_ref) {
  var favoritesById = _ref.favoritesById,
      topLevelId = _ref.topLevelId;
  return Object.values(favoritesById).filter(function (f) {
    return getTopLevelId(f) === topLevelId;
  });
}; // returns the list of layers for the "recent" section


var getActiveNotFavorites = function getActiveNotFavorites(_ref2) {
  var activeFoldersById = _ref2.activeFoldersById,
      favoritesById = _ref2.favoritesById;
  return Object.values(activeFoldersById).filter(function (f) {
    return !favoritesById[f.folderId];
  });
};

var buildRootFolder = function buildRootFolder(_ref3) {
  var folderId = _ref3.folderId,
      name = _ref3.name,
      folders = _ref3.folders;
  return {
    folderId: folderId,
    name: name,
    folders: folders,
    actions: function actions() {
      return {};
    },
    canCreateFolder: false,
    canCreateLayer: false,
    canMoveToFolder: function canMoveToFolder() {
      return false;
    },
    moveTo: function moveTo() {}
  };
};

var Favorites = function Favorites(props) {
  var showSecondaryActions = !props.currentFolder || !props.currentFolder.editFeaturesOptions.hideOtherFoldersOnEdit;
  return _react.default.createElement(_greenState.Inject, {
    diKey: _ActiveFoldersState.ActiveFoldersState
  }, function (instance) {
    return _react.default.createElement(_greenState.Subscribe, {
      to: function to() {
        instance.ensureLoadedAtLeastOnce();
        return instance;
      }
    }, function (_ref4) {
      var activeFoldersById = _ref4.activeFoldersById,
          favoritesById = _ref4.favoritesById;
      return _react.default.createElement(_DrawerContent.DrawerContent, null, _react.default.createElement(_DrawerContent.DrawerContentHeading, {
        title: "Favorites",
        description: "Adding a layer to favorites makes content visible at all zoom levels. Country-wide overlays not supported."
      }), _react.default.createElement("div", {
        style: {
          marginLeft: '-21px',
          marginRight: '-21px'
        }
      }, _react.default.createElement(_Folder.FolderList, _extends({}, props, {
        isRoot: true,
        hideRootActions: true,
        showSecondaryActions: showSecondaryActions,
        folders: [buildRootFolder({
          folderId: 'favorite-layers',
          name: 'My Layers',
          folders: filterByTopLevel({
            favoritesById: favoritesById,
            topLevelId: 'my-layers'
          })
        }), buildRootFolder({
          folderId: 'favorite-shares',
          name: 'Shared with Me',
          folders: filterByTopLevel({
            favoritesById: favoritesById,
            topLevelId: 'shared-with-me'
          })
        }), buildRootFolder({
          folderId: 'favorite-trails',
          name: 'Featured Trail Layers',
          folders: filterByTopLevel({
            favoritesById: favoritesById,
            topLevelId: 'global-folders'
          })
        })]
      }))), _react.default.createElement(_core.Divider, {
        style: {
          marginTop: '20px',
          marginBottom: '20px'
        }
      }), _react.default.createElement(_DrawerContent.DrawerContentHeading, {
        title: "Recent",
        description: "Quick access to layers you\u2019re working with that haven\u2019t been added to favorites. Also visible at all zoom levels."
      }), _react.default.createElement("div", {
        style: {
          marginLeft: '-21px',
          marginRight: '-21px'
        }
      }, _react.default.createElement(_Folder.FolderList, _extends({}, props, {
        isRoot: true,
        hideRootActions: true,
        startCollapsed: true,
        showSecondaryActions: showSecondaryActions,
        folders: [buildRootFolder({
          folderId: 'recently-viewed',
          name: 'Recently Viewed Layers',
          folders: getActiveNotFavorites({
            activeFoldersById: activeFoldersById,
            favoritesById: favoritesById
          })
        })]
      }))));
    });
  });
};

exports.Favorites = Favorites;
Favorites.propTypes = {
  currentFolder: _propTypes.default.object
};