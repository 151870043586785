"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.featureFlags = void 0;
/* globals window:readonly */

var storageKey = 'wl-feature_flags'; // save in localStorage as a stringified array of strings like '["flag1","flag2"]'
// (to make it easier to read and type in the browser console)

var load = function load() {
  return JSON.parse(window.localStorage.getItem(storageKey) || '[]').reduce(function (m, f) {
    return _objectSpread({}, m, _defineProperty({}, f, true));
  }, {});
};

var save = function save() {
  return window.localStorage.setItem(storageKey, JSON.stringify(Object.keys(featureFlags)));
}; // export as an object like {flag1:true,flag2:true}
// (to make it easier to check which flags are set)


var featureFlags = load(); // provide dev helpers for enabling/disabling
// IMPORTANT: these are also used by cypress commands and FeatureFlagsPage

exports.featureFlags = featureFlags;
window.__wlFeatureFlags = {
  enable: function enable() {
    for (var _len = arguments.length, flagNames = new Array(_len), _key = 0; _key < _len; _key++) {
      flagNames[_key] = arguments[_key];
    }

    flagNames.map(function (flagName) {
      if (typeof flagName === 'string') {
        featureFlags[flagName] = true;
      }
    });
    save();
  },
  disable: function disable(flagName) {
    if (flagName) {
      delete featureFlags[flagName];
    } else {
      // if no flag specified, clear them all
      Object.keys(featureFlags).map(function (f) {
        return delete featureFlags[f];
      });
    }

    save();
  }
};