"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PointFeatureFormatter = void 0;

var _Feature = _interopRequireDefault(require("ol/Feature"));

var _Point = _interopRequireDefault(require("ol/geom/Point"));

var _IconOptions = require("../forms/IconOptions");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var PointFeatureFormatter = function PointFeatureFormatter(iconOptions) {
  _classCallCheck(this, PointFeatureFormatter);

  var icons = Array.from(iconOptions);

  var formatPointFeature = function formatPointFeature(feature) {
    var _feature$getPropertie = feature.getProperties(),
        name = _feature$getPropertie.name,
        description = _feature$getPropertie.desc,
        url = _feature$getPropertie.link,
        symbol = _feature$getPropertie.sym,
        geometry = _feature$getPropertie.geometry; // GPX format was producing the string 'null' for name


    if (!name || name === 'null') {
      name = 'Imported Marker';
    }

    if (description === 'null') {
      description = null;
    } // default to black marker icon


    var iconId = 'marker';
    var color = '#000000'; // if feature has recognized symbol, find matching icon

    if (symbol) {
      var icon = icons.find(function (i) {
        return i.gpxSymbol === symbol || i.label === symbol;
      });

      if (icon) {
        iconId = icon.iconId;
        color = icon.color;
      }
    }

    var _geometry$flatCoordin = _slicedToArray(geometry.flatCoordinates, 2),
        x = _geometry$flatCoordin[0],
        y = _geometry$flatCoordin[1];

    return new _Feature.default({
      geometry: new _Point.default([x, y]),
      name: name,
      description: description,
      url: url,
      type: 'waypoint',
      icon: iconId,
      color: color
    });
  };

  this.formatFeatures = function (features) {
    var rejectedFeatures = [];
    var acceptedFeatures = [];
    features.forEach(function (feature) {
      var isPoint = feature.getGeometry().getType() === 'Point';
      var isLine = feature.getGeometry().getType() === 'LineString';

      if (isPoint) {
        // a <rte> may contain only one waypoint which open layers turns into a one point line
        // our api considers a one point line invalid, so lets convert it to a waypoint
        acceptedFeatures.push(formatPointFeature(feature));
      } else if (isLine && getPointCount(feature) === 1) {
        if (feature.getProperties().name) {
          acceptedFeatures.push(formatPointFeature(feature)); // if there is no name, we likely do more harm than good to add nameless waypoint
        } else {
          rejectedFeatures.push(feature);
        }
      } // Otherwise, consider it accepted
      else {
          acceptedFeatures.push(feature);
        }
    });
    return {
      acceptedFeatures: acceptedFeatures,
      rejectedFeatures: rejectedFeatures
    };
  };
};

exports.PointFeatureFormatter = PointFeatureFormatter;

_defineProperty(PointFeatureFormatter, "inject", function () {
  return [_IconOptions.IconOptions];
});

function getPointCount(feature) {
  // layout might be 'XYMZ'
  // flatCoordinates then might be [x1, y1, m1, z1, x2, y2, m2, z2]
  var _feature$getPropertie2 = feature.getProperties().geometry,
      layout = _feature$getPropertie2.layout,
      flatCoordinates = _feature$getPropertie2.flatCoordinates;
  return flatCoordinates.length / layout.length;
}