"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventBus = void 0; // TODO: move me to a better folder

var EventBus = function EventBus() {
  var _this = this;

  _classCallCheck(this, EventBus);

  var listeners = {};
  var listenerCount = 0;

  this.emit = function (name, payload) {
    if (listeners[name]) {
      // TODO: what if one fails... write unit tests or find an off the shelf library
      listeners[name].forEach(function (listener) {
        return listener.callback(payload);
      });
    }
  }; // persist=true means don't dispose when cartographer unloads


  this.on = function (name, callback, persist) {
    if (!listeners[name]) {
      listeners[name] = [];
    }

    listeners[name].push({
      callback: callback,
      persist: persist
    });
    listenerCount++;

    var unsub = function unsub() {
      listeners[name] = listeners[name].filter(function (listener) {
        return listener.callback !== callback;
      });
      listenerCount--;
    };

    return unsub;
  };

  this.once = function (name, callback) {
    var unsub = _this.on(name, function () {
      try {
        callback.apply(void 0, arguments);
      } finally {
        unsub();
      }
    });
  };

  this.getListenerCount = function () {
    return listenerCount;
  }; // remove all non-persistent listeners


  this.dispose = function () {
    var count = 0;

    var _arr = Object.keys(listeners);

    for (var _i = 0; _i < _arr.length; _i++) {
      var name = _arr[_i];
      var namedListeners = listeners[name].filter(function (listener) {
        return Boolean(listener.persist);
      });

      if (!namedListeners.length) {
        delete listeners[name];
      }

      count += namedListeners.length;
    }

    listenerCount = count;
  };
};

exports.EventBus = EventBus;