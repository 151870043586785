"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripsNav = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var SecondaryNav = _interopRequireWildcard(require("./SecondaryNav"));

var _nav = require("../nav");

var _trips = require("../trips");

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TripsNav = function TripsNav(_ref) {
  var closeMenu = _ref.closeMenu;
  return _react.default.createElement(SecondaryNav.Menu, null, _react.default.createElement(SecondaryNav.Heading, null, "Upcoming"), _react.default.createElement(_nav.UpcomingTripsNav, null), _react.default.createElement(SecondaryNav.Divider, null), _react.default.createElement(SecondaryNav.MenuItem, {
    to: "tripList"
  }, "View all trips"), _react.default.createElement(SecondaryNav.MenuButton, null, _react.default.createElement(_trips.AddTripButton, {
    onDone: closeMenu,
    buttonProps: {
      variant: 'contained',
      color: 'primary',
      size: 'small'
    }
  })), _react.default.createElement(SecondaryNav.Divider, null), _react.default.createElement(SecondaryNav.Heading, null, "Featured"), _react.default.createElement(_nav.ExploreTripsNav, null));
};

exports.TripsNav = TripsNav;
TripsNav.propTypes = {
  closeMenu: _propTypes.default.func.isRequired
};