import { State } from '@symbiotic/green-state';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';
import { SortablePackingListState } from './SortablePackingListState';

export class AddExistingListState extends State {
    static inject = () => [WanderLostApiClient];

    constructor(apiClient) {
        super({ isOpen: false, selectedPackingList: null });

        this.open = () => this.setState({ isOpen: true });
        this.close = () => this.setState({ isOpen: false, selectedPackingList: null });

        const isTripPackingList = packingList => packingList.tripId;
        const isGearList = packingList => !packingList.tripId && packingList.type !== 'starter';
        const isStarterList = packingList => packingList.type === 'starter';

        const decorateList = list =>
            new SortablePackingListState({
                packingList: list,
            }).get().packingList;

        this.load = async () => {
            const entry = await apiClient.entry();
            const packingLists = await apiClient.get(entry.links.packingLists);
            this.setState({
                tripPackingLists: packingLists.filter(isTripPackingList).map(decorateList),
                gearLists: packingLists.filter(isGearList).map(decorateList),
                starterLists: packingLists.filter(isStarterList).map(decorateList)
            });
        };

        this.selectPackingList = packingList => this.setState({ selectedPackingList: packingList });

    }

}
