import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Dialog, ErrorMessage } from '@wanderlost-sdk/components';

export class GearListNameDialog extends Component {

    static defaultProps = {
        gearList: { name : '' }
    }

    state = {
        name: this.props.gearList.name,
        error: null,
        saving: false,
    };

    handleChange = name => e => {
        const { value } = e.target;
        this.setState({ [name]: value });
    }

    handleCancel = e => {
        e.preventDefault();
        this.props.onCancel();
    }

    handleSubmit = async e => {
        this.setState({ error: null, saving: true });

        e.preventDefault();
        const { name } = this.state;

        try{
            await this.props.onSubmit({ name });
        }
        catch(err){
            if (err.displayMessage){
                this.setState({ error: err.displayMessage });
            }
            else {
                this.setState({ error: `Sorry, something went wrong. Please try again.` });
                throw err;
            }
        } finally {
            this.setState({ saving: false });
        }
    }

    render() {
        const { title, saveButtonLabel = 'Save', children } = this.props;
        const { name, error, saving } = this.state;

        return (
            <Dialog
                isOpen={true}
                title={title}
                actionsComponent={
                    <>
                        <Button onClick={this.handleCancel} tabIndex="-1">
                            Cancel
                        </Button>
                        <Button disabled={!name || saving} onClick={this.handleSubmit} color="primary" variant="contained" name="save-gear-list">
                            {saveButtonLabel}
                        </Button>
                    </>
                }
            >
                { Boolean(error) &&
                    <ErrorMessage>{error}</ErrorMessage>
                }
                {children}
                <form name="gear-list-name-form" onSubmit={this.handleSubmit}>
                    <TextField
                        label="Name"
                        name="name"
                        value={name}
                        onChange={this.handleChange('name')}
                        autoFocus
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="dense"
                        style={{width: '500px'}}
                    />
                </form>
            </Dialog>
        );
    }
}

GearListNameDialog.propTypes = {
    gearList: PropTypes.object,
    title: PropTypes.string.isRequired,
    saveButtonLabel: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.node
};
