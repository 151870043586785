"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var moment = require("moment");

function daysBetween(start, end) {
  var startMoment = moment(start).startOf('day');
  var endMoment = moment(end).startOf('day');
  return Math.round(moment.duration(endMoment.diff(startMoment)).asDays());
}

exports.daysBetween = daysBetween;

function minutesBetween(start, end) {
  var startMoment = moment(start);
  var endMoment = moment(end);
  return Math.round(moment.duration(endMoment.diff(startMoment)).asMinutes());
}

exports.minutesBetween = minutesBetween;

function daysUntil(_ref) {
  var startDate = _ref.startDate;
  return daysBetween(moment().format('YYYY-MM-DD'), startDate);
}

exports.daysUntil = daysUntil;

function durationInDays(_ref2) {
  var startDate = _ref2.startDate,
      endDate = _ref2.endDate;
  return daysBetween(startDate, endDate);
}

exports.durationInDays = durationInDays;

function formatDateRange(_ref3) {
  var startDate = _ref3.startDate,
      endDate = _ref3.endDate;
  var startMoment = moment(startDate).startOf('day');
  var endMoment = moment(endDate).startOf('day');

  if (startMoment.valueOf() === endMoment.valueOf()) {
    return startMoment.format('ddd, MMM D, YYYY');
  }

  return "".concat(startMoment.format('ddd, MMM D'), " - ").concat(endMoment.format('ddd, MMM D, YYYY'));
}

exports.formatDateRange = formatDateRange;

function formatDate(date) {
  return moment(date).format('ddd, MMM D, h:mm A');
}

exports.formatDate = formatDate;

function formatTripDate(date) {
  return moment(date).format('dddd, MMMM Do');
}

exports.formatTripDate = formatTripDate;

function addDays(date, days) {
  var dateMoment = moment(date).startOf('day');
  return dateMoment.add(days, 'd').format('YYYY-MM-DD');
}

exports.addDays = addDays;