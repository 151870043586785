"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseLayerAttribution = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _greenState = require("@symbiotic/green-state");

var _BaseLayersState = require("../base-layers/BaseLayersState");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var BaseLayerAttribution = function BaseLayerAttribution(_ref) {
  var classes = _ref.classes,
      isDrawerOpen = _ref.isDrawerOpen;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _BaseLayersState.BaseLayersState
  }, function (_ref2) {
    var getAttributionHTML = _ref2.getAttributionHTML,
        getActiveLayerId = _ref2.getActiveLayerId;
    var attributionHTML = getAttributionHTML();

    if (!attributionHTML) {
      return null;
    }

    return _react.default.createElement("div", {
      "data-base-layer-attribution": getActiveLayerId(),
      className: (0, _classnames.default)(classes.attribution, _defineProperty({}, classes.attributionDrawerOpen, isDrawerOpen)),
      dangerouslySetInnerHTML: {
        __html: attributionHTML
      }
    });
  });
};

exports.BaseLayerAttribution = BaseLayerAttribution;
BaseLayerAttribution.propTypes = {
  classes: _propTypes.default.object.isRequired,
  isDrawerOpen: _propTypes.default.bool.isRequired
};
exports.BaseLayerAttribution = BaseLayerAttribution = (0, _withStyles.default)(function (theme) {
  return {
    attribution: _objectSpread({}, theme.typography.body2, {
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
      padding: '1px 5px',
      fontSize: '10px',
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: theme.wanderlost.zIndex.mapAttribution,
      lineHeight: '1.2 !important',
      marginLeft: 0,
      color: '#000',
      '& a': {
        color: '#000 !important'
      }
    }),
    attributionDrawerOpen: {
      right: theme.wanderlost.drawerWidth
    }
  };
})(BaseLayerAttribution);