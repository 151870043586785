import React from 'react';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { useInstance } from '@symbiotic/green-state';
import { P, Dialog, WanderLostNavigator, ContextMenu, Button } from '@wanderlost-sdk/components';
import { hasAction } from '@wanderlost-sdk/core';
import { GearListNameDialog } from './GearListNameDialog';

export const PackingListContextMenu = ({ packingList, classes }) => {
    const [isDuplicateDialogOpen, setDuplicateDialogOpen] = React.useState(false);
    const [isRenameDialogOpen, setRenameDialogOpen] = React.useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const navigator = useInstance(WanderLostNavigator);
    const isTripList = Boolean(packingList.tripId);
    const duplicateLabel = isTripList ? 'Save as Gear List' : 'Duplicate';

    const items = [];

    items.push(
        <MenuItem
            onClick={() => setDuplicateDialogOpen(true)}
            key="duplicate"
        >
            {duplicateLabel}
        </MenuItem>
    );

    if (hasAction(packingList, 'self', 'rename')) {
        items.push(
            <MenuItem
                onClick={() => setRenameDialogOpen(true)}
                key="rename"
            >
                Rename
            </MenuItem>
        );
    }

    if (hasAction(packingList, 'self', 'delete')) {
        items.push(
            <MenuItem
                onClick={() => setDeleteDialogOpen(true)}
                key="delete"
            >
                Delete
            </MenuItem>
        );
    }

    items.push(
        <MenuItem
            onClick={packingList.print}
            key="print"
        >
            Print
        </MenuItem>
    );

    return (
        <>
            <ContextMenu
                name="packing-list-context-menu-button"
                classes={{
                    button: classes.button
                }}
            >
                <MenuList dense={true} name="packing-list-context-menu">
                    {items}
                </MenuList>
            </ContextMenu>
            {isDuplicateDialogOpen &&
                <GearListNameDialog
                    gearList={{ name: `Copied from ${packingList.name}` }}
                    title={duplicateLabel}
                    saveButtonLabel={duplicateLabel}
                    onSubmit={async ({ name }) => {
                        const { packingListId } = await packingList.duplicate({ name });
                        setDuplicateDialogOpen(false);
                        navigator.gearList({ packingListId }).go();
                    }}
                    onCancel={() => {
                        setDuplicateDialogOpen(false);
                    }}
                >
                    {packingList.tripId ?
                        <P>
                            Want to manage this list outside of trips? Save to your Gear Lists. Please note that this will be a copy, and changes you make in this trip will not be reflected in that list, nor will changes made there be reflected in this trip.
                        </P> :
                        <P>
                            Please note that this will be a copy, and changes you make in this list will not be reflected in that list, and vice versa.
                        </P>
                    }
                </GearListNameDialog>
            }
            {isRenameDialogOpen &&
                <GearListNameDialog
                    gearList={packingList}
                    title="Rename List"
                    saveButtonLabel="Rename"
                    onSubmit={async ({ name }) => {
                        await packingList.rename(name);
                        setRenameDialogOpen(false);
                    }}
                    onCancel={() => {
                        setRenameDialogOpen(false);
                    }}
                />
            }
            <Dialog
                isOpen={isDeleteDialogOpen}
                title="Delete List"
                actionsComponent={
                    <>
                        <Button
                            onClick={() => setDeleteDialogOpen(false)}
                            size="large"
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            name="delete"
                            size="large"
                            color="primary"
                            variant="contained"
                            style={{ backgroundColor: 'red' }}
                            onClick={async () => {
                                await packingList.delete();
                                setDeleteDialogOpen(false);
                                navigator.gearLists().go();
                            }}
                        >
                            Delete
                        </Button>
                    </>
                }
            >
                <P>Are you sure you want to delete this list? This cannot be undone.</P>
            </Dialog>
        </>
    );
};

PackingListContextMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    packingList: PropTypes.object.isRequired,
    visibleFields: PropTypes.array.isRequired,
    setVisibleFields: PropTypes.func.isRequired,
};
