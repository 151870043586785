"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZIndexManager = exports.LAYER_ZINDEXES = void 0;
var LAYER_ZINDEXES = {
  baseLayers: 0,
  publicLands: 1,
  otherLayers: 2
};
exports.LAYER_ZINDEXES = LAYER_ZINDEXES;

var ZIndexManager = function ZIndexManager() {
  _classCallCheck(this, ZIndexManager);

  // eslint-disable-next-line no-unused-vars
  this.getZIndexForNewFeature = function (feature) {
    return Date.now();
  };

  this.setZIndexForImportedFeatures = function (features) {
    var zIndex = Date.now();
    return features.map(function (feature) {
      return _objectSpread({}, feature, {
        attributes: _objectSpread({
          zIndex: zIndex++
        }, feature.attributes)
      });
    });
  };
  /**
   * In order to deterministically render features at the appropriate layer. We are using a combination
   * of epoch and a factor of the epoch. While epoch gives us a simple way for deterministic rendering across
   * layers, it came at the cost of a not so simple factor list...
   * see https://docs.google.com/spreadsheets/d/1qhbXxmmdC2fmvLRgotRoK2zX3-DvXlszlxTuBYxia20/edit#gid=0
   *
   * Note that all features types are only displayed in the same layer during editing
   * When viewing, trails and non-trails are in different layers which impacts stacking order
   */


  var zIndexFactors = {
    'trail': 1,
    'route': 10,
    'trail-highlight': 31,
    'waypoint': 95,
    'text-annotation': 290
  };

  this.getZIndexForMapRender = function (feature) {
    var zIndexFactor = zIndexFactors[feature.getProperties().type] || 1;
    return feature.getProperties().zIndex * zIndexFactor;
  };

  this.getZIndexForSelectedRoute = function () {
    return 3194852707564 * zIndexFactors.route;
  }; // year 2071

};

exports.ZIndexManager = ZIndexManager;