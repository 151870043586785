import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withDependencies } from '@symbiotic/green-state';
import {
    RouteFeature,
    DumbRouter,
    SmartRouter,
    FeatureRouter,
    DistanceCalculator,
    FeatureAdapter,
    SmartRouteRequestParamBuilder,
    SmartRouterDAO,
    FeatureExporter,
} from '@wanderlost-sdk/cartographer';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';
import { LineStringSampler } from '@wanderlost-sdk/core/dist/gis/LineStringSampler';

/**
 * This component exposes some values on window.__wlTest to be used by cypress for unit/integration tests (.e.g. RouteFeature tests)
 * This allows us to avoid concerns about transpiling the source code (for example open layers npm package is es6 so you have to transpile it which works with webpack but not with mocha)
 * It also allows us to run test code IN THE BROWSER, which is required for open layers since its a browser only library
 */
export class TestSandbox extends Component {
    static propTypes = {
        lineStringSampler: PropTypes.instanceOf(LineStringSampler).isRequired,
        featureAdapter: PropTypes.instanceOf(FeatureAdapter).isRequired,
        apiClient: PropTypes.instanceOf(WanderLostApiClient).isRequired,
        distanceCalculator: PropTypes.instanceOf(DistanceCalculator).isRequired,
        smartRouteRequestParamBuilder: PropTypes.instanceOf(SmartRouteRequestParamBuilder).isRequired,
        smartRouterDAO: PropTypes.instanceOf(SmartRouterDAO).isRequired,
        featureExporter: PropTypes.instanceOf(FeatureExporter).isRequired,
    };

    componentDidMount() {
        const { apiClient, featureAdapter, distanceCalculator, lineStringSampler, smartRouteRequestParamBuilder, smartRouterDAO, featureExporter } = this.props;

        window.__wlTest = {
            RouteFeature,
            DumbRouter,
            SmartRouter,
            apiClient,
            featureAdapter,
            FeatureRouter,
            distanceCalculator,
            lineStringSampler,
            smartRouteRequestParamBuilder,
            smartRouterDAO,
            featureExporter,
            ol: require('ol'),
            Geometry: require('ol/geom'),
            Feature: require('ol/Feature').default,
        };
    }
    render() {
        return (
            <div page="test-sandbox"></div>
        );
    }
}

TestSandbox = withDependencies({
    smartRouteRequestParamBuilder: SmartRouteRequestParamBuilder,
    lineStringSampler: LineStringSampler,
    apiClient: WanderLostApiClient,
    featureAdapter: FeatureAdapter,
    distanceCalculator: DistanceCalculator,
    smartRouterDAO: SmartRouterDAO,
    featureExporter: FeatureExporter,
})(TestSandbox);
