import { once } from '@wanderlost-sdk/core/dist/async';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';
import { State } from '@symbiotic/green-state';
import { TripDetailsStateFactory } from './TripDetailsState';
import moment from 'moment';
import { daysBetween } from '@wanderlost-sdk/core';

export class TripsStateConfig{}

export class TripsState extends State {
    static inject = [TripsStateConfig, TripDetailsStateFactory, WanderLostApiClient];

    constructor({ stockTripCoverPhotos }, tripDetailsStateFactory, apiClient) {
        super({ trips: null, upcomingTrips: null, pastTrips: null, featuredTrips: null });

        const sortSoonestToLatest = (l , r) => {
            return moment(l.startDate, 'YYYY-MM-DD').valueOf() - moment(r.startDate, 'YYYY-MM-DD').valueOf();
        };

        const getToday = () => moment().format('YYYY-MM-DD');

        const isFeatured = trip => trip.isFeatured;
        const isMyUpcoming = trip => !trip.isFeatured && trip.endDate >= getToday();
        const isMyPast = trip => !trip.isFeatured && trip.endDate < getToday();

        this.WEBAPP = true;

        const updateTrips = trips => {
            this.setState({
                trips,
                upcomingTrips: trips.filter(isMyUpcoming).sort(sortSoonestToLatest),
                pastTrips: trips.filter(isMyPast).sort(sortSoonestToLatest).reverse(),
                featuredTrips: trips.filter(isFeatured).sort(sortSoonestToLatest)
            })
        }

        const refreshTrip = async trip => {
            // It's possible the trip list hasn't loaded yet, in which case we don't
            // need to do anything, as it will get the updated trip when it loads.
            const { trips } = this.get();
            if (trips) {
                const freshTrip = await apiClient.get(trip, 'application/wander-lost.trip+json');
                updateTrips(trips.filter(t => t.tripId !== trip.tripId).concat(decorateTrip(freshTrip)))
            }
        };

        // Only need to support 1 callback
        let onTripDatesChange = () => {};
        this.onTripDatesChange = callback => {
            onTripDatesChange = callback;
        };

        const superDispose = this.dispose;
        this.dispose = () => {
            superDispose();
            onTripDatesChange = () => {};
        };

        const getById = tripId => this.get().trips.find(t => t.tripId === tripId);

        this.getById = getById;

        const decorateTrip = trip => {
            return {
                ...trip,
                durationInDays: daysBetween(trip.startDate, trip.endDate) + 1,
                returnTo: trip.isFeatured ? 'exploreTrips' : 'tripList',
                refresh : async () => await refreshTrip(trip),
                update : async updatedTrip => {
                    await apiClient.put(trip, updatedTrip, { 'content-type': 'application/wander-lost.trip+json' });
                    await refreshTrip(trip);
                    if (updatedTrip.startDate !== trip.startDate || updatedTrip.endDate !== trip.endDate) {
                        onTripDatesChange(getById(trip.tripId));
                    }
                },
                delete : async () => {
                    await apiClient.delete(trip);

                    // No need to remove the trip if the list hasn't been loaded yet
                    // There is a race condition here that was triggered by the tests where tripsState.subscribe
                    // Causes load to be called, but if you delete the trip fast enough then it tries to remove the trip but this.state.trips isnt set
                    // There is still a race condition that if the user...
                    // Loads the detail page (subscribe is called, causing a load of all trips)
                    // Deletes single trip from the API
                    // Deletes the trip via the web
                    // And we don't remove the trip from the list (cos the list isn't there yet)
                    // THEN their request to get all trips comes back and includes the deleted trip
                    // They'll see it, and click it and get a 404
                    // But it doesn't seem worth solving
                    const { trips } = this.get();
                    if (trips) {
                        updateTrips(trips.filter(t => t.tripId !== trip.tripId));
                    }
                }
            }
        }

        this.load = async () => {
            const entry = await apiClient.entry();
            const myTrips = await apiClient.get(entry.links.trips, 'application/wander-lost.trip+json');

            let featuredTrips = await apiClient.get(entry.links.featuredTrips, 'application/wander-lost.trip+json');

            featuredTrips = featuredTrips
                // if my trips are featured, don't duplicate them
                // this is particularly important b/c the trips in this list will have the wrong permissions IF the trip has been shared with you or you own it
                // this is acceptable while real users can't own or have a featured trip shared with them, so it only impacts internal/admin users who manage featured trips
                // in the future, if we support real users sharing their trips as public, this would likely become confusing but things may change a lot at that point :)
                .filter(trip => !myTrips.find(t => t.tripId === trip.tripId))

            const allTrips = [ ...myTrips, ...featuredTrips ];
            updateTrips(allTrips.map(decorateTrip))
        };

        let loadOnce = once(this.load);

        const superSubscribe = this.subscribe;
        this.subscribe = sub => {
            loadOnce()
                .then(() => superSubscribe(sub));
            return () => {
                // this is supposed to be an unsubscribe but that isn't trivial to implement in this case
                // and won't ever get hit because dispose will take care of it
            }
        }

        function getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }

        const getRandomCoverPhotoURL = () => {
            return stockTripCoverPhotos[getRandomInt(stockTripCoverPhotos.length)];
        }

        this.createTrip = async (trip) => {
            const entry = await apiClient.entry();
            trip = {
                ...trip,
                coverPhotoURL : trip.coverPhotoURL || getRandomCoverPhotoURL(),
            }
            const { tripId } = await apiClient.post(entry.links.trips, trip);
            await this.load();

            return { tripId };
        };

        this.copyTrip = async ({ copyFromTripId, name, startDate }) => {
            const entry = await apiClient.entry();

            const { tripId } = await apiClient.post(entry.links.trips, { copyFromTripId, startDate, name, coverPhotoURL: getRandomCoverPhotoURL() });
            await this.load();

            return { tripId };
        };

        this.getTripDetailsState = async tripId => {
            const entry = await apiClient.entry();
            const rawTrip = await apiClient.get(entry.links.tripDetails, 'application/wander-lost.trip+json', { tripId });
            const trip = decorateTrip(rawTrip);

            // TODO: Do we have a bug with isFeatured being only set via the list?
            // If we have already fetched the trip list, and this trip is NOT in it, then add it
            // Otherwise, the trip is already in the list or the list hasn't loaded yet and will be loaded when needed
            // This code fixes the issue where
            // 1) You visit the home page (or the trip list), so we load trips to render the nav (or the trip list)
            // 2) A friend shares a trip with you and gives you the link
            // 3) You go directly to the trip detail via the url
            // 4) You go back to the trip list, and that trip is not in it since the list was cached
            const trips = this.get().trips;
            if (trips) {
                const tripInList = trips.find(t => t.tripId === trip.tripId);
                if (!tripInList) {
                    updateTrips(trips.concat(trip));
                }
            }

            const detailsState = tripDetailsStateFactory.create({ trip, tripsState: this });

            return detailsState;
        };
    }

}
