"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LayoutContainer = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = {
  root: {},
  // Leave so it can be overridden via classes prop
  rootCentered: {
    width: "calc(100% - 50px)",
    maxWidth: "1600px",
    margin: '0 auto'
  },
  rootFullWidth: {
    width: '100%'
  }
};

var LayoutContainer = function LayoutContainer(_ref) {
  var _ref2;

  var classes = _ref.classes,
      children = _ref.children,
      _ref$fullWidth = _ref.fullWidth,
      fullWidth = _ref$fullWidth === void 0 ? false : _ref$fullWidth;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)((_ref2 = {}, _defineProperty(_ref2, classes.rootFullWidth, fullWidth), _defineProperty(_ref2, classes.rootCentered, !fullWidth), _ref2), classes.root)
  }, children);
};

exports.LayoutContainer = LayoutContainer;
LayoutContainer.propTypes = {
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired,
  fullWidth: _propTypes.default.bool
};
exports.LayoutContainer = LayoutContainer = (0, _core.withStyles)(styles)(LayoutContainer);