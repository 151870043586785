"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectedRoutePlugin = void 0;

var _EventBus = require("../EventBus");

var _SelectedFolderState = require("../state/SelectedFolderState");

var _state = require("../state");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/**
 * When a route is selected on the map, that route should be selected in the route listing in the drawer
 */


var SelectedRoutePlugin = function SelectedRoutePlugin(eventBus, routesState, selectedFolderState) {
  _classCallCheck(this, SelectedRoutePlugin);

  this.start = function () {
    eventBus.on('feature.selected', function (_ref) {
      var feature = _ref.feature;

      if (feature.get('type') === 'route') {
        var routeId = feature.get('folderId');
        var routeAsFolder = routesState.getByIdAsFolder(routeId);
        selectedFolderState.selectFolder({
          folder: routeAsFolder
        });
      }
    });
  }; // event bus disposes of subscriptions


  this.dispose = function () {};
};

exports.SelectedRoutePlugin = SelectedRoutePlugin;

_defineProperty(SelectedRoutePlugin, "inject", function () {
  return [_EventBus.EventBus, _state.TripRoutesState, _SelectedFolderState.SelectedFolderState];
});