"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureDetailsFull = void 0;

var _react = _interopRequireDefault(require("react"));

var _greenState = require("@symbiotic/green-state");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DrawerContent = require("./DrawerContent");

var _state = require("../state");

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

var _ButtonBase = _interopRequireDefault(require("@material-ui/core/ButtonBase"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _Link = _interopRequireDefault(require("@material-ui/icons/Link"));

var _reactNl2br = _interopRequireDefault(require("react-nl2br"));

var _Table = _interopRequireDefault(require("@material-ui/core/Table"));

var _TableBody = _interopRequireDefault(require("@material-ui/core/TableBody"));

var _TableCell = _interopRequireDefault(require("@material-ui/core/TableCell"));

var _TableRow = _interopRequireDefault(require("@material-ui/core/TableRow"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* globals window */


var shouldShowExtraFeatureAttributes = window.localStorage.getItem('shouldShowExtraFeatureAttributes') === '1';

var formatCoordinate = function formatCoordinate(coordinate) {
  return Number(coordinate.toFixed(5));
}; // Only extract lat/lng from points


var extractLatLng = function extractLatLng(feature) {
  if (!feature.geometry) {
    return null;
  }

  var _feature$geometry = feature.geometry,
      x = _feature$geometry.x,
      y = _feature$geometry.y;

  if (x && y) {
    return {
      lng: x,
      lat: y
    };
  }

  return null;
};

var FeatureDetailsFull = function FeatureDetailsFull(_ref) {
  var classes = _ref.classes;
  var featureDetailsStateInstance = (0, _greenState.useInstance)(_state.FeatureDetailsState);

  var _ref2 = (0, _greenState.useSubscription)(function () {
    return featureDetailsStateInstance;
  }) || {},
      featureDetails = _ref2.featureDetails,
      isLoading = _ref2.isLoading;

  if (!featureDetails || isLoading) {
    return _react.default.createElement(_DrawerContent.DrawerContent, {
      title: "Loading..."
    }, _react.default.createElement("span", null));
  }

  var _featureDetails$attri = featureDetails.attributes,
      name = _featureDetails$attri.name,
      description = _featureDetails$attri.description,
      subtitle = _featureDetails$attri.subtitle,
      url = _featureDetails$attri.url,
      extraFeatureAttributes = _objectWithoutProperties(_featureDetails$attri, ["name", "description", "subtitle", "url"]); // const isEditable = feature.attributes.isEditable === true && onEdit;


  var latLng = extractLatLng(featureDetails);
  var showContent = Boolean(url || description);
  var showFooter = Boolean(latLng);
  return _react.default.createElement(_DrawerContent.DrawerContent, null, _react.default.createElement("div", {
    className: classes.header
  }, _react.default.createElement(_Typography.default, {
    variant: "h5",
    gutterBottom: false
  }, name || 'Unnamed'), Boolean(subtitle) && _react.default.createElement(_Typography.default, {
    variant: "h6",
    className: classes.subtitle
  }, subtitle)), showContent && _react.default.createElement("div", {
    className: classes.content
  }, Boolean(url) && _react.default.createElement(_Typography.default, {
    paragraph: true,
    gutterBottom: true
  }, _react.default.createElement(_ButtonBase.default, {
    href: url,
    rel: "noopener noreferrer",
    target: "_blank",
    disableRipple: true,
    size: "small"
  }, _react.default.createElement(_Link.default, {
    style: {
      marginRight: '4px'
    }
  }), "Website")), Boolean(description) && _react.default.createElement(_Typography.default, {
    className: classes.description
  }, (0, _reactNl2br.default)(description))), featureDetails.renderers.map(function (renderer) {
    return _react.default.createElement("div", {
      "data-type": "feature-attribute",
      key: renderer.field
    }, renderer.render());
  }), showFooter && _react.default.createElement("div", {
    className: classes.footer
  }, Boolean(latLng) && _react.default.createElement(_Typography.default, {
    className: classes.coordinates
  }, formatCoordinate(latLng.lat), ", ", formatCoordinate(latLng.lng))), shouldShowExtraFeatureAttributes && _react.default.createElement("div", {
    style: {
      maxHeight: '300px',
      overflowY: 'auto'
    }
  }, _react.default.createElement(_Table.default, null, _react.default.createElement(_TableBody.default, null, Object.keys(extraFeatureAttributes).map(function (key) {
    var value = extraFeatureAttributes[key];
    return _react.default.createElement(_TableRow.default, {
      key: key,
      style: {
        height: 'auto'
      }
    }, _react.default.createElement(_TableCell.default, {
      style: {
        padding: '6px'
      },
      variant: "head",
      align: "right"
    }, key), _react.default.createElement(_TableCell.default, {
      style: {
        wordWrap: 'break-word',
        padding: '6px'
      }
    }, formatExtraFeatureAttributeValue(value)));
  })))));
};

exports.FeatureDetailsFull = FeatureDetailsFull;
FeatureDetailsFull.propTypes = {
  classes: _propTypes.default.object.isRequired
};
exports.FeatureDetailsFull = FeatureDetailsFull = (0, _withStyles.default)(function (theme) {
  return {
    content: {
      margin: "".concat(theme.spacing(1), "px 0")
    },
    description: {
      lineHeight: 1.2
    },
    header: {
      marginBottom: "".concat(theme.spacing(1), "px")
    },
    subtitle: {
      color: theme.palette.grey[500],
      display: 'flex',
      alignItems: 'center'
    },
    coordinates: {
      color: theme.palette.grey[500],
      fontSize: '0.9rem',
      fontWeight: 'bold'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: "".concat(theme.spacing(1), "px")
    }
  };
})(FeatureDetailsFull);

function formatExtraFeatureAttributeValue(value) {
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  return value;
}