"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MultiLineStringFeatureFormatter = void 0;

var _flatMap = _interopRequireDefault(require("lodash/fp/flatMap"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var MultiLineStringFeatureFormatter = function MultiLineStringFeatureFormatter() {
  _classCallCheck(this, MultiLineStringFeatureFormatter);

  /**
   * Transform an array of Features to an array of features where any features with geometry type MultiLineString have been split into separate features of geometry type LineString
   * Example: [MultiLineFeatureWithLinesABC, PointFeatureD, LineStringFeatureE] -> [LineStringFeatureA, LineStringFeatureB, LineStringFeatureC, PointFeatureD, LineStringFeatureE]
   * The main reason to do this is that it's simplest for editing tools to only have to deal with LineString features and not MultiLineString (for example ol-ext's split interaction doesnt support MultiLinestring, our glue code would be more complex to support it)
   */
  var multiLineStringsToLineStrings = (0, _flatMap.default)(function (feature) {
    return feature.getGeometry().getType() === 'MultiLineString' ? multiLineStringToLineStrings(feature) : feature;
  });
  /**
   * Transform a feature of geometry type MultiLineString to an array of individual line string features
   * Example: MultiLineFeatureWithLinesABC -> [LineStringFeatureA, LineStringFeatureB, LineStringFeatureC]
   * Note: All of the original feature's properties will be copied to the resulting features
   */

  var multiLineStringToLineStrings = function multiLineStringToLineStrings(feature) {
    return feature.getGeometry().getLineStrings().map(function (geom) {
      var newFeature = feature.clone();
      newFeature.setGeometry(geom);
      return newFeature;
    });
  };

  this.formatFeatures = function (features) {
    var acceptedFeatures = multiLineStringsToLineStrings(features);
    return {
      acceptedFeatures: acceptedFeatures,
      rejectedFeatures: []
    };
  };
};

exports.MultiLineStringFeatureFormatter = MultiLineStringFeatureFormatter;