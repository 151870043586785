"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripDates = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _typography = require("../typography");

var _core = require("@wanderlost-sdk/core");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var TripDates = function TripDates(_ref) {
  var trip = _ref.trip,
      className = _ref.className,
      other = _objectWithoutProperties(_ref, ["trip", "className"]);

  return _react.default.createElement(_typography.P, _extends({
    color: "textSecondary",
    className: className
  }, other), (0, _core.formatDateRange)(trip));
};

exports.TripDates = TripDates;
TripDates.propTypes = {
  trip: _propTypes.default.object.isRequired,
  className: _propTypes.default.string
};