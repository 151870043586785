import React from 'react';
import { TripPackingListsFilter } from './TripPackingListsFilter';
import { PackingListForm } from './PackingListForm';

export const TripPackingListForm = ({ ...rest }) => {
    return (
        <>
            <div style={{ float: 'left' }}>
                <TripPackingListsFilter {...rest} />
            </div>
            <PackingListForm {...rest} />
        </>
    );
};
