import React from 'react';
import { InjectAndSubscribe } from '@symbiotic/green-state';
import { Grid } from '@material-ui/core';
import { H2 } from '@wanderlost-sdk/components';
import { Quote } from '../components';
import { TripsState } from './TripsState';
import { TripsList } from './TripsList';

export const FeaturedTripsList = () => (
    <InjectAndSubscribe diKey={TripsState}>
        {({ featuredTrips }) =>
            <>
                <H2>Featured Trips</H2>
                <Grid container spacing={3}>
                    <Grid item sm={9}>
                        <TripsList trips={featuredTrips} />
                    </Grid>
                    <Grid item sm={3}>
                        <Quote
                            content="In every walk with nature one receives far more than he seeks."
                            attribution="John Muir"
                        />
                    </Grid>
                </Grid>
            </>
        }
    </InjectAndSubscribe>
);
