import { HTTPClient } from '@wanderlost-sdk/api-client/dist/wanderlost-api-client/HTTPClient';
import { XRayService } from './XRayService';

export class XRayServiceToHttpClientBridge {
    static inject = () => [HTTPClient, XRayService];
    constructor(httpClient, xrayService) {
        this.start = () => {
            httpClient.onRequesting(async ({ url, method, headers, requestPromise }) => {
                const segment = xrayService.createHttpSegment({ url, method });
                const [headerName, headerValue] = segment.getTraceHeader();
                headers[headerName] = headerValue;
                const { status } = await requestPromise;

                segment.finally({ status });
            });

            // start segment for initial page load
            xrayService.createSegment();

            // start segment for route change page loads
            // this allows us to observe even when no http requests are required
            window.addEventListener('hashchange', () => {
                xrayService.createSegment();
            });
        }

    }
}
