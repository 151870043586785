import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { P, Dialog, Button } from '@wanderlost-sdk/components';
import withStyles from '@material-ui/core/styles/withStyles';
import { GroupingForm } from './GroupingForm';

const styles = () => ({
    dialogBody: { width: '600px' }
});

export let GroupingDialog = ({ name, label, pluralLabel, defaultNames = [], buttonLabel, buttonProps = {}, onDone, classes }) => {

    const [isOpen, setOpen] = useState(false);
    const [items, setItems] = useState(defaultNames.map(name => ({ name })).concat({ name: '', autoFocus: true }));

    const addItem = () => setItems([ ...items.map(item => ({ ...item, autoFocus: false })), { name: '', autoFocus: true } ]);
    const removeItem = (index) => setItems(items.filter((_, i) => i !== index));
    const updateItem = (index, updates) => setItems(items.map((item, i) => (i === index ? { ...item, ...updates } : item)));
    const setAutoFocus = (index) => setItems(items.map((item, i) => ({ ...item, autoFocus: (i === index) })));

    const dedupe = names => Object.values(names.reduce((map, name) => {
        map[name.trim().toLowerCase()] = name.trim();
        return map;
    }, {}));

    return (
        <>
            <Button size="large" variant="outlined" name={name} {...buttonProps} onClick={() => setOpen(true)}>{buttonLabel}</Button>
            <Dialog
                name="grouping-dialog"
                isOpen={isOpen}
                title={`Define ${pluralLabel} for this list`}
                closeButtonProps={{ name: 'close-add-existing-list-button' }}
                actionsComponent={
                    <>
                        <Button
                            onClick={() => setOpen(false)}
                            size="large"
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => onDone(dedupe(items.map(i => i.name).filter(n => Boolean(n))))}
                            name="done"
                            size="large"
                            color="primary"
                            variant="contained"
                        >
                            Use these {pluralLabel}
                        </Button>
                    </>
                }
            >
                <div className={classes.dialogBody}>
                    <P>
                        Enter the {pluralLabel.toLowerCase()} you want to start with below.
                        This will create sections in your list. We've pre-loaded the list with some that you might want to use.
                        You can delete these, and/or add your own.
                    </P>
                    <GroupingForm
                        items={items}
                        label={label}
                        addItem={addItem}
                        removeItem={removeItem}
                        updateItem={updateItem}
                        setAutoFocus={setAutoFocus}
                    />
                </div>
            </Dialog>
        </>
    );
};

GroupingDialog.propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    buttonLabel: PropTypes.string,
};

GroupingDialog = withStyles(styles)(GroupingDialog);
