"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _toConsumableArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeaderNav = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _greenState = require("@symbiotic/green-state");

var _WanderLostURLBuilder = require("../urls/WanderLostURLBuilder");

var _core = require("@material-ui/core");

var _typography = require("../typography");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var HeaderNav = function HeaderNav(_ref) {
  var name = _ref.name,
      navItems = _ref.navItems,
      classes = _ref.classes;

  // the built-in matching algorithm doesn't consider query params, so we use this custom
  // implementation, which does an exact match on path, and optionally an exact match on
  // query params (we sort nav items so a match on query params takes precedence)
  var findMatchingNavItem = function findMatchingNavItem(location) {
    var sorted = _toConsumableArray(navItems);

    sorted.sort(function (n1, n2) {
      return Boolean(n2.matchParams) - Boolean(n1.matchParams);
    });
    return sorted.find(function (_ref2) {
      var route = _ref2.route,
          routeParams = _ref2.routeParams,
          matchParams = _ref2.matchParams;
      var path = urlBuilder[route](routeParams).path();

      var _path$split = path.split('?'),
          _path$split2 = _slicedToArray(_path$split, 2),
          pathname = _path$split2[0],
          search = _path$split2[1];

      if (pathname !== location.pathname) {
        return false;
      }

      if (matchParams) {
        var searchParams = new URLSearchParams(search);
        searchParams.sort();
        var locationParams = new URLSearchParams(location.search);
        locationParams.sort();

        if (locationParams.toString() !== searchParams.toString()) {
          return false;
        }
      }

      return true;
    });
  };

  var urlBuilder = (0, _greenState.useInstance)(_WanderLostURLBuilder.WanderLostURLBuilder);
  return _react.default.createElement("nav", {
    name: name,
    className: classes.nav
  }, navItems.map(function (navItem) {
    var label = navItem.label,
        route = navItem.route,
        routeParams = navItem.routeParams,
        navtarget = navItem.navtarget;
    return _react.default.createElement(_typography.P, {
      key: label,
      className: classes.navItemLabel,
      gutterBottom: false
    }, _react.default.createElement(_reactRouterDom.NavLink, {
      to: urlBuilder[route](routeParams).path(),
      className: classes.navItem,
      activeClassName: classes.navItemCurrent,
      navtarget: navtarget,
      isActive: function isActive(_, location) {
        return navItem === findMatchingNavItem(location);
      }
    }, label));
  }));
};

exports.HeaderNav = HeaderNav;
HeaderNav.propTypes = {
  name: _propTypes.default.string.isRequired,
  navItems: _propTypes.default.arrayOf(_propTypes.default.shape({
    label: _propTypes.default.string.isRequired,
    route: _propTypes.default.string.isRequired,
    routeParams: _propTypes.default.object,
    navtarget: _propTypes.default.node.isRequired
  })).isRequired,
  classes: _propTypes.default.object.isRequired
};
exports.HeaderNav = HeaderNav = (0, _core.withStyles)(function (theme) {
  var _ref3;

  return _ref3 = {
    nav: {
      display: 'flex',
      alignItems: 'stretch',
      marginLeft: theme.spacing(2)
    },
    navItem: {
      width: '100%',
      textAlign: 'left',
      textDecoration: 'none',
      textTransform: 'uppercase',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
      display: 'block',
      '&:link, &:visited, &:hover, &:focus, &:active': {
        color: '#D7D7D7'
      }
    },
    navItemCurrent: {
      '&:link, &:visited, &:hover, &:focus, &:active': {
        color: '#56C5AC'
      }
    },
    // Hide all nav items when printing (except the current item, which is overridden below)
    '@media print': {
      navItem: {
        display: 'none',
        textShadow: 'none!important'
      }
    },
    // Only apply hover styles to browser that really support them (i.e. NOT iOS which will stickily apply hover styles when you click on elements)
    // This also fixes the issue of clicking on an element in a diferent screen (trip card) and having that cause the hover state of a nav item in the same place from getting activated
    '@media (hover: hover)': {
      navItem: {
        '&:hover': {
          color: "#5BAC9A"
        }
      }
    }
  }, _defineProperty(_ref3, "@media (min-width: ".concat(theme.breakpoints.values.md, "px)"), {
    nav: {
      marginLeft: 0
    },
    navItem: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  }), _defineProperty(_ref3, "navItemLabel", {
    fontWeight: 'bold'
  }), _ref3;
})(HeaderNav);