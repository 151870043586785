import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import memoize from 'lodash/fp/memoize';

// From https://material-ui.com/customization/css-in-js/
function createStyled(styles, options) {
    function Styled(props) {
        const { children, ...other } = props;
        return children(other);
    }
    Styled.propTypes = {
        children: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };
    return withStyles(styles, options)(Styled);
}

export class WithStyles extends Component {
    static propTypes = {
        styles: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
        children: PropTypes.any.isRequired
    }

    createStyledComponent = memoize(() => createStyled(this.props.styles))

    render() {
        const StyledComponent = this.createStyledComponent();
        const { children } = this.props;

        return (
            <StyledComponent>{children}</StyledComponent>
        );
    }
}
