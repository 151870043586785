"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipeTag = void 0;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RecipeTag = function RecipeTag(_ref) {
  var label = _ref.label,
      backgroundColor = _ref.color,
      onClick = _ref.onClick,
      classes = _ref.classes;
  return _react.default.createElement(_core.Chip, {
    onClick: onClick,
    className: classes.chip,
    style: {
      backgroundColor: backgroundColor
    },
    label: label,
    size: "small"
  });
};

exports.RecipeTag = RecipeTag;
RecipeTag.propTypes = {
  onClick: _propTypes.default.func,
  label: _propTypes.default.string.isRequired,
  color: _propTypes.default.string.isRequired,
  classes: _propTypes.default.object
};
exports.RecipeTag = RecipeTag = (0, _core.withStyles)(function () {
  return {
    chip: {
      borderRadius: '4px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      height: '18px',
      color: 'white',
      marginRight: '8px'
    }
  };
})(RecipeTag);