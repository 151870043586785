"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompositeTrailStyler = void 0;

var _MVUMTrailStyler = require("./MVUMTrailStyler");

var _GlobalTrailStyler = require("./GlobalTrailStyler");

var _UserTrailStyler = require("./UserTrailStyler");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var CompositeTrailStyler = function CompositeTrailStyler() {
  for (var _len = arguments.length, stylers = new Array(_len), _key = 0; _key < _len; _key++) {
    stylers[_key] = arguments[_key];
  }

  _classCallCheck(this, CompositeTrailStyler);

  var getStyleKey = function getStyleKey(feature) {
    var folder = feature.get('getFolder') && feature.get('getFolder')();
    return folder && folder.styleKey ? folder.styleKey : 'USER';
  };

  var getStyler = function getStyler(feature) {
    var styleKey = getStyleKey(feature);
    return stylers.find(function (s) {
      return s.styleKey === styleKey;
    });
  };

  this.getUberSimplifiedStyle = function (feature) {
    var _getStyler;

    for (var _len2 = arguments.length, rest = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      rest[_key2 - 1] = arguments[_key2];
    }

    return (_getStyler = getStyler(feature)).getUberSimplifiedStyle.apply(_getStyler, [feature].concat(rest));
  };

  this.getSimplifiedStyle = function (feature) {
    var _getStyler2;

    for (var _len3 = arguments.length, rest = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      rest[_key3 - 1] = arguments[_key3];
    }

    return (_getStyler2 = getStyler(feature)).getSimplifiedStyle.apply(_getStyler2, [feature].concat(rest));
  };

  this.getDetailedStyle = function (feature) {
    var _getStyler3;

    for (var _len4 = arguments.length, rest = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
      rest[_key4 - 1] = arguments[_key4];
    }

    return (_getStyler3 = getStyler(feature)).getDetailedStyle.apply(_getStyler3, [feature].concat(rest));
  };

  this.getHighlightStyle = function (_ref) {
    var feature = _ref.feature,
        rest = _objectWithoutProperties(_ref, ["feature"]);

    return getStyler(feature).getHighlightStyle(_objectSpread({
      feature: feature
    }, rest));
  };
};

exports.CompositeTrailStyler = CompositeTrailStyler;

_defineProperty(CompositeTrailStyler, "inject", function () {
  return [_MVUMTrailStyler.MVUMTrailStyler, _GlobalTrailStyler.GlobalTrailStyler, _UserTrailStyler.UserTrailStyler];
});