import React, { Component } from 'react';
import { hasAction } from '@wanderlost-sdk/core';
import { InjectTripDetails } from '../InjectTripDetails';
import { TripPageHeading, TripBreadcrumbs, TripBreadcrumbItem } from '@wanderlost-sdk/components';
import { TripTeamList } from './TripTeamList';
import { Grid } from '@material-ui/core';
import { TripDetailsLayout } from '../TripDetailsLayout';

export class TripTeamPage extends Component {
    render() {
        return (
            <InjectTripDetails>
                {({ trip }) => {

                    const canEdit = hasAction(trip, 'self', 'put');

                    return (
                        <TripDetailsLayout
                            trip={trip}
                            page="trip-routes-page"
                            breadcrumbs={
                                <TripBreadcrumbs trip={trip}>
                                    <TripBreadcrumbItem>Team</TripBreadcrumbItem>
                                </TripBreadcrumbs>
                            }
                        >
                            <TripPageHeading>
                                Team
                            </TripPageHeading>

                            <Grid container spacing={3}>
                                <Grid item sm={9}>
                                    <TripTeamList trip={trip} readOnly={!canEdit} />
                                </Grid>
                                <Grid item sm={3}>
                                </Grid>
                            </Grid>
                        </TripDetailsLayout>
                    );
                }}
            </InjectTripDetails>
        );
    }
}
