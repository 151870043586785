"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouteColorPlugin = void 0;

var _EventBus = require("../EventBus");

var _state = require("../state");

var _StyleManager = require("../styles/StyleManager");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var RouteColorPlugin = function RouteColorPlugin(eventBus, routesState, styleManager) {
  _classCallCheck(this, RouteColorPlugin);

  this.start = function () {
    eventBus.on('route.colorChanged', function (_ref) {
      var routeId = _ref.routeId,
          color = _ref.color;
      routesState.onColorChanged({
        routeId: routeId,
        color: color
      });
      styleManager.forceUpdateStyles();
    });
  }; // event bus disposes of subscriptions


  this.dispose = function () {};
};

exports.RouteColorPlugin = RouteColorPlugin;

_defineProperty(RouteColorPlugin, "inject", function () {
  return [_EventBus.EventBus, _state.TripRoutesState, _StyleManager.StyleManager];
});