"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureLayersLoadingBridgePlugin = void 0;

var _state = require("./state");

var _EventBus = require("./EventBus");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FeatureLayersLoadingBridgePlugin = function FeatureLayersLoadingBridgePlugin(featuresLoadingState, eventBus) {
  _classCallCheck(this, FeatureLayersLoadingBridgePlugin);

  this.start = function () {
    eventBus.on('features.loading', function (props) {
      var layerId = props.layerId,
          olExtent = props.olExtent;
      featuresLoadingState.startLoading("features.".concat(JSON.stringify({
        layerId: layerId,
        olExtent: olExtent
      })));
    });
    eventBus.on('features.loaded', function (props) {
      var layerId = props.layerId,
          olExtent = props.olExtent;
      featuresLoadingState.endLoading("features.".concat(JSON.stringify({
        layerId: layerId,
        olExtent: olExtent
      })));
    });
  };

  this.dispose = function () {// do nothing... the only thing to dispose is event bus subscriptions and that is getting disposed for us...
  };
};

exports.FeatureLayersLoadingBridgePlugin = FeatureLayersLoadingBridgePlugin;

_defineProperty(FeatureLayersLoadingBridgePlugin, "inject", function () {
  return [_state.FeaturesLoadingState, _EventBus.EventBus];
});