"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var WanderLostApiClient_1 = require("./WanderLostApiClient");

var HTTPClient_1 = require("./HTTPClient");

var WanderLostAPIClientFactory = function WanderLostAPIClientFactory(httpClientConfig) {
  var _this = this;

  _classCallCheck(this, WanderLostAPIClientFactory);

  this.getHTTPClient = function (_ref) {
    var accessToken = _ref.accessToken;
    return new HTTPClient_1.HTTPClient(Object.assign(Object.assign({}, httpClientConfig), {
      accessToken: accessToken
    }));
  };

  this.getAPIClient = function (_ref2) {
    var accessToken = _ref2.accessToken;

    var httpClient = _this.getHTTPClient({
      accessToken: accessToken
    });

    return new WanderLostApiClient_1.WanderLostApiClient(httpClient);
  };
};

exports.WanderLostAPIClientFactory = WanderLostAPIClientFactory;
WanderLostAPIClientFactory.inject = [HTTPClient_1.HTTPClientConfig];