import { WanderLostPrincipal } from './users';
import { EventBus } from '@wanderlost-sdk/cartographer';
import { Theme } from './theme';
import { Log } from './debugging';

export class IntercomConfig {
    constructor({
        appId,
        coviewProjectKey,
        isDisabled
    }) {
        Object.assign(this, {
            appId,
            coviewProjectKey,
            isDisabled
        });
    }
}

export class Intercom {

    static inject = () => [IntercomConfig, WanderLostPrincipal, EventBus, Theme, Log];
    constructor({ appId: app_id, coviewProjectKey, isDisabled }, principal, events, theme, log) {

        this.setup = () => {
            if (isDisabled) {
                return;
            }
            try {
                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + app_id;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

                this.setupCoview({ projectKey: coviewProjectKey });

                window.Intercom('boot', {
                    app_id,
                    email: principal.email,
                    name: principal.name,
                    user_id: principal.userId,
                    alignment: 'right',
                    horizontal_padding: 0
                });

                // We need intercom to move left when the drawer opens, but their default launcher only supports static padding.
                // So we set horizontal_padding:0 by default when we initialize it above, then override this with css based on a
                // global class we set when the drawer opens or closes. We also set z-index to 1299, since our mui dialogs have
                // z-index 1300, and when intercom moves left, it's more likely to overlap (e.g. with the routing help dialog).
                events.on('activeTab:changed', async ({ activeTabId }) => {
                    if (activeTabId === '') {
                        document.body.classList.remove('drawer-open');
                    } else {
                        document.body.classList.add('drawer-open');
                    }
                }, true); // true means persist listener across cartographer unloads

                events.on('cartographer:unloaded', async () => {
                    document.body.classList.remove('drawer-open');
                }, true); // true means persist listener across cartographer unloads

                const drawerWidth = parseInt(theme.wanderlost.drawerWidth);

                document.head.insertAdjacentHTML('beforeend', `
                    <style>
                        body.drawer-open .intercom-lightweight-app {z-index: 1299}
                        body.drawer-open .intercom-launcher {right: ${drawerWidth + 20}px!important}
                        body.drawer-open .intercom-launcher-frame {right: ${drawerWidth + 20}px!important; z-index: 1299}
                        body.drawer-open .intercom-lightweight-app-messenger {right: ${drawerWidth + 20}px!important}
                        body.drawer-open .intercom-messenger-frame {right: ${drawerWidth + 20}px!important}
                    </style>
                `);

            } catch (e) {
                e.message = `Failed to initialize Intercom. ${e.message}`;
                log.error(e);
            }
        };

        this.setupCoview = ({ projectKey }) => {
            (function(c,o,v,i,e,w){c[v]=c[v]||function(){(c[v].a=c[v].a||[]).push(arguments)};var s=o.createElement(i);s.src=e;s.async=1;var h=o.getElementsByTagName(i)[0];h.parentNode.insertBefore(s,h);c.addEventListener('error',function(err){c[v]('error',err)});c.addEventListener('message',function(msg){c[v]('message',msg)})})(window,document,'coview','script','https://cdn.coview.com/coview.js');
            window.coview('start', { projectKey });
        };
    }
}
