import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, Paper, withStyles } from '@material-ui/core';
import { P } from '@wanderlost-sdk/components';

const styles = {
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        padding: '10px',
        maxWidth: '300px'
    },
    trigger: {
        cursor: 'pointer'
    }
};

export class ToolTip extends Component {
    state = { anchorEl: null }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
        content: PropTypes.node.isRequired,
    }

    handleOpen = e => {
        this.setState({ anchorEl: e.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { classes, content, children } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <>
                <span onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose} className={classes.trigger}>
                    {children}
                </span>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    hideBackdrop={true}
                    className={classes.popover}
                    onClose={this.handleClose}
                >
                    <Paper className={classes.popoverContent}>
                        <P gutterBottom={false}>{content}</P>
                    </Paper>
                </Popover>
            </>
        );
    }

}

ToolTip = withStyles(styles)(ToolTip);
