import React from 'react';
import PropTypes from 'prop-types';
import { Button, SectionV2 } from '@wanderlost-sdk/components';
import { useInstance, useSubscription } from '@symbiotic/green-state';
import { RouteCard } from './RouteCard';
import { TripRoutesState, GPXRouteImport } from '@wanderlost-sdk/cartographer';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    routeList: {
        minWidth: '300px',
        paddingTop: 0,
    },
    route: {
        padding: `${theme.spacing(1)}px 0`
    },
    icon: {
        margin: 0
    },
    import: {
        width: 250,
        marginLeft: 'auto'
    }
});

export let DailyTripRoutesList = ({ dayNumber, classes, trip: { tripId }, canEdit = false }) => {
    const tripRoutesStateInstance = useInstance(TripRoutesState);
    const tripRoutesState = useSubscription(() => {
        tripRoutesStateInstance.ensureLoaded();
        return tripRoutesStateInstance;
    });

    if (!tripRoutesState) {
        return null;
    }

    const routes = tripRoutesState.getByDayNumber(dayNumber);

    return (
        <SectionV2
            title="Routes"
            actions={
                <>
                    <Button to="tripMap" routeParams={{ tripId }} variant="outlined" size="small" name="manage-routes-button">Go to Map</Button>
                    {canEdit &&
                        <Button to="tripMap" routeParams={{ tripId, addRouteToDayNumber: dayNumber }} color="primary" variant="contained" size="small" name="add-route-button" style={{ marginLeft: '8px' }}>Add a Route</Button>
                    }
                </>
            }
            iconImageURL={'/images/section-icons/routes.svg'}
        >
            <Grid container spacing={5} className={classes.container} name="route-list" style={{ marginBottom: 0 /** grid normally has -12 margin on the bottom but removes spacing b/w sections */ }}>
                <Grid item xs={12} md={7} lg={8}>
                    {routes.map(route => (
                        <RouteCard key={route.routeId} route={route} tripId={tripId} />
                    ))}
                </Grid>
                <Grid item xs={1} md={5} lg={4} key="import">
                    {canEdit &&
                        <div className={classes.import}>
                            <GPXRouteImport
                                onSuccess={() => {
                                    window.location.hash = `/trips/${tripId}/map`;
                                }}
                            />
                        </div>
                    }
                </Grid>
            </Grid>
        </SectionV2>
    );
};

DailyTripRoutesList.propTypes = {
    dayNumber: PropTypes.number.isRequired,
    trip: PropTypes.object.isRequired,
    canEdit: PropTypes.bool,
    classes: PropTypes.object.isRequired,
}

DailyTripRoutesList = withStyles(styles)(DailyTripRoutesList);
