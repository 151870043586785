"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthProvider = void 0;

var AuthProvider = function AuthProvider() {
  _classCallCheck(this, AuthProvider);
};

exports.AuthProvider = AuthProvider;