"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripBanner = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

var _core2 = require("@wanderlost-sdk/core");

var _Banner = require("./Banner");

var _ = require("../");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = {
  tripMetadata: {
    '& $tripDate, & $tripDateStats': {
      fontSize: 18,
      marginBottom: 0
    }
  },
  tripDate: {},
  tripDateStats: {},
  content: {}
};

var TripBanner = function TripBanner(_ref) {
  var trip = _ref.trip,
      classes = _ref.classes,
      _ref$noActions = _ref.noActions,
      noActions = _ref$noActions === void 0 ? false : _ref$noActions,
      _ref$noNav = _ref.noNav,
      noNav = _ref$noNav === void 0 ? false : _ref$noNav,
      rest = _objectWithoutProperties(_ref, ["trip", "classes", "noActions", "noNav"]);

  var showEdit = (0, _core2.hasAction)(trip, 'self', 'put');
  var showCopy = (0, _core2.hasAction)(trip, 'self', 'copy');
  return _react.default.createElement(_Banner.Banner, _extends({
    title: trip.name,
    classes: {
      title: classes.title
    },
    shouldPrint: true,
    renderContent: function renderContent() {
      return _react.default.createElement("div", {
        className: classes.tripMetadata,
        id: "trip-metadata"
      }, _react.default.createElement(_.TripDates, {
        trip: trip,
        className: (0, _classnames.default)(classes.tripDate),
        color: "inherit"
      }), _react.default.createElement(_.TripDateStats, {
        trip: trip,
        className: (0, _classnames.default)(classes.tripDateStats),
        color: "inherit",
        noIcon: true
      }), trip.routes && _react.default.createElement(_.TripDistance, {
        trip: trip,
        color: "inherit",
        style: {
          fontWeight: 'bold'
        }
      }));
    },
    imageURL: trip.coverPhotoURL,
    nav: !noNav && _react.default.createElement(_.TripNav, {
      trip: trip
    }),
    actions: !noActions && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_.PrintTripButton, {
      trip: trip
    }), showCopy && _react.default.createElement(_.CopyTripButton, {
      trip: trip
    }), showEdit && _react.default.createElement(_.EditTripButton, {
      trip: trip
    }))
  }, rest));
};

exports.TripBanner = TripBanner;
exports.TripBanner = TripBanner = (0, _core.withStyles)(styles)(TripBanner);
TripBanner.propTypes = {
  trip: _propTypes.default.object.isRequired,
  noNav: _propTypes.default.bool,
  noActions: _propTypes.default.bool,
  // classes should be required, and is always provided by withStyles, but something related
  // to how we're wrapping this in Layout is causing react to complain that it's undefined
  classes: _propTypes.default.object // .isRequired

};