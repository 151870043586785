"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var FormData = !global && window.FormData || require('form-data');

exports.buildHeaders = function (formData) {
  var headers = {};

  if (formData.getHeaders) {
    var _formData$getHeaders = formData.getHeaders(),
        contentType = _formData$getHeaders['content-type'];

    if (contentType) {
      headers['Content-Type'] = contentType;
    }
  }

  return headers;
};

function createFormData(object) {
  var formData = new FormData();
  Object.keys(object).forEach(function (key) {
    if (object[key] instanceof Array) {
      return formData.append(key, JSON.stringify(object[key]));
    }

    return formData.append(key, object[key]);
  });
  return formData;
}

exports.createFormData = createFormData;