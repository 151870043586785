"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipesPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _components = require("../components");

var _InjectRecipesStateAndSyncQueryString = require("./InjectRecipesStateAndSyncQueryString");

var _RecipesExplorer = require("./RecipesExplorer");

var _RecipesLayout = require("./RecipesLayout");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var RecipesPage = function RecipesPage() {
  return _react.default.createElement(_InjectRecipesStateAndSyncQueryString.InjectRecipesStateAndSyncQueryString, null, function (state) {
    return _react.default.createElement(_RecipesLayout.RecipesLayout, {
      page: "recipes-page",
      noPadding: true
    }, _react.default.createElement(_RecipesExplorer.RecipesExplorer, _extends({}, state, {
      breadcrumbs: _react.default.createElement(_components.Breadcrumbs, null, _react.default.createElement(_components.BreadcrumbItem, {
        to: "recipesHome"
      }, "Recipes"), _react.default.createElement(_components.BreadcrumbItem, null, "All Recipes"))
    })));
  });
};

exports.RecipesPage = RecipesPage;