"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateField = exports.DateProvider = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _device = require("../../../framework/device");

var _TextField = _interopRequireDefault(require("@material-ui/core/TextField"));

var _pickers = require("@material-ui/pickers");

var _moment = _interopRequireDefault(require("@material-ui/pickers/adapter/moment"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var DateProvider = function DateProvider(_ref) {
  var children = _ref.children;
  return _react.default.createElement(_pickers.LocalizationProvider, {
    dateAdapter: _moment.default
  }, children);
};

exports.DateProvider = DateProvider;
DateProvider.propTypes = {
  children: _propTypes.default.any
};

var DateField = function DateField(_ref2) {
  var value = _ref2.value,
      _onChange = _ref2.onChange,
      rest = _objectWithoutProperties(_ref2, ["value", "onChange"]);

  if (!(0, _device.isHTML5DateSupported)()) {
    return _react.default.createElement(_pickers.DatePicker, {
      renderInput: function renderInput(props) {
        return _react.default.createElement(_TextField.default, _extends({}, props, {
          InputLabelProps: {
            shrink: true
          },
          fullWidth: true,
          margin: "dense",
          helperText: "",
          error: false
        }, rest));
      },
      value: value,
      onChange: function onChange(date) {
        return _onChange({
          target: {
            value: date ? date.format('YYYY-MM-DD') : ''
          }
        });
      }
    });
  }

  return _react.default.createElement(_TextField.default, _extends({
    type: "date",
    InputLabelProps: {
      shrink: true
    },
    inputProps: {
      min: '1970-01-01',
      max: '3000-01-01'
    },
    fullWidth: true,
    margin: "dense",
    value: value,
    onChange: _onChange
  }, rest));
};

exports.DateField = DateField;
DateField.propTypes = {
  value: _propTypes.default.string,
  onChange: _propTypes.default.func
};