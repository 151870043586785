"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FavoriteButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _Favorite = _interopRequireDefault(require("@material-ui/icons/Favorite"));

var _FavoriteBorder = _interopRequireDefault(require("@material-ui/icons/FavoriteBorder"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var FavoriteButton = function FavoriteButton(_ref) {
  var _ref$selected = _ref.selected,
      selected = _ref$selected === void 0 ? false : _ref$selected,
      _onClick = _ref.onClick,
      classes = _ref.classes;
  return _react.default.createElement(_core.IconButton, {
    className: classes.button,
    onClick: function onClick() {
      return _onClick();
    },
    name: "favorite-button",
    title: selected ? 'Remove from Favorites' : 'Add to Favorites'
  }, selected && _react.default.createElement(_Favorite.default, {
    className: classes.icon
  }), !selected && _react.default.createElement(_FavoriteBorder.default, {
    className: classes.icon
  }));
};

exports.FavoriteButton = FavoriteButton;
FavoriteButton.propTypes = {
  selected: _propTypes.default.bool,
  onClick: _propTypes.default.func.isRequired,
  classes: _propTypes.default.object.isRequired
};
exports.FavoriteButton = FavoriteButton = (0, _core.withStyles)(function (theme) {
  return {
    button: {
      backgroundColor: 'rgba(235, 235, 235, 0.8)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
      }
    },
    icon: {
      width: '100%',
      height: '100%',
      color: theme.palette.primary.main
    }
  };
})(FavoriteButton);