import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PackingListItemForm } from "./PackingListItemForm";
import { NavigationPrompt, ErrorMessage, P, H2, SMALL, Button, ColumnHeading, Display } from '@wanderlost-sdk/components';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { dedupeArray } from '@wanderlost-sdk/core';
import { PackingListTotalWeight } from './PackingListTotalWeight'
import { AddExistingListDialog } from './AddExistingListDialog';
import { GroupingDialog } from './GroupingDialog';
import { PackingListContextMenu } from './PackingListContextMenu';
import { GroupNameField } from './GroupNameField';
import { DropHandler } from '@wanderlost-sdk/cartographer/dist/drag-and-drop';
import { PackingList } from './PackingList';
import { ListConfigurator } from './ListConfigurator';
import SettingsIcon from '@material-ui/icons/Settings';
import cn from 'classnames';

// If we find we are changing these frequently and the redeploy is a time sync consider alternatives
const defaultCategories = [ 'Campsite', 'Kitchen', 'Tools', 'Vehicle', 'Personal', 'First Aid' ];
const defaultLocations = [ 'Trunk', 'Roof', 'Back Seat', 'Trailer' ];

const styles = theme => ({
    container: { padding: `${theme.spacing(2)}px 0` },
    headingRow: { marginBottom: theme.spacing(1) },
    deleteColumn: { width: '35px', paddingLeft: '8px', paddingRight: '8px' },
    qtyColumn: { textAlign: 'center' },
    weightColumn: { textAlign: 'center' },
    sharedColumn: { textAlign: 'center' },
    visibleFieldsTip: {
        fontSize: 18,
        position: 'absolute',
        top: 8,
        right: 40,
    },
    groupItems: {
        minHeight: '72px', // at least big enough for one item, ideally we'd prevent deleting the last item and wouldn't need this
        border: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '12px 0 18px',
        backgroundColor: '#fff',
        position: 'relative',
    },
    groupItemsDropHighlight: {
        borderColor: theme.palette.primary.main
    },
    actions: {
        /* Start: Trip-specific styles b/c of the filter */
        float: 'right',
        /* End: Trip-specific styles b/c of the filter */
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(6),
        '& > *': {
            marginRight: theme.spacing(1),
        },
        '& > *:last-child': {
            marginRight: 0
        }
    },
    saveContainer: {
        position: 'relative',
    },
    saveStatus: {
        opacity: 0.7,
        fontSize: 12,
        textAlign: 'center',
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        paddingTop: theme.spacing(0.5),
    },
    error: {
        textAlign: 'right',
        maxWidth: 250,
        fontSize: 12,
        lineHeight: 1.3,
    },
    emptyListButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& button': {
            marginBottom: '20px'
        },
    },
    contextMenuButton: {
        padding: 0
    }
});

export class PackingListForm extends Component {
    static propTypes = {
        isEmpty: PropTypes.bool.isRequired,
        clearEmpty: PropTypes.func.isRequired,
        status: PropTypes.string,
        classes: PropTypes.object.isRequired,
        packingList: PropTypes.object.isRequired,
        getMatchingItems: PropTypes.func.isRequired,
        isDirty: PropTypes.bool.isRequired,
        setDirty: PropTypes.func.isRequired,
        focusNext: PropTypes.func.isRequired,
        error: PropTypes.object,
        queueAutoSave: PropTypes.func.isRequired,
        updateItem: PropTypes.func.isRequired,
        copyList: PropTypes.func.isRequired,
    }

    handleSuggestedItemSelected = (item) => {
        // make sure we only attempt to update attributes that are stored in form state,
        // also we don't update name because the AutoComplete already took care of that
        const { itemId, qty, category = '', notes = '', location = '', weight, isShared = false } = item;
        const patchedItem = { itemId, qty, category, notes, location, weight, isShared };

        // if the packing list view is in group by, then do NOT use the auto complete value
        // put it in the category / location the user chose
        if (this.props.packingList.groupBy){
            delete patchedItem[this.props.packingList.groupBy];
        }

        this.props.updateItem(patchedItem);
    };

    handleExitLastRow = ({ values, e }) => {
        // If the row is totally empty, don't create a new one on enter
        if (!values.name && !values.category && !values.notes && !values.location && !values.weight) {
            return;
        }

        // Otherwise add a new to the current category (which will also end up getting focused)
        e.preventDefault();
        this.props.packingList.addItem();
    }

    getSuggestions = ({ search, propName, currentItem }) => {
        // for name field, return a sorted array of items from prior trips, where the item name contains the search string
        // (since these are objects, they must have a "label" attribute to tell AutoComplete what to display)
        if (propName === 'name') {
            return this.props.getMatchingItems(search).map(item => ({ ...item, label: item.name }));
        }

        // otherwise (for category/location), return a sorted array of unique values that start with the search string,
        // from the specified property on the current packing list, including all saved values, and all unsaved values
        // in form state except for the item being edited
        const items = this.props.packingList.items;
        const savedValues = items.map(i => i[propName]);
        const otherUnsavedValues = items.filter(i => i.itemId !== currentItem.itemId).map(i => i.formState.state.values[propName]);
        return dedupeArray(savedValues.concat(otherUnsavedValues).filter(l => l && l.toLowerCase().startsWith(search.toLowerCase())).sort());
    }

    render() {
        const { classes, packingList: { getFields, groupBy, visibleFields, getGroups, addGroup, setGroupBy, setVisibleFields, packingListId, put, items, addItem }, sortBy, sortDirection, onSort, isDirty, status, setDirty, focusNext, error, queueAutoSave, copyList, isEmpty, clearEmpty } = this.props;

        const sortProps = { sortBy, sortDirection, onSort };

        const groups = getGroups();
        const fields = getFields();

        if (this.props.packingList.isPrintPreview) {
            return <PackingList packingList={this.props.packingList} print={true} />
        }

        return (
            <>
                <div className={classes.actions}>
                    <PackingListTotalWeight items={items} />
                    {!isEmpty && <PackingListContextMenu classes={{ button: classes.contextMenuButton }} packingList={this.props.packingList} visibleFields={visibleFields} setVisibleFields={setVisibleFields} />}
                    {!isEmpty && <ListConfigurator visibleFields={visibleFields} setVisibleFields={setVisibleFields} groupBy={groupBy} setGroupBy={setGroupBy} />}
                    {!isEmpty && <AddExistingListDialog handleCopyList={copyList} currentListId={packingListId} />}
                    {error &&
                        <ErrorMessage className={classes.error}>{error.displayMessage || `There appears to be something wrong, and it's probably on us. Please try again!`}</ErrorMessage>
                    }
                    {!isEmpty &&
                        <Display screen>
                            <div className={classes.saveContainer}>
                                <Button size="large" color="primary" variant="contained" name="save-packing-list" disabled={!isDirty || status === 'saving'} onClick={put}>Save</Button>
                                <P name="save-status" className={classes.saveStatus}>
                                    {
                                        status === 'saving' ?
                                            'Saving' :
                                            status === 'saved' ?
                                                'Saved' :
                                                isDirty ?
                                                    'Unsaved' :
                                                    'No changes'
                                    }
                                </P>
                            </div>
                        </Display>
                    }
                </div>

                {isEmpty &&
                    <div style={{ clear: 'both', display: 'flex', marginTop: '50px' }}>
                        <div style={{maxWidth: '500px', marginRight: '50px'}}>
                            <H2>Welcome!<br /><br />Choose one of these options, so we can help you get started <SMALL>(you can change it later!)</SMALL></H2>
                        </div>
                        <div className={classes.emptyListButtonContainer}>
                            <AddExistingListDialog name="start-from-existing-list" buttonLabel="Start from existing list" buttonProps={{ color: 'primary', variant: 'contained' }} handleCopyList={copyList} currentListId={packingListId} />
                            <GroupingDialog label="Location" pluralLabel="Packing Locations" buttonLabel="Based on where I'll pack things" name="use-locations" defaultNames={defaultLocations} onDone={(names) => setGroupBy('location', names)} />
                            <GroupingDialog label="Category" pluralLabel="Categories" buttonLabel="Based on my own categories" name="use-categories" defaultNames={defaultCategories} onDone={(names) => setGroupBy('category', names)} />
                            <Button variant="outlined" color="default" onClick={clearEmpty} name="dismiss-empty-list-wizard">Just let me enter items</Button>
                        </div>
                    </div>
                }

                {!isEmpty && !this.props.packingList.isPrintPreview &&
                    <>
                        <div className={classes.container} name="packing-list-form">
                            <div className={classes.headingRow}>
                                <Grid container wrap="nowrap" spacing={4} alignItems="center" data-type="column-headings">
                                    <Grid item className={classes.deleteColumn}>
                                        {/* Delete doesnt need a column header */}
                                    </Grid>
                                    {fields.name.isVisible &&
                                        <Grid item sm={2}>
                                            <ColumnHeading name="name" {...sortProps}>Item Name</ColumnHeading>
                                        </Grid>
                                    }
                                    {fields.category.isVisible &&
                                        <Grid item sm={2}>
                                            <ColumnHeading name="category" {...sortProps}>Category</ColumnHeading>
                                        </Grid>
                                    }
                                    {fields.notes.isVisible &&
                                        <Grid item sm={2} md={3} className={classes.notesColumn}>
                                            <ColumnHeading name="notes" {...sortProps}>Notes</ColumnHeading>
                                        </Grid>
                                    }
                                    {fields.location.isVisible &&
                                        <Grid item sm={2}>
                                            <ColumnHeading name="location" {...sortProps}>Packed Location</ColumnHeading>
                                        </Grid>
                                    }
                                    {fields.weight.isVisible &&
                                        <Grid item sm={2} className={classes.weightColumn}>
                                            <ColumnHeading name="weight" {...sortProps}>Weight</ColumnHeading>
                                        </Grid>
                                    }
                                    {fields.qty.isVisible &&
                                        <Grid item sm={1} className={classes.qtyColumn}>
                                            <ColumnHeading name="qty" {...sortProps}>Qty</ColumnHeading>
                                        </Grid>
                                    }
                                    {fields.isShared.isVisible &&
                                        <Grid item sm={1} className={classes.sharedColumn}>
                                            <ColumnHeading name="isShared" {...sortProps}>
                                                Shared <span style={{whiteSpace: 'nowrap'}}><small>w/ </small>Team</span>
                                            </ColumnHeading>
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                            {groups.map((group, groupIndex) => {
                                return (
                                    <DropHandler
                                        key={group.name || 'none'}
                                        canDrop={item => {
                                            return item.getField(groupBy) !== group.name;
                                        }}
                                        onDrop={droppedItem => {
                                            this.props.updateItem({
                                                ...droppedItem,
                                                [groupBy]: group.name
                                            });
                                        }}
                                    >
                                        {({ isOverCurrent, canDrop }) => (
                                            <div name="item-group" key={group.name || 'none'} data-grouping={group.name || ''}>
                                                {'name' in group &&
                                                    <GroupNameField
                                                        group={group}
                                                        groupBy={groupBy}
                                                    />
                                                }
                                                <div className={cn(classes.groupItems, { [classes.groupItemsDropHighlight]: isOverCurrent })}>
                                                    {groupIndex === 0 && visibleFields.length === 1 &&
                                                        <Display screen>
                                                            <P className={classes.visibleFieldsTip} color="textSecondary" gutterBottom={false}>
                                                                Want to {groupBy ? 'un-' : ''}group your items, or add info like weight or notes?<br />
                                                                Just click the <SettingsIcon style={{height: '20px', marginBottom: '-3px'}} /> icon in the header above.
                                                            </P>
                                                        </Display>
                                                    }
                                                    {group.items.map((item, index) => {
                                                        const isLast = index === items.length - 1;
                                                        return (
                                                            <PackingListItemForm
                                                                visibleFields={visibleFields}
                                                                item={item}
                                                                key={item.itemId}
                                                                handleSuggestedItemSelected={this.handleSuggestedItemSelected}
                                                                handleExitRow={isLast ? this.handleExitLastRow : undefined}
                                                                onDirtyChange={setDirty}
                                                                onFocusNext={group.focusNext ? group.focusNext : focusNext}
                                                                columnClasses={classes}
                                                                getSuggestions={this.getSuggestions}
                                                                queueAutoSave={queueAutoSave}
                                                                fields={fields}
                                                                canDrag={Boolean(groupBy)}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <Display screen>
                                                    <div style={{ marginTop: '-22px', textAlign: 'center' }}>
                                                        <Button onClick={group.addItem ? group.addItem : addItem} variant="fab" size="small" color="primary" style={{ marginRight: '2em', transform: 'scale(0.75)' }} name="add-item" tabIndex={-1}>
                                                            <AddIcon fontSize="small" />
                                                        </Button>
                                                    </div>
                                                </Display>
                                            </div>
                                        )}
                                    </DropHandler>
                                );
                            })}
                        </div>
                        {groupBy &&
                            <>
                                <Display screen>
                                    <Button onClick={addGroup} size="large" color="primary" variant="outlined" name="add-group" tabIndex={-1}>
                                        Add {groupBy === 'category' ? 'Category' : 'Location'}
                                    </Button>
                                </Display>
                            </>
                        }
                        <NavigationPrompt when={isDirty} />
                    </>
                }
            </>
        );
    }
}

PackingListForm = withStyles(styles)(PackingListForm);
