"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InjectLoadAndSubscribe = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var InjectLoadAndSubscribe = function InjectLoadAndSubscribe(_ref) {
  var children = _ref.children,
      diKey = _ref.diKey,
      _ref$methodName = _ref.methodName,
      methodName = _ref$methodName === void 0 ? 'load' : _ref$methodName;
  return _react.default.createElement(_greenState.Inject, {
    diKey: diKey
  }, function (instance) {
    return _react.default.createElement(_greenState.Subscribe, {
      to: function to() {
        instance[methodName]();
        return instance;
      }
    }, function (value) {
      return children(value);
    });
  });
};

exports.InjectLoadAndSubscribe = InjectLoadAndSubscribe;
InjectLoadAndSubscribe.propTypes = {
  children: _propTypes.default.func.isRequired,
  diKey: _propTypes.default.func.isRequired,
  methodName: _propTypes.default.string
};