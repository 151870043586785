"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnHeading = void 0;

var _react = _interopRequireDefault(require("react"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _TableSortLabel = _interopRequireDefault(require("@material-ui/core/TableSortLabel"));

var _typography = require("../../typography");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // eslint-disable-next-line react/prop-types


var ColumnHeading = function ColumnHeading(_ref) {
  var name = _ref.name,
      sortBy = _ref.sortBy,
      sortDirection = _ref.sortDirection,
      onSort = _ref.onSort,
      children = _ref.children,
      classes = _ref.classes;

  var heading = _react.default.createElement(_typography.P, {
    gutterBottom: false,
    color: "textSecondary",
    style: {
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    }
  }, children); // all these props are required for sorting to work


  if (!name || !sortBy || !sortDirection || !onSort) {
    return heading;
  }

  return _react.default.createElement(_TableSortLabel.default, {
    name: name,
    active: sortBy === name,
    direction: sortBy === name ? sortDirection : 'asc',
    onClick: function onClick() {
      return onSort(name);
    },
    classes: classes
  }, heading);
};

exports.ColumnHeading = ColumnHeading;
exports.ColumnHeading = ColumnHeading = (0, _withStyles.default)(function () {
  return {
    iconDirectionAsc: {
      position: 'absolute',
      left: '-18px',
      top: '4px',
      height: '12px'
    },
    iconDirectionDesc: {
      position: 'absolute',
      left: '-18px',
      top: '4px',
      height: '12px'
    }
  };
})(ColumnHeading);