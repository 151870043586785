"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddToMealButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _components = require("../components");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var AddToMealButton = function AddToMealButton(_ref) {
  var _onClick = _ref.onClick,
      classes = _ref.classes;
  return _react.default.createElement(_components.Button, {
    name: "add-to-meal",
    className: classes.button,
    color: "primary",
    variant: "contained",
    size: "large",
    onClick: function onClick(e) {
      e.stopPropagation();

      _onClick();
    }
  }, "Add to Meal Plan");
};

exports.AddToMealButton = AddToMealButton;
AddToMealButton.propTypes = {
  onClick: _propTypes.default.func.isRequired,
  classes: _propTypes.default.object.isRequired
};
exports.AddToMealButton = AddToMealButton = (0, _withStyles.default)(function () {
  return {
    button: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      zIndex: 1
    }
  };
})(AddToMealButton);