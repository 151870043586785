"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Layout = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Header = require("./Header");

var _core = require("@material-ui/core");

var _Banner = require("./Banner");

var _LayoutContainer = require("./LayoutContainer");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles(theme) {
  return {
    content: {
      zIndex: theme.wanderlost.zIndex.mainContent,
      position: 'relative',
      marginTop: -theme.wanderlost.mainContentPullTopPixels,
      borderRadius: theme.spacing(2),
      backgroundColor: '#fff',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
      marginBottom: theme.spacing(4),
      '@media print': {
        boxShadow: 'none'
      }
    },
    contentPadding: {
      padding: theme.spacing(5)
    },
    contentNarrow: {
      width: '700px'
    }
  };
};

var Layout = function Layout(_ref) {
  var _ref3;

  var classes = _ref.classes,
      breadcrumbs = _ref.breadcrumbs,
      children = _ref.children,
      page = _ref.page,
      BannerProps = _ref.BannerProps,
      _ref$BannerComponent = _ref.BannerComponent,
      BannerComponent = _ref$BannerComponent === void 0 ? _Banner.Banner : _ref$BannerComponent,
      noPadding = _ref.noPadding,
      fullWidth = _ref.fullWidth,
      narrow = _ref.narrow;

  var _ref2 = BannerProps || {},
      imageURL = _ref2.imageURL,
      hideNav = _ref2.hideNav,
      otherProps = _objectWithoutProperties(_ref2, ["imageURL", "hideNav"]);

  return _react.default.createElement("div", {
    page: page
  }, _react.default.createElement(_Header.Header, {
    imageURL: imageURL,
    hideNav: hideNav
  }), _react.default.createElement(BannerComponent, _extends({
    fullWidth: fullWidth,
    imageURL: imageURL
  }, otherProps)), _react.default.createElement(_LayoutContainer.LayoutContainer, {
    classes: {
      root: (0, _classnames.default)((_ref3 = {}, _defineProperty(_ref3, classes.contentNarrow, narrow), _defineProperty(_ref3, classes.content, !fullWidth), _defineProperty(_ref3, classes.contentPadding, !noPadding), _ref3))
    },
    fullWidth: fullWidth
  }, Boolean(breadcrumbs) && breadcrumbs, children));
};

exports.Layout = Layout;
Layout.propTypes = {
  classes: _propTypes.default.object.isRequired,
  BannerProps: _propTypes.default.shape({
    imageURL: _propTypes.default.string,
    hideNav: _propTypes.default.bool
  }).isRequired,
  breadcrumbs: _propTypes.default.node,
  children: _propTypes.default.node.isRequired,
  BannerComponent: _propTypes.default.any,
  // can be object or func
  page: _propTypes.default.string,
  fullWidth: _propTypes.default.bool,
  noPadding: _propTypes.default.bool,
  narrow: _propTypes.default.bool
};
exports.Layout = Layout = (0, _core.withStyles)(styles)(Layout);