"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipeAttribute = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../typography");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RecipeAttribute = function RecipeAttribute(_ref) {
  var label = _ref.label,
      text = _ref.text,
      Icon = _ref.icon,
      classes = _ref.classes;
  return _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement(Icon, {
    className: classes.icon
  }), _react.default.createElement("div", null, _react.default.createElement(_typography.P, {
    className: classes.label
  }, label), _react.default.createElement(_typography.P, {
    className: classes.text
  }, text)));
};

exports.RecipeAttribute = RecipeAttribute;
RecipeAttribute.propTypes = {
  label: _propTypes.default.string.isRequired,
  text: _propTypes.default.string.isRequired,
  icon: _propTypes.default.any.isRequired,
  classes: _propTypes.default.object.isRequired
};
exports.RecipeAttribute = RecipeAttribute = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      display: 'flex',
      width: '180px'
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(0.5)
    },
    text: {
      lineHeight: 1.2,
      fontSize: '16px'
    },
    icon: {
      width: '40px',
      height: '40px',
      margin: '0 8px'
    }
  };
})(RecipeAttribute);