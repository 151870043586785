"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InjectRecipesStateAndSyncQueryString = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _urls = require("../urls");

var _InjectRecipesState = require("./InjectRecipesState");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var SyncQueryString = function SyncQueryString(_ref) {
  var filters = _ref.filters,
      setFilters = _ref.setFilters,
      setFilter = _ref.setFilter,
      children = _ref.children,
      otherState = _objectWithoutProperties(_ref, ["filters", "setFilters", "setFilter", "children"]);

  var queryStrings = (0, _greenState.useInstance)(_urls.QueryStrings);

  if (!queryStrings) {
    return null;
  } // sync filters with querystring on mount


  (0, _react.useEffect)(function () {
    var reset = Boolean(queryStrings.get('reset'));
    var cookbook = queryStrings.getAll('cookbook');
    var meal = queryStrings.getAll('meal');
    var tag = queryStrings.getAll('tag');
    var flag = queryStrings.getAll('flag');

    if (reset) {
      // "explore" on the nav passes reset=true to clear all filters
      queryStrings.remove('reset');
      setFilters({});
    } else if (cookbook.length || meal.length || tag.length || flag.length) {
      // if any filters in querystring, update all of them in state
      setFilters({
        cookbook: cookbook,
        meal: meal,
        tag: tag,
        flag: flag
      });
    } else {
      // otherwise, need to sync querystring with state
      var _arr = Object.keys(filters);

      var _loop = function _loop() {
        var key = _arr[_i];
        filters[key].forEach(function (v) {
          return queryStrings.append(key, v);
        });
      };

      for (var _i = 0; _i < _arr.length; _i++) {
        _loop();
      }
    }
  }, []); // when a filter changes, set into state and sync with querystring

  var setAndSyncFilter = function setAndSyncFilter(key, id, selected) {
    setFilter(key, id, selected);

    if (selected) {
      queryStrings.append(key, id);
    } else {
      queryStrings.remove(key, id);
    }
  };

  return children(_objectSpread({}, otherState, {
    filters: filters,
    setFilter: setAndSyncFilter
  }));
};

var InjectRecipesStateAndSyncQueryString = function InjectRecipesStateAndSyncQueryString(_ref2) {
  var children = _ref2.children;
  return _react.default.createElement(_InjectRecipesState.InjectRecipesState, null, function (state) {
    return _react.default.createElement(SyncQueryString, state, children);
  });
};

exports.InjectRecipesStateAndSyncQueryString = InjectRecipesStateAndSyncQueryString;
InjectRecipesStateAndSyncQueryString.propTypes = {
  children: _propTypes.default.func.isRequired
};