import React from 'react';
import { AddTripButton, Layout } from '@wanderlost-sdk/components';
import { FeaturedTripsList } from './FeaturedTripsList';

export const ExploreTripsPage = () => (
    <Layout
        page="explore-trips-page"
        BannerProps={{
            title: "Explore Trips",
            imageURL: "/images/explore-trips-banner.jpg",
            renderContent: () => (
                <AddTripButton />
            )
        }}
    >
        <FeaturedTripsList />
    </Layout>
);
