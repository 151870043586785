import React from 'react';
import { InjectAndSubscribe } from '@symbiotic/green-state';
import { TripDetailsLoadingState } from './TripDetailsLoadingState';
import { CircularProgress } from '@material-ui/core';

export const TripDetailsLoadingIndicator = () => {
    return (
        <InjectAndSubscribe diKey={TripDetailsLoadingState}>
            {({ isLoading }) => (
                isLoading && <CircularProgress fontSize="large" />
            )}
        </InjectAndSubscribe>
    );
}
