import { State } from '@symbiotic/green-state';
import { EditablePackingListState } from './EditablePackingListState';
import { SortablePackingListState } from './SortablePackingListState';

export class TripPackingListsState extends State {
    constructor({ apiClient, trip, principal, focusManager }) {
        super();

        this.isLoading = () => !this.state;

        this.load = async () => {
            const entryResource = await apiClient.entry();

            const packingLists = await apiClient.get(trip.links.packingLists);
            const myItems = await apiClient.get(entryResource.links.packingListItems);

            // TODO: Might want a better way of figuring this out....
            this.setState({
                principal,
                packingLists,
                myItems, // for auto-complete
            });

            // if it's a featured trip, the current user won't be in the member list
            const tripIncludesMe = (packingLists.find(l => l.userId === principal.userId));
            if (tripIncludesMe) {
                this.setActiveFilter({ filterId: 'my-packing-list', label: 'My Packing List' });
            } else {
                this.setActiveFilter({ filterId: 'all-team-gear', label: 'All Team Items' });
            }
        };

        this.getMyPackingList = () => {
            const myPackingList = this.state.packingLists.find(({ userId }) => userId === principal.userId);
            if (!myPackingList) {
                return undefined;
            }
            const decorated = new SortablePackingListState({ packingList: myPackingList }).get().packingList;
            return decorated;
        };

        this.getTripOwnerPackingList = () => {
            const ownerPackingList = this.state.packingLists.find(({ owner }) => owner.role === 'owner');
            const decorated = new SortablePackingListState({ packingList: ownerPackingList }).get().packingList;
            return decorated;
        };

        const decorateItems = ({ packingList, items }) => {
            return items.map(item => ({
                ...item,
                owner: packingList.owner
            }));
        };

        // TODO: Is this a bad idea? The list won't end up in GearListState
        // Also in terms of copying from lists it won't be in that list but that's not likely an issue
        const createGearList = async (gearList) => {
            const entryResource = await apiClient.entry();
            const listRef = await apiClient.post(entryResource.links.packingLists, gearList);
            return await apiClient.get(listRef);
        };

        this.setActiveFilter = activeFilter => {
            const { filterId } = activeFilter;
            if (filterId === 'my-packing-list') {
                const myPackingList = this.getMyPackingList();

                this.setState({
                    activeFilter,
                    currentPackingListState: new EditablePackingListState({
                        apiClient,
                        packingList: myPackingList,
                        myItems: this.state.myItems,
                        focusManager,

                        // When a packing list is saved, update the copy in the list
                        afterSave: updatedPackingList => {
                            this.setState({
                                showOwner: false,
                                packingLists: this.state.packingLists.map(_packingList => {
                                    if (_packingList.userId === principal.userId) {
                                        return updatedPackingList;
                                    }

                                    return _packingList;
                                })
                            })
                        },
                        createGearList
                    })
                });
            } else if (filterId.startsWith('user:')) {
                const { userId } = activeFilter;
                const packingList = this.state.packingLists.find(_packingList => _packingList.userId === userId);
                packingList.items = decorateItems({ items: packingList.items, packingList });
                this.setState({
                    showOwner: false,
                    activeFilter,
                    currentPackingListState: new SortablePackingListState({ packingList })
                });
            } else {
                if (filterId === 'shared-team-gear') {
                    const packingList = {
                        items: this.state.packingLists.reduce(
                            (allItems, packingList) => {
                                const sharedItems = packingList.items.filter(item => item.isShared);
                                return allItems.concat(decorateItems({ items: sharedItems, packingList }));
                            },
                            []
                        )
                    };
                    this.setState({
                        showOwner: true,
                        activeFilter,
                        currentPackingListState: new SortablePackingListState({ packingList, sortBy: 'owner' })
                    });
                } else if (filterId === 'all-team-gear') {
                    const packingList = {
                        items: this.state.packingLists.reduce(
                            (allItems, packingList) => allItems.concat(decorateItems({ items: packingList.items, packingList })),
                            []
                        )
                    };
                    this.setState({
                        showOwner: true,
                        activeFilter,
                        currentPackingListState: new SortablePackingListState({ packingList, sortBy: 'owner' })
                    });
                }
            }
        };
    }
}

