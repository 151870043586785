"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FullWidthLayout = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Layout = require("./Layout");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var FullWidthLayout = function FullWidthLayout(_ref) {
  var children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["children"]);

  return _react.default.createElement(_Layout.Layout, _extends({
    fullWidth: true,
    BannerProps: {
      size: 'mini'
    },
    noPadding: true
  }, rest), children);
};

exports.FullWidthLayout = FullWidthLayout;
FullWidthLayout.propTypes = {
  children: _propTypes.default.node.isRequired
};