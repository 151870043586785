import React from 'react';
import PropTypes from 'prop-types';
import { Layout, TripBanner } from '@wanderlost-sdk/components';
import { hasAction } from '@wanderlost-sdk/core';

export let TripDetailsLayout = ({ children, trip, showEdit, ...rest }) => {
    const canEdit = hasAction(trip, 'self', 'put');
    showEdit = typeof showEdit === 'undefined' ? canEdit : showEdit;
    return (
        <Layout
            {...rest}
            BannerComponent={TripBanner}
            BannerProps={{
                trip,
                showEdit,
                imageURL: trip.coverPhotoURL,
                ...rest
            }}
        >
            {children}
        </Layout>
    );
};

TripDetailsLayout.propTypes = {
    children: PropTypes.node.isRequired,
    trip: PropTypes.object.isRequired,
};
