"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.formatMiles = function (miles) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$roundToIntegerAb = _ref.roundToIntegerAboveThreshold,
      roundToIntegerAboveThreshold = _ref$roundToIntegerAb === void 0 ? 20 : _ref$roundToIntegerAb;

  var formatted = roundToIntegerAboveThreshold !== undefined && miles > roundToIntegerAboveThreshold ? Math.round(miles) : Number(miles.toFixed(2));
  return formatted.toLocaleString();
};

exports.round = function (num, dp) {
  return Math.round((num + Number.EPSILON) * Math.pow(10, dp)) / Math.pow(10, dp);
};

exports.formatBytes = function (bytes) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

  if (bytes === 0) {
    return '0 Bytes';
  }

  var k = 1024;
  var dm = decimals < 0 ? 0 : decimals;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};