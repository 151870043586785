"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CopyTripButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _BannerButton = require("../components/Button/BannerButton");

var _react2 = require("../framework/react");

var _CopyTripDialog = require("./CopyTripDialog");

var _greenState = require("@symbiotic/green-state");

var _urls = require("../urls");

var _FileCopy = _interopRequireDefault(require("@material-ui/icons/FileCopy"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* eslint-disable react/prop-types */


var CopyTripButton = function CopyTripButton(_ref) {
  var trip = _ref.trip;
  return _react.default.createElement(_react2.InjectTogglable, {
    initialValue: false
  }, function (_ref2) {
    var isDialogVisible = _ref2.isOn,
        showDialog = _ref2.on,
        hideDialog = _ref2.off;
    return _react.default.createElement(_greenState.Inject, {
      diKey: _urls.WanderLostNavigator
    }, function (navigator) {
      var onDone = function onDone(_ref3) {
        var tripId = _ref3.tripId;
        hideDialog();
        navigator.tripDetails({
          tripId: tripId
        }).go();
      };

      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_BannerButton.BannerButton, {
        onClick: showDialog,
        name: "show-copy-trip",
        tooltip: "Copy trip",
        icon: _react.default.createElement(_FileCopy.default, null),
        label: "Copy"
      }), isDialogVisible && _react.default.createElement(_CopyTripDialog.CopyTripDialog, {
        trip: trip,
        onDone: onDone,
        onCancel: hideDialog
      }));
    });
  });
};

exports.CopyTripButton = CopyTripButton;
CopyTripButton.propTypes = {
  trip: _propTypes.default.object.isRequired,
  onDone: _propTypes.default.func,
  onClick: _propTypes.default.func
};