"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CurrentDialog = void 0;

var _greenState = require("@symbiotic/green-state");

var _state = require("./state");

var CurrentDialog = function CurrentDialog() {
  var dialogStateInstance = (0, _greenState.useInstance)(_state.DialogState);
  var dialogState = (0, _greenState.useSubscription)(function () {
    return dialogStateInstance;
  });

  if (!dialogState || !dialogState.dialog) {
    return null;
  }

  return dialogState.dialog;
};

exports.CurrentDialog = CurrentDialog;