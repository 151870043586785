"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.hasAction = function (obj, link, action) {
  try {
    return obj.links[link].actions.includes(action);
  } catch (e) {
    return false;
  }
};