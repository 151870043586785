"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LineStringFeatureFormatter = void 0;

var _Feature = _interopRequireDefault(require("ol/Feature"));

var _LineString = _interopRequireDefault(require("ol/geom/LineString"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LineStringFeatureFormatter = function LineStringFeatureFormatter() {
  _classCallCheck(this, LineStringFeatureFormatter);

  /**
   * Ensure LineStrings are in our known format ([x, y])
   * This means we are losing z (elevation) m (distance) but API doesn't support those for trails
   */
  var formatLineStringGeometry = function formatLineStringGeometry(geometry) {
    return new _LineString.default(geometry.getCoordinates().map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          x = _ref2[0],
          y = _ref2[1];

      return [x, y];
    }), 'XY');
  };

  var attributesToImport = {
    name: 'name',
    desc: 'description'
  };

  var extractTrailAttributes = function extractTrailAttributes(feature) {
    var attributes = {};
    Object.keys(attributesToImport).forEach(function (fromAttribute) {
      var value = safeTrim(feature.get(fromAttribute));
      var toAttribute = attributesToImport[fromAttribute];

      if (value) {
        attributes[toAttribute] = value;
      }
    });
    return attributes;
  };

  this.formatFeatures = function (features) {
    var acceptedFeatures = [];
    var rejectedFeatures = [];
    features.forEach(function (feature) {
      if (feature.getGeometry().getType() !== 'LineString') {
        acceptedFeatures.push(feature);
        return;
      } // GPX files sometimes have lines where all of the points are the same, skip them
      else if (areAllPointsToSame(feature)) {
          rejectedFeatures.push(feature);
          return;
        } else {
          acceptedFeatures.push(new _Feature.default(_objectSpread({
            geometry: formatLineStringGeometry(feature.getGeometry()),
            type: 'trail'
          }, extractTrailAttributes(feature))));
        }
    });
    return {
      acceptedFeatures: acceptedFeatures,
      rejectedFeatures: rejectedFeatures
    };
  };
};

exports.LineStringFeatureFormatter = LineStringFeatureFormatter;

function areAllPointsToSame(feature) {
  var firstPoint = feature.getGeometry().getFirstCoordinate();
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = feature.getGeometry().getCoordinates()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var point = _step.value;

      if (firstPoint[0] !== point[0] || firstPoint[1] !== point[1]) {
        return false;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return true;
}

var safeTrim = function safeTrim(thing) {
  return typeof thing === 'string' ? thing.trim() : thing;
};