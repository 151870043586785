import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Inject } from '@symbiotic/green-state';
import { InjectTogglable, WanderLostNavigator, Button } from '@wanderlost-sdk/components';
import { GearListNameDialog } from './GearListNameDialog';

export const AddGearListButton = ({ onSubmit, buttonProps, buttonLabel = 'Add Gear List' }) => (
    <InjectTogglable initialValue={false}>
        {({ isOn: isAddDialogVisible, on: showAddDialog, off: hideAddDialog }) => (
            <Inject diKey={WanderLostNavigator}>
                {navigator => {
                    const handleSubmit = async (list) => {
                        const { packingListId } = await onSubmit(list);
                        hideAddDialog();
                        navigator.gearList({ packingListId }).go();
                    };
                    const onCancel = () => {
                        hideAddDialog();
                    };

                    const onClick = (e) => {
                        e.preventDefault();
                        showAddDialog();
                    };

                    const name = `Gear list created ${moment(new Date()).format('MMM D, h:mm:ss A')}`;

                    buttonProps = buttonProps || {
                        variant: 'contained',
                        color: 'primary',
                        size: 'large'
                    };

                    return (
                        <>
                            <Button onClick={onClick} name="add-gear-list" {...buttonProps}>{buttonLabel}</Button>
                            {isAddDialogVisible && (
                                <GearListNameDialog
                                    gearList={{name}}
                                    title="Create Gear List"
                                    saveButtonLabel="Create"
                                    onSubmit={handleSubmit}
                                    onCancel={onCancel}
                                />
                            )}
                        </>
                    );
                }}
            </Inject>
        )}
    </InjectTogglable>
);

AddGearListButton.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    buttonProps: PropTypes.object,
    buttonLabel: PropTypes.string
};
