"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureSimplifier = void 0;

var _format = require("ol/format");

var _turf = require("@turf/turf");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FeatureSimplifier = function FeatureSimplifier(geoJSONFormat) {
  _classCallCheck(this, FeatureSimplifier);

  var geoJSONAdapter = {
    featuresToGeoJSON: function featuresToGeoJSON(features) {
      /**
       * We've seen converting from OLFeature -> GeoJSON corrupt the geometry
       * Specifically when there are consecutive duplicate points (e.g. [A, A, B, B, ...]) we end up with only 2 points
       * The solution is to remove consecutive duplicate points prior to converting to geojson
       * In the case we observed the duplicate points seemed to originate in our smart routing system
       * However in order to be confident we don't corrupt imports of valid GPX (nothing inherently invalid about duplicate points)
       * We just do this to all GPXes during import
       * We've only observed this on line strings (not multi line strings)
       */
      var noDupeCoordFeatures = features.map(function (feature) {
        if (feature.getGeometry().getType() === 'LineString') {
          var newGeometry = feature.getGeometry().clone();
          newGeometry.setCoordinates(removeConsecutiveDuplicateCoordinates(newGeometry.getCoordinates()));
          var newFeature = feature.clone();
          newFeature.setGeometry(newGeometry);
          return newFeature;
        }

        return feature;
      });
      return geoJSONFormat.writeFeaturesObject(noDupeCoordFeatures);
    },
    geoJSONToFeatures: function geoJSONToFeatures(geoJSONFeatures) {
      return geoJSONFormat.readFeaturesFromObject(geoJSONFeatures);
    }
  };

  var removeConsecutiveDuplicateCoordinates = function removeConsecutiveDuplicateCoordinates(coordinates) {
    return coordinates.reduce(function (uniqueCoords, coord, index, allCoords) {
      var lastCoord = allCoords[index - 1];

      if (!lastCoord || lastCoord[0] !== coord[0] || lastCoord[1] !== coord[1] || lastCoord[2] !== coord[2]) {
        return uniqueCoords.concat([coord]);
      }

      return uniqueCoords;
    }, []);
  };
  /**
   * Simplify the geometry of features
   * The intent is to reduce the size of the features without causing an unacceptable level of data loss/change to geometry
   * This enables us to handle larger feature sets (e.g. GPX imports of cross country trips) without breaking undo, causing long load times, running afoul of lambda/api gateway payload size limits, etc.
   * We currently use turf.simplify which uses simplifyjs which uses the Peucker algorithm to simplify lines https://en.wikipedia.org/wiki/Ramer%E2%80%93Douglas%E2%80%93Peucker_algorithm
   * There is at least one other algorithm for line simplification, Visvalingam's algorithm but
   * a) its not clear this would yield better results
   * b) its more flexible due to the number of input params but this makes it more complex to use
   * c) at time of writing there is not a standalone js implementaiton
   * d) while its bundled into topojson's simplify implementation, using topojson proved to be more complex than geojson
   * So we went with Peucker algorithm as it was simpler and we could not justify the time to explore visvalingam's algorithm further knowing we could swap it out later
   * Comparison between 2 algorithms: https://stackoverflow.com/questions/35290973/line-simplification-algorithm-visvalingam-vs-douglas-peucker
   */


  this.simplify = function (_ref) {
    var features = _ref.features,
        simplificationLevel = _ref.simplificationLevel;
    var geoJSONFeatures = geoJSONAdapter.featuresToGeoJSON(features);
    var simplifiedGeoJSONFeatures = (0, _turf.simplify)(geoJSONFeatures, {
      tolerance: calculateTolerance(simplificationLevel),
      // We found that highQuality had little visible impact on the quality of the resulting line
      // The docs say it causes a performance degradation, so we decided not use it
      highQuality: false,
      // Perf boost, safe to mutate since we just created the object above
      mutate: true
    });
    return geoJSONAdapter.geoJSONToFeatures(simplifiedGeoJSONFeatures);
  };

  var MIN_LEVEL = 0.1;
  var MAX_LEVEL = 1;
  var MIN_TOLERANCE = 0.00001; // Below this value we don't get meaningful reduction in size

  var MAX_TOLERANCE = 0.001; // Above this value features are too distorted to be acceptable

  var TOLERANCE_PRECISION = MIN_TOLERANCE.toString().split('.')[1].length;
  /**
   * Convert a simplification level between MIN_LEVEL and MAX_LEVEL to
   * a tolerance value between MIN_TOLERANCE and MAX_TOLERANCE
   * There is a fair amount of complexity in this code, if we decide its not worth it or discover bugs we may want to just have the slider use the MIN_TOLERANCE/MAX_TOLERANCE values directly
   * If we receive MIN_TOLERANCE, consider it NO SIMPLIFICATION
   */

  var calculateTolerance = function calculateTolerance(simplificationLevel) {
    var newValue = convertRange({
      oldValue: simplificationLevel,
      oldMin: MIN_LEVEL,
      oldMax: MAX_LEVEL,
      newMin: MIN_TOLERANCE,
      newMax: MAX_TOLERANCE
    });
    newValue = Number(newValue.toFixed(TOLERANCE_PRECISION));
    return newValue;
  };
  /**
   * Convert a value from one range of values to another
   * E.g. 1 in the range 0...1 to the range 10..20 => 20
   * E.g. 0.1 in the range 0.1...1 to the range 10..20 => 10
   */


  var convertRange = function convertRange(_ref2) {
    var oldValue = _ref2.oldValue,
        oldMin = _ref2.oldMin,
        oldMax = _ref2.oldMax,
        newMin = _ref2.newMin,
        newMax = _ref2.newMax;
    return (oldValue - oldMin) / (oldMax - oldMin) * (newMax - newMin) + newMin;
  };
};

exports.FeatureSimplifier = FeatureSimplifier;

_defineProperty(FeatureSimplifier, "inject", function () {
  return [_format.GeoJSON];
});