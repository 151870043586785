import React, { useState, useEffect, useRef } from 'react';
import { Divider, Grid, Input, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Button, ColumnHeading, Display } from '@wanderlost-sdk/components';

const Row = ({ item, label, onDelete, onChange, handleBlur, handleSubmit, classes }) => {
    const [isEditMode, setEditMode] = useState(false);
    const startEditMode = () => setEditMode(true);
    const endEditMode = () => setEditMode(false);

    const inputElement = useRef(null);
    useEffect(() => {
        if (item.autoFocus && inputElement.current) {
            inputElement.current.focus();
        }
    });

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" spacing={1} alignItems="flex-start" className={classes.form} onMouseOver={startEditMode} onMouseOut={endEditMode} onFocus={startEditMode} onBlur={endEditMode}>
                    <Grid item sm={1}>
                        <IconButton style={{ visibility: isEditMode ? 'visible' : 'hidden', padding: '0.25em' }} onClick={onDelete} tabIndex={1} name="delete">
                            <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                    </Grid>
                    <Grid item sm={5}>
                        <Input
                            name="name"
                            placeholder={label}
                            value={item.name}
                            disableUnderline={!isEditMode}
                            inputRef={inputElement}
                            onBlur={handleBlur}
                            onChange={onChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export let GroupingForm = ({ items, label, addItem, removeItem, updateItem, setAutoFocus, classes }) => {

    const moveNext = (index) => {
        if (index === items.length - 1) {
            addItem();
        } else {
            setAutoFocus(index + 1);
        }
    };

    return (
        <>
            <div className={classes.container} name="packing-list-form">
                <Grid container wrap="nowrap" spacing={1} alignItems="flex-start" data-type="column-headings">
                    <Grid item sm={1} />
                    <Grid item sm={5}>
                        <ColumnHeading>{label}</ColumnHeading>
                    </Grid>
                </Grid>
                <div>
                    {items.map((item, index) => {
                        return <Row
                            key={index}
                            item={item}
                            label={label}
                            handleBlur={() => setAutoFocus(undefined)}
                            handleSubmit={(e) => { e.preventDefault(); moveNext(index); }}
                            onDelete={() => removeItem(index)}
                            onChange={(e) => updateItem(index, { name: e.target.value })}
                            classes={classes}
                        />
                    })}
                </div>
            </div>
            <Divider style={{ marginTop: '14px' }} />
            <Display screen>
                <div style={{ marginTop: '-22px', textAlign: 'center' }}>
                    <Button onClick={addItem} variant="fab" size="small" color="primary" style={{ marginRight: '2em', transform: 'scale(0.75)' }} name="add-item" tabIndex={-1}>
                        <AddIcon fontSize="small" />
                    </Button>
                </div>
            </Display>
        </>
    );
};

GroupingForm = withStyles(theme => ({
    container: {
        padding: `${theme.spacing(2)}px 0`
    },
}))(GroupingForm);
