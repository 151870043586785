"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BannerButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _core = require("@material-ui/core");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var BannerButton = function BannerButton(_ref) {
  var icon = _ref.icon,
      label = _ref.label,
      name = _ref.name,
      tooltip = _ref.tooltip,
      onClick = _ref.onClick,
      classes = _ref.classes,
      className = _ref.className,
      _ref$willPrint = _ref.willPrint,
      willPrint = _ref$willPrint === void 0 ? false : _ref$willPrint;

  // eslint-disable-next-line react/display-name
  var Button = _react.default.forwardRef(function (_ref2, ref) {
    var title = _ref2.title;
    return _react.default.createElement(_core.Button, {
      ref: ref,
      name: name,
      title: title,
      className: (0, _classnames.default)(classes.button, className),
      onClick: onClick
    }, _react.default.cloneElement(icon, {
      size: 'small',
      className: classes.icon
    }), _react.default.createElement("span", {
      className: classes.label
    }, label));
  });

  Button.propTypes = {
    title: _propTypes.default.string
  }; // if we wrap the button in a mui tooltip, then execute window.print() in the onClick
  // handler, the print zoom level defined on the App class is applied twice, causing
  // the printout to be smaller than intended; the workaround is to omit the tooltip if the
  // button needs to print (we still get the native tooltip from the "title" attribute)

  if (willPrint) {
    return _react.default.createElement(Button, {
      title: tooltip
    });
  }

  return _react.default.createElement(_core.Tooltip, {
    title: tooltip,
    onClick: onClick
  }, _react.default.createElement(Button, null));
};

exports.BannerButton = BannerButton;
BannerButton.propTypes = {
  icon: _propTypes.default.node.isRequired,
  onClick: _propTypes.default.func.isRequired,
  tooltip: _propTypes.default.string.isRequired,
  name: _propTypes.default.string,
  classes: _propTypes.default.object,
  className: _propTypes.default.string,
  label: _propTypes.default.node,
  willPrint: _propTypes.default.bool
};
exports.BannerButton = BannerButton = (0, _core.withStyles)(function (theme) {
  var _ref3;

  return _ref3 = {
    button: {
      fontSize: '1rem',
      lineHeight: 1.5,
      minWidth: 'auto',
      padding: 0,
      marginLeft: '5px',
      backgroundColor: 'transparent',
      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
      textTransform: 'uppercase',
      color: '#D7D7D7',
      '&:hover, &:focus, &:active': {
        color: theme.palette.primary.main
      },
      '&:hover $icon, &:focus $icon, &:active $icon': {
        color: theme.palette.primary.main
      }
    },
    label: {
      display: 'none'
    }
  }, _defineProperty(_ref3, "@media only screen and (min-width: ".concat(theme.breakpoints.values.md, "px)"), {
    label: {
      display: 'block'
    }
  }), _defineProperty(_ref3, "icon", {
    padding: theme.spacing(0.5),
    color: '#D7D7D7'
  }), _ref3;
})(BannerButton);