import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { InjectLoadAndSubscribe, TripMealsState, MealPlanSummary } from '@wanderlost-sdk/components';
import { TripMealsSummaryTable } from './TripMealsSummaryTable';

export class TripMealsSummary extends Component {
    render() {
        return (
            <InjectLoadAndSubscribe diKey={TripMealsState} methodName="ensureLoaded">
                {({ getMealPlanSummaryState, getMealSummaryState }) => {
                    const { mealTypeSummaries, teamMemberSummaries } = getMealPlanSummaryState().get();
                    const { datesWithMeals, mealTypes } = getMealSummaryState().get();

                    return (
                        <>
                            <div style={{ marginTop: '-16px' }}>
                                <MealPlanSummary
                                    mealTypeSummaries={mealTypeSummaries}
                                    teamMemberSummaries={teamMemberSummaries}
                                />
                            </div>

                            <Paper>
                                <TripMealsSummaryTable mealTypes={mealTypes} datesWithMeals={datesWithMeals} />
                            </Paper>
                        </>
                    );
                }}
            </InjectLoadAndSubscribe>
        );
    }
}

TripMealsSummary.propTypes = {
    classes: PropTypes.object.isRequired,
}

TripMealsSummary = withStyles(theme => ({
    container: {
        padding: `${theme.spacing(3)}px`,
        paddingTop: 0,
        marginTop: `${theme.spacing(2)}px`,
    },
}))(TripMealsSummary);
