"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuoteIcon = void 0;

var _react = _interopRequireDefault(require("react"));

var _SvgIcon = _interopRequireDefault(require("@material-ui/core/SvgIcon"));

var _quote = require("./svg/quote.js");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}
/**
 * WARNING: Not ready for use in web-app.
 *
 * TODO: the size/color of this svg are incorrect
 */


var QuoteIcon = function QuoteIcon(_ref) {
  var classes = _ref.classes,
      color = _ref.color;
  return _react.default.createElement(_SvgIcon.default, _extends({
    classes: classes,
    color: color
  }, {
    component: function component() {
      return _react.default.createElement("div", {
        dangerouslySetInnerHTML: {
          __html: _quote.quoteSVGString
        }
      });
    }
  }), _react.default.createElement(_react.default.Fragment, null));
};

exports.QuoteIcon = QuoteIcon;