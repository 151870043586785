"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureFormatter = exports.FeatureFormatterCollection = void 0;

var _MultiLineStringFeatureFormatter = require("./MultiLineStringFeatureFormatter");

var _PointFeatureFormatter = require("./PointFeatureFormatter");

var _LineStringFeatureFormatter = require("./LineStringFeatureFormatter");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FeatureFormatterCollection =
/**
 * Note that the order matters here
 * We want to split multi line strings to line strings
 * Then convert any single point lines to points
 * Then format the remaining lines
 */
function FeatureFormatterCollection() {
  for (var _len = arguments.length, formatters = new Array(_len), _key = 0; _key < _len; _key++) {
    formatters[_key] = arguments[_key];
  }

  _classCallCheck(this, FeatureFormatterCollection);

  // Proxy to array of formatters for iteration
  this[Symbol.iterator] = function () {
    return formatters[Symbol.iterator]();
  };
};

exports.FeatureFormatterCollection = FeatureFormatterCollection;

_defineProperty(FeatureFormatterCollection, "inject", function () {
  return [_MultiLineStringFeatureFormatter.MultiLineStringFeatureFormatter, _PointFeatureFormatter.PointFeatureFormatter, _LineStringFeatureFormatter.LineStringFeatureFormatter];
});

var FeatureFormatter = function FeatureFormatter(featureFormatters) {
  _classCallCheck(this, FeatureFormatter);

  this.formatFeatures = function (features) {
    var acceptedFeatures = features;
    var rejectedFeatures = [];
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = featureFormatters[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var formatter = _step.value;
        var formatted = formatter.formatFeatures(acceptedFeatures);
        acceptedFeatures = formatted.acceptedFeatures;
        rejectedFeatures = rejectedFeatures.concat(formatted.rejectedFeatures);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return {
      acceptedFeatures: acceptedFeatures,
      rejectedFeatures: rejectedFeatures
    };
  };
};

exports.FeatureFormatter = FeatureFormatter;

_defineProperty(FeatureFormatter, "inject", function () {
  return [FeatureFormatterCollection];
});