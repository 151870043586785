"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InjectRecipesState = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _typography = require("../typography");

var _RecipesState = require("./RecipesState");

var _RecipesLayout = require("./RecipesLayout");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var InjectRecipesState = function InjectRecipesState(_ref) {
  var children = _ref.children;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _RecipesState.RecipesState
  }, function (_ref2) {
    var error = _ref2.error,
        state = _objectWithoutProperties(_ref2, ["error"]);

    if (error) {
      return _react.default.createElement(_RecipesLayout.RecipesLayout, {
        title: "Recipes",
        page: "recipes-error-page"
      }, _react.default.createElement(_typography.H3, null, error));
    }

    return children(state);
  });
};

exports.InjectRecipesState = InjectRecipesState;
InjectRecipesState.propTypes = {
  children: _propTypes.default.func.isRequired
};