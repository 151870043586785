"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripHeader = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _TripBanner = require("../layout/TripBanner");

var _TripNav = require("./TripNav");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TripHeader = function TripHeader(_ref) {
  var trip = _ref.trip;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_TripBanner.TripBanner, {
    trip: trip
  }), _react.default.createElement(_TripNav.TripNav, {
    trip: trip
  }));
};

exports.TripHeader = TripHeader;
TripHeader.propTypes = {
  trip: _propTypes.default.object.isRequired
};