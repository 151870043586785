import React from 'react';
import { DependencyContainerContext } from '@symbiotic/green-state';
import {
    FeatureLayersStrategyDAO,
    ElevationsDAO,
    LocationSearchDAO,
    InitialFeaturesLoader,
    TripRoutesState,
    Log as CartographerLog,
} from '@wanderlost-sdk/cartographer';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';
import { LoadingPage } from "../LoadingPage";
import { Log } from '../debugging';

export class MapContainerContext extends DependencyContainerContext {

    renderLoading() {
        return <LoadingPage message="Loading Trip Map" />
    }

    /**
     * Be careful not to use registerInstance here for State classes (or anything implementing dispose) that should be shared with the edit map
     * This container would call .dipose when it unmounts and then you will start to see errors with the message 'Cannot subscribe to a disposed State instance'
     * Use registerAlias or register it in MapContainerContext shared by both maps
     */
    async containerMounted(container) {
        container.registerInstance(CartographerLog, container.get(Log));

        container.registerInstance(TripRoutesState, {
            ...container.get(TripRoutesState),
            dispose: () => {
                // no op - we want it to dispose in the parent container (TripDetailsContainer)
            }
        });

        const initialFeaturesLoader = container.get(InitialFeaturesLoader);

        // Want to preload the features before rendering the map
        // But if we refresh the initial features, we don't want to use the cache
        // We must load this before rendering the map or other code that is assumign TripRoutesState has been hydrated won't work :/
        let initialFeaturesCache = await initialFeaturesLoader.load();

        // Implementations of Cartographer DAOs using apiClient
        const apiClient = container.get(WanderLostApiClient)

        container.registerInstance(FeatureLayersStrategyDAO, {
            getGlobalTrailsTile: async ({ z, x, y }) => {
                return await apiClient.getTileFeatures({ z, x, y });
            },
            getUserTrailsByExtent: async extent => {
                return await apiClient.getFeatures({
                    extent,
                    types: ['trail'],
                    folderFilter: 'mine-and-shared'
                });
            },
            getNonTrailsByExtent: async extent => {
                return await apiClient.getFeatures({
                    extent,
                    types: ['trail-highlight', 'waypoint', 'text-annotation']
                });
            },
            getRoutes: async () => {
                if (initialFeaturesCache) {
                    const cache = initialFeaturesCache;
                    initialFeaturesCache = undefined;
                    return cache;
                }

                return initialFeaturesLoader.load();
            },
            getFeatureDetails: async({ featureId }) => {
                const entry = await apiClient.entry();
                return await apiClient.get(entry.links.featureDetails, 'application/json', { featureId });
            }
        });

        container.registerInstance(ElevationsDAO, {
            getElevations: async ({ coords }) => {
                const entry = await apiClient.entry();
                return await apiClient.post(entry.links.elevationProfiles, { coords });
            }
        });

        container.registerInstance(LocationSearchDAO, {
            getLocations: async ({ search }) => {
                const entry = await apiClient.entry();
                return await apiClient.get(entry.links.locations, 'application/json', { search });
            }
        });

        // window.mapContainerInspector = new (require('../ioc').ContainerInspector)(container);

        // web-app style manager is aware of whether features are hidden b/c of folders, explicit marker hiding, etc.
        // container.registerInstance(CartographerStyleManager, container.get(StyleManager));
    }
}
