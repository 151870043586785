"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipesNav = void 0;

var _react = _interopRequireDefault(require("react"));

var _nav = require("../nav");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var navItems = [{
  label: 'Explore',
  route: 'recipesHome',
  navtarget: 'recipesHome'
}, {
  label: 'All Recipes',
  route: 'recipes',
  routeParams: {
    reset: [true]
  },
  navtarget: 'recipes'
}, {
  label: 'Favorites',
  route: 'recipes',
  routeParams: {
    flag: ['favorite']
  },
  navtarget: 'favorites',
  matchParams: true
}];

var RecipesNav = function RecipesNav() {
  return _react.default.createElement(_nav.HeaderNav, {
    name: "recipes-nav",
    navItems: navItems
  });
};

exports.RecipesNav = RecipesNav;