import React from 'react';
import { useInstance } from '@symbiotic/green-state';
import { Map } from './maps';
import { Route, Switch } from "react-router-dom";
import { LandingPage } from './LandingPage';
import { TripsPage, ExploreTripsPage, TripDetailsPage, TripMapPage, TripDetailsContainer, TripMealsPage, TripMealsSummaryPage, TripTeamPage, TripLogPage, TripPrintPage } from './trips';
import { GearListsPage, GearListPage, TripPackingListPage, StarterListsPage } from './packing-lists';
import { NotFoundPage } from './NotFoundPage';
import { TestSandbox } from './TestSandbox';
import { FullWidthLayout, RecipesLandingPage, RecipesPage, RecipeDetailsPage, CookbookDetailsPage, ContentPage } from '@wanderlost-sdk/components';
import { FeatureFlagsPage } from './debugging/FeatureFlagsPage';
import { WanderLostPrincipal, UserConsentPage } from './users';
// import { Scratch } from './Scratch';

export const Routes = () => {

    // prevent user getting page setup page if not yet set up
    const principal = useInstance(WanderLostPrincipal);
    if (!principal.isConsentSubmitted) {
        return <UserConsentPage />
    }

    return (
        <Switch>
            {/* <Route exact={true} path="/scratch">
                <Scratch />
            </Route> */}
            <Route exact={true} path="/">
                <LandingPage />
            </Route>
            <Route path="/maps">
                <FullWidthLayout page="maps-page">
                    <Map />
                </FullWidthLayout>
            </Route>
            <Route path="/trips" exact={true}>
                <TripsPage />
            </Route>
            <Route path="/explore/trips" exact={true}>
                <ExploreTripsPage />
            </Route>
            <Route path="/trips/:tripId">
                {({ match }) => (
                    <TripDetailsContainer key={match.params.tripId} tripId={match.params.tripId}>
                        <Switch>
                            <Route path="/trips/:tripId" exact={true}>
                                <TripDetailsPage />
                            </Route>
                            <Route path="/trips/:tripId/map" exact={true}>
                                <TripMapPage />
                            </Route>
                            <Route path="/trips/:tripId/map/:zoomToFolderId" exact={true}>
                                {({ match }) => <TripMapPage zoomToFolderId={match.params.zoomToFolderId} />}
                            </Route>
                            <Route path="/trips/:tripId/team" exact={true}>
                                <TripTeamPage />
                            </Route>
                            <Route path="/trips/:tripId/meals" exact={true}>
                                <TripMealsSummaryPage />
                            </Route>
                            <Route path="/trips/:tripId/meals/manage" exact={true}>
                                <TripMealsPage />
                            </Route>
                            <Route path="/trips/:tripId/packing-lists/manage" exact={true}>
                                <TripPackingListPage />
                            </Route>
                            <Route path="/trips/:tripId/log" exact={true}>
                                <TripLogPage />
                            </Route>
                            <Route path="/trips/:tripId/print" exact={true}s>
                                <TripPrintPage />
                            </Route>
                            <Route>
                                <NotFoundPage />
                            </Route>
                        </Switch>
                    </TripDetailsContainer>
                )}
            </Route>
            <Route path="/gear-lists" exact={true}>
                <GearListsPage />
            </Route>
            <Route path="/starter-kits" exact={true}>
                <StarterListsPage />
            </Route>
            <Route path="/gear-lists/:packingListId" exact={true}>
                {({ match }) => (
                    <GearListPage key={match.params.packingListId} packingListId={Number(match.params.packingListId)} />
                )}
            </Route>
            <Route path="/recipes/home" exact={true}>
                <RecipesLandingPage/>
            </Route>
            <Route path="/recipes" exact={true}>
                {({ location }) => {
                    return <RecipesPage key={location.search} />
                }}
            </Route>
            <Route path="/recipes/:recipeId" exact={true}>
                {({ match }) => (
                    <RecipeDetailsPage key={match.params.recipeId} recipeId={match.params.recipeId} />
                )}
            </Route>
            <Route path="/cookbooks/:cookbookId" exact={true}>
                {({ match }) => (
                    <CookbookDetailsPage key={match.params.cookbookId} cookbookId={match.params.cookbookId} />
                )}
            </Route>
            <Route path="/pages/:pageId" exact={true}>
                {({ match }) => (
                    <ContentPage key={match.params.pageId} pageId={match.params.pageId} />
                )}
            </Route>
            <Route exact={true} path="/__test-sandbox">
                <TestSandbox />
            </Route>
            <Route exact={true} path="/__feature-flags">
                <FeatureFlagsPage />
            </Route>
            <Route>
                <NotFoundPage />
            </Route>
        </Switch>
    );
};
