"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartographerPlugins = void 0;

var _FeatureLayersLoadingBridgePlugin = require("./FeatureLayersLoadingBridgePlugin");

var _TooManyFeaturesPlugin = require("./TooManyFeaturesPlugin");

var _ActiveFoldersPlugin = require("./folders/ActiveFoldersPlugin");

var _DrawerStatePlugin = require("./DrawerStatePlugin");

var _LayerOpacityPlugin = require("./plugins/LayerOpacityPlugin");

var _ZoomToFolderPlugin = require("./plugins/ZoomToFolderPlugin");

var _CenterOnEditedFeaturePlugin = require("./plugins/CenterOnEditedFeaturePlugin");

var _ElevationProfilePlugin = require("./plugins/ElevationProfilePlugin");

var _SelectedRoutePlugin = require("./plugins/SelectedRoutePlugin");

var _RouteDistancePlugin = require("./plugins/RouteDistancePlugin");

var _ActiveFeatureManager = require("./tools/ActiveFeatureManager");

var _RouteColorPlugin = require("./plugins/RouteColorPlugin");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/**
 * for now a plugin is a very simple interface
 *
 * {
 *   start: () => {},
 *   dispose: () => {}
 * }
 *
 * so that we can start moving some concerns to events and reduce coupling
 */


var CartographerPlugins = function CartographerPlugins() {
  for (var _len = arguments.length, plugins = new Array(_len), _key = 0; _key < _len; _key++) {
    plugins[_key] = arguments[_key];
  }

  _classCallCheck(this, CartographerPlugins);

  this.getPlugins = function () {
    return plugins;
  };
};

exports.CartographerPlugins = CartographerPlugins;

_defineProperty(CartographerPlugins, "inject", function () {
  return [_FeatureLayersLoadingBridgePlugin.FeatureLayersLoadingBridgePlugin, _TooManyFeaturesPlugin.TooManyFeaturesPlugin, _ActiveFoldersPlugin.ActiveFoldersPlugin, _DrawerStatePlugin.DrawerStatePlugin, _LayerOpacityPlugin.LayerOpacityPlugin, _ZoomToFolderPlugin.ZoomToFolderPlugin, _CenterOnEditedFeaturePlugin.CenterOnEditedFeaturePlugin, _ElevationProfilePlugin.ElevationProfilePlugin, _SelectedRoutePlugin.SelectedRoutePlugin, _ActiveFeatureManager.ActiveFeatureManager, _RouteDistancePlugin.RouteDistancePlugin, _RouteColorPlugin.RouteColorPlugin];
});