import React, { Component, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'ol/ol.css';
import 'fontsource-roboto/latin-400.css';
import 'fontsource-roboto/latin-700.css';
import 'fontsource-roboto-slab/latin-700-normal.css';
import { AppContainer } from './ioc';
import withStyles from '@material-ui/core/styles/withStyles';
import { Router } from "react-router-dom";
import { History } from '@wanderlost-sdk/components';
import { DragAndDropContext } from '@wanderlost-sdk/cartographer/dist/drag-and-drop';
import { Inject } from '@symbiotic/green-state';
import { ThemeProvider } from './theme';
import { Routes } from './Routes';
import { SupportWarning } from './SupportWarning';
import { Log } from './debugging';
import { ErrorPage } from './ErrorPage';
import { DateProvider } from '@wanderlost-sdk/components';
import { EnvConfiguration } from './EnvConfiguration';

const styles = () => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.5em'
        },
        '*::-webkit-scrollbar-track': {
            borderRadius: '0.5em',
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.1)'
        },
        '*::-webkit-scrollbar-thumb': {
            borderRadius: '0.5em',
            backgroundColor: 'rgba(0,0,0,0.25)'
        }
    },
    container: {
        '@media print': {
            zoom: '75%',
            minWidth: '0',

            /* Fix MUI grid negative left/right margins causing horizontal scroll */
            padding: '10px',
            overflowX: 'hidden'
        }
    }
});

const HotjarScript = ({ hotjarId, children }) => {
    useEffect(() => {
        if (hotjarId) {
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:hotjarId,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        }
    }, []);
    return children;
};

const ScrollToTop = withRouter(({ location, children }) => {
    const { pathname } = location;
    useEffect(() => window.scrollTo(0, 0), [pathname]);
    return children;
});

export class App extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    state = {
        error: null
    };

    componentDidCatch(error, errorInfo) {
        try {
            const log = new Log(); // new up log b/c we don't have ioc yet
            log.error(error, errorInfo);
        } catch (e) {
            console.warn(`Failed to log error in App.componentDidCatch. ${e.message}`);
            console.error(e);
        }
    }

    render() {
        const { error } = this.state;
        const { classes } = this.props;

        if (error) {
            return <ErrorPage />
        }

        return (
            <DragAndDropContext>
                <AppContainer>
                    <Inject diKeys={[History, EnvConfiguration]}>
                        {(history, {hotjarId}) => (
                            <HotjarScript hotjarId={hotjarId}>
                                <DateProvider>
                                    <ThemeProvider>
                                        <Router history={history}>
                                            <ScrollToTop>
                                                <div className={classes.container}>
                                                    <Routes />
                                                    <SupportWarning />
                                                </div>
                                            </ScrollToTop>
                                        </Router>
                                    </ThemeProvider>
                                </DateProvider>
                            </HotjarScript>
                        )}
                    </Inject>
                </AppContainer>
            </DragAndDropContext>
        );
    }
}

App = withStyles(styles)(App);
