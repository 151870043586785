import React from 'react';
import PropTypes from 'prop-types';
import { H1, Breadcrumbs, BreadcrumbItem, InjectLoadAndSubscribe } from '@wanderlost-sdk/components';
import { GearListsState } from './state/GearListsState';
import { PackingListForm } from './PackingListForm';
import { Subscribe } from '@symbiotic/green-state';
import { NotFoundPage } from '../NotFoundPage';
import { PackingListsLayout } from './PackingListsLayout';
import { CircularProgress } from '@material-ui/core';

export let GearListPage = ({ packingListId }) => (
    <InjectLoadAndSubscribe diKey={GearListsState}>
        {({ gearLists, getEditableListState }) => {

            if (!gearLists) {
                return <CircularProgress fontSize="large" />;
            }

            if (!gearLists.find(l => l.packingListId === packingListId)) {
                // <InjectLoadAndSubscribe> does not await, and we don't want it to, or we'll see a blank page for a moment
                // every time we return to a list that already loaded. But this means when the user is redirected here right
                // after a list is created, it might not have loaded yet. So if we can't find the list, we're  returning null,
                // assuming it will load momentarily. Note however, that the downside is that if the user has an invalid
                // packingListId in the url, they will see a blank page (instead of the expected "Not Found" page).
                return <NotFoundPage />;
            }

            return (
                <Subscribe to={() => getEditableListState({ packingListId })}>
                    {({ packingList, ...rest }) => {
                        return (
                            <PackingListsLayout
                                breadcrumbs={
                                    <Breadcrumbs>
                                        <BreadcrumbItem to="gearLists">My Gear Lists</BreadcrumbItem>
                                        <BreadcrumbItem>{packingList.name}</BreadcrumbItem>
                                    </Breadcrumbs>
                                }
                                page="gear-list-page"
                            >
                                <H1>{packingList.name}</H1>

                                <PackingListForm packingList={packingList} {...rest} />
                            </PackingListsLayout>
                        );
                    }}
                </Subscribe>
            );

        }}
    </InjectLoadAndSubscribe>
);

GearListPage.propTypes = {
    packingListId: PropTypes.number.isRequired
}
