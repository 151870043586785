"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var CallbackQueue = function CallbackQueue() {
  var _this = this;

  var queue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  _classCallCheck(this, CallbackQueue);

  this.queue = queue;

  this.enqueue = function (cb) {
    _this.queue.push(cb);

    return function () {
      _this.queue = _this.queue.filter(function (_sub) {
        return _sub !== cb;
      });
    };
  };

  this.invoke = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (_this.queue.length === 0) {
      return;
    }

    _this.queue.forEach(function (cb) {
      return cb.apply(void 0, args);
    });
  };

  this.flush = function () {
    _this.invoke();

    _this.clear();
  };

  this.clear = function () {
    var oldQueue = _this.queue;
    _this.queue = [];
    return oldQueue;
  };
};

exports.CallbackQueue = CallbackQueue;