"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrintButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Print = _interopRequireDefault(require("@material-ui/icons/Print"));

var _BannerButton = require("./BannerButton");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* globals window:readonly, document:readonly */


var PrintButton = function PrintButton(_ref) {
  var _ref$tooltip = _ref.tooltip,
      tooltip = _ref$tooltip === void 0 ? 'Print' : _ref$tooltip,
      title = _ref.title;
  return _react.default.createElement(_BannerButton.BannerButton, {
    name: "print-recipe",
    onClick: function onClick() {
      var oldTitle = document.title;

      if (title) {
        document.title = title;
      }

      window.print();

      if (title) {
        document.title = oldTitle;
      }
    },
    tooltip: tooltip,
    icon: _react.default.createElement(_Print.default, null),
    label: "Print",
    willPrint: true
  });
};

exports.PrintButton = PrintButton;
PrintButton.propTypes = {
  tooltip: _propTypes.default.string,
  title: _propTypes.default.string
};