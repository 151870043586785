import React from 'react';
import { useInstance, useSubscription, Subscribe } from '@symbiotic/green-state';
import { TripPackingListsState } from './state';
import { TripPackingListForm } from './TripPackingListForm';
import { TripPackingList } from './TripPackingList';

export const TripPackingLists = () => {
    const packingListsStateInstance = useInstance(TripPackingListsState);
    const packingListsState = useSubscription(() => {
        packingListsStateInstance.load();

        return packingListsStateInstance;
    });

    if (!packingListsState || !packingListsState.currentPackingListState) {
        return null;
    }

    const { activeFilter, currentPackingListState } = packingListsState;

    const PackingListComponent = activeFilter.filterId === 'my-packing-list' ? TripPackingListForm : TripPackingList;

    return (
        <>
            <Subscribe key={activeFilter.filterId} to={() => currentPackingListState}>
                {currentPackingListProps =>
                    <PackingListComponent {...packingListsState} {...currentPackingListProps} />
                }
            </Subscribe>
        </>
    );
};
