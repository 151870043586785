"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripDetailsPageV2 = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _Page = require("../Page");

var _components = require("../components");

var _greenState = require("@symbiotic/green-state");

var _TripHeader = require("./TripHeader");

var _TripPageHeading = require("./TripPageHeading");

var _notes = require("./notes");

var _TripState = require("./TripState");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * This is currently only used by Mobile and therefore is diverging.
 *  - Trip actions/welcome is hidden
 *  - Different layout
 *  - No breadcrumbs
 */


var TripDetailsPageV2 = function TripDetailsPageV2() {
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _TripState.TripState
  }, function (_ref) {
    var trip = _ref.trip;

    /* 404 is handled in DetailsContainer, but when a trip is deleted this code can run momentarily and should not render */
    if (!trip) {
      return null;
    }

    return _react.default.createElement(_Page.Page, {
      page: "trip-detail"
    }, _react.default.createElement(_TripHeader.TripHeader, {
      trip: trip
    }), _react.default.createElement(_TripPageHeading.TripPageHeading, null, "My Trip"), _react.default.createElement(_core.Grid, {
      container: true,
      spacing: 5,
      justify: "space-between",
      alignItems: "flex-start"
    }, _react.default.createElement(_core.Grid, {
      item: true,
      sm: 9
    }, _react.default.createElement(_notes.TripNotes, {
      readOnly: true
    })), _react.default.createElement(_core.Grid, {
      item: true,
      sm: 3
    }, _react.default.createElement(_components.Quote, {
      content: "Life is either a daring adventure or nothing.",
      attribution: "Helen Keller"
    }))));
  });
};

exports.TripDetailsPageV2 = TripDetailsPageV2;
TripDetailsPageV2.propTypes = {
  tripActions: _propTypes.default.bool
};