import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TripMealNotes } from './TripMealNotes';

const buildColumns = (mealTypes) => {
    let columns = [
        { label: 'Date', width: '10%', align: 'right' },
    ];

    columns = columns.concat(mealTypes.map((mealType, index) => ({
        label: mealType.label,
        // Make the first 3 meal columns larger (breakfast/lunch/dinner)
        width: index <= 2 ? '20%' : '15%'
    })));

    return columns;
};

export let TripMealsSummaryTable = ({ mealTypes, datesWithMeals, classes }) => {
    const columns = buildColumns(mealTypes);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map(({ label, width, ...rest }) => (
                        <TableCell key={label} style={{ width: width }} {...rest} className={classes.headCell}>{label}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {datesWithMeals.map(({ date, meals }) => (
                    <TableRow key={date} name="meal-summary">
                        <TableCell align="right" variant="head" className={classes.dateHeadCell}>
                            <strong>{moment(date).format('dddd')}</strong><br />
                            {moment(date).format('MMMM Do')}
                        </TableCell>
                        {meals.map(meal => (
                            <TableCell key={meal.mealTypeId}>
                                {Boolean(meal.owner) &&
                                    <div><strong>{meal.isMine ? 'You' : meal.owner.name}</strong></div>
                                }
                                {Boolean(meal.title) &&
                                    <span className={classes.mealTitle}>{meal.title}</span>
                                }
                                {Boolean(meal.notesHTML) &&
                                    <TripMealNotes meal={meal} />
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

TripMealsSummaryTable.propTypes = {
    mealTypes: PropTypes.array.isRequired,
    datesWithMeals: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
}

TripMealsSummaryTable = withStyles(theme => ({
    mealTitle: {
        whiteSpace: 'pre-line',
    },
    headCell: {
        fontSize: theme.typography.caption.fontSize,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    dateHeadCell: {
        fontWeight: 'normal',
        backgroundColor: theme.palette.background.highlight,
        padding: '24px',
    },
}))(TripMealsSummaryTable);
