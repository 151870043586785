"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.URLBuilder = void 0;

var URLBuilder = function URLBuilder(urlBase, url) {
  _classCallCheck(this, URLBuilder);

  this.path = function () {
    return url;
  };

  this.url = function () {
    return "".concat(urlBase).concat(url);
  };
};

exports.URLBuilder = URLBuilder;