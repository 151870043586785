"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipesLandingPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _components = require("../components");

var _RecipesLayout = require("./RecipesLayout");

var _typography = require("../typography");

var _CategoryCard = require("./CategoryCard");

var _CookbookCard = require("./CookbookCard");

var _ContentPageCard = require("./ContentPageCard");

var _InjectRecipesState = require("./InjectRecipesState");

var _ContentfulRichText = require("./ContentfulRichText");

var _RecipeCard = require("./RecipeCard");

var _contentfulHelpers = require("./contentfulHelpers");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RecipesLandingPage = function RecipesLandingPage(_ref) {
  var classes = _ref.classes;
  return _react.default.createElement(_InjectRecipesState.InjectRecipesState, null, function (_ref2) {
    var meals = _ref2.meals,
        featuredRecipes = _ref2.featuredRecipes,
        cookbooks = _ref2.cookbooks,
        pageGroups = _ref2.pageGroups,
        sections = _ref2.sections;

    var getMealImage = function getMealImage(list, name) {
      var entity = list.find(function (e) {
        return e.name === name;
      });
      return entity && entity.images ? (0, _contentfulHelpers.buildAvatarUrl)(entity.images[0]) : undefined;
    };

    return _react.default.createElement(_RecipesLayout.RecipesLayout, {
      page: "recipes-landing-page"
    }, _react.default.createElement(_core.Grid, {
      className: classes.container,
      container: true,
      spacing: 1,
      justify: "space-between"
    }, _react.default.createElement(_core.Grid, {
      item: true,
      xs: 12,
      md: 4,
      lg: 6
    }, _react.default.createElement(_typography.H2, null, sections.recipesLandingPageIntro.headingText), sections.recipesLandingPageIntro.content && _react.default.createElement(_ContentfulRichText.ContentfulRichText, {
      content: sections.recipesLandingPageIntro.content
    })), _react.default.createElement(_core.Grid, {
      item: true
    }, _react.default.createElement(_core.Grid, {
      container: true,
      spacing: 2
    }, _react.default.createElement(_core.Grid, {
      item: true
    }, _react.default.createElement(_CategoryCard.CategoryCard, {
      title: "Breakfasts",
      imageURL: getMealImage(meals, 'Breakfast'),
      filters: {
        meal: ['45G7c633z4Md3xSTfPtmIq']
      }
    })), _react.default.createElement(_core.Grid, {
      item: true
    }, _react.default.createElement(_CategoryCard.CategoryCard, {
      title: "Lunches",
      imageURL: getMealImage(meals, 'Lunch'),
      filters: {
        meal: ['5eKQhiEPfFFL31IdIBFAWJ']
      }
    })), _react.default.createElement(_core.Grid, {
      item: true
    }, _react.default.createElement(_CategoryCard.CategoryCard, {
      title: "Dinners",
      imageURL: getMealImage(meals, 'Dinner'),
      filters: {
        meal: ['7EUweX88l0Y8Nf70E3wGxm']
      }
    })), _react.default.createElement(_core.Grid, {
      item: true
    }, _react.default.createElement(_CategoryCard.CategoryCard, {
      title: "Snacks",
      imageURL: getMealImage(meals, 'Snacks'),
      filters: {
        meal: ['64CXQH0Ft00Ia4SGCOpw24']
      }
    })), _react.default.createElement(_core.Grid, {
      item: true
    }, _react.default.createElement(_CategoryCard.CategoryCard, {
      title: "Drinks",
      imageURL: getMealImage(meals, 'Drinks'),
      filters: {
        meal: ['5YqyGJVXSVu3oLDSmNe2f8']
      }
    }))))), _react.default.createElement(_core.Grid, {
      container: true,
      justify: "space-between"
    }, _react.default.createElement(_core.Grid, {
      item: true
    }, _react.default.createElement(_typography.H2, null, sections.featuredRecipeHeading.headingText)), _react.default.createElement(_core.Grid, {
      item: true
    }, _react.default.createElement(_typography.P, {
      gutterBottom: false
    }, _react.default.createElement(_components.Button, {
      "data-id": "view-featured-button",
      bare: true,
      to: "recipes",
      routeParams: {
        flag: ['featured']
      }
    }, "View All")))), _react.default.createElement(_core.Grid, {
      container: true,
      spacing: 2,
      className: classes.container
    }, _react.default.createElement(_core.Grid, {
      item: true,
      style: {
        width: '45%'
      }
    }, _react.default.createElement(_RecipeCard.RecipeCard, {
      height: 350,
      recipe: featuredRecipes[0]
    })), _react.default.createElement(_core.Grid, {
      item: true,
      style: {
        width: '55%'
      }
    }, _react.default.createElement(_core.Grid, {
      container: true,
      spacing: 2
    }, featuredRecipes.slice(1, 7).map(function (recipe) {
      return _react.default.createElement(_core.Grid, {
        item: true,
        sm: 4,
        key: recipe.recipeId
      }, _react.default.createElement(_RecipeCard.RecipeCard, {
        mini: true,
        height: 167,
        recipe: recipe
      }));
    })))), _react.default.createElement(_typography.H2, null, "Cookbooks"), _react.default.createElement(_core.Grid, {
      container: true,
      spacing: 3,
      className: classes.container
    }, cookbooks.map(function (cookbook) {
      return _react.default.createElement(_core.Grid, {
        item: true,
        sm: 6,
        key: cookbook.cookbookId
      }, _react.default.createElement(_CookbookCard.CookbookCard, {
        cookbook: cookbook
      }));
    })), _react.default.createElement("div", {
      className: classes.containerBackground
    }, _react.default.createElement(_typography.H2, null, sections.recipesLandingPageAdviceHeading.headingText), _react.default.createElement(_core.Grid, {
      container: true,
      spacing: 3
    }, pageGroups.advice.map(function (page) {
      return _react.default.createElement(_core.Grid, {
        item: true,
        key: page.pageId,
        sm: 4
      }, _react.default.createElement(_ContentPageCard.ContentPageCard, {
        page: page
      }));
    }))));
  });
};

exports.RecipesLandingPage = RecipesLandingPage;
RecipesLandingPage.propTypes = {
  classes: _propTypes.default.object
};
exports.RecipesLandingPage = RecipesLandingPage = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      marginBottom: theme.spacing(6)
    },
    containerBackground: {
      marginBottom: theme.spacing(6),
      marginLeft: -theme.spacing(5),
      marginRight: -theme.spacing(5),
      padding: theme.spacing(4),
      backgroundColor: theme.palette.layout.default.backgroundColor
    }
  };
})(RecipesLandingPage);