/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

export class ErrorPage extends Component {
    render() {
        return (
            <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: '#444', textAlign: 'center', padding: '50px' }}>
                <div>
                    <a href="https://www.thewanderlostproject.com">
                        <img src="/images/logo.png" alt="Wanderlost Project" />
                    </a>
                </div>

                <div>
                    <h1>We're sorry, something went wrong.</h1>
                    <h3>
                        Please try <a href="#" onClick={() => { window.location.reload(); return false; }} style={{ color: '#00A8BD', textDecoration: 'none' }}>reloading the page</a>.
                    </h3>
                </div>
            </div>
        );
    }
}
