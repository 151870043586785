"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MealTypeMealGrouper = void 0;

var _core = require("@wanderlost-sdk/core");

var MealTypeMealGrouper = function MealTypeMealGrouper() {
  _classCallCheck(this, MealTypeMealGrouper);

  this.group = function (_ref) {
    var mealTypes = _ref.mealTypes,
        meals = _ref.meals;
    return new _core.ItemGrouper({
      getGroupId: function getGroupId(mealType) {
        return mealType.mealTypeId;
      },
      getItemGroupId: function getItemGroupId(meal) {
        return meal.mealTypeId;
      },
      groupKey: 'meals'
    }).group({
      groups: mealTypes,
      items: meals
    });
  };
};

exports.MealTypeMealGrouper = MealTypeMealGrouper;