"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ItemGrouper_1 = require("./ItemGrouper");

var SingularItemGrouper = function SingularItemGrouper(_ref) {
  var _this = this;

  var getGroupId = _ref.getGroupId,
      getItemGroupId = _ref.getItemGroupId,
      groupKey = _ref.groupKey;

  _classCallCheck(this, SingularItemGrouper);

  this.group = function (_ref2) {
    var items = _ref2.items,
        groups = _ref2.groups;

    var grouped = _this.grouper.group({
      items: items,
      groups: groups
    });

    return grouped.map(function (group) {
      return Object.assign(Object.assign({}, group), _defineProperty({}, _this.groupKey, group[_this.groupKey][0] || null));
    });
  };

  this.grouper = new ItemGrouper_1.ItemGrouper({
    getGroupId: getGroupId,
    getItemGroupId: getItemGroupId,
    groupKey: groupKey
  });
  this.groupKey = groupKey;
};

exports.SingularItemGrouper = SingularItemGrouper;