import React from 'react';
import PropTypes from 'prop-types';
import { toOunces, formatWeight } from '@wanderlost-sdk/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { P } from '@wanderlost-sdk/components';

const styles = theme => ({
    root: {
        marginRight: theme.spacing(4),
        '& strong': {
            textTransform: 'uppercase',
        },
    },
});

const getWeight = item => item.formState ? item.formState.state.values.weight : item.weight;

export let PackingListTotalWeight = ({ items, classes }) => {
    const anyHasWeight = items.some(item => {
        const weight = getWeight(item);
        return weight && 'unit' in weight && 'value' in weight;
    });

    if (!anyHasWeight) {
        return null;
    }

    const totalWeightInOunces = items.reduce((total, item) => {
        const { qty } = item.formState ? item.formState.state.values : item;
        const weight = getWeight(item);
        return total + (weight ? toOunces(weight) * (qty || 1) : 0);
    }, 0);

    return (
        <P gutterBottom={false} className={classes.root}>
            <strong>Total Weight:</strong> {formatWeight({ value: totalWeightInOunces, unit: 'oz' }) || '0'}
        </P>
    );
};

PackingListTotalWeight.propTypes = {
    items: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
};

PackingListTotalWeight = withStyles(styles)(PackingListTotalWeight);
