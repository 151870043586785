"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DynamicEditor = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react2 = require("../../framework/react");

var _core = require("@material-ui/core");

var _components = require("../../components");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    // by default, hide toolbar and show top border
    editor: {
      '@global': {
        '.ql-toolbar': {
          display: 'none'
        },
        '.ql-container': {
          borderTop: '1px solid rgba(0, 0, 0, 0.12) !important'
        },
        '.ql-editor': _defineProperty({
          minHeight: '130px'
        }, "@media (min-width: ".concat(theme.breakpoints.values.lg, "px)"), {
          minHeight: '190px'
        })
      }
    },
    // on hover, make borders darker
    highlighted: {
      '@global': {
        '.ql-toolbar': {
          border: '1px solid rgba(0, 0, 0, 0.4)'
        },
        '.ql-container': {
          border: '1px solid rgba(0, 0, 0, 0.4) !important'
        }
      }
    },
    // on focus, show toolbar and hide top border (to avoid double border)
    editing: {
      '@global': {
        '.ql-toolbar': {
          display: 'block'
        },
        '.ql-container': {
          borderTop: 'none !important'
        },
        '.ql-editor': _defineProperty({
          minHeight: '88px'
        }, "@media (min-width: ".concat(theme.breakpoints.values.lg, "px)"), {
          minHeight: '148px'
        })
      }
    }
  };
};

var DynamicEditor = function DynamicEditor(_ref) {
  var html = _ref.html,
      name = _ref.name,
      readOnly = _ref.readOnly,
      highlighted = _ref.highlighted,
      onKeyUp = _ref.onKeyUp,
      onChange = _ref.onChange,
      classes = _ref.classes;
  return _react.default.createElement(_react2.InjectTogglable, {
    initialValue: false
  }, function (_ref2) {
    var isEditing = _ref2.isOn,
        startEditing = _ref2.on,
        stopEditing = _ref2.off;
    return _react.default.createElement(_components.RichTextStyles, null, _react.default.createElement(_components.RichTextEditor, {
      readOnly: readOnly,
      name: name,
      value: html,
      onChange: onChange,
      onKeyUp: onKeyUp,
      onFocus: function onFocus() {
        return !readOnly && startEditing();
      },
      onBlur: function onBlur() {
        return stopEditing();
      },
      className: "".concat(classes.editor, " ").concat(highlighted ? classes.highlighted : '', " ").concat(isEditing ? classes.editing : '')
    }));
  });
};

exports.DynamicEditor = DynamicEditor;
DynamicEditor.propTypes = {
  html: _propTypes.default.string.isRequired,
  name: _propTypes.default.string,
  readOnly: _propTypes.default.bool,
  highlighted: _propTypes.default.bool,
  onKeyUp: _propTypes.default.func,
  onChange: _propTypes.default.func.isRequired,
  classes: _propTypes.default.object
};
exports.DynamicEditor = DynamicEditor = (0, _core.withStyles)(styles)(DynamicEditor);