import React from 'react';
import { TripPackingListsFilter } from './TripPackingListsFilter';
import { PackingList } from './PackingList';

export const TripPackingList = ({ ...rest }) => {
    return (
        <>
            <div style={{ float: 'left' }}>
                <TripPackingListsFilter {...rest} />
            </div>
            <PackingList {...rest} showOwner={rest.showOwner} />
        </>
    );
};
