"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipesLayout = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _layout = require("../layout");

var _RecipesNav = require("./RecipesNav");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RecipesLayout = function RecipesLayout(_ref) {
  var page = _ref.page,
      breadcrumbs = _ref.breadcrumbs,
      children = _ref.children,
      noPadding = _ref.noPadding,
      props = _objectWithoutProperties(_ref, ["page", "breadcrumbs", "children", "noPadding"]);

  return _react.default.createElement(_layout.Layout, {
    breadcrumbs: breadcrumbs,
    page: page,
    noPadding: noPadding,
    BannerProps: _objectSpread({
      size: 'small',
      title: 'Recipes',
      imageURL: '/images/recipes-banner.png',
      nav: _react.default.createElement(_RecipesNav.RecipesNav, null)
    }, props)
  }, children);
};

exports.RecipesLayout = RecipesLayout;
RecipesLayout.propTypes = {
  children: _propTypes.default.node.isRequired,
  breadcrumbs: _propTypes.default.node,
  noPadding: _propTypes.default.bool,
  page: _propTypes.default.string
};