"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InjectRoutesAsFolders = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _state = require("../state");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var InjectRoutesAsFolders = function InjectRoutesAsFolders(_ref) {
  var children = _ref.children;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _state.TripRoutesState
  }, function (_ref2) {
    var getTrip = _ref2.getTrip,
        routes = _ref2.routes,
        asFoldersGroupedByDay = _ref2.asFoldersGroupedByDay;
    return children(_objectSpread({
      trip: getTrip(),
      routes: routes
    }, asFoldersGroupedByDay()));
  });
};

exports.InjectRoutesAsFolders = InjectRoutesAsFolders;
InjectRoutesAsFolders.propTypes = {
  children: _propTypes.default.func.isRequired
};