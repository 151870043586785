import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { TripCard } from '@wanderlost-sdk/components';
import { WithStyles } from '../framework/styles';

const tripStyles = theme => ({
    container: {
        marginBottom: `${theme.spacing(2)}px`
    }
});

export const TripsList = ({ classes, trips, ...other }) => (
    <WithStyles styles={tripStyles}>
        {({ classes }) => (
            <Grid container spacing={3} className={classes.container} {...other}>
                {trips.map(trip => (
                    <Grid item xs={12} md={6} lg={4} key={trip.tripId}>
                        <TripCard
                            trip={trip}
                        />
                    </Grid>
                ))}
            </Grid>
        )}
    </WithStyles>
);

TripsList.propTypes = {
    trips: PropTypes.array.isRequired,
}
