"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SettingsStore = void 0;
/* globals window:readonly */

var SettingsStore = function SettingsStore() {
  _classCallCheck(this, SettingsStore);

  this.getItem = function () {
    var _window$localStorage;

    return (_window$localStorage = window.localStorage).getItem.apply(_window$localStorage, arguments);
  };

  this.setItem = function () {
    var _window$localStorage2;

    return (_window$localStorage2 = window.localStorage).setItem.apply(_window$localStorage2, arguments);
  };

  this.removeItem = function () {
    var _window$localStorage3;

    return (_window$localStorage3 = window.localStorage).removeItem.apply(_window$localStorage3, arguments);
  };
};

exports.SettingsStore = SettingsStore;