"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripPaper = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TripPaper = function TripPaper(_ref) {
  var children = _ref.children,
      classes = _ref.classes,
      className = _ref.className;
  return _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement(_core.Paper, {
    className: (0, _classnames.default)(className, classes.content)
  }, children));
};

exports.TripPaper = TripPaper;
TripPaper.propTypes = {
  children: _propTypes.default.node.isRequired,
  classes: _propTypes.default.object.isRequired,
  className: _propTypes.default.string
};
exports.TripPaper = TripPaper = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      display: 'flex' // so that it shrinks to content

    },
    content: {
      padding: theme.spacing(4),
      flex: 1
    },
    '@media print': {
      content: {
        boxShadow: 'none',
        paddingTop: 0
      }
    }
  };
})(TripPaper);