"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WanderLostPrincipal = void 0;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var WanderLostPrincipal = function WanderLostPrincipal() {
  _classCallCheck(this, WanderLostPrincipal);

  _defineProperty(this, "userId", '');

  _defineProperty(this, "email", '');

  _defineProperty(this, "name", '');

  _defineProperty(this, "pictureURL", '');
};

exports.WanderLostPrincipal = WanderLostPrincipal;