import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { InjectLoadAndSubscribe, P, Button, SectionV2 } from '@wanderlost-sdk/components';
import { TripPackingListsState } from './state/TripPackingListsState';
import { PackingList } from './PackingList';

export let TripPackingListSummary = ({ trip, canEdit = false }) => (
    <div name="packing-list-summary">
        <InjectLoadAndSubscribe diKey={TripPackingListsState}>
            {({getMyPackingList, getTripOwnerPackingList}) => {

                // show my packing list if I have one, otherwise show owner packing list (for featured trips)
                const myPackingList = getMyPackingList();
                const packingList = myPackingList || getTripOwnerPackingList() || { items: [] };

                return (
                    <SectionV2
                        title="Packing List"
                        actions={
                            canEdit &&
                                <Button to="tripPackingLists" routeParams={{tripId: trip.tripId }} color="primary" variant="contained" size="small" name="manage-packing-list">Plan Gear</Button>
                        }
                        iconImageURL={'/images/section-icons/gear.svg'}
                    >
                        {!packingList.items.length && Boolean(myPackingList) &&
                            <P gutterBottom={false}>
                                Looks like you haven't added anything to the trip yet!<br />
                                <span><Button link to="tripPackingLists" routeParams={{tripId: trip.tripId}}>Add Gear</Button> to get started!</span>
                            </P>
                        }

                        <PackingList packingList={packingList} groupHeadingProps={{ variant: 'h3' }} />
                    </SectionV2>
                );
            }}
        </InjectLoadAndSubscribe>
    </div>
);

TripPackingListSummary.propTypes = {
    trip: PropTypes.object.isRequired,
    gutterBottom: PropTypes.bool,
    canEdit: PropTypes.bool,
    classes: PropTypes.object.isRequired
};

TripPackingListSummary = withStyles((theme) => ({
}))(TripPackingListSummary);

