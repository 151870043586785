"use strict";

var _slicedToArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextMenu = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Popper = _interopRequireDefault(require("@material-ui/core/Popper"));

var _Paper = _interopRequireDefault(require("@material-ui/core/Paper"));

var _ClickAwayListener = _interopRequireDefault(require("@material-ui/core/ClickAwayListener"));

var _Grow = _interopRequireDefault(require("@material-ui/core/Grow"));

var _IconButton = _interopRequireDefault(require("@material-ui/core/IconButton"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _MoreVert = _interopRequireDefault(require("@material-ui/icons/MoreVert"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles() {
  return {
    root: {},
    button: {},
    popper: {
      zIndex: 1150 // must be > AppBar zIndex (1100) but below dialog (1200)

    }
  };
};

var ContextMenu = function ContextMenu(_ref) {
  var name = _ref.name,
      buttonComponent = _ref.buttonComponent,
      classes = _ref.classes,
      children = _ref.children,
      _ref$placement = _ref.placement,
      placement = _ref$placement === void 0 ? 'bottom-start' : _ref$placement,
      rest = _objectWithoutProperties(_ref, ["name", "buttonComponent", "classes", "children", "placement"]);

  var anchorRef = _react.default.useRef(null);

  var _react$default$useSta = _react.default.useState(false),
      _react$default$useSta2 = _slicedToArray(_react$default$useSta, 2),
      isOpen = _react$default$useSta2[0],
      setIsOpen = _react$default$useSta2[1];

  var _react$default$useSta3 = _react.default.useState(false),
      _react$default$useSta4 = _slicedToArray(_react$default$useSta3, 2),
      isHidden = _react$default$useSta4[0],
      setIsHidden = _react$default$useSta4[1];

  var closeMenu = function closeMenu() {
    setIsOpen(false);
  }; // true if parent contains or is child


  var containsElement = function containsElement(parent, child) {
    if (!child) {
      return false;
    }

    if (child === parent) {
      return true;
    }

    return containsElement(parent, child.parentElement);
  };

  var handleClick = function handleClick(e) {
    // if this was a click on the root element
    if (containsElement(anchorRef.current, e.target)) {
      setIsOpen(!isOpen || isHidden);
      setIsHidden(false); // otherwise its a click on a menu item
    } else {
      if (e.target.getAttribute('role') === 'menuitem') {
        // if it's a link, then we assume the menu should be closed immediately
        closeMenu();
      } else {
        // otherwise, the handler may require child components to remain rendered, so
        // hide it for now, and require the handler to invoke closeMenu() when it's done
        setIsHidden(true);
      }
    }
  };

  return _react.default.createElement("div", _extends({
    className: classes.root
  }, rest), _react.default.createElement(_ClickAwayListener.default, {
    onClickAway: closeMenu
  }, _react.default.createElement(_IconButton.default, {
    onClick: handleClick,
    buttonRef: function buttonRef(node) {
      anchorRef.current = node;
    },
    disableRipple: true,
    className: classes.button,
    name: name
  }, buttonComponent ? buttonComponent : _react.default.createElement(_MoreVert.default, null), _react.default.createElement(_Popper.default, {
    anchorEl: anchorRef.current,
    open: isOpen,
    onClose: closeMenu,
    placement: placement,
    transition: true,
    className: classes.popper,
    style: {
      display: isHidden ? 'none' : ''
    }
  }, function (_ref2) {
    var TransitionProps = _ref2.TransitionProps;
    return _react.default.createElement(_Grow.default, _extends({}, TransitionProps, {
      style: {
        transformOrigin: 'center top'
      }
    }), _react.default.createElement(_Paper.default, null, typeof children === 'function' ? children({
      closeMenu: closeMenu
    }) : children));
  }))));
};

exports.ContextMenu = ContextMenu;
ContextMenu.propTypes = {
  name: _propTypes.default.string,
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.oneOfType([_propTypes.default.node, _propTypes.default.func]).isRequired,
  buttonComponent: _propTypes.default.node,
  placement: _propTypes.default.string
};
exports.ContextMenu = ContextMenu = (0, _withStyles.default)(styles)(ContextMenu);