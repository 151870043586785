"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripPageHeading = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _typography = require("../typography");

var _Display = require("../components/Display");

var _core = require("@material-ui/core");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TripPageHeading = function TripPageHeading(_ref) {
  var actionsComponent = _ref.actionsComponent,
      children = _ref.children,
      classes = _ref.classes;
  return _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement("div", {
    className: classes.content
  }, _react.default.createElement(_typography.H1, null, children), _react.default.createElement(_Display.Display, {
    screen: true
  }, actionsComponent)));
};

exports.TripPageHeading = TripPageHeading;
TripPageHeading.propTypes = {
  children: _propTypes.default.node.isRequired,
  actionsComponent: _propTypes.default.node,
  classes: _propTypes.default.object.isRequired
};
exports.TripPageHeading = TripPageHeading = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      marginBottom: theme.spacing(2)
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  };
})(TripPageHeading);