import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ItineraryEditor, InjectLoadAndSubscribe, SectionV2 } from '@wanderlost-sdk/components';
import { TripItinerariesState } from './state/TripItinerariesState';

export class TripItinerary extends Component {
    static propTypes = {
        dayNumber: PropTypes.number.isRequired,
        canEdit: PropTypes.bool
    }

    render() {
        const { dayNumber, canEdit = false } = this.props;
        return (
            <InjectLoadAndSubscribe diKey={TripItinerariesState}>
                {({ itineraries }) => (
                    <SectionV2
                        title="Itinerary &amp; Notes"
                        iconImageURL={'/images/section-icons/notes.svg'}
                        name="itinerary"
                    >
                        <div>
                            <ItineraryEditor
                                itinerary={itineraries[dayNumber - 1]}
                                readOnly={!canEdit}
                            />
                        </div>
                    </SectionV2>
                )}
            </InjectLoadAndSubscribe>
        );
    }
}
