"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var numbers_1 = require("../numbers");

;

exports.toOunces = function (_ref) {
  var value = _ref.value,
      unit = _ref.unit;

  if (!value) {
    return 0;
  }

  if (unit === 'oz') {
    return value || 0;
  }

  if (unit === 'lb') {
    return (value || 0) * 16;
  }

  throw new Error("Unrecognized weight unit \"".concat(unit, "\""));
};

exports.formatPounds = function (value) {
  return numbers_1.round(value, 2).toLocaleString();
};

exports.formatOunces = function (value) {
  return numbers_1.round(value, 1).toLocaleString();
};

exports.formatWeight = function (_ref2) {
  var value = _ref2.value,
      unit = _ref2.unit;

  if (!value) {
    return '';
  }

  if (unit === 'lb') {
    return "".concat(exports.formatPounds(value), " ").concat(unit);
  }

  if (unit === 'oz') {
    if (value <= 16) {
      return "".concat(exports.formatOunces(value), " ").concat(unit);
    }

    var pounds = Math.floor(value / 16);
    var ounces = value % 16;
    return ounces ? "".concat(exports.formatPounds(pounds), " lb, ").concat(exports.formatOunces(ounces), " oz") : "".concat(exports.formatPounds(pounds), " lb");
  }

  throw new Error("Unrecognized weight unit \"".concat(unit, "\""));
};