"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MVUMTrailStyler = void 0;

var _ZIndexManager = require("../ZIndexManager");

var _TrailStyler = require("./TrailStyler");

var _style = require("ol/style.js");

var _EventBus = require("../EventBus");

var _hexToRgba = require("./hexToRgba");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var MVUMTrailStyler = function MVUMTrailStyler(_ref, zIndexManager, eventBus) {
  var MVUM_COLOR = _ref.MVUM;

  _classCallCheck(this, MVUMTrailStyler);

  _defineProperty(this, "styleKey", 'MVUM');
  /**
   * Changing opacity via layer.setOpacity doesn't work for the global trails layer
   * So we have to do this via rgba on the styles, so styleCache changes with opacity
   * See: https://github.com/openlayers/openlayers/issues/11284
   */


  var styleCache;
  eventBus.on('layer:opacityChanged', function (_ref2) {
    var layerId = _ref2.layerId,
        opacity = _ref2.opacity;

    if (layerId !== 'layer-global-trails') {
      return;
    } // We are coupled to this being called before we render the map
    // Otherwise styleCache won't be defined


    updateStyleCache({
      opacity: opacity
    });
  });

  var updateStyleCache = function updateStyleCache(_ref3) {
    var opacity = _ref3.opacity;
    styleCache = {
      HIGHLIGHT: function HIGHLIGHT(_ref4) {
        var zIndex = _ref4.zIndex;

        if (!opacity) {
          return [];
        }

        return [new _style.Style({
          stroke: new _style.Stroke({
            color: "rgba(255, 210, 0, ".concat(opacity * 0.35, ")"),
            width: 14
          }),
          zIndex: zIndex
        })];
      },
      DETAILED_OUTER_STROKE: new _style.Stroke({
        color: (0, _hexToRgba.hexToRgbaString)(MVUM_COLOR, opacity),
        width: 4
      }),
      SIMPLIFIED_STYLE: [new _style.Style({
        stroke: new _style.Stroke({
          color: (0, _hexToRgba.hexToRgbaString)(MVUM_COLOR, opacity),
          width: 3
        }),
        zIndex: 1
      })],
      UBER_SIMPLIFIED_STYLE: [new _style.Style({
        stroke: new _style.Stroke({
          color: (0, _hexToRgba.hexToRgbaString)(MVUM_COLOR, opacity),
          width: 2
        }),
        zIndex: 1
      })]
    };
    eventBus.emit('styles:changed');
  };

  this.getUberSimplifiedStyle = function () {
    return styleCache.UBER_SIMPLIFIED_STYLE;
  };

  this.getSimplifiedStyle = function () {
    return styleCache.SIMPLIFIED_STYLE;
  };

  this.getDetailedStyle = function (feature) {
    var zIndex = zIndexManager.getZIndexForMapRender(feature);
    return [new _style.Style({
      stroke: styleCache.DETAILED_OUTER_STROKE,
      zIndex: zIndex
    })];
  };

  this.getHighlightStyle = function (_ref5) {
    var zIndex = _ref5.zIndex;
    return styleCache.HIGHLIGHT({
      zIndex: zIndex
    });
  };
};

exports.MVUMTrailStyler = MVUMTrailStyler;

_defineProperty(MVUMTrailStyler, "inject", function () {
  return [_TrailStyler.TrailColors, _ZIndexManager.ZIndexManager, _EventBus.EventBus];
});