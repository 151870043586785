"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InjectHideFolder = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _greenState = require("@symbiotic/green-state");

var _HiddenFoldersState = require("./HiddenFoldersState");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var InjectHideFolder = function InjectHideFolder(_ref) {
  var children = _ref.children,
      folder = _ref.folder;
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _HiddenFoldersState.HiddenFoldersState
  }, function (hideFolderState) {
    return children({
      isVisible: hideFolderState.isVisible(folder.folderId),
      show: function show() {
        return hideFolderState.show(folder);
      },
      hide: function hide() {
        return hideFolderState.hide(folder);
      }
    });
  });
};

exports.InjectHideFolder = InjectHideFolder;
InjectHideFolder.propTypes = {
  children: _propTypes.default.func.isRequired,
  folder: _propTypes.default.object.isRequired
};