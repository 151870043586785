"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TooManyFeaturesPlugin = void 0;

var _EventBus = require("./EventBus");

var _state = require("./state");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var TooManyFeaturesPlugin = function TooManyFeaturesPlugin(mapErrorState, eventBus) {
  _classCallCheck(this, TooManyFeaturesPlugin);

  this.start = function () {
    var mapErrorStateHasOurError = false;
    var layersWithTooManyFeatures = {}; // TODO: still a potential bug here where the current viewport has not failed...
    // but the last response to come in a previous viewport has...

    eventBus.on('features.loaded', function (_ref) {
      var layerId = _ref.layerId,
          error = _ref.error;

      if (error) {
        layersWithTooManyFeatures[layerId] = error;
      } else {
        delete layersWithTooManyFeatures[layerId];
      } // TODO: feels like MapErrorState needs to evolve so we give it an error and clear an error
      //        so it can track this (and so we dont' clear someone else's error)


      var anyHaveTooManyFeatures = Object.keys(layersWithTooManyFeatures).length > 0;

      if (anyHaveTooManyFeatures && !mapErrorStateHasOurError) {
        var firstLayerId = Object.keys(layersWithTooManyFeatures)[0];
        mapErrorState.error(layersWithTooManyFeatures[firstLayerId]);
        mapErrorStateHasOurError = true;
      } else if (!anyHaveTooManyFeatures && mapErrorStateHasOurError) {
        mapErrorState.clear();
        mapErrorStateHasOurError = false;
      }
    });
  };

  this.dispose = function () {// do nothing... event bus will be disposed of for us
  };
};

exports.TooManyFeaturesPlugin = TooManyFeaturesPlugin;

_defineProperty(TooManyFeaturesPlugin, "inject", function () {
  return [_state.MapErrorState, _EventBus.EventBus];
});