"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureAttributeKeyValueRenderStrategy = void 0;

var _react = _interopRequireDefault(require("react"));

var _FeatureAttribute = require("./FeatureAttribute");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FeatureAttributeKeyValueRenderStrategy = function FeatureAttributeKeyValueRenderStrategy() {
  _classCallCheck(this, FeatureAttributeKeyValueRenderStrategy);

  _defineProperty(this, "strategyId", 'key-value'); // eslint-disable-next-line react/display-name, react/prop-types


  this.render = function (_ref) {
    var value = _ref.value,
        label = _ref.label,
        _ref$FeatureAttribute = _ref.FeatureAttributeProps,
        FeatureAttributeProps = _ref$FeatureAttribute === void 0 ? {} : _ref$FeatureAttribute;
    return _react.default.createElement(_FeatureAttribute.FeatureAttribute, _extends({
      value: value,
      label: label
    }, FeatureAttributeProps));
  };
};

exports.FeatureAttributeKeyValueRenderStrategy = FeatureAttributeKeyValueRenderStrategy;