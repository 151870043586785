import React, { Component } from 'react';
import { hasAction } from '@wanderlost-sdk/core';
import { InjectTripDetails } from '../InjectTripDetails';
import { TripMealsSummary } from './TripMealsSummary';
import { Button, TripPageHeading, TripBreadcrumbs, TripBreadcrumbItem } from '@wanderlost-sdk/components';
import { TripDetailsLayout } from '../TripDetailsLayout';

export class TripMealsSummaryPage extends Component {
    render() {
        return (
            <InjectTripDetails>
                {({ trip }) => {
                    const canEdit = hasAction(trip, 'self', 'put');

                    return (
                        <TripDetailsLayout
                            trip={trip}
                            breadcrumbs={
                                <TripBreadcrumbs trip={trip}>
                                    <TripBreadcrumbItem>Meals</TripBreadcrumbItem>
                                </TripBreadcrumbs>
                            }
                            page="trip-meals-summary-page"
                        >
                            <TripPageHeading
                                actionsComponent={canEdit
                                    ? <Button to="tripMealsManage" routeParams={{tripId: trip.tripId}} color="primary" variant="contained" name="manage-meals">Plan for Whole Trip</Button>
                                    : null
                                }
                            >
                                Trip Meal Plan
                            </TripPageHeading>

                            <TripMealsSummary trip={trip} />
                        </TripDetailsLayout>
                    );
                }}
            </InjectTripDetails>
        );
    }
}
