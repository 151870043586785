import React, { Component } from 'react';
import compose from 'compose-function';
import PropTypes from 'prop-types';
import { WanderLostNavigator, InjectLoadAndSubscribe } from '@wanderlost-sdk/components';
import { TripsState } from '../TripsState';
import { withDependencies } from '@symbiotic/green-state';
import { FolderSharingState, FolderSharesForm } from '@wanderlost-sdk/cartographer';
import { withStyles } from '@material-ui/core';

export class TripTeamList extends Component {
    static propTypes = {
        trip: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
    }

    handleRemoveSelf = async () => {
        const { navigator, tripsState } = this.props;

        // User no longer has access to the trip, refresh the trip list
        await tripsState.load();

        // ... then send them to the trip list
        navigator.tripList().go();
    }

    render() {
        const { classes, trip, ...rest } = this.props;

        return (
            <InjectLoadAndSubscribe diKey={FolderSharingState}>
                {({ shares, add }) => (
                    <div className={classes.container}>
                        <FolderSharesForm
                            folder={trip}
                            labelText="Wander with friends!"
                            buttonText="Invite"
                            sharesListTitle="Who is invited?"
                            shares={shares}
                            addShare={({ email }) => add({ email, role: 'manage' })}
                            showContentScrollbars={false}
                            {...rest}
                        />
                    </div>
                )}
            </InjectLoadAndSubscribe>
        );
    }

}

TripTeamList = compose(
    withStyles({
        container: {
            // So the share form isn't too narrow or wide
            minWidth: 500,
        }
    }),
    withDependencies({
        navigator: WanderLostNavigator,
        tripsState: TripsState
    })
)(TripTeamList);
