"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

var WanderlostError_1 = require("./errors/WanderlostError");

exports.WanderlostError = WanderlostError_1.WanderlostError;

var FolderMoveValidator_1 = require("./validation/FolderMoveValidator");

exports.FolderMoveValidator = FolderMoveValidator_1.FolderMoveValidator;

__export(require("./arrays"));

__export(require("./numbers"));

__export(require("./dates"));

__export(require("./async"));

__export(require("./objects"));

__export(require("./queues"));

__export(require("./weights"));

__export(require("./actions"));

exports.logCypress = function (message, cypressLogArgs) {
  window.cypressLog = window.cypressLog || [];
  window.cypressLog.push({
    message: message,
    cypressLogArgs: cypressLogArgs
  });
};