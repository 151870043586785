"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MarkerValidator = void 0;

var _core = require("@wanderlost-sdk/core");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/**
 * Restirct waypoints to a bounding box to reduce user errors
 * It seems far more likely right now that a user accidentally reverses lat/lng
 * and end up in russia or south america by accident than that we are actually trying to support waypoints in those areas
 * If we ever want to remove this we can just set it to -180/180 and -90/90 to ensure we have validate lat/lngs
 * This bbox includes US + Canada
 * Generated using https://boundingbox.klokantech.com/
 */


var MarkerValidatorConfig = function MarkerValidatorConfig() {
  _classCallCheck(this, MarkerValidatorConfig);

  _defineProperty(this, "MIN_X", -183.1640625);

  _defineProperty(this, "MAX_X", -51.2806307222);

  _defineProperty(this, "MIN_Y", 17.811456088564483);

  _defineProperty(this, "MAX_Y", 72.3424643905499);
};

var MarkerValidator = function MarkerValidator(_ref) {
  var MIN_X = _ref.MIN_X,
      MIN_Y = _ref.MIN_Y,
      MAX_X = _ref.MAX_X,
      MAX_Y = _ref.MAX_Y;

  _classCallCheck(this, MarkerValidator);

  this.validate = function (_ref2) {
    var name = _ref2.attributes.name,
        _ref2$geometry = _ref2.geometry,
        x = _ref2$geometry.x,
        y = _ref2$geometry.y;
    var errors = {};

    if (!name) {
      errors.name = 'Name is required';
    }

    if (y === null) {
      errors.geometry = _objectSpread({}, errors.geometry || {}, {
        y: 'Latitude is required'
      });
    } else if (!isFiniteNumber(y)) {
      errors.geometry = _objectSpread({}, errors.geometry || {}, {
        y: 'Latitude is invalid'
      });
    } else if (y < MIN_Y || y > MAX_Y) {
      errors.geometry = _objectSpread({}, errors.geometry || {}, {
        y: 'Latitude is out of range'
      });
    }

    if (x === null) {
      errors.geometry = _objectSpread({}, errors.geometry || {}, {
        x: 'Longitude is required'
      });
    } else if (!isFiniteNumber(x)) {
      errors.geometry = _objectSpread({}, errors.geometry || {}, {
        x: 'Longitude is invalid'
      });
    } else if (x < MIN_X || x > MAX_X) {
      errors.geometry = _objectSpread({}, errors.geometry || {}, {
        x: 'Longitude is out of range'
      });
    }

    if (Object.keys(errors).length > 0) {
      throw new _core.WanderlostError({
        statusCode: 400,
        message: 'Invalid waypoint',
        details: errors
      });
    }
  };
};

exports.MarkerValidator = MarkerValidator;

_defineProperty(MarkerValidator, "inject", function () {
  return [MarkerValidatorConfig];
});

var isFiniteNumber = function isFiniteNumber(value) {
  return Number.isFinite(value);
};