"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TrailContextMenuFactory = void 0;

var _ = require(".");

var TrailContextMenuFactory = function TrailContextMenuFactory() {
  var _this = this;

  _classCallCheck(this, TrailContextMenuFactory);

  this.createContextMenu = function (_ref) {
    var map = _ref.map,
        onOpen = _ref.onOpen,
        onClose = _ref.onClose,
        onFeaturesChanged = _ref.onFeaturesChanged,
        editFeature = _ref.editFeature;
    return new _.ContextMenuInteraction({
      filter: function filter(feature) {
        return feature.get('type') === 'trail';
      },
      onOpen: onOpen,
      onClose: onClose,
      getFeatureActions: function getFeatureActions(_ref2) {
        var feature = _ref2.feature,
            layer = _ref2.layer,
            closestCoordinate = _ref2.closestCoordinate;
        return _this.getFeatureActions({
          feature: feature,
          layer: layer,
          map: map,
          onFeaturesChanged: onFeaturesChanged,
          editFeature: editFeature,
          closestCoordinate: closestCoordinate
        });
      }
    });
  };

  this.getFeatureActions = function (_ref3) {
    var map = _ref3.map,
        feature = _ref3.feature,
        layer = _ref3.layer,
        onFeaturesChanged = _ref3.onFeaturesChanged,
        editFeature = _ref3.editFeature,
        closestCoordinate = _ref3.closestCoordinate;
    var actions = [];

    if (layer === map.getEditingLayer()) {
      actions = actions.concat([{
        text: 'Delete',
        callback: function callback() {
          map.getEditingSource().removeFeature(feature);
          onFeaturesChanged();
        }
      }, {
        text: 'Edit',
        callback: function callback() {
          editFeature({
            feature: feature,
            closestCoordinate: closestCoordinate
          });
        }
      }]);
    }

    return {
      title: feature.get('name') || 'Unnamed Trail',
      actions: actions
    };
  };
};

exports.TrailContextMenuFactory = TrailContextMenuFactory;