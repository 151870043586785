"use strict";

var _toConsumableArray = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureAttributesMerger = void 0;

var FeatureAttributesMerger = function FeatureAttributesMerger() {
  _classCallCheck(this, FeatureAttributesMerger);

  var mergeUniqueValues = function mergeUniqueValues() {
    for (var _len = arguments.length, values = new Array(_len), _key = 0; _key < _len; _key++) {
      values[_key] = arguments[_key];
    }

    return _toConsumableArray(new Set(values)).filter(Boolean).join(', ');
  };

  var getMergedZIndex = function getMergedZIndex(f1, f2) {
    var f1ZIndex = f1.get('zIndex');
    var f2ZIndex = f2.get('zIndex'); // return the lower zIndex

    return f1ZIndex < f2ZIndex ? f1ZIndex : f2ZIndex;
  };

  var attributesToMerge = ['name', 'description'];

  this.mergeFeatureAttributes = function (f1, f2) {
    var mergedAttributes = {
      // No need to merge type, shouldnt allow merging 2 features of different types
      type: f1.get('type'),
      zIndex: getMergedZIndex(f1, f2)
    };
    attributesToMerge.forEach(function (attribute) {
      var mergedValue = mergeUniqueValues(f1.get(attribute), f2.get(attribute));

      if (mergedValue) {
        mergedAttributes[attribute] = mergedValue;
      }
    });
    return mergedAttributes;
  };
};
/*
function mergeAttributesForGlue(feature1, feature2) {
    const mergedAttributes = {};
    const attributesToMerge = ['name', 'description'];
    attributesToMerge.forEach(attribute => {
        const uniqueValues = new Set([feature1.get(attribute), feature2.get(attribute)]);

    });

    return mergedAttributes;
}
*/


exports.FeatureAttributesMerger = FeatureAttributesMerger;