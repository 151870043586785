import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import { P, Display } from '@wanderlost-sdk/components';

const styles = {
    columns: {
        columnCount: 3,
        columnFill: 'auto',
    },
    checkColumn: {
        textAlign: 'center',
        position: 'relative',
        top: '-3px',
        marginRight: '4px',
    },
    nameColumn: {
        flexGrow: 1,
    },
    nameValue: {
        lineHeight: 1.4
    },
    '@media print': {
        checkbox: {
            padding: 0,

            '& svg': {
                width: '20px'
            }
        }
    },
};

export let SimplePackingListSummary = ({ classes, packingList }) => {
    return (
        <div className={classes.columns}>
            {packingList.items.map(item => (
                <div key={item.itemId} name="packing-list-item" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Display print>
                        <div className={classes.checkColumn} style={{ }}>
                            <P gutterBottom={false}><Checkbox size="small" className={classes.checkbox} /></P>
                        </div>
                    </Display>
                    <div className={classes.nameColumn} data-type="packing-list-item-name">
                        <P gutterBottom={false} className={classes.nameValue}>{item.name || <i>(Unnamed)</i>}</P>
                    </div>
                </div>
            ))}
        </div>
    );
};

SimplePackingListSummary.propTypes = {
    classes: PropTypes.object.isRequired,
    packingList: PropTypes.object.isRequired,
};

SimplePackingListSummary = withStyles(styles)(SimplePackingListSummary);
