"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AllTripsList = void 0;

var _react = _interopRequireDefault(require("react"));

var _react2 = require("../framework/react");

var _greenState = require("@symbiotic/green-state");

var _TripsState = require("./TripsState");

var _TripList = require("./TripList");

var _core = require("@material-ui/core");

var _typography = require("../typography");

var _RefreshButton = require("../components/Button/RefreshButton");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Not ready for web to adopt
 * (because TripsState is not ready)
 */


var AllTripsList = function AllTripsList() {
  return _react.default.createElement(_greenState.InjectAndSubscribe, {
    diKey: _TripsState.TripsState
  }, function (_ref) {
    var upcomingTrips = _ref.upcomingTrips,
        pastTrips = _ref.pastTrips,
        refreshTrips = _ref.refreshTrips,
        asOf = _ref.asOf;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '.5em'
      }
    }, _react.default.createElement(_typography.H2, {
      gutterBottom: false
    }, "Your Upcoming Trips"), _react.default.createElement(_RefreshButton.RefreshButton, {
      renderLabel: function renderLabel(_ref2) {
        var isRefreshing = _ref2.isRefreshing;
        return isRefreshing ? 'Refreshing...' : 'Refresh Trips';
      },
      asyncRefresh: refreshTrips,
      "data-name": "refresh-trips",
      asOf: asOf,
      style: {
        minWidth: '125px'
      }
    })), _react.default.createElement(_core.Grid, {
      container: true,
      spacing: 3
    }, _react.default.createElement(_core.Grid, {
      item: true,
      sm: 12
    }, upcomingTrips.length > 0 ? _react.default.createElement(_TripList.TripList, {
      trips: upcomingTrips
    }) : _react.default.createElement(_typography.P, null, "You have no upcoming trips, you should plan a trip!"))), pastTrips.length > 0 && _react.default.createElement(_react2.InjectTogglable, {
      initialValue: upcomingTrips.length === 0
    }, function (_ref3) {
      var showPastTrips = _ref3.isOn,
          show = _ref3.on;
      return _react.default.createElement(_react.default.Fragment, null, !showPastTrips && _react.default.createElement(_core.Button, {
        onClick: show,
        variant: "outlined",
        color: "primary",
        "show-past-trips-button": "true"
      }, "Show past trips"), showPastTrips && _react.default.createElement(_core.Grid, {
        container: true,
        spacing: 3
      }, _react.default.createElement(_core.Grid, {
        item: true,
        sm: 12
      }, _react.default.createElement(_typography.H2, null, "Past Trips"), _react.default.createElement(_TripList.TripList, {
        trips: pastTrips,
        "past-trips-list": "true"
      }))));
    }));
  });
};

exports.AllTripsList = AllTripsList;