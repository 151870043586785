"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CookbookDetailsPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../typography");

var _components = require("../components");

var _RecipesNotFoundPage = require("./RecipesNotFoundPage");

var _InjectRecipesState = require("./InjectRecipesState");

var _ContentfulRichText = require("./ContentfulRichText");

var _RecipeHeadings = require("./RecipeHeadings");

var _contentfulHelpers = require("./contentfulHelpers");

var _RecipesLayout = require("./RecipesLayout");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var CookbookDetailsPage = function CookbookDetailsPage(_ref) {
  var cookbookId = _ref.cookbookId,
      classes = _ref.classes;
  return _react.default.createElement(_InjectRecipesState.InjectRecipesState, null, function (_ref2) {
    var cookbooks = _ref2.cookbooks;
    var cookbook = cookbooks.find(function (c) {
      return c.cookbookId === cookbookId;
    });

    if (!cookbook) {
      return _react.default.createElement(_RecipesNotFoundPage.RecipesNotFoundPage, null);
    }

    var name = cookbook.name,
        description = cookbook.description,
        _cookbook$author = cookbook.author,
        author = _cookbook$author === void 0 ? {} : _cookbook$author;
    return _react.default.createElement(_RecipesLayout.RecipesLayout, {
      page: "cookbook-details-page",
      className: classes.page,
      breadcrumbs: _react.default.createElement(_components.Breadcrumbs, null, _react.default.createElement(_components.BreadcrumbItem, {
        to: "recipesHome"
      }, "Recipes"), _react.default.createElement(_components.BreadcrumbItem, null, name))
    }, _react.default.createElement(_RecipeHeadings.H1, null, name), _react.default.createElement(_core.Grid, {
      container: true,
      spacing: 5
    }, _react.default.createElement(_core.Grid, {
      "data-id": "cookbook-info",
      item: true,
      sm: 8,
      lg: 9
    }, _react.default.createElement(_ContentfulRichText.ContentfulRichText, {
      content: description
    }), _react.default.createElement(_RecipeHeadings.H2, null, "About the Author"), _react.default.createElement(_ContentfulRichText.ContentfulRichText, {
      content: author.biography
    })), _react.default.createElement(_core.Grid, {
      "data-id": "author-info",
      item: true,
      sm: 4,
      lg: 3
    }, _react.default.createElement("img", {
      src: (0, _contentfulHelpers.buildThumbnailUrl)(cookbook.logo),
      alt: cookbook.name,
      className: classes.image
    }), _react.default.createElement("div", {
      className: classes.author
    }, _react.default.createElement("img", {
      height: "400px",
      alt: author.name,
      src: (0, _contentfulHelpers.buildAvatarUrl)(author.picture),
      className: classes.authorImage
    }), _react.default.createElement(_typography.H3, {
      className: classes.authorName,
      gutterBottom: false
    }, _react.default.createElement("span", {
      className: classes.authorHeading
    }, "Author"), author.name, " ", _react.default.createElement("span", {
      className: classes.authorTitle
    }, author.title))), _react.default.createElement("div", {
      style: {
        textAlign: 'center'
      }
    }, _react.default.createElement(_components.Button, {
      className: classes.recipesButton,
      "data-id": "recipes-button",
      variant: "contained",
      color: "primary",
      size: "large",
      to: "recipes",
      routeParams: {
        cookbook: [cookbookId]
      }
    }, "Explore Recipes")))));
  });
};

exports.CookbookDetailsPage = CookbookDetailsPage;
CookbookDetailsPage.propTypes = {
  cookbookId: _propTypes.default.string.isRequired,
  classes: _propTypes.default.object.isRequired
};
exports.CookbookDetailsPage = CookbookDetailsPage = (0, _core.withStyles)(function (theme) {
  return {
    image: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    author: {
      display: 'flex',
      alignItems: 'center'
    },
    authorHeading: {
      fontSize: 12,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: theme.typography.h1.color,
      lineHeight: 1,
      marginBottom: theme.spacing(0.5),
      display: 'block'
    },
    authorTitle: {
      fontSize: '80%',
      color: theme.palette.text.secondary
    },
    authorImage: {
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      marginRight: theme.spacing(2)
    },
    recipesButton: {
      marginTop: theme.spacing(4)
    }
  };
})(CookbookDetailsPage);