import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InjectDelayedTogglable, AutoComplete, P } from '@wanderlost-sdk/components';
import { Subscribe } from '@symbiotic/green-state';
import { Grid, IconButton, withStyles } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Draggable } from '@wanderlost-sdk/cartographer/dist/drag-and-drop';

const styles = {
    form: { padding: '8px 0 0' },
    qtyInput: { textAlign: 'center' },
    weightInput: { textAlign: 'right' },
    progressIndicator: { opacity: 0.5 },
    errorMessage: { lineHeight: 1 /* So it doesnt change row height if its 2 lines */ },
};

/**
 * The primary motiviation for creating this nested form is that refs do not work properly if the element is conditionally rendered
 * Refs in the parent component dont work because the input/form are not rendered until the subscription to form state is ready
 * Cannot use autoFocus as its only respected on initial render per html5 spec which react respects https://stackoverflow.com/questions/28889826/set-focus-on-input-after-render
 */
class PackingListItemFormNested extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,

        visibleFields: PropTypes.array.isRequired,
        isEditMode: PropTypes.bool.isRequired,
        startEditMode: PropTypes.func.isRequired,
        endEditMode: PropTypes.func.isRequired,
        disableUnderline: PropTypes.bool.isRequired,

        values: PropTypes.object.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleSuggestedItemSelected: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        onKeyPressLastField: PropTypes.func,
        handleBlur: PropTypes.func.isRequired,
        handleFocus: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,

        getSuggestions: PropTypes.func.isRequired,

        classes: PropTypes.object.isRequired,
        columnClasses: PropTypes.object.isRequired,
        queueAutoSave: PropTypes.func.isRequired,
        fields: PropTypes.object.isRequired,
        canDrag: PropTypes.bool.isRequired,
    }

    renderItemSuggestion = (suggestion, { isHighlighted }) => {
        const search = this.props.values.name;
        const { name, category = '', location = '' } = suggestion;
        const index = name.toLowerCase().indexOf(search.toLowerCase());
        let moreInfo = '';

        if (category || location) {
            const separator = (category && location ? ' | ' : '');
            moreInfo = `(${category}${separator}${location})`;
        }

        const formattedName = index === -1 ? name : (
            <span>
                {name.substr(0, index)}
                <b>{name.substr(index, search.length)}</b>
                {name.substr(index + search.length)}
            </span>
        );

        return (
            <MenuItem selected={isHighlighted} component="div" dense>
                <P>
                    {formattedName}
                    &nbsp;&nbsp;
                    <small>{moreInfo.toLowerCase()}</small>
                </P>
            </MenuItem>
        );
    };

    render() {
        const {
            startEditMode,
            endEditMode,
            handleChange,
            handleSuggestedItemSelected,
            disableUnderline,
            handleSubmit,
            onKeyPressLastField,
            isEditMode,
            handleBlur,
            handleFocus,
            values,
            item,
            onDelete,
            classes,
            columnClasses,
            visibleFields,
            getSuggestions,
            fields,
            canDrag
        } = this.props;

        const hasError = Boolean(item.error);

        const handleWeightValueChange = (value) => {
            if (value === '') {
                handleChange({ name: 'weight', value: undefined });
                return;
            }
            const { unit } = values.weight || {};
            handleChange({ name: 'weight', value: { value: Number(value), unit: unit || 'lb' } });
        };

        const handleWeightUnitChange = (unit) => {
            const { value } = values.weight || {};
            handleChange({ name: 'weight', value: { value: value || 0, unit } });
        };

        return (
            <Draggable item={item} canDrag={canDrag}>
                <form onSubmit={handleSubmit} name="packing-list-item-form" data-item-id={item.itemId}>
                    <Grid container wrap="nowrap" spacing={4} alignItems="flex-start" className={classes.form} onMouseOver={startEditMode} onMouseOut={endEditMode} onFocus={startEditMode} onBlur={endEditMode}>
                        <Grid item className={columnClasses.deleteColumn}>
                            <IconButton style={{ visibility: isEditMode ? 'visible' : 'hidden', padding: '0.25em', paddingLeft: '0.35em' }} onClick={onDelete} tabIndex={1} name="delete-packing-list-item">
                                <DeleteIcon color="error" fontSize="small" />
                            </IconButton>
                        </Grid>
                        <Grid item sm={2}>
                            <AutoComplete
                                initialValue={values.name}
                                loadSuggestions={search => getSuggestions({ search, propName: 'name', currentItem: item })}
                                onChange={(e, { newValue }) => handleChange({ name: 'name', value: newValue })}
                                onSelect={newItem => handleSuggestedItemSelected({ ...newItem, itemId: item.itemId })}
                                onKeyDown={visibleFields.length > 1 ? undefined : onKeyPressLastField}
                                minLength={2}
                                debounceMS={200}
                                selectFirstSuggestionOnEnter={false}
                                focusInputOnSuggestionClick={true}
                                renderSuggestion={this.renderItemSuggestion}
                                renderInputComponent={({ ref, ...other }) => (
                                    <Input
                                        {...other}
                                        tabIndex={2}
                                        placeholder="Name"
                                        name="name"
                                        error={hasError}
                                        fullWidth
                                        disableUnderline={disableUnderline}
                                        inputRef={node => {
                                            if (node) {
                                                // this.inputElement must be set for focus on page load
                                                item.onFocus(() => node.focus());
                                                // ref(node) required if focusInputOnSuggestionClick true
                                                ref(node);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {fields.category.isVisible &&
                            <Grid item sm={2}>
                                <AutoComplete
                                    initialValue={values.category}
                                    loadSuggestions={search => getSuggestions({ search, propName: 'category', currentItem: item })}
                                    onChange={(e, { newValue }) => handleChange({ name: 'category', value: newValue })}
                                    minLength={1}
                                    debounceMS={200}
                                    selectFirstSuggestionOnEnter={false}
                                    focusInputOnSuggestionClick={true}
                                    renderInputComponent={({ ref, ...other }) => (
                                        <Input
                                            {...other}
                                            tabIndex={4}
                                            placeholder="Category"
                                            error={hasError}
                                            disableUnderline={disableUnderline}
                                            fullWidth
                                            name="category"
                                            inputRef={node => node && ref(node)} // required if focusInputOnSuggestionClick true
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        {fields.notes.isVisible &&
                            <Grid item sm={2} md={3} className={columnClasses.notesColumn}>
                                <Input
                                    tabIndex={5}
                                    placeholder="Notes"
                                    error={hasError}
                                    disableUnderline={disableUnderline}
                                    value={values.notes}
                                    multiline
                                    fullWidth
                                    name="notes"
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    onChange={e => handleChange({ name: 'notes', value: e.target.value })}
                                />
                            </Grid>
                        }
                        {fields.location.isVisible &&
                            <Grid item sm={2}>
                                <AutoComplete
                                    initialValue={values.location}
                                    loadSuggestions={search => getSuggestions({ search, propName: 'location', currentItem: item })}
                                    onChange={(e, { newValue }) => handleChange({ name: 'location', value: newValue })}
                                    minLength={1}
                                    debounceMS={200}
                                    selectFirstSuggestionOnEnter={false}
                                    focusInputOnSuggestionClick={true}
                                    renderInputComponent={({ ref, ...other }) => (
                                        <Input
                                            {...other}
                                            tabIndex={6}
                                            placeholder="Location"
                                            error={hasError}
                                            disableUnderline={disableUnderline}
                                            fullWidth
                                            name="location"
                                            inputRef={node => node && ref(node)} // required if focusInputOnSuggestionClick true
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        {fields.weight.isVisible &&
                            <Grid item sm={2} className={columnClasses.weightColumn}>
                                <Input
                                    tabIndex={7}
                                    style={{maxWidth: '50px'}}
                                    disableUnderline={disableUnderline}
                                    value={values.weight ? values.weight.value : ''}
                                    error={hasError}
                                    name="weightValue"
                                    type="number"
                                    fullWidth
                                    inputProps={{ min: 0, className: classes.weightInput }}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    onChange={e => handleWeightValueChange(e.target.value)}
                                    onKeyDown={onKeyPressLastField}
                                />
                                <Select
                                    tabIndex={8}
                                    style={{paddingLeft: '10px', maxWidth: '60px'}}
                                    disableUnderline={disableUnderline}
                                    value={values.weight ? values.weight.unit : ''}
                                    error={hasError}
                                    name="weightUnit"
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    onChange={e => handleWeightUnitChange(e.target.value)}
                                >
                                    <MenuItem key="lb" value="lb" dense={true}>lb</MenuItem>
                                    <MenuItem key="oz" value="oz" dense={true}>oz</MenuItem>
                                </Select>
                            </Grid>
                        }
                        {fields.qty.isVisible &&
                            <Grid item sm={1} className={columnClasses.qtyColumn}>
                                <Input
                                    tabIndex={3}
                                    disableUnderline={disableUnderline}
                                    value={values.qty}
                                    error={hasError}
                                    name="qty"
                                    type="number"
                                    fullWidth
                                    inputProps={{ min: 0, className: classes.qtyInput }}
                                    onChange={e => handleChange({ name: 'qty', value: e.target.value ? Number(e.target.value) : undefined })}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                />
                            </Grid>
                        }
                        {fields.isShared.isVisible &&
                            <Grid item sm={1} className={columnClasses.sharedColumn}>
                                <Checkbox
                                    name="isShared"
                                    title="Share with team"
                                    color="primary"
                                    checked={values.isShared}
                                    style={{ padding: 0, position: 'relative', top: '2px' }}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    onChange={e => handleChange({ name: 'isShared', value: e.target.checked })}
                                />
                            </Grid>
                        }
                        <button type="submit" style={{ display: 'none' }} tabIndex={-1}>Submit</button>
                    </Grid>
                </form>
            </Draggable>

        );
    }
}

PackingListItemFormNested = withStyles(styles)(PackingListItemFormNested);


export class PackingListItemForm extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        visibleFields: PropTypes.array.isRequired,
        handleSuggestedItemSelected: PropTypes.func,
        handleExitRow: PropTypes.func,
        onDirtyChange: PropTypes.func.isRequired,
        onFocusNext: PropTypes.func.isRequired,
        getSuggestions: PropTypes.func.isRequired,
        columnClasses: PropTypes.object.isRequired,
        queueAutoSave: PropTypes.func.isRequired,
        fields: PropTypes.object.isRequired,
        canDrag: PropTypes.bool.isRequired,
    };

    handleBlur = save => {
        // Timeout to give focusing on a field in this form a chance to cancel so we dont save if you are tabbing between fields in the same row
        this.props.queueAutoSave();
    };

    handleFocus = () => {
        this.props.queueAutoSave();
    }

    render() {
        const { columnClasses, item, handleSuggestedItemSelected, handleExitRow, onFocusNext, onDirtyChange, visibleFields, getSuggestions, queueAutoSave, fields, canDrag } = this.props;
        return (
            <InjectDelayedTogglable delayOnMS={0} delayOffMS={200} initialValue={false} key={item.itemId}>
                {({ isOn: isEditMode, on, off }) => (
                    <Subscribe to={() => item.formState}>
                        {({ values, handleChange }) => {
                            const disableUnderline = !isEditMode;
                            const handleChangeAndNotifyDirty = (...args) => {
                                handleChange(...args);
                                onDirtyChange();
                            };
                            const handleFocusNext = () => onFocusNext(item);
                            const submit = ({ focusNext = false } = {}) => {
                                focusNext && handleFocusNext();
                            };
                            const handleDeleteAndNotifyDirty = () => {
                                item.delete();
                                onDirtyChange();
                            };
                            const handleSubmit = e => {
                                e.preventDefault();
                                submit({ focusNext: true });
                            };
                            const onKeyPressLastField = handleExitRow ? e => {
                                const keyCode = e.which || e.keyCode;

                                // Enter, but not with shift since that should add new lines
                                if (keyCode === 13 && !e.shiftKey) {
                                    handleExitRow({ values, e });
                                // Tab
                                } else if (keyCode === 9) {
                                    handleExitRow({ values, e });
                                }
                            } : undefined;
                            const handleBlur = () => this.handleBlur(submit);
                            return (
                                <PackingListItemFormNested
                                    visibleFields={visibleFields}
                                    values={values}
                                    isEditMode={isEditMode}
                                    startEditMode={on}
                                    endEditMode={off}
                                    handleChange={handleChangeAndNotifyDirty}
                                    handleSuggestedItemSelected={handleSuggestedItemSelected}
                                    disableUnderline={disableUnderline}
                                    handleSubmit={handleSubmit}
                                    onKeyPressLastField={onKeyPressLastField}
                                    handleBlur={handleBlur}
                                    handleFocus={this.handleFocus}
                                    item={item}
                                    onDelete={handleDeleteAndNotifyDirty}
                                    getSuggestions={getSuggestions}
                                    columnClasses={columnClasses}
                                    queueAutoSave={queueAutoSave}
                                    fields={fields}
                                    canDrag={canDrag}
                                />
                            );
                        }}
                    </Subscribe>
                )}
            </InjectDelayedTogglable>
        );
    }
}
