"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripsPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Page = require("../Page");

var _AllTripsList = require("./AllTripsList");

var _AddTripButton = require("./AddTripButton");

var _components = require("../components");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Not ready for web to adopt
 * (because AllTripsList is not ready)
 */


var TripsPage = function TripsPage(_ref) {
  var _ref$showBanner = _ref.showBanner,
      showBanner = _ref$showBanner === void 0 ? true : _ref$showBanner;
  return _react.default.createElement(_Page.Page, {
    page: "trips-page"
  }, showBanner && _react.default.createElement(_components.Banner, {
    headingComponent: "Trips",
    imageURL: "/images/trip-listing-banner.jpg"
  }, _react.default.createElement(_AddTripButton.AddTripButton, null)), _react.default.createElement(_AllTripsList.AllTripsList, null));
};

exports.TripsPage = TripsPage;
TripsPage.propTypes = {
  showBanner: _propTypes.default.bool
};