"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripList = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _TripCard = require("./TripCard");

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles(theme) {
  return {
    container: {
      marginBottom: "".concat(theme.spacing(2), "px")
    }
  };
};

var TripList = function TripList(_ref) {
  var classes = _ref.classes,
      trips = _ref.trips,
      other = _objectWithoutProperties(_ref, ["classes", "trips"]);

  return _react.default.createElement(_Grid.default, _extends({
    container: true,
    spacing: 3,
    className: classes.container
  }, other), trips.map(function (trip) {
    return _react.default.createElement(_Grid.default, {
      item: true,
      xs: 12,
      md: 4,
      key: trip.tripId
    }, _react.default.createElement(_TripCard.TripCard, {
      trip: trip
    }));
  }));
};

exports.TripList = TripList;
TripList.propTypes = {
  trips: _propTypes.default.array.isRequired
};
exports.TripList = TripList = (0, _withStyles.default)(styles)(TripList);