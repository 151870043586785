import { once } from '@wanderlost-sdk/core';
import { State } from '@symbiotic/green-state';
import { WanderLostApiClient } from '@wanderlost-sdk/api-client';
import { QueryStrings } from '@wanderlost-sdk/components';

class RecipeStateLocalStore {
    key = 'RecipesState';
    get() {
        const value = window.localStorage.getItem(this.key);
        return value ? JSON.parse(value) : {};
    }
    set(newSettings = {}) {
        const existingSettings = this.get();
        window.localStorage.setItem(this.key, JSON.stringify({ ...existingSettings, ...newSettings }));
    }
}

export class WebRecipesState extends State {

    static inject = () => ([WanderLostApiClient, RecipeStateLocalStore, QueryStrings]);

    constructor(apiClient, localStore, queryStrings) {

        super({ error: null, recipes: null, featuredRecipes: null, cookbooks: null, meals: null, tags: null, pages: null, pageGroups: null, sections: null, filters: {} });

        const load = async () => {
            let entries;

            try {
                // if the current page include ?preview, we want to get unpublished content directly from contentful
                const preview = (queryStrings.has('preview'));

                const entry = await apiClient.entry();
                entries = await apiClient.get(entry.links.content, 'application/json', { preview });

            } catch(err) {
                console.error(err);
                this.setState({ error: 'Sorry, we are currently unable to access recipes. Please try again later.' });
                return;
            }

            const { recipes, cookbooks, meals, tags, pages, sections } = entries;

            const tagHeadings = [...new Set(tags.map(t => t.tagHeading))].sort();

            // set "favorite" attribute based on favorite recipeIds saved in local storage
            const { favoriteRecipeIds = [] } = localStore.get();
            for (const recipe of recipes) {
                recipe.favorite = favoriteRecipeIds.includes(recipe.recipeId);
            }

            // create groupings based on page.groupIds
            const pageGroups = pages.reduce(
                (byId, page) => {
                    for (const groupId of page.groupIds || []) {
                        byId[groupId] = (byId[groupId] || []).concat(page);
                    }
                    return byId;
                }, {}
            );

            this.setState({ error: undefined, ...buildRecipeViews(recipes), cookbooks, meals, tags, pages, pageGroups, tagHeadings, sections });
        };

        this.getRecipeById = recipeId => this.get().recipes.find(recipe => recipe.recipeId === recipeId);

        const applyRecipeFilters = ({ recipes, filters }) => {
            // filter on favorites first; if no favorites, ignore this filter so user doesn't see a blank page
            const favorites = recipes.filter(r => {
                return (!filters.flag || !filters.flag.includes('favorite') || r.favorite);
            });
            return (favorites.length ? favorites : recipes).filter(r =>
                // include recipe if its cookbook matches the cookbook filter
                (!filters.cookbook || !filters.cookbook.length || (r.cookbook && filters.cookbook.includes(r.cookbook.cookbookId)))
                // include receipe if one of its meals matches a selected meal filter
                && (!filters.meal || !filters.meal.length || (r.meal && r.meal.map(m => m.mealId).find(id => filters.meal.includes(id))))
                // include recipe if it has ALL of the selected tag filters
                && (!filters.tag || !filters.tag.length || (r.tag && filters.tag.every(id => r.tag.find(t => t.tagId === id))))
                // include only featured recipes if featured filter set
                && (!filters.flag || !filters.flag.includes('featured') || r.featured)
            );
        };

        // all of these need to be rebuilt whenever a recipe changes (e.g. favorites is toggled)
        const buildRecipeViews = (recipes, filters = {}) => {
            return {
                recipes,
                featuredRecipes: recipes.filter(r => r.featured),
                filteredRecipes: applyRecipeFilters({ recipes, filters })
            }
        };

        const loadOnce = once(load);

        const superSubscribe = this.subscribe;
        this.subscribe = sub => {
            loadOnce().then(() => superSubscribe(sub));
            return () => {};
        }

        this.isFiltered = (key, id) => {
            const filters = this.get().filters;
            return Boolean(filters[key] && filters[key].includes(id));
        };

        this.setFilter = (key, id, selected) => {
            const filters = this.get().filters;
            if (!filters[key]) {
                filters[key] = [];
            }
            if (selected) {
                filters[key].push(id);
            } else {
                filters[key] = filters[key].filter(v => v !== id);
            }
            this.setFilters(filters);
        };

        this.toggleFavorite = (recipe) => {
            recipe.favorite = !recipe.favorite;
            const recipes = this.get().recipes.map(r => r.recipeId === recipe.recipeId ? recipe : r);
            localStore.set({ favoriteRecipeIds: recipes.filter(r => r.favorite).map(r => r.recipeId)});
            this.setState({ ...buildRecipeViews(recipes, this.get().filters) });
        }

        this.setFilters = filters => {
            const { filteredRecipes } = buildRecipeViews(this.get().recipes, filters);
            this.setState({ filteredRecipes, filters });
        }

        this.load = load;
    }
}
