"use strict";

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RichTextStyles = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _mapValues = _interopRequireDefault(require("lodash/fp/mapValues"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var importantize = function importantize(styles) {
  return (0, _mapValues.default)(function (value) {
    return "".concat(value, " !important");
  })(styles);
};

var RichTextStyles = function RichTextStyles(_ref) {
  var children = _ref.children,
      classes = _ref.classes;
  return _react.default.createElement("div", {
    className: classes.container
  }, children);
};

exports.RichTextStyles = RichTextStyles;
RichTextStyles.propTypes = {
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired
};
exports.RichTextStyles = RichTextStyles = (0, _core.withStyles)(function (theme) {
  return {
    container: {
      '& ul, & ol': importantize(_objectSpread({}, theme.typography.body1, {
        padding: 0,
        margin: 0
      }, theme.typography.gutterBottom, {
        marginLeft: "".concat(theme.spacing(4), "px")
      })),
      '& li': importantize(_objectSpread({}, theme.typography.body1, {
        padding: 0,
        margin: "0 0 0 ".concat(theme.spacing(2), "px")
      })),
      '& p': importantize(_objectSpread({}, theme.typography.body1, {
        margin: 0
      }, theme.typography.gutterBottom)),
      '& h4': importantize(_objectSpread({}, theme.typography.h4, {
        margin: 0
      }, theme.typography.gutterBottom)),
      '& .ql-editor': importantize({
        padding: '24px 24px 18px'
      }),
      '@media print': {
        '& .quill-empty': importantize({
          display: 'none'
        }),
        '& .ql-container': importantize({
          border: 'none'
        }),
        '& .ql-toolbar': importantize({
          display: 'none'
        }),
        '& .ql-editor': importantize({
          padding: '0'
        })
      }
    }
  };
})(RichTextStyles);