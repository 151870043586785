"use strict";

var _classCallCheck = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SupportedFeatureChecker = void 0;

var _EditingState = require("../state/EditingState");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var SupportedFeatureChecker = function SupportedFeatureChecker(editingState) {
  _classCallCheck(this, SupportedFeatureChecker);

  var supportedFeatureTypes = ['trail', 'waypoint', 'text-annotation', 'route'];
  var supportedEditFeatureTypes = ['trail', 'waypoint', 'text-annotation'];

  this.isSupported = function (feature) {
    var type = getType(feature);
    var allowList = editingState.isEditing() ? supportedEditFeatureTypes : supportedFeatureTypes;
    return allowList.includes(type);
  };

  var getType = function getType(feature) {
    return feature.attributes ? feature.attributes.type : feature.get('type');
  };
};

exports.SupportedFeatureChecker = SupportedFeatureChecker;

_defineProperty(SupportedFeatureChecker, "inject", function () {
  return [_EditingState.EditingState];
});