"use strict";

var _defineProperty = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipeCard = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _typography = require("../typography");

var _urls = require("../urls");

var _greenState = require("@symbiotic/green-state");

var _classnames = _interopRequireDefault(require("classnames"));

var _AddToMealButton = require("./AddToMealButton");

var _RemoveFromMealButton = require("./RemoveFromMealButton");

var _contentfulHelpers = require("./contentfulHelpers");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    card: {
      '&:hover': {
        cursor: 'pointer',
        top: -5
      },
      position: 'relative',
      transition: 'top 250ms',
      top: 0
    },
    cardMini: {
      '& $recipeName': {
        fontSize: 18
      },
      '& $metadata': {
        fontSize: 14
      },
      '& $imageContainer': {
        '&:after': {
          top: '45%'
        }
      }
    },
    imageContainer: {
      display: 'block',
      position: 'relative',
      width: '100%',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      '&:after': {
        content: 'close-quote',
        position: 'absolute',
        top: '60%',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'linear-gradient(to bottom, rgba(27, 63, 55, 0) 0%, rgba(27, 63, 55, 1) 75%)'
      }
    },
    image: {
      width: '100%'
    },
    content: {
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      color: '#fff',
      padding: theme.spacing(2),
      right: 0,
      left: 0
    },
    recipeName: {
      fontSize: 22,
      fontWeight: 'bold',
      color: 'inherit',
      textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
      margin: 0,
      marginBottom: theme.spacing(0.5)
    },
    metadata: {
      display: 'flex',
      fontSize: '16px',
      justifyContent: 'space-between',
      textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
      color: 'inherit',
      margin: 0
    }
  };
};

var RecipeCardBase = function RecipeCardBase(_ref) {
  var recipe = _ref.recipe,
      classes = _ref.classes,
      _ref$mini = _ref.mini,
      mini = _ref$mini === void 0 ? false : _ref$mini,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? 300 : _ref$height,
      onSelect = _ref.onSelect,
      onRemove = _ref.onRemove;
  var navigator = (0, _greenState.useInstance)(_urls.WanderLostNavigator);

  var getImageUrl = function getImageUrl() {
    if (recipe.images) {
      return (0, _contentfulHelpers.buildCardImageUrl)(recipe.images[0]);
    }

    if (recipe.meal && recipe.meal[0].images) {
      return (0, _contentfulHelpers.buildCardImageUrl)(recipe.meal[0].images[0]);
    }

    return '/images/recipes-banner.png';
  };

  return _react.default.createElement("div", {
    className: (0, _classnames.default)(classes.card, _defineProperty({}, classes.cardMini, mini)),
    "data-type": "recipe-card",
    onClick: function onClick() {
      return navigator.recipeDetails({
        recipeId: recipe.recipeId
      }).go();
    }
  }, _react.default.createElement("div", {
    className: classes.imageContainer,
    style: {
      height: "".concat(height, "px"),
      backgroundImage: "url(".concat(getImageUrl(), ")")
    }
  }, onSelect && _react.default.createElement(_AddToMealButton.AddToMealButton, {
    onClick: function onClick() {
      return onSelect(recipe);
    }
  }), onRemove && _react.default.createElement(_RemoveFromMealButton.RemoveFromMealButton, {
    onClick: function onClick() {
      return onRemove(recipe);
    }
  })), _react.default.createElement("div", {
    className: classes.content
  }, _react.default.createElement(_typography.H2, {
    className: classes.recipeName
  }, recipe.name), _react.default.createElement(_typography.P, {
    className: classes.metadata
  }, "By ", recipe.author.name)));
};

RecipeCardBase.propTypes = {
  height: _propTypes.default.number,
  mini: _propTypes.default.bool,
  recipe: _propTypes.default.object.isRequired,
  toggleFavorite: _propTypes.default.func,
  onSelect: _propTypes.default.func,
  onRemove: _propTypes.default.func,
  classes: _propTypes.default.object
};
var RecipeCard = (0, _core.withStyles)(styles)(RecipeCardBase);
exports.RecipeCard = RecipeCard;