import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { P, SMALL, H3, WanderLostNavigator } from '@wanderlost-sdk/components';
import { formatMiles } from '@wanderlost-sdk/core';
import { RoutePreview } from './RoutePreview';
import { InjectToggle, useInstance } from '@symbiotic/green-state';

const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: theme.spacing(2),
        borderRadius: 8,
        margin: `0 -${theme.spacing(2)}px`,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        }
    },
    image: {
        flexShrink: 0,
        width: '200px',
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 8,
        overflow: 'hidden',
        marginRight: theme.spacing(4),
        lineHeight: 0,
    },
    placeholderImage: {
        backgroundImage: 'url(/images/no-content.png)',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        height: '200px',
        width: '100%',
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        image: {
            width: '300px'
        }
    },
    content: {
    },
    name: {
        marginBottom: theme.spacing(2)
    },
    notes: {
        whiteSpace: 'pre-line',
        lineHeight: 1.1,
        marginBottom: theme.spacing(2),
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
    },
    icon: {
        marginRight: theme.spacing(1)
    }
});

const ExpandableText = ({ className, text, maxLength }) => {
    const needsExpanding = text.length > maxLength;
    const shortText = text.slice(0, maxLength - 1);
    return (
        <InjectToggle>
            {({ isOn: isExpanded, on: expand, off: collapse }) => (
                <P className={className} onClick={isExpanded ? collapse : expand}>
                    <SMALL>
                        {isExpanded ? text : shortText}{' '}
                        {needsExpanding &&
                            <a
                                href={'#ignore'}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (isExpanded) {
                                        collapse();
                                    } else {
                                        expand();
                                    }
                                }}
                            >
                                {isExpanded ? 'see less' : 'see more'}
                            </a>
                        }
                    </SMALL>
                </P>
            )}
        </InjectToggle>
    );
};

ExpandableText.propTypes = {
    className: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    maxLength: PropTypes.number.isRequired,
};

export let RouteCard = ({ classes, route, tripId }) => {
    const navigator = useInstance(WanderLostNavigator);

    return (
        <div
            className={classes.root}
            data-type="route-card"
            onClick={() => {
                navigator.tripMap({ tripId, zoomToFolderId: route.extent ? route.routeId : undefined }).go();
            }}
        >
            <div className={classes.image}>
                {(!route.features || route.features.length === 0) &&
                    <div className={classes.placeholderImage} />
                }
                {route.features && route.features.length > 0 &&
                    <RoutePreview route={route} />
                }
            </div>
            <div className={classes.content}>
                <H3 className={classes.name}><span name="route-name">{route.name}</span></H3>
                {Boolean(route.notes) &&
                    <ExpandableText maxLength={50} text={route.notes} className={classes.notes} />
                }
                <P color="textSecondary" className={classes.status}>
                    {route.distanceMiles !== undefined ? <>{formatMiles(route.distanceMiles)} miles</> : 'Loading miles'}
                </P>
            </div>
        </div>
    );
};

RouteCard.propTypes = {
    classes: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    tripId: PropTypes.string.isRequired,
};

RouteCard = withStyles(styles)(RouteCard);
