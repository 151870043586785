import { SortablePackingListState } from './SortablePackingListState';
import { FormState } from '@wanderlost-sdk/components';
import pick from 'lodash/fp/pick';
import uuid from 'uuid/v4';
import { PackingListGrouper } from './PackingListGrouper';
import { PackingListFieldsBuilder } from './PackingListFieldsBuilder';

export class EditablePackingListState extends SortablePackingListState {
    constructor({ apiClient, packingList: initialPackingList, myItems, afterSave, afterDelete, createGearList, focusManager }) {

        const grouper = new PackingListGrouper();
        const fieldsBuilder = new PackingListFieldsBuilder();

        const updateList = updater => this.setState({ packingList: updater(this.get().packingList) });

        const groupObserver = itemId => {
            const groupBy = this.get().packingList.groupBy;
            const item = this.get().packingList.items.find(i => i.itemId === itemId);
            const oldGroupName = item.getField(groupBy);

            return {
                preserveGroups: () => {
                    const postGroups = this.get().packingList.getGroups();

                    // we don't need to preserve the unnamed group if there are other groups
                    if (!oldGroupName && postGroups.length > 1) {
                        return;
                    }

                    if (!postGroups.map(g => g.name).includes(oldGroupName)){
                        const newItemToSaveGroup = createNewItem();
                        if (groupBy) {
                            newItemToSaveGroup[groupBy] = oldGroupName;
                        }
                        addItem(newItemToSaveGroup);
                    }
                }
            }
        }

        const removeItem = itemId => {
            const { preserveGroups } = groupObserver(itemId);

            updateList(
                packingList => ({
                    ...packingList,
                    items: packingList.items.filter(i => i.itemId !== itemId)
                })
            );

            preserveGroups();
        };

        const buildFirstFieldItemFocusKey = item => `packing-list-item:${item.itemId}.firstField`;

        const addItem = (item, { autoFocus = true } = {}) => {
            const newItem = decorateItem(item);

            updateList(
                packingList => ({
                    ...packingList,
                    items: packingList.items.concat(newItem)
                })
            );

            if (autoFocus) {
                setAutoFocus(item);
            }

            return newItem;
        };

        const updateItem = ({ itemId, ...updates }) => {
            const item = this.get().packingList.items.find(i => i.itemId === itemId);

            const { preserveGroups } = groupObserver(itemId);

            item.formState.handleUpdates(updates);

            preserveGroups();

            this.setDirty();

            // the item was updated, but this state class (and its subscribers might not know)
            // so we need to trigger a state change (which could be the 2nd one if was the last
            // in a group or first thing to cause dirty)
            this.setState({ })
        };

        const decorateItem = item => {
            let decoratedItem = {
                ...item,
                getField: name => decoratedItem.formState ? decoratedItem.formState.field(name) : decoratedItem[name],
                formState: new FormState({ ...item }),
                delete: () => Promise.resolve(removeItem(item.itemId)),
                focusNext: () => this.focusNext(decoratedItem),
                onFocus: callback => focusManager.onFocus(buildFirstFieldItemFocusKey(item), callback),
            };
            return decoratedItem;
        };

        // Get the packing list with all of the values even if they are unsaved
        // Also filter out empty items
        const getCurrentPackingList = () => {
            const { packingList } = this.get();
            const newItems = packingList.items.map(i => {
                const item = {
                    itemId: String(i.itemId),
                    copiedFrom: i.copiedFrom,
                    ...pick(
                        ['qty', 'name', 'notes', 'location', 'category', 'weight', 'isShared'],
                        i.formState.get().values
                    )
                };
                item.qty = item.qty ? Number(item.qty) : 0;
                if (item.weight) {
                    if (item.weight.value && item.weight.unit) {
                        item.weight.value = Number(item.weight.value);
                    } else {
                        delete item.weight;
                    }
                }

                return item;
            }).filter(item => item.name || item.notes || item.location || item.category || (item.weight && item.weight.value && item.weight.unit));
            return {
                ...packingList,
                items: newItems
            };
        };

        const save = async () => {
            clearAutoSaveTimeout();
            const newPackingList = getCurrentPackingList();
            this.setState({ status: 'saving', isDirty: false });
            try {
                await apiClient.put(newPackingList, newPackingList);

                // update items from form state so we show the current values if the user prints
                // (mutate in place to avoid breaking decorator methods that rely on closures)
                for (const item of this.get().packingList.items) {
                    Object.assign(item, newPackingList.items.find(i => i.itemId === item.itemId));
                }

                this.setState({ status: 'saved', error: null });
                if (afterSave) {
                    afterSave(newPackingList);
                }
            } catch (error) {
                this.setState({ status: 'unsaved', error, isDirty: true });

                if (!error.displayMessage) {
                    throw error;
                }
            }
        };

        const createNewItem = (props) => decorateItem({
            itemId: uuid(),
            qty: 1,
            isShared: false,
            ...props
        });

        const groupBy = (groupBy, initWithNames) => {
            updateList(packingList => ({
                ...packingList,
                groupBy,
                items: !initWithNames ?
                    packingList.items :
                    initWithNames.map(name => createNewItem({ [groupBy]: name }))
            }));
            save();

            const groups = this.state.packingList.items.length ? getGroups() : [];

            // if no other groups, make sure unnamed group is not empty
            if (!groups.length || (groups.length === 1 && !groups[0].items.length)) {
                this.state.packingList.addItem();
            }

            if (groups[0]) {
                setAutoFocus(groups[0].items[0]);
            }

            this.setState({ isEmpty: false });
        };

        const addGroup = () => {
            const { groupBy } = this.get().packingList;

            const newGroupName = getNextNewGroupName();
            addItem({ ...createNewItem(), [groupBy]: newGroupName }, { autoFocus: false });
            this.setDirty();

            focusManager.focus(buildGroupFocusKey({ group: { name: newGroupName }, groupBy }));
        };

        const getNextNewGroupName = () => {
            const { items, groupBy } = this.get().packingList;
            const existingGroupNames = [...new Set(items.map(item => item.getField(groupBy) || ''))];

            const groupLabelPrefix = groupBy === 'category' ? 'New Category ' : 'New Location ';

            for (let i = 1;; i++) {
                const groupName = `${groupLabelPrefix}${i}`;
                if (!existingGroupNames.includes(groupName)) {
                    return groupName;
                }
            }
        };

        const buildGroupFocusKey = ({ group, groupBy }) => `packing-list:${groupBy || 'all'}:${group.name || 'empty'}`;

        const getGroups = () => {
            const { packingList } = this.get();
            const { groupBy } = packingList;

            let groups = grouper.group({ packingList, groupBy });

            return groups.map(group => {
                if (!('name' in group)) {
                    return group;
                }

                return {
                    ...group,
                    onFocus: callback => focusManager.onFocus(buildGroupFocusKey({ group, groupBy }), callback),
                    rename: newName => this.renameGroup({ group, newName }),
                    addItem: () => addItem({ ...createNewItem(), [groupBy]: group.name }),
                    focusNext: (item) => focusNextInGroup({ item, group, groups, groupBy }),

                    // don't allow deleting the empty group
                    // it will be immediately re-created when its items are moved to the empty group
                    delete: group.name ? () => this.deleteGroup({ name: group.name }) : null,
                };
            });
        };

        const setVisibleFields = visibleFields => {
            window.localStorage.setItem('packingList.visibleFields', visibleFields);
            updateList(packingList => ({ ...packingList, visibleFields }));
            save();
        };

        const print = () => {
            updateList(packingList => ({
                ...packingList,
                isPrintPreview: true
            }));

            window.onafterprint = () => {
                updateList(packingList => ({
                    ...packingList,
                    isPrintPreview: false
                }));
                window.onafterprint = null;
            };
        };

        const decoratePackingList = packingList => {
            return {
                ...packingList,
                items: packingList.items.map(decorateItem),
                addItem: () => addItem(createNewItem()),
                duplicate: async ({ name }) => {
                    const packingListToDuplicate = getCurrentPackingList();
                    const newGearList = {
                        name: name || `Copied from ${packingListToDuplicate.name}`,
                        items: packingListToDuplicate.items.map(item => ({
                            ...item,
                            itemId: uuid(),
                            copiedFrom: { packingListId: packingList.packingListId, itemId: item.itemId }
                        }))
                    };
                    return await createGearList(newGearList);
                },
                put: save,
                rename: async name => {
                    await apiClient.patch(packingList, { name });
                    updateList(packingList => ({ ...packingList, name }));
                },
                setGroupBy: groupBy,
                addGroup: addGroup,
                getGroups,
                setVisibleFields,
                getFields: () => {
                    const { packingList } = this.get();
                    return fieldsBuilder.build({ packingList });
                },
                delete: async () => {
                    await apiClient.delete(packingList);
                    if (afterDelete) {
                        afterDelete();
                    }
                },
                isPrintPreview: false,
                print
            };
        };

        const isItemEmpty = item => {
            const values = item.formState ? item.formState.get().values : item;
            return !values.name && !values.notes && !values.category && !values.location && (!values.weight || !values.weight.value);
        };

        // A list is empty if all its items are empty
        const isPackingListEmpty = packingList => !packingList.items.find(item => !isItemEmpty(item));

        if (!initialPackingList.visibleFields || !initialPackingList.visibleFields.length) {
            const savedVisibleFields = window.localStorage.getItem('packingList.visibleFields');
            initialPackingList.visibleFields = savedVisibleFields ? savedVisibleFields.split(',') : ['name'];
        }

        super({
            packingList: decoratePackingList(initialPackingList),
            isDirty: false,
            myItems,
            isEmpty: isPackingListEmpty(initialPackingList),
        });

        const setAutoFocus = item => {
            focusManager.focus(buildFirstFieldItemFocusKey(item));
        };

        this.focusNext = item => {
            // If its the last one, then add a new item
            const { packingList } = this.get();
            const { items } = packingList;
            const index = items.findIndex(i => i === item);
            const isLast = index === items.length - 1;

            if (isLast) {
                packingList.addItem();
            } else {
                setAutoFocus(items[index + 1]);
            }
        };

        let clearAutoSaveTimeout = () => {};

        this.queueAutoSave = () => {
            clearAutoSaveTimeout();
            const lastTimeout = setTimeout(() => {
                if (!this.state.isDirty) {
                    return;
                }

                save();
            }, 3000);
            clearAutoSaveTimeout = () => clearTimeout(lastTimeout);
        };

        // We might want to isolate this so it doesn't cause unnecessary re-renders
        this.setDirty = () => {
            if (this.state.status !== 'unsaved' || !this.state.isDirty) {
                this.setState({ status: 'unsaved', isDirty: true });
            }

            this.queueAutoSave();
        };

        // get matching items from my packing lists on all my trips for auto-complete
        this.getMatchingItems = (search) => {
            if (!myItems) {
                return [];
            }
            const items = myItems.filter(item => item.name && item.name.toLowerCase().includes(search.toLowerCase()));
            return items.sort((i1, i2) => (i1.name || '').localeCompare(i2.name || ''))
        };

        this.updateItem = updateItem;

        // This logic is duplicated in the API, would be good to remove duplication but implementation is different there
        // as we are deduplicating a large collection of items, where as here we want to preserve the existing items
        const deduplicateItems = ({ existingItems, itemsToDeduplicate }) => {
            const makeKey = item => `${item.name}~${item.category}~${item.location}`;

            const existingKeys = existingItems.reduce(
                (keys, item) => {
                    keys[makeKey(item)] = true;
                    return keys;
                },
                {}
            );

            return itemsToDeduplicate.filter(item => !existingKeys[makeKey(item)]);
        };

        this.copyList = copyFromPackingList => {
            const newItems = deduplicateItems({
                existingItems: this.state.packingList.items,
                itemsToDeduplicate: copyFromPackingList.items
            })
                .filter(item => Boolean(item.name)) // skip placeholder items
                .map(item => decorateItem({
                    ...item,
                    itemId: uuid(),
                    copiedFrom: { packingListId: copyFromPackingList.packingListId, itemId: item.itemId },
                }));

            updateList(
                packingList => {
                    return {
                        ...packingList,

                        // If the list is empty, then just replace it, otherwise we'll have an empty placeholder item above everything
                        items: isPackingListEmpty(packingList) ? newItems : packingList.items.concat(newItems)
                    };
                }
            );

            this.reSort();

            // TODO: Is this a bad pattern of trying to maintain this boolean vs just having isEmpty fn that is called on each render
            this.setState({ isEmpty: false });

            save();
        };

        this.clearEmpty = () => {
            this.setState({ isEmpty: false });
            if (this.state.packingList.items.length) {
                setAutoFocus(this.state.packingList.items[0]);
            }
        };

        const focusNextInGroup = ({ item, group, groups, groupBy }) => {
            const index = group.items.findIndex(i => i.itemId === item.itemId);
            const isLast = index === group.items.length - 1;

            if (isLast) {
                addItem({ ...createNewItem(), [groupBy]: group.name });
            } else {
                setAutoFocus(group.items[index + 1]);
            }
        };

        this.deleteGroup = ({ name }) => {
            const groupBy = this.get().packingList.groupBy;

            updateList(packingList => {
                return {
                    ...packingList,
                    items: packingList.items
                        // Move the items to the empty group
                        .reduce((all, item) => {
                            const itemGroupName = item.getField(groupBy) || '';

                            if (itemGroupName !== name) {
                                return all.concat(item);
                            }

                            item.formState.handleChange({ name: groupBy, value: '' });

                            // If the item is now empty, don't move it to the next category
                            if (isItemEmpty(item)) {
                                return all;
                            }

                            return all.concat({ ...item }); // make a copy so consumers can tell it changed
                        }, [])
                };
            });

            save();
        };

        this.renameGroup = ({ group, newName }) => {
            const groupBy = this.get().packingList.groupBy;

            grouper.onRename({ newName, oldName: group.name, groupBy });

            updateList(packingList => ({
                ...packingList,
                items: packingList.items.map(item => {
                    if (!group.items.find(i => i.itemId === item.itemId)) {
                        return item;
                    }

                    item.formState.handleChange({ name: groupBy, value: newName });
                    return { ...item }; // make a copy so consumers can tell it changed
                })
            }));

            save();
        };

        // if no other groups, make sure unnamed group is not empty
        const groups = this.state.packingList.items.length ? getGroups() : [];
        if (!groups.length || (groups.length === 1 && !groups[0].items.length)) {
            this.state.packingList.addItem();
        }
    }
}
