import React from 'react';
import PropTypes from 'prop-types';
import { H3, Section, SPAN, P } from '@wanderlost-sdk/components';
import { RoutePrint } from './RoutePrint';
import { formatMiles } from '@wanderlost-sdk/core';

export let RoutesListPrint = ({ routes, dayNumber, header, onRouteRendered }) => {
    return (
        <Section
            title="Routes"
            noDivider
        >
            {!routes.length &&
                <P>No routes on this day.</P>
            }

            {routes.map((route, index) => {
                // safari inserts a blank page if we don't suppress the page break on the final
                // route of the day (because we also request a page break before each day)
                const isLastRoute = (index === routes.length - 1);
                const pageBreakAfter = (isLastRoute ? '' : 'always');

                return (
                    <div key={route.routeId} style={{pageBreakAfter, pageBreakInside: 'avoid'}}
                    >
                        {index > 0 && header}

                        <H3>
                            <b>{route.name}</b>
                            <SPAN style={{marginLeft: '20px'}} color="textSecondary">{route.distanceMiles !== undefined ? <>{formatMiles(route.distanceMiles)} miles</> : ''}</SPAN>
                        </H3>

                        <RoutePrint route={route} onRendered={() => onRouteRendered({ dayNumber, route })} />
                    </div>
                );
            })}
        </Section>
    );
};

RoutesListPrint.propTypes = {
    routes: PropTypes.array.isRequired,
    dayNumber: PropTypes.number.isRequired,
    header: PropTypes.node.isRequired,
    onRouteRendered: PropTypes.func.isRequired
}
