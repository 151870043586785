"use strict";

var _objectWithoutProperties = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

var _objectSpread = require("/wanderlost-app/wanderlost-apps/web-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Card = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Card = _interopRequireDefault(require("@material-ui/core/Card"));

var _CardActionArea = _interopRequireDefault(require("@material-ui/core/CardActionArea"));

var _CardContent = _interopRequireDefault(require("@material-ui/core/CardContent"));

var _CardMedia = _interopRequireDefault(require("@material-ui/core/CardMedia"));

var _core = require("@material-ui/core");

var _typography = require("../../typography");

var _composeFunction = _interopRequireDefault(require("compose-function"));

var _greenState = require("@symbiotic/green-state");

var _urls = require("../../urls");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles(theme) {
  return _objectSpread({}, theme.wanderlost.Card, {
    image: {
      height: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '&:before': {
        content: "' '",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.55)',
        zIndex: 2
      }
    },
    titleContainer: {
      textAlign: 'center',
      position: 'relative',
      zIndex: 3
    },
    title: _objectSpread({}, theme.wanderlost.Card.title, {
      maxHeight: '2.9rem',
      overflow: 'hidden',
      fontWeight: 500
    }),
    description: _objectSpread({}, theme.wanderlost.Card.description),
    icon: {
      marginRight: theme.spacing(1)
    },
    '@media print': {
      container: {
        height: 'auto'
      },
      image: {
        backgroundImage: 'none'
      }
    }
  });
};

var Card = function Card(_ref) {
  var className = _ref.className,
      classes = _ref.classes,
      children = _ref.children,
      onClick = _ref.onClick,
      titleComponent = _ref.titleComponent,
      descriptionComponent = _ref.descriptionComponent,
      imageSrc = _ref.imageSrc,
      mediaComponent = _ref.mediaComponent,
      route = _ref.route,
      navigator = _ref.navigator,
      other = _objectWithoutProperties(_ref, ["className", "classes", "children", "onClick", "titleComponent", "descriptionComponent", "imageSrc", "mediaComponent", "route", "navigator"]);

  var content = _react.default.createElement(_react.default.Fragment, null, Boolean(imageSrc) && _react.default.createElement(_CardMedia.default, {
    className: classes.image,
    image: imageSrc
  }, _react.default.createElement("div", {
    className: classes.titleContainer
  }, _react.default.createElement(_typography.H2, {
    className: classes.title
  }, titleComponent), Boolean(descriptionComponent) && _react.default.createElement("div", {
    className: classes.description
  }, descriptionComponent))), Boolean(mediaComponent) && mediaComponent, _react.default.createElement(_CardContent.default, null, children));

  if (onClick || route) {
    if (route) {
      var to = route.to,
          routeParams = _objectWithoutProperties(route, ["to"]); // Not currently supporting route AND onClick cos we don't need it


      onClick = function onClick() {
        navigator[to](routeParams).go();
      };
    } // Currently always pass other as props to action area since that's the only use case


    content = _react.default.createElement(_CardActionArea.default, _extends({
      disableRipple: true,
      onClick: onClick
    }, other), content);
  }

  return _react.default.createElement(_Card.default, {
    className: className
  }, content);
};

exports.Card = Card;
Card.propTypes = {
  // Can't use instanceOf since the instance will be overriden via IOC
  // Can't use shape since we are expecting an object with a method with the name props.route.to
  navigator: _propTypes.default.object.isRequired,
  className: _propTypes.default.string,
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node,
  titleComponent: _propTypes.default.node.isRequired,
  descriptionComponent: _propTypes.default.node.isRequired,
  imageSrc: _propTypes.default.string,
  mediaComponent: _propTypes.default.node,
  onClick: _propTypes.default.func,
  route: _propTypes.default.object
};
exports.Card = Card = (0, _composeFunction.default)((0, _greenState.withDependencies)({
  navigator: _urls.WanderLostNavigator
}), (0, _core.withStyles)(styles))(Card);